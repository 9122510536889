import {JSONSchemaType} from 'ajv';

import {ScoreTag, ScoreTagSchema} from './form';
import {PdfReport, PdfReportSchema} from './pdfReport';
import {EmptyObjectT} from './shared';

// ----------------------- pollReport -------------------------

export const pollReportPath = '/rpc/pollReport';
export interface PollReportI {
  reportId?: number;
}
export interface PollReportO {
  signedGet: string;
  hasReport: boolean;
  reportDate: string;
  generating: boolean;
}

export const PollReportISchema: JSONSchemaType<PollReportI> = {
  type: 'object',
  additionalProperties: false,
  required: [],
  properties: {
    reportId: {type: 'integer', nullable: true},
  },
};

export const PollReportOSchema: JSONSchemaType<PollReportO> = {
  type: 'object',
  additionalProperties: false,
  required: ['signedGet', 'hasReport', 'reportDate', 'generating'],
  properties: {
    signedGet: {type: 'string'},
    hasReport: {type: 'boolean'},
    reportDate: {type: 'string'},
    generating: {type: 'boolean'},
  },
};

// ----------------------- generateReport -------------------------

export const generateReportPath = '/rpc/generateReport';
export interface GenerateReportI {
  reportId?: number;
  outputHtml?: boolean;
}
export interface GenerateReportO {
  signedGet: string;
  hasReport: boolean;
  reportDate: string;
  generating: boolean;
}

export const GenerateReportISchema: JSONSchemaType<GenerateReportI> = {
  type: 'object',
  additionalProperties: false,
  required: [],
  properties: {
    outputHtml: {type: 'boolean', nullable: true},
    reportId: {type: 'integer', nullable: true},
  },
};

export const GenerateReportOSchema: JSONSchemaType<GenerateReportO> = {
  type: 'object',
  additionalProperties: false,
  required: ['signedGet', 'hasReport', 'reportDate', 'generating'],
  properties: {
    signedGet: {type: 'string'},
    hasReport: {type: 'boolean'},
    reportDate: {type: 'string'},
    generating: {type: 'boolean'},
  },
};

// ----------------------- createReport -------------------------
export const createReportPath = '/rpc/createReport';
export interface CreateReportI {
  name: string;
  description?: string;
  report: PdfReport;
}
export interface CreateReportO {
  name: string;
  reportId: number;
}

export const CreateReportISchema: JSONSchemaType<CreateReportI> = {
  type: 'object',
  additionalProperties: false,
  required: ['name', 'report'],
  properties: {
    name: {type: 'string'},
    description: {type: 'string', nullable: true},
    report: PdfReportSchema,
  },
};
export const CreateReportOSchema: JSONSchemaType<CreateReportO> = {
  type: 'object',
  additionalProperties: false,
  required: ['name', 'reportId'],
  properties: {
    name: {type: 'string'},
    reportId: {type: 'integer'},
  },
};

// ----------------------- getReport -------------------------
export const getReportPath = '/rpc/getReport';
export interface GetReportI {
  reportId: number;
  accessFiles: boolean;
}
export interface GetReportO {
  reportId: number;
  name: string;
  description?: string;
  report: PdfReport;
  accessFiles: Array<AccessReportFile> | null;
}
export interface AccessReportFile {
  s3Key: string;
  hasFile: boolean;
  getSignedUrl: string;
  putSignedUrl: string;
}

export const AccessReportFileSchema: JSONSchemaType<AccessReportFile> = {
  type: 'object',
  additionalProperties: false,
  required: ['s3Key', 'hasFile', 'getSignedUrl', 'putSignedUrl'],
  properties: {
    s3Key: {type: 'string'},
    hasFile: {type: 'boolean'},
    getSignedUrl: {type: 'string'},
    putSignedUrl: {type: 'string'},
  },
};
export const GetReportISchema: JSONSchemaType<GetReportI> = {
  type: 'object',
  additionalProperties: false,
  required: ['reportId', 'accessFiles'],
  properties: {
    reportId: {type: 'integer'},
    accessFiles: {type: 'boolean'},
  },
};
export const GetReportOSchema: JSONSchemaType<GetReportO> = {
  type: 'object',
  additionalProperties: false,
  required: ['reportId', 'name', 'report', 'accessFiles'],
  properties: {
    reportId: {type: 'integer'},
    name: {type: 'string'},
    description: {type: 'string', nullable: true},
    report: PdfReportSchema,
    accessFiles: {type: 'array', items: AccessReportFileSchema},
  },
};

// ----------------------- getAllReports -------------------------
export const getAllReportsPath = '/rpc/getAllReports';

type GetAllReportsC = 'name' | 'description';

export interface GetAllReportsI {
  sort?: GetAllReportsC;
  direction?: 'asc' | 'desc';
  filters?: Array<[GetAllReportsC, string]>;
  skip?: number;
  take?: number;
}

export interface AllReportsItem {
  id: number;
  name: string;
  description?: string;
  packs: Array<{packName: string; packId: number}>;
}
export interface GetAllReportsO {
  entities: Array<AllReportsItem>;
  total: number;
}
export const GetAllReportsISchema: JSONSchemaType<GetAllReportsI> = {
  type: 'object',
  additionalProperties: false,
  properties: {
    sort: {type: 'string', enum: ['name', 'description'], nullable: true},
    direction: {type: 'string', enum: ['asc', 'desc'], nullable: true},
    filters: {
      type: 'array',
      items: {
        type: 'array',
        items: [
          {type: 'string', enum: ['name', 'description']},
          {type: 'string'},
        ],
        minItems: 2,
        maxItems: 2,
      },
      nullable: true,
    },
    skip: {type: 'integer', nullable: true},
    take: {type: 'integer', nullable: true},
  },
};

export const GetAllReportsOSchema: JSONSchemaType<GetAllReportsO> = {
  type: 'object',
  additionalProperties: false,
  required: ['entities', 'total'],
  properties: {
    total: {type: 'integer'},
    entities: {
      type: 'array',
      items: {
        type: 'object',
        additionalProperties: false,
        required: ['id', 'name', 'packs'],
        properties: {
          id: {type: 'integer'},
          name: {type: 'string'},
          description: {type: 'string', nullable: true},
          packs: {
            type: 'array',
            items: {
              type: 'object',
              additionalProperties: false,
              required: ['packName', 'packId'],
              properties: {
                packName: {type: 'string'},
                packId: {type: 'number'},
              },
            },
          },
        },
      },
    },
  },
};

// ----------------------- updateReport -------------------------
export const updateReportPath = '/rpc/updateReport';
export interface UpdateReportI {
  reportId: number;
  name?: string;
  description?: string;
  report?: PdfReport;
}
export type UpdateReportO = EmptyObjectT;

export const UpdateReportISchema: JSONSchemaType<UpdateReportI> = {
  type: 'object',
  additionalProperties: false,
  required: ['reportId'],
  properties: {
    reportId: {
      type: 'integer',
    },
    name: {type: 'string', nullable: true},
    description: {type: 'string', nullable: true},
    report: {...PdfReportSchema, nullable: true},
  },
};
export const UpdateReportOSchema: JSONSchemaType<UpdateReportO> = {
  type: 'object',
  additionalProperties: false,
  required: [],
};

// ----------------------- deleteReport -------------------------
export const deleteReportPath = '/rpc/deleteReport';
export interface DeleteReportI {
  reportId: number;
}
export type DeleteReportO = EmptyObjectT;

export const DeleteReportISchema: JSONSchemaType<DeleteReportI> = {
  type: 'object',
  additionalProperties: false,
  required: ['reportId'],
  properties: {
    reportId: {
      type: 'integer',
    },
  },
};
export const DeleteReportOSchema: JSONSchemaType<DeleteReportO> = {
  type: 'object',
  additionalProperties: false,
  required: [],
};

// ----------------------- duplicateReport -------------------------
export const duplicateReportPath = '/rpc/duplicateReport';
export interface DuplicateReportI {
  reportId: number;
}
export interface DuplicateReportO {
  duplicateId: number;
}

export const DuplicateReportISchema: JSONSchemaType<DuplicateReportI> = {
  type: 'object',
  additionalProperties: false,
  required: ['reportId'],
  properties: {
    reportId: {type: 'number'},
  },
};
export const DuplicateReportOSchema: JSONSchemaType<DuplicateReportO> = {
  type: 'object',
  additionalProperties: false,
  required: ['duplicateId'],
  properties: {
    duplicateId: {type: 'integer'},
  },
};

// ----------------------- reportContext -------------------------
export const reportContextPath = '/rpc/reportContext';
export interface ReportContextI {}
export interface ReportContextO {
  prelimTags: Array<ScoreTag>;
}
export const ReportContextISchema: JSONSchemaType<ReportContextI> = {
  type: 'object',
  additionalProperties: false,
  required: [],
  properties: {},
};
export const ReportContextOSchema: JSONSchemaType<ReportContextO> = {
  type: 'object',
  additionalProperties: false,
  required: ['prelimTags'],
  properties: {
    prelimTags: {type: 'array', items: ScoreTagSchema},
  },
};
