import {FC, useCallback, useEffect, useRef} from 'react';
import {useBeforeUnload, useBlocker} from 'react-router-dom';

export const PROMPT_DEFAULT_MESSAGE = 'Êtes-vous sûr de vouloir quitter ?';

export const usePrompt = (
  /** whether the prompt is enabled */
  when: boolean,
  /** message to be displayed to user */
  message: string = PROMPT_DEFAULT_MESSAGE,
  /** whether to also display prompt on `window.onbeforeunload`. Defaults to true. */
  options: {beforeUnload?: boolean} = {},
) => {
  const {beforeUnload = true} = options;

  const blocker = useBlocker(
    useCallback(
      () => (when ? !window.confirm(message) : false),
      [when, message],
    ),
  );
  const prevState = useRef(blocker.state);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      blocker.reset();
    }
    prevState.current = blocker.state;
  }, [blocker]);

  useBeforeUnload(
    useCallback(
      (event) => {
        if (beforeUnload) {
          event.preventDefault();
          event.returnValue = message;
        }
      },
      [message, beforeUnload],
    ),
    {capture: true},
  );
};

export const Prompt: FC<{
  when: boolean;
  message?: string;
  beforeUnload?: boolean;
}> = (props) => {
  usePrompt(props.when, props.message, {beforeUnload: props.beforeUnload});
  return null;
};
