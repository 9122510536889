import {DeleteOutlined, EditOutlined, FilePdfOutlined} from '@ant-design/icons';
import {
  BarChartOutlined,
  DotChartOutlined,
  FileTextOutlined,
  PictureOutlined,
  RadarChartOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  InputNumber,
  Row,
  Space,
  Typography,
  UploadFile,
} from 'antd';
import {
  AccessReportFile,
  assertUnreachable,
  reportLabel,
  ReportPageType,
  ReportPageV2,
  StaticReportPage,
} from 'holo-api';
import {ReactNode, useState} from 'react';

import {AnyReportPage} from './AnyReportPage';
import {ActifSelectorOptions} from './charts/shared/reportInterfaces';
import {TemplateTag} from './charts/shared/RichTextEditor';

const UpDownButtons = ({
  index,
  nbrPages,
  onReorderPage,
}: {
  index: number;
  nbrPages: number;
  onReorderPage: ({
    fromIndex,
    toIndex,
  }: {
    fromIndex: number;
    toIndex: number;
  }) => void;
}) => {
  const [toIndex, setToIndex] = useState<number>(index);

  const moveItem = () => {
    onReorderPage({fromIndex: index, toIndex});
  };

  const onInputChange = (value: number | null) => {
    if (value) {
      setToIndex(value - 1); // YES -1 ZERO based API
    }
  };

  return (
    <Space>
      <Typography.Text type="secondary">Ordre :</Typography.Text>
      <InputNumber
        min={1}
        max={nbrPages}
        value={index + 1} // YES +1 ZERO based API
        wheel={false}
        onChange={onInputChange}
        onBlur={moveItem}
      />
    </Space>
  );
};

const ChartContainer = ({
  index,
  page,
  label,
  icon,
  children,
  upDownButtons,
  onDeletePage,
}: {
  index: number;
  page: ReportPageV2;
  label: string;
  icon: ReactNode;
  children?: ReactNode;
  upDownButtons: JSX.Element;
  onDeletePage: (page: ReportPageV2, removeIndex: number) => void;
}) => {
  return (
    <>
      <Row justify={'space-between'} align="middle">
        <Col>
          <Typography.Title level={3} style={{marginTop: 0}}>
            <Space>
              {icon}
              {label}
            </Space>
          </Typography.Title>
        </Col>
        <Col>{upDownButtons}</Col>
      </Row>
      <Row gutter={8} justify="center">
        <Col style={{padding: 8, maxWidth: '1024px'}} span={24}>
          {children}
        </Col>
      </Row>
      <Row justify="end">
        <Col>
          <Button.Group>
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => onDeletePage(page, index)}
            ></Button>
          </Button.Group>
        </Col>
      </Row>
    </>
  );
};

export const chartIcon = (chartType: ReportPageType): ReactNode => {
  switch (chartType) {
    case ReportPageType.forceFaiblesse:
      return <UnorderedListOutlined />;
    case ReportPageType.poidsActif:
      return <BarChartOutlined rotate={90} />;
    case ReportPageType.radar:
      return <RadarChartOutlined />;
    case ReportPageType.competitivite:
      return <BarChartOutlined />;
    case ReportPageType.weightedCapital:
      return <BarChartOutlined />;
    case ReportPageType.formulaTree:
      return <DotChartOutlined />;
    case ReportPageType.cover:
      return <PictureOutlined />;
    case ReportPageType.indexDurable:
      return <FileTextOutlined />;
    case ReportPageType.tagRadar:
      return <RadarChartOutlined />;
    case ReportPageType.equlibreForce:
      return <BarChartOutlined />;
    case ReportPageType.tagDetails:
      return <FileTextOutlined />;
    case ReportPageType.conseilConsultant:
      return <FileTextOutlined />;
    case ReportPageType.static:
      return <FilePdfOutlined />;
    case ReportPageType.richtext:
      return <EditOutlined />;
    case ReportPageType.chart:
      throw new Error(`ReportPageType.chart is deprecated, no icon available`);
    default:
      return assertUnreachable(chartType); // we can return null if we have no icon
  }
};

export const AllReportPages = ({
  allPages,
  onReorderPage,
  onDeletePage,
  onSetPage,
  accessFiles,
  onUploadStaticPage,
  actifSelectorOptions,
  templateTags,
}: {
  allPages: Array<ReportPageV2>;
  onReorderPage: ({
    fromIndex,
    toIndex,
  }: {
    fromIndex: number;
    toIndex: number;
  }) => void;
  onDeletePage: (page: ReportPageV2, removeIndex: number) => void;
  onSetPage: (page: ReportPageV2, index: number) => void;
  accessFiles: Array<AccessReportFile> | null;
  onUploadStaticPage: (
    uploadFile: UploadFile<unknown>,
    page: StaticReportPage,
  ) => void;
  actifSelectorOptions: ActifSelectorOptions;
  templateTags: Array<TemplateTag>;
}) => {
  return (
    <>
      {allPages.map((page, index, allItems) => {
        const upDownButtons = (
          <UpDownButtons
            index={index}
            nbrPages={allItems.length}
            onReorderPage={onReorderPage}
          ></UpDownButtons>
        );
        return (
          <div
            key={index}
            style={{
              boxShadow: '0 0 8px 1px #ddd',
              marginTop: '12px',
              marginBottom: '12px',
              borderRadius: '10px',
              padding: '8px',
            }}
          >
            <ChartContainer
              index={index}
              icon={chartIcon(page.pageType)}
              label={reportLabel(page.pageType)}
              onDeletePage={onDeletePage}
              page={page}
              upDownButtons={upDownButtons}
            >
              <AnyReportPage
                index={index}
                page={page}
                setPage={(newPage: ReportPageV2) => {
                  void onSetPage(newPage, index);
                }}
                accessFiles={accessFiles}
                onUploadStaticPage={onUploadStaticPage}
                actifSelectorOptions={actifSelectorOptions}
                templateTags={templateTags}
              ></AnyReportPage>
            </ChartContainer>
          </div>
        );
      })}
    </>
  );
};
