import {APIClass} from 'aws-amplify';
import {
  ClosePackI,
  ClosePackO,
  closePackPath,
  CompanySchemaType,
  CreateCompanyConsultantUserO,
  CreateCompanyConsultantUserSchemaType,
  CreateCompanyGroupSchemaType,
  CreateCompanyGroupUserResponse,
  CreateCompanyResponseSchemaType,
  CreateCompanySchemaType,
  CreateSingleCompanyGroupUserSchemaType,
  CreateUserSchemaType,
  GetCompanyGroups0,
  GetCompanyUsersO,
  GetConsultantLinkedCompanies0,
  GetCurrentPackI,
  GetCurrentPackO,
  getCurrentPackPath,
  GetGlobalAverages,
  GetUsersInGroup,
  OpenPackI,
  OpenPackO,
  openPackPath,
  UpdateCompanyGroupSchemaType,
  UpdateCompanyRequestSchemaType,
  UpdateCompanyResponseSchemaType,
} from 'holo-api';

import {CompaniesTableDataType} from '../Companies';
import {CompanyGroupsTableDataType} from '../CompanyGroups';
import {apiDel, apiGet, apiPost, apiPut} from '../util/api';
import {
  PaginationQuery,
  queryParameters,
  SearchQuery,
  SortQuery,
} from './queryParameters';

export const getCurrentPack = ({api}: {api: APIClass}) =>
  apiPost<GetCurrentPackO, GetCurrentPackI>(api, getCurrentPackPath, {
    body: {},
  });

export const companyGetSingular = (api: APIClass, companyId: number) =>
  apiGet<CompanySchemaType>(api, `/company/${companyId}`);

export const deleteCompanyGroupUser = ({
  api,
  companyGroupId,
  userId,
}: {
  api: APIClass;
  companyGroupId: number;
  userId: number;
}) => apiDel(api, `/companyGroupUser/${companyGroupId}/${userId}`);

export const deleteCompanyConsultantUser = ({
  api,
  companyId,
  userId,
}: {
  api: APIClass;
  companyId: number;
  userId: number;
}) => apiDel(api, `/companyConsultantUser/${companyId}/${userId}`);

export const getGlobalAverages = ({api}: {api: APIClass}) =>
  apiGet<GetGlobalAverages>(api, '/company/globalAverages');

export const openPack = ({api}: {api: APIClass}) =>
  apiPost<OpenPackO, OpenPackI>(api, openPackPath, {
    body: {},
  });

export const closePack = ({api}: {api: APIClass}) =>
  apiPost<ClosePackO, ClosePackI>(api, closePackPath, {
    body: {},
  });

export const createCompany = ({
  api,
  company,
  admin,
}: {
  api: APIClass;
  company: CreateCompanySchemaType;
  admin: CreateUserSchemaType;
}) =>
  apiPut<
    CreateCompanyResponseSchemaType,
    {
      company: CreateCompanySchemaType;
      admin: CreateUserSchemaType;
    }
  >(api, '/company', {
    body: {company, admin},
  });

export const updateCompany = ({
  api,
  companyId,
  input,
}: {
  api: APIClass;
  companyId: number;
  input: UpdateCompanyRequestSchemaType;
}) =>
  apiPost<UpdateCompanyResponseSchemaType, UpdateCompanyRequestSchemaType>(
    api,
    `/company/${companyId}`,
    {
      body: input,
    },
  );

export const updateCompanyGroup = ({
  api,
  companyGroupId,
  input,
}: {
  api: APIClass;
  companyGroupId: number;
  input: UpdateCompanyGroupSchemaType;
}) =>
  apiPost<number, UpdateCompanyGroupSchemaType>(
    api,
    `/companyGroup/${companyGroupId}`,
    {body: input},
  );

export const createCompanyGroup = ({
  api,
  input,
}: {
  api: APIClass;
  input: CreateCompanyGroupSchemaType;
}) =>
  apiPut<number, CreateCompanyGroupSchemaType>(api, '/companyGroup', {
    body: input,
  });

export const createCompanyGroupUser = ({
  api,
  input,
}: {
  api: APIClass;
  input: CreateSingleCompanyGroupUserSchemaType;
}) =>
  apiPut<
    CreateCompanyGroupUserResponse,
    CreateSingleCompanyGroupUserSchemaType
  >(api, '/companyGroupUser', {
    body: input,
  });

export const createCompanyConsultantUser = ({
  api,
  input,
}: {
  api: APIClass;
  input: CreateCompanyConsultantUserSchemaType;
}) =>
  apiPut<CreateCompanyConsultantUserO, CreateCompanyConsultantUserSchemaType>(
    api,
    '/companyConsultantUser',
    {
      body: input,
    },
  );

export const getUsersInGroup = ({
  api,
  companyGroupId,
}: {
  api: APIClass;
  companyGroupId: number;
}) => apiGet<GetUsersInGroup>(api, `/companyGroup/${companyGroupId}/users`);

export const getConsultantExport = ({api}: {api: APIClass}) =>
  apiGet<string>(api, '/company/consultantExport');

export const getConsultantLinkedCompanies = ({
  api,
  userId,
}: {
  api: APIClass;
  userId: number;
}) =>
  apiGet<GetConsultantLinkedCompanies0>(
    api,
    `/companyConsultantUser/${userId}/companies`,
  );

export const getCompanyUsers = ({
  api,
  companyId,
  includeAdmins,
}: {
  api: APIClass;
  companyId: number;
  includeAdmins: boolean;
}) =>
  apiGet<GetCompanyUsersO>(
    api,
    `/company/${companyId}/users${includeAdmins ? '?includeAdmins=true' : ''}`,
  );

export const getCompanyConsultants = ({
  api,
  companyId,
}: {
  api: APIClass;
  companyId: number;
}) => apiGet<GetCompanyUsersO>(api, `/company/${companyId}/consultants`);

export const getCompanyGroups = ({
  api,
  companyId,
}: {
  api: APIClass;
  companyId: number;
}) => apiGet<GetCompanyGroups0>(api, `/company/${companyId}/groups`, {});

export const getPluralCompanies = ({
  api,
  sort,
  searchText,
  pagination,
}: {
  api: APIClass;
  sort?: SortQuery;
  searchText?: SearchQuery;
  pagination: PaginationQuery;
}) => {
  const params = queryParameters({sort, searchText, pagination});
  return apiGet<{
    entities: CompaniesTableDataType[];
    total: number;
  }>(api, '/companies?' + params.toString());
};

export const getPluralCompanyGroups = ({
  api,
  sort,
  searchText,
  pagination,
}: {
  api: APIClass;
  sort?: SortQuery;
  searchText?: SearchQuery;
  pagination: PaginationQuery;
}) => {
  const params = queryParameters({sort, searchText, pagination});
  return apiGet<{
    entities: CompanyGroupsTableDataType[];
    total: number;
  }>(api, '/companyGroups?' + params.toString());
};
