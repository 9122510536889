import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';
import {useDebounceFn} from 'ahooks';
import {Button, Col, Form, FormInstance, Input, Row} from 'antd';
import {TagRadarChartReportPage, TagRadarGroup} from 'holo-api';
import {useEffect} from 'react';

import {mergeNamePath} from '../../../util/form';
import {ColorPicker} from '../../ui/creator/ColorPicker';
import {RichTextEditor, TemplateTag} from './shared/RichTextEditor';
import {TagList} from './shared/TagList';

//const percentFormatter = (value: number | undefined) => `${value}%`;

const Group = ({
  form,
  groupIndex,
  remove,
}: {
  form: FormInstance<TagRadarFormType>;
  groupIndex: number;
  remove: (index: number | number[]) => void;
}) => {
  const color = Form.useWatch(
    mergeNamePath('groups', groupIndex, 'groupColor'),
    {form},
  );

  return (
    <div
      style={{
        background: `linear-gradient(to bottom, transparent, #dddddd40), linear-gradient(135deg, #${
          color ?? '000000'
        }20, transparent)`,
        padding: '16px',
        margin: '8px',
        borderRadius: '16px',
      }}
    >
      <Form.Item label="Titre du Groupe" name={[groupIndex, 'groupTitle']}>
        <Input />
      </Form.Item>
      <Form.Item label="Couleur du Groupe" name={[groupIndex, 'groupColor']}>
        <ColorPicker />
      </Form.Item>

      <TagList subNamePath={[groupIndex]}></TagList>

      <Row justify="end">
        <Col>
          <Button.Group>
            <Button
              onClick={() => remove(groupIndex)}
              danger
              icon={<DeleteOutlined />}
            >
              Enlever Groupe
            </Button>
          </Button.Group>
        </Col>
      </Row>
    </div>
  );
};

interface TagRadarFormType {
  title: string;
  subTitle?: string;
  chartTitle?: string;
  //radarZoomPercent: number;
  groups: Array<TagRadarGroup>;
}

export const TagRadarChart = ({
  index,
  chartPage,
  setChartPage,
  templateTags,
}: {
  index: number;
  chartPage: TagRadarChartReportPage;
  setChartPage: (page: TagRadarChartReportPage) => void;
  templateTags: Array<TemplateTag>;
}) => {
  const [form] = Form.useForm<TagRadarFormType>();

  useEffect(() => {
    form.setFieldsValue({
      title: chartPage.title,
      subTitle: chartPage.subTitle,
      chartTitle: chartPage.chartTitle,
      groups: chartPage.groups,
    });
  }, [form, chartPage]);

  const {run: onChangedPage} = useDebounceFn(
    async (page: TagRadarChartReportPage) => {
      try {
        const validated = await form.validateFields();
        const newChart = {...chartPage};
        newChart.title = validated.title;
        newChart.subTitle = validated.subTitle;
        newChart.chartTitle = validated.chartTitle;
        newChart.groups = validated.groups;
        setChartPage(newChart);
      } catch (e) {
        console.warn('onChangedPage', page);
        console.error('Not a valid form');
      }
    },
    {wait: 500},
  );

  const onRichTextChanged = (richText: string) => {
    const newChart = {...chartPage};
    newChart.richExplain = richText;
    setChartPage(newChart);
  };

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div>
        <Form
          form={form}
          onValuesChange={(_changedValues, values) => onChangedPage(values)}
        >
          <Form.Item label="Titre" name="title">
            <Input />
          </Form.Item>
          <Form.Item label="Sous Titre" name="subTitle">
            <Input />
          </Form.Item>
          <Form.Item label="Titre du radar" name="chartTitle">
            <Input />
          </Form.Item>

          <Form.Item label="Groupes">
            <Form.List name="groups">
              {(fields, {add, remove}, {errors}) => {
                return (
                  <>
                    {fields.map((field) => (
                      <Group
                        form={form}
                        groupIndex={field.name}
                        key={field.key}
                        remove={remove}
                      ></Group>
                    ))}
                    <Button
                      type="dashed"
                      onClick={() =>
                        add({
                          groupTitle: 'Titre du groupe',
                          groupColor: '000000',
                          tags: [],
                        })
                      }
                      style={{width: '60%'}}
                      icon={<PlusOutlined />}
                    >
                      Ajouter un groupe de tags
                    </Button>

                    <Form.ErrorList errors={errors} />
                  </>
                );
              }}
            </Form.List>
          </Form.Item>
        </Form>
      </div>
      <div style={{flex: 1}}>
        <RichTextEditor
          uniqueId={`page_${index}`}
          richText={chartPage.richExplain}
          setRichText={onRichTextChanged}
          templateTags={templateTags}
        ></RichTextEditor>
      </div>
    </div>
  );
};
