import {DownloadOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import {Button, Col, Collapse, Row, Space, theme, Typography} from 'antd';
import {FC} from 'react';
import {Link} from 'react-router-dom';

import {panelStyle} from './App';
import cartography from './images/cartography.png';

const ulStyle = {
  listStyleType: 'disc',
  marginLeft: 32,
};

export const Introduction: FC = () => {
  const {token} = theme.useToken();

  // if (process.env.REACT_APP_APP_NAME !== `Holo Diag`) {
  //   return <div />;
  // }

  return (
    <Space direction="vertical" style={{width: '100%'}} size="middle">
      <Typography.Title
        style={{textAlign: 'center', color: token.colorPrimary}}
      >
        UTILISATION DE LA PLATEFORME
      </Typography.Title>
      <Collapse
        bordered={false}
        style={{background: token.colorBgContainer}}
        items={[
          {
            label: (
              <span style={{fontWeight: 'bold'}}>MÉTHODOLOGIE D'ANALYSE</span>
            ),
            style: panelStyle,
            key: 1,
            children: (
              <Row wrap={false} align="middle" gutter={32}>
                <Col>
                  <Typography>
                    <Typography.Paragraph>
                      <Typography.Text>
                        La plateforme Holo Diag propose un ensemble de plus de{' '}
                      </Typography.Text>
                      <Typography.Text strong>
                        270 questions, comprenant des choix de réponse à cocher
                        ou à renseigner,
                      </Typography.Text>
                      <Typography.Text>
                        {' '}
                        ainsi que des justifications à fournir pour certaines
                        questions, avec la possibilité d'ajouter des
                        commentaires pour étayer les preuves nécessaires.
                      </Typography.Text>
                    </Typography.Paragraph>
                    <Typography.Paragraph strong>
                      Les preuves citées dans les commentaires se révéleront
                      particulièrement précieuses en cas de nécessité d'établir
                      un rapport de durabilité ultérieur. Par souci de
                      confidentialité et de responsabilité, nous avons pris la
                      décision de limiter la possibilité pour les utilisateurs
                      de télécharger des documents à partir de notre plateforme
                      "serverless". C'est pourquoi les preuves doivent être
                      rédigées dans les commentaires.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                      <Typography.Text strong>
                        Chaque actif est renseigné par au moins deux managers de
                        service et leurs équipes respectives.
                      </Typography.Text>
                      <Typography.Text>
                        {' '}
                        Ces personnes sont désignées en début de parcours dans
                        le tableau de bord et en haut de chaque actif à évaluer,
                        incluant les responsables des ressources humaines, des
                        finances, de la direction générale, du marketing, du
                        commerce, de la production, de la recherche et
                        développement, de la direction juridique, de la
                        direction des systèmes d'information...
                      </Typography.Text>
                    </Typography.Paragraph>
                  </Typography>
                  <Typography>
                    <Typography.Paragraph>
                      La synthèse relative à l’analyse des 10 actifs immatériels
                      est organisée de la manière suivante :
                    </Typography.Paragraph>
                    <ul style={{...ulStyle, lineHeight: '2em'}}>
                      <li>
                        Trois types de capitaux immatériels :
                        <ul style={ulStyle}>
                          <li style={{fontWeight: 'bold'}}>Humain</li>
                          <li style={{fontWeight: 'bold'}}>Relationnel</li>
                          <li style={{fontWeight: 'bold'}}>Structurel</li>
                        </ul>
                      </li>
                      <li>
                        Trois axes stratégiques sont considérés :
                        <ul style={ulStyle}>
                          <li style={{fontWeight: 'bold'}}>
                            L’attractivité de la marque employeur
                          </li>
                          <li style={{fontWeight: 'bold'}}>
                            La compétitivité et l'innovation
                          </li>
                          <li style={{fontWeight: 'bold'}}>L'engagement RSE</li>
                        </ul>
                      </li>
                    </ul>
                  </Typography>
                  <Typography>
                    <Typography.Paragraph>
                      Si vous avez choisi une analyse partielle plutôt que
                      complète de votre capital immatériel, seuls les éléments
                      traités au cours de ce processus d’analyse seront inclus
                      dans la synthèse finale.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                      <Typography.Text>
                        Les réponses quantitatives et qualitatives sont traitées
                        par nos algorithmes et pondérées en fonction de
                        l'environnement sectoriel spécifique de l'entreprise.
                      </Typography.Text>
                      <Typography.Text strong>
                        {' '}
                        C’est pourquoi il est crucial que votre référent
                        immatériel interne ou le consultant qui vous accompagne
                        renseigne l’onglet « Informations préalables » au début
                        de l’analyse.
                      </Typography.Text>
                    </Typography.Paragraph>
                  </Typography>
                </Col>
                <Col>
                  <img src={cartography} style={{height: '800px'}} />
                </Col>
              </Row>
            ),
          },
          {
            label: (
              <span style={{fontWeight: 'bold'}}>
                DÉROULEMENT DU DIAGNOSTIC
              </span>
            ),
            style: panelStyle,
            key: 3,
            children: (
              <>
                <Space direction="vertical" size={16}>
                  <Typography>
                    1- Commencez par renseigner les informations relatives à
                    l'entreprise auditée dans le formulaire "Infos préalables".
                  </Typography>
                  <Typography>
                    2- Utilisez l'onglet "Groupes" pour établir vos catégories
                    de contributeurs/utilisateurs et définir les autorisations
                    associées : aucune, lecture, écriture.
                  </Typography>
                  <Typography>
                    3- À partir de l'onglet "Utilisateurs", établissez les
                    divers contributeurs/utilisateurs de la plateforme, puis
                    assignez-leur le(s) groupe(s) auxquels ils auront accès.
                    Veillez à renseigner les adresses e-mail des utilisateurs
                    pour qu'ils puissent accéder aux formulaires qui leur sont
                    attribués.{' '}
                    <ul style={ulStyle}>
                      <li style={{marginTop: 16}}>
                        « L’administrateur de l’entreprise » désigne le
                        dirigeant qui a accès à l’ensemble des actifs, tout
                        comme le « Consultant ».
                      </li>
                      <li>
                        « L'utilisateur » peut accéder aux groupes qui lui sont
                        assignés, selon les autorisations qui y sont définies
                        (écriture, lecture…)
                      </li>
                    </ul>
                  </Typography>
                  <Typography>
                    4- Nous vous recommandons de prévoir environ une
                    demi-journée pour chaque actif évalué. La sauvegarde de vos
                    réponses est effectuée en temps réel, vous permettant de
                    finaliser l'administration d'un actif en plusieurs fois si
                    nécessaire.
                  </Typography>
                  <Typography>
                    5- La barre de progression indique le nombre de questions
                    restant à renseigner pour finaliser le diagnostic.
                  </Typography>
                  <Typography>
                    6- Le consultant accompagnant l'administration du diagnostic
                    peut ajouter ses commentaires au sein des questions
                    individuelles. De plus, il dispose d'un bloc de commentaires
                    en bas du formulaire de chaque actif, ainsi que d'un accès
                    aux diapositives du plan d'action pour rédiger ses
                    conclusions, qui seront incluses dans le rapport final.
                  </Typography>
                  <Typography>
                    7- La génération du rapport sera effective une fois que 100%
                    des réponses auront été renseignées, quel que soit le type
                    d'analyse sélectionné (analyse intégrale ou partielle du
                    capital immatériel).
                  </Typography>
                  <Typography>
                    Une section AIDE est disponible pour certaines questions.
                    Vous les repérerez facilement grâce au sigle{' '}
                    <QuestionCircleOutlined />. <br />
                    Pour vous aider à identifier les éléments de preuves
                    correspondant à une thématique ESG/RSE, vous trouverez un
                    sigle <DownloadOutlined style={{color: 'blue'}} /> à droite
                    de certaines questions qui vous indiquera les exemples de
                    preuves attendues dans un rapport de durabilité.
                  </Typography>
                  <Typography>
                    En cas de difficulté lors du parcours d'évaluation, veuillez
                    contacter le support par e-mail à l'adresse{' '}
                    <a href="mailto:diag@holo-way.com">diag@holo-way.com</a>
                  </Typography>
                </Space>
              </>
            ),
          },
        ]}
      />
      <Link to="/dashboard">
        <Button type="primary">Accéder à l'évaluation</Button>
      </Link>
    </Space>
  );
};
