import {APIClass} from 'aws-amplify';
import {
  AnswerMatrixGetPlural,
  answerMatrixRoutes,
  AnswerMatrixT,
  CreateAnswerMatrixT,
  UpdateAnswerMatrixT,
} from 'holo-api';

import {apiGet, apiPost, apiPut} from '../util/api';

export const answerMatrixGetPlural = async (api: APIClass) =>
  apiGet<AnswerMatrixGetPlural>(api, answerMatrixRoutes.pRoute);

export const answerMatrixCreate = async (
  api: APIClass,
  input: CreateAnswerMatrixT,
) =>
  apiPut<AnswerMatrixT, CreateAnswerMatrixT>(api, answerMatrixRoutes.sRoute, {
    body: input,
  });

export const answerMatrixUpdate = async (
  api: APIClass,
  matrixId: number,
  input: UpdateAnswerMatrixT,
) =>
  apiPost<AnswerMatrixT, UpdateAnswerMatrixT>(
    api,
    `${answerMatrixRoutes.sRoute}/${matrixId}`,
    {body: input},
  );
