import './index.css';

import {ConfigProvider} from 'antd';
import fr from 'antd/lib/locale/fr_FR';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';

import {ActionPlan} from './ActionPlan';
import {App} from './App';
import {Companies} from './Companies';
import {CompanyGroups} from './CompanyGroups';
import {AuthProvider} from './components/auth/Context';
import {AuthGuard} from './components/auth/Guards';
import {Login} from './components/auth/Login';
import {Logout} from './components/auth/Logout';
import {Register} from './components/auth/Register';
import {Layout} from './components/ui/Layout';
import {Settings} from './components/ui/Settings';
import {Consultants} from './Consultants';
import {Creator} from './Creator';
import {Dashboard} from './Dashboard';
import {Introduction} from './Introduction';
import {Matrices} from './Matrices';
import {Modele} from './Modele';
import {Modeles} from './Modeles';
import {Packs} from './Packs';
import reportWebVitals from './reportWebVitals';
import {SharedFiles} from './SharedFiles';
import {Users} from './Users';
import {Viewer} from './Viewer';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Layout />}>
      <Route path="/" element={<AuthGuard guarded={<App />} />} />

      <Route
        path="/register"
        element={<AuthGuard guarded={<Register />} invert />}
      />
      <Route
        path="/login/:encodedCredentials?"
        element={<AuthGuard guarded={<Login />} invert />}
      />
      <Route path="/logout" element={<Logout />} />
      <Route
        path="/introduction"
        element={<AuthGuard guarded={<Introduction />} />}
      />
      <Route
        path="/dashboard"
        element={<AuthGuard guarded={<Dashboard />} />}
      />
      <Route
        path="/action-plan"
        element={<AuthGuard guarded={<ActionPlan />} />}
      />
      <Route path="/creator" element={<AuthGuard guarded={<Creator />} />} />
      <Route path="/packs" element={<AuthGuard guarded={<Packs />} />} />
      <Route path="/viewer" element={<AuthGuard guarded={<Viewer />} />} />
      <Route path="/matrices" element={<AuthGuard guarded={<Matrices />} />} />
      <Route path="/modeles" element={<AuthGuard guarded={<Modeles />} />} />
      <Route path="/modele/:id" element={<AuthGuard guarded={<Modele />} />} />
      <Route
        path="/consultants"
        element={<AuthGuard guarded={<Consultants />} />}
      />
      <Route
        path="/companies"
        element={<AuthGuard guarded={<Companies />} />}
      />
      <Route
        path="/company-groups"
        element={<AuthGuard guarded={<CompanyGroups />} />}
      />
      <Route path="/users" element={<AuthGuard guarded={<Users />} />} />
      <Route path="/settings" element={<AuthGuard guarded={<Settings />} />} />
      <Route
        path="/shared-files"
        element={<AuthGuard guarded={<SharedFiles />} />}
      />
    </Route>,
  ),
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <ConfigProvider
      locale={fr}
      form={{
        validateMessages: {required: 'Le champ est obligatoire'},
      }}
      theme={{
        token: {
          colorPrimary: '#6C63FF',
          colorInfo: '#6C63FF',
          colorFillSecondary: '#6C63FF40',
          colorSplit: '#6C63FF40',
        },
        components: {
          Popover: {
            titleMinWidth: 400,
          },
          Spin: {
            dotSizeLG: 64,
          },
        },
      }}
    >
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </ConfigProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
