import {JSONSchemaType} from 'ajv';

export interface CreatePackSchemaType {
  initialVersionId?: number;
  minorVersion: number;
  patchVersion: number;
  name: string;
  description?: string;
  formIds: Array<number>;
}

export const CreatePackSchema: JSONSchemaType<CreatePackSchemaType> = {
  type: 'object',
  additionalProperties: false,
  required: ['minorVersion', 'patchVersion', 'name', 'formIds'],
  properties: {
    initialVersionId: {type: 'number', nullable: true},
    minorVersion: {type: 'number'},
    patchVersion: {type: 'number'},
    name: {type: 'string'},
    description: {type: 'string', nullable: true},
    formIds: {
      type: 'array',
      items: {
        type: 'number',
      },
    },
  },
} as const;

export interface UpdatePackSchemaType {
  name?: string;
  description?: string;
  formIds?: Array<number>;
}

export const UpdatePackSchema: JSONSchemaType<UpdatePackSchemaType> = {
  type: 'object',
  additionalProperties: false,
  properties: {
    name: {type: 'string', nullable: true},
    description: {type: 'string', nullable: true},
    formIds: {
      type: 'array',
      nullable: true,
      items: {
        type: 'number',
      },
    },
  },
} as const;

export interface GetSingularPack {
  id: number;
  initialVersionId?: number;
  minorVersion: number;
  patchVersion: number;
  name: string;
  description?: string;
  createdAt: string;
  updatedAt: string;
}

export interface GetPackAvailablecompaniesElem {
  id: number;
  name: string;
}
export interface GetPackAvailablecompaniesO {
  entities: Array<GetPackAvailablecompaniesElem>;
  total: number;
}

// ---------------------------------  updatePackReport ------------------------------
export const updatePackReportPath = '/rpc/updatePackReport';

export interface UpdatePackReportI {
  packId: number;
  reportId: number | null | undefined;
}

export const UpdatePackReportISchema: JSONSchemaType<UpdatePackReportI> = {
  type: 'object',
  additionalProperties: false,
  required: ['packId'],
  properties: {
    packId: {type: 'integer'},
    reportId: {type: 'integer', nullable: true},
  },
};

export interface UpdatePackReportO {
  packId: number;
  name: string;
  reportId: number | null | undefined;
  updatedAt: string;
}

export const UpdatePackReportOSchema: JSONSchemaType<UpdatePackReportO> = {
  type: 'object',
  additionalProperties: false,
  required: ['packId', 'name', 'updatedAt'],
  properties: {
    packId: {type: 'integer'},
    name: {type: 'string'},
    reportId: {type: 'integer', nullable: true},
    updatedAt: {type: 'string'},
  },
};

// ---------------------------------  packGetPlural ------------------------------
export const packGetPluralPath = '/packs';

export interface PackGetPluralQ {
  sort?: string;
  direction?: string;
  filters?: string;
  skip?: string;
  take?: string;
}

export const PackGetPluralQSchema: JSONSchemaType<PackGetPluralQ> = {
  type: 'object',
  additionalProperties: false,
  properties: {
    sort: {type: 'string', nullable: true},
    direction: {type: 'string', nullable: true},
    filters: {type: 'string', nullable: true},
    skip: {type: 'string', nullable: true},
    take: {type: 'string', nullable: true},
  },
};

export interface PackGetPluralItem {
  id: number;
  name: string;
  description: string;
  minorVersion: number;
  patchVersion: number;
  _count: {
    forms: number;
    companies: number;
    activeCompanies: number;
  };
  report?: {
    id: number;
    name: string;
  };
}
export const PackGetPluralItemSchema: JSONSchemaType<PackGetPluralItem> = {
  type: 'object',
  additionalProperties: false,
  required: [
    'id',
    'name',
    'description',
    'minorVersion',
    'patchVersion',
    '_count',
  ],
  properties: {
    id: {type: 'integer'},
    name: {type: 'string'},
    description: {type: 'string'},
    minorVersion: {type: 'integer'},
    patchVersion: {type: 'integer'},
    _count: {
      type: 'object',
      additionalProperties: false,
      required: ['forms', 'companies', 'activeCompanies'],
      properties: {
        forms: {type: 'integer'},
        companies: {type: 'integer'},
        activeCompanies: {type: 'integer'},
      },
    },
    report: {
      type: 'object',
      additionalProperties: false,
      required: ['id', 'name'],
      properties: {
        id: {type: 'integer'},
        name: {type: 'string'},
      },
      nullable: true,
    },
  },
};

export interface PackGetPluralO {
  entities: Array<PackGetPluralItem>;
  total: number;
}
export const PackGetPluralOSchema: JSONSchemaType<PackGetPluralO> = {
  type: 'object',
  additionalProperties: false,
  required: ['entities', 'total'],
  properties: {
    entities: {type: 'array', items: PackGetPluralItemSchema},
    total: {type: 'integer'},
  },
};
