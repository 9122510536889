import {Form, message} from 'antd';
import {GetReportO} from 'holo-api';
import {useEffect, useState} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';

import {getReport} from './client/pdf';
import {ModeleFormData, ReportEditor} from './components/report/ReportEditor';
import {useApi} from './util/auth';

export const Modele = () => {
  const api = useApi();
  const params = useParams();
  const [getReportO, setGetReportO] = useState<GetReportO | undefined>();
  const [queryParams] = useSearchParams();
  const [modeleForm] = Form.useForm<ModeleFormData>();

  const reportIdParam = params['id'];
  const previewParam = queryParams.get('preview');

  useEffect(() => {
    if (api) {
      void (async () => {
        if (reportIdParam) {
          const reportId = parseInt(reportIdParam);
          try {
            const report = await getReport(api, {
              reportId,
              accessFiles: true,
            });
            setGetReportO(report);
            modeleForm.setFieldsValue({
              name: report.name,
              description: report.description,
            });
          } catch (err: unknown) {
            console.error('getReport err', err);
            void message.error(
              `Le rapport ${reportId} n'a pas pu être récupéré`,
            );
          }
        } else {
          setGetReportO(undefined);
        }
      })();
    }
  }, [api, reportIdParam, modeleForm]);

  return (
    <>
      {getReportO && (
        <ReportEditor
          reportO={getReportO}
          setReportO={setGetReportO}
          preview={previewParam !== null}
          modeleForm={modeleForm}
        ></ReportEditor>
      )}
    </>
  );
};
