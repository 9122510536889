import {LoadingOutlined} from '@ant-design/icons';
import {Col, Collapse, Divider, Input, Row, Space, Typography} from 'antd';
import {Formula} from 'holo-api';
import {FC} from 'react';

import {FormulaAverages, useViewerStore} from '../../../stores/viewer';

interface ViewerTemplateSummaryProps {
  loading: boolean;
  formFormula: Formula | undefined;
  templateInstances: {name: string; id: number; weight: number}[];
  transferredFormulas: Formula[];
  formulaAverages: {
    [key: string]: FormulaAverages;
  };
}

interface ViewerTemplateSummaryItemProps {
  formula: Formula;
  templateInstances: {name: string; id: number; weight: number}[];
  formulaAverages: {
    [key: string]: FormulaAverages;
  };
}

const ViewerTemplateSummaryItem: FC<ViewerTemplateSummaryItemProps> = ({
  formula,
  templateInstances,
  formulaAverages,
}) => {
  const formulaValues = useViewerStore((state) => state.formulaValues);
  return (
    <div>
      <Row justify="space-between" align="middle" style={{marginBottom: 8}}>
        <Col>
          <Typography>{formula?.title}</Typography>
        </Col>
        <Col>
          <Input
            readOnly
            value={
              formulaAverages[formula.uuid]
                ? isNaN(formulaAverages[formula.uuid].average)
                  ? 'Résulat indisponible'
                  : formulaAverages[formula.uuid].average
                : 'Résulat indisponible'
            }
          />
        </Col>
      </Row>
      <Collapse
        items={[
          {
            label: `Notes pour la formule "${formula.title}"`,
            children: (
              <>
                {templateInstances.map((templateInstance, instanceIndex) => {
                  const currentTemplateInstanceResult = formulaValues[
                    templateInstance.id
                  ]
                    ? formulaValues[templateInstance.id][formula.uuid].result
                    : 'Résultat indisponible';
                  return (
                    <Row
                      key={instanceIndex}
                      align="middle"
                      justify="space-between"
                      style={{marginBottom: 8}}
                    >
                      <Col>
                        <Typography.Text>
                          {templateInstance.name}
                        </Typography.Text>
                        <Typography.Text type="secondary">
                          {` (${templateInstance.weight}%)`}
                        </Typography.Text>
                      </Col>
                      <Col>
                        <Input
                          readOnly
                          value={
                            currentTemplateInstanceResult ??
                            'Résulat indisponible'
                          }
                        ></Input>
                      </Col>
                    </Row>
                  );
                })}
              </>
            ),
          },
        ]}
      />
    </div>
  );
};

export const ViewerTemplateSummary: FC<ViewerTemplateSummaryProps> = ({
  loading,
  formFormula,
  templateInstances,
  transferredFormulas,
  formulaAverages,
}) => {
  return (
    <>
      {loading && <LoadingOutlined />}
      {!loading && (
        <Row justify="center">
          <Col
            style={{
              maxWidth: 1000,
              padding: 24,
              borderLeft: '1px solid #eee',
              borderRight: '1px solid #eee',
            }}
          >
            <Space direction="vertical" size="middle">
              {formFormula && (
                <>
                  <ViewerTemplateSummaryItem
                    formula={formFormula}
                    templateInstances={templateInstances}
                    formulaAverages={formulaAverages}
                  />
                  <Divider />
                </>
              )}
              {transferredFormulas.map((formula, index) => {
                return (
                  <ViewerTemplateSummaryItem
                    key={index}
                    formula={formula}
                    templateInstances={templateInstances}
                    formulaAverages={formulaAverages}
                  />
                );
              })}
            </Space>
          </Col>
        </Row>
      )}
    </>
  );
};
