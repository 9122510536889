import {JSONSchemaType} from 'ajv';

export interface UserAnswerSelect {
  answer: string[] | string;
  willNotAnswer?: boolean;
  comment?: string;
  commentDate?: string;
  consultantComment?: string;
  consultantCommentDate?: string;
  date?: string; // FIXME: date should not be optional but old data needs to be fixed first (#412)
}
export const UserAnswerSelectSchema: JSONSchemaType<UserAnswerSelect> = {
  type: 'object',
  additionalProperties: false,
  required: ['answer'],
  properties: {
    answer: {
      oneOf: [{type: 'string'}, {type: 'array', items: {type: 'string'}}],
    },
    comment: {type: 'string', nullable: true},
    commentDate: {type: 'string', nullable: true},
    willNotAnswer: {type: 'boolean', nullable: true},
    date: {type: 'string', nullable: true},
    consultantComment: {type: 'string', nullable: true},
    consultantCommentDate: {type: 'string', nullable: true},
  },
};

export interface UserAnswerText {
  text: string;
  comment?: string;
  commentDate?: string;
  willNotAnswer?: boolean;
  consultantComment?: string;
  consultantCommentDate?: string;
  date?: string; // FIXME: date should not be optional but old data needs to be fixed first (#412)
}
export const UserAnswerTextSchema: JSONSchemaType<UserAnswerText> = {
  type: 'object',
  additionalProperties: false,
  required: ['text'],
  properties: {
    text: {type: 'string'},
    comment: {type: 'string', nullable: true},
    commentDate: {type: 'string', nullable: true},
    willNotAnswer: {type: 'boolean', nullable: true},
    date: {type: 'string', nullable: true},
    consultantComment: {type: 'string', nullable: true},
    consultantCommentDate: {type: 'string', nullable: true},
  },
};

export interface ChapterValidated {
  validated: boolean;
  date?: string;
}
export const ChapterValidatedSchema: JSONSchemaType<ChapterValidated> = {
  type: 'object',
  required: ['validated'],
  properties: {
    validated: {type: 'boolean'},
    date: {type: 'string', nullable: true},
  },
};

export interface UserAnswerNoAnswer {
  date?: string; // FIXME: date should not be optional but old data needs to be fixed first (#412)
  willNotAnswer: boolean;
  text?: string;
  answer?: string;
  comment?: string;
  commentDate?: string;
  consultantComment?: string;
  consultantCommentDate?: string;
}
const UserAnswerNoAnswerSchema: JSONSchemaType<UserAnswerNoAnswer> = {
  type: 'object',
  required: ['willNotAnswer'],
  properties: {
    willNotAnswer: {type: 'boolean'},
    date: {type: 'string', nullable: true},
    text: {type: 'string', nullable: true},
    answer: {
      type: 'string',
      nullable: true,
    },
    consultantComment: {type: 'string', nullable: true},
    consultantCommentDate: {type: 'string', nullable: true},
    comment: {type: 'string', nullable: true},
    commentDate: {type: 'string', nullable: true},
  },
};

interface UserAnswerConsultantComment {
  consultantCommentDate?: string;
  consultantComment: string;
  comment?: string;
  commentDate?: string;
}
const UserAnswerConsultantCommentSchema: JSONSchemaType<UserAnswerConsultantComment> =
  {
    type: 'object',
    required: ['consultantComment'],
    properties: {
      consultantComment: {type: 'string'},
      consultantCommentDate: {type: 'string', nullable: true},
      comment: {type: 'string', nullable: true},
      commentDate: {type: 'string', nullable: true},
    },
  };

interface UserAnswerComment {
  commentDate?: string;
  comment: string;
  consultantCommentDate?: string;
  consultantComment?: string;
}
const UserAnswerCommentSchema: JSONSchemaType<UserAnswerComment> = {
  type: 'object',
  required: ['comment'],
  properties: {
    comment: {type: 'string'},
    commentDate: {type: 'string', nullable: true},
    consultantComment: {type: 'string', nullable: true},
    consultantCommentDate: {type: 'string', nullable: true},
  },
};

export type SingleUserAnswer =
  | UserAnswerSelect
  | UserAnswerText
  | ChapterValidated
  | UserAnswerNoAnswer
  | UserAnswerComment
  | UserAnswerConsultantComment;

export const SingleUserAnswerSchema: JSONSchemaType<SingleUserAnswer> = {
  anyOf: [
    UserAnswerSelectSchema,
    UserAnswerTextSchema,
    ChapterValidatedSchema,
    UserAnswerNoAnswerSchema,
    UserAnswerCommentSchema,
    UserAnswerConsultantCommentSchema,
  ],
};

export type UserAnswers = {
  [key: string]: SingleUserAnswer | undefined;
  develop?: UserAnswerConsultantComment;
  preserve?: UserAnswerConsultantComment;
};
export const UserAnswersSchema: JSONSchemaType<UserAnswers> = {
  type: 'object',
  propertyNames: {
    type: 'string',
  },
  additionalProperties: SingleUserAnswerSchema,
};

export type FormAnswer = {
  id: number;
  userId: number;
  contents: UserAnswers;
};

export const FormAnswerSchema: JSONSchemaType<FormAnswer> = {
  type: 'object',
  additionalProperties: false,
  required: ['id', 'userId', 'contents'],
  properties: {
    id: {type: 'integer'},
    userId: {type: 'integer'},
    contents: UserAnswersSchema,
  },
};

export type FormAnswers = Array<FormAnswer>;

export const FormAnswersSchema: JSONSchemaType<FormAnswers> = {
  type: 'array',
  items: FormAnswerSchema,
};

export const isAnswerToSelectQuestion = (
  answer: SingleUserAnswer,
): answer is UserAnswerSelect => Object.hasOwn(answer, 'answer');

export const isAnswerToTextQuestion = (
  answer: SingleUserAnswer,
): answer is UserAnswerText => Object.hasOwn(answer, 'text');

export const isAnswerToChapterValidated = (
  answer: SingleUserAnswer,
): answer is ChapterValidated => Object.hasOwn(answer, 'validated');

export const isAnswerNoAnswer = (
  answer: SingleUserAnswer,
): answer is UserAnswerNoAnswer => Object.hasOwn(answer, 'willNotAnswer');

// --------------------------------------------------

export interface CreateAnswerSchemaType {
  formId: number;
  userId: number;
  contents: UserAnswers;
}

export const CreateAnswerSchema: JSONSchemaType<CreateAnswerSchemaType> = {
  type: 'object',
  additionalProperties: false,
  required: ['formId', 'userId', 'contents'],
  properties: {
    formId: {type: 'number'},
    userId: {type: 'number'},
    contents: UserAnswersSchema,
  },
} as const;

export interface UpdateAnswerSchemaType {
  contents: UserAnswers;
}

export const UpdateAnswerSchema: JSONSchemaType<UpdateAnswerSchemaType> = {
  type: 'object',
  additionalProperties: false,
  required: ['contents'],
  properties: {
    contents: UserAnswersSchema,
  },
} as const;

// ----------------------------------- API ----------------------------

export interface GetSingularAnswerO {
  id: number;
  formId: number;
  userId: number;
  companyId?: number;
  contents: UserAnswers;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
}

export const GetSingularAnswerOSchema: JSONSchemaType<GetSingularAnswerO> = {
  type: 'object',
  additionalProperties: false,
  required: ['id', 'formId', 'userId', 'contents', 'createdAt', 'updatedAt'],
  properties: {
    id: {type: 'integer'},
    formId: {type: 'integer'},
    userId: {type: 'integer'},
    companyId: {type: 'integer', nullable: true},
    contents: UserAnswersSchema,
    createdAt: {type: 'string'},
    updatedAt: {type: 'string'},
    deletedAt: {type: 'string', nullable: true},
  },
};

export interface GetPluralAnswerO {
  entities: Array<GetSingularAnswerO>;
  total: number;
}

export const GetPluralAnswerOSchema: JSONSchemaType<GetPluralAnswerO> = {
  type: 'object',
  additionalProperties: false,
  required: ['entities', 'total'],
  properties: {
    entities: {type: 'array', items: GetSingularAnswerOSchema},
    total: {type: 'integer'},
  },
};
