import {SearchOutlined} from '@ant-design/icons';
import {Button, Input, Space} from 'antd';
import {FilterDropdownProps} from 'antd/es/table/interface';
export const FilterSeachText = ({
  selectedKeys,
  setSelectedKeys,
  confirm,
  close,
  setSearchText,
  dataIndex,
}: FilterDropdownProps & {
  setSearchText: React.Dispatch<
    React.SetStateAction<{[key: string]: string} | undefined>
  >;
  dataIndex: string;
}) => {
  const handleSearch = (selectedKeys: string[]) => {
    confirm();
    if (selectedKeys.length === 0 || selectedKeys[0] === '') {
      setSearchText((prev) => {
        if (!prev) {
          return undefined;
        }
        delete prev[dataIndex];
        if (Object.keys(prev).length === 0) {
          return undefined;
        }
        return prev;
      });
      return;
    }
    setSearchText((prev) => ({...prev, [dataIndex]: selectedKeys[0]}));
  };

  return (
    <div style={{padding: 8}} onKeyDown={(e) => e.stopPropagation()}>
      <Input
        value={selectedKeys[0]}
        onChange={(e) => {
          setSelectedKeys(e.target.value ? [e.target.value] : []);
        }}
        onPressEnter={() => handleSearch(selectedKeys as string[])}
        style={{marginBottom: 8, display: 'block'}}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys as string[])}
          icon={<SearchOutlined />}
          size="small"
          style={{width: 90}}
        >
          Search
        </Button>
        <Button
          onClick={() => {
            setSelectedKeys([]);
            handleSearch(['']);
          }}
          size="small"
          style={{width: 90}}
        >
          Reset
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            close();
          }}
        >
          close
        </Button>
      </Space>
    </div>
  );
};
