import {DownloadOutlined} from '@ant-design/icons';
import {Modal, Typography} from 'antd';
import Linkify from 'linkify-react';
import {FC} from 'react';

interface ProofFactorTooltipProps {
  tooltipText: string;
  questionTitle?: string;
}

export const ProofFactorTooltip: FC<ProofFactorTooltipProps> = ({
  tooltipText,
  questionTitle,
}) => {
  const [modal, contextHolder] = Modal.useModal();
  return (
    <span>
      {contextHolder}
      <DownloadOutlined
        style={{cursor: 'pointer', color: 'blue', fontSize: '1.3em'}}
        onClick={() => {
          void modal.info({
            closable: true,
            icon: null,
            centered: true,
            width: '600px',
            content: (
              <>
                <Typography.Title level={4}>
                  {`Indications pour fournir des preuves à la question «${questionTitle}»`}
                </Typography.Title>
                <Typography.Text style={{whiteSpace: 'pre-wrap'}}>
                  <Linkify
                    options={{
                      target: '_blank',
                    }}
                  >
                    {tooltipText}
                  </Linkify>
                </Typography.Text>
              </>
            ),
          });
        }}
      />
    </span>
  );
};
