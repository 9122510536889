import {MinusOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, Flex, Form, FormInstance, InputNumber, Popconfirm} from 'antd';
import {MatrixID, MatType} from 'holo-api';

import {defaultMatrixTableCellStyle, MatrixShared} from './MatrixShared';

export const FormMatID = ({
  formID,
  setDirty,
}: {
  formID: FormInstance<MatrixID>;
  setDirty: (dirty: boolean) => void;
}) => {
  const cols = Form.useWatch('cols', {form: formID, preserve: true});

  return (
    <Form
      initialValues={{
        name: 'Indice Création Valeur Durable',
        description: "Matrice de l'indice création valeur durable",
        mat2d: false,
        matType: MatType.INDICE_DURABLE,
        cols: [{max: 0, value: 0}],
      }}
      layout="vertical"
      form={formID}
      onValuesChange={() => setDirty(true)}
    >
      <MatrixShared disabled></MatrixShared>
      <Flex>
        <table style={{borderCollapse: 'collapse'}}>
          <thead>
            <tr>
              <th style={defaultMatrixTableCellStyle}>Maximum</th>
              {cols?.map((_col, index) => (
                <th style={defaultMatrixTableCellStyle} key={index}>
                  <Form.Item
                    noStyle
                    name={['cols', index, 'max']}
                    rules={[
                      {
                        validator: (_r, value) => {
                          if (typeof value !== 'number') {
                            return Promise.reject();
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <InputNumber
                      style={{width: '100px'}}
                      wheel={false}
                      controls={false}
                      variant="borderless"
                    />
                  </Form.Item>
                  <Popconfirm
                    title={`Suppression de la colonne`}
                    description="Êtes-vous sûr de vouloir supprimer cette colonne ?"
                    onConfirm={() => {
                      const formData = formID.getFieldsValue();
                      formData.cols.splice(index, 1);
                      formID.setFieldsValue(formData);
                    }}
                  >
                    <Button
                      type="default"
                      size="small"
                      icon={<MinusOutlined />}
                    />
                  </Popconfirm>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{...defaultMatrixTableCellStyle, textAlign: 'center'}}>
                Valeur
              </td>
              {cols?.map((_col, index) => (
                <td style={defaultMatrixTableCellStyle} key={index}>
                  <Form.Item
                    noStyle
                    name={['cols', index, 'value']}
                    rules={[
                      {
                        validator: (_r, value) => {
                          if (typeof value !== 'number') {
                            return Promise.reject();
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <InputNumber
                      variant="borderless"
                      controls={false}
                      wheel={false}
                    />
                  </Form.Item>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
        <Button
          type="dashed"
          style={{marginLeft: 8}}
          onClick={() => {
            const formData = formID.getFieldsValue();
            if (!formData.cols) {
              formData.cols = [];
            }
            formData.cols.push({max: 0, value: 0});
            formID.setFieldsValue(formData);
          }}
          icon={<PlusOutlined />}
        />
      </Flex>
    </Form>
  );
};
