import {DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {
  Button,
  Col,
  message,
  Popconfirm,
  Row,
  Space,
  TableProps,
  Tooltip,
  Typography,
} from 'antd';
import {FilterDropdownProps, SortOrder} from 'antd/es/table/interface';
import {Role} from 'holo-api';
import {Key, useCallback, useEffect, useState} from 'react';

import {deleteUser, getPluralUsers} from './client/user';
import {FilterSeachText} from './components/ui/FilterSearchText';
import {GenericTable} from './components/ui/GenericTable';
import {UsersModal} from './components/ui/modals/UsersModal';
import {ResendTempPasswordButton} from './components/ui/viewer/ResendEmailButton';
import {useUserStore} from './stores/user';
import {useApi, useUserRole} from './util/auth';

export interface UsersTableDataType {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  role: Role;
  companyGroups: {companyGroup: {id: number; name: string}}[];
}

export const Users = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const api = useApi();
  const companyId = useUserStore((state) => state.companyId);
  const role = useUserRole();
  const isAdmin = role === Role.ADMIN;
  const [users, setUsers] = useState<UsersTableDataType[]>([]);
  const [currentSort, setCurrentSort] = useState<
    | {
        column: Key | readonly Key[] | undefined;
        order: SortOrder | undefined;
      }
    | undefined
  >({column: 'lastName', order: 'ascend'});
  const usePagination = useState<{
    pageNumber: number;
    pageSize: number;
  }>({pageNumber: 1, pageSize: 10});
  const [searchText, setSearchText] = useState<
    {[key: string]: string} | undefined
  >(undefined);
  const [totalUsers, setTotalUsers] = useState<number>();
  const [pagination] = usePagination;
  const [currentId, setCurrentId] = useState<number | 'new' | undefined>(
    undefined,
  );
  const [editedUser, setEditedUser] = useState<UsersTableDataType | undefined>(
    undefined,
  );

  const columns: TableProps<UsersTableDataType>['columns'] = [
    {
      title: 'Nom',
      dataIndex: 'lastName',
      defaultSortOrder: 'ascend',
      sorter: true,
      filterDropdown: (props: FilterDropdownProps) => (
        <FilterSeachText
          dataIndex="lastName"
          setSearchText={setSearchText}
          {...props}
        />
      ),
    },
    {
      title: 'Prénom',
      dataIndex: 'firstName',
      sorter: true,
      filterDropdown: (props: FilterDropdownProps) => (
        <FilterSeachText
          dataIndex="firstName"
          setSearchText={setSearchText}
          {...props}
        />
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: true,
      filterDropdown: (props: FilterDropdownProps) => (
        <FilterSeachText
          dataIndex="email"
          setSearchText={setSearchText}
          {...props}
        />
      ),
    },
    {
      title: 'Groupes',
      dataIndex: 'companyGroups',
      render: (
        value: UsersTableDataType['companyGroups'],
        record: UsersTableDataType,
      ) => {
        if (!api) {
          return;
        }
        if (record.role !== Role.USER) {
          return (
            <Typography.Text type="secondary" italic>
              N/A
            </Typography.Text>
          );
        }
        if (value.length === 0) {
          return <Typography.Text>Aucun groupe associé</Typography.Text>;
        }
        const groups = value.map((group) => group.companyGroup.name).join(', ');
        return <Typography.Text>{groups}</Typography.Text>;
      },
    },
    {
      title: 'Actions',
      width: 200,
      align: 'center',
      render: (_v, record) => {
        return (
          <Space>
            <Tooltip title="Éditer l'utilisateur">
              <Button
                icon={<EditOutlined />}
                onClick={async () => {
                  setEditedUser(record);
                  setCurrentId(record.id);
                }}
              />
            </Tooltip>
            <ResendTempPasswordButton resourceId={record.id} />
            <Tooltip title="Supprimer cet utilisateur">
              <Popconfirm
                title="Suprimer l'utilisateur"
                description="Êtes-vous sûr de vouloir supprimer cet utilisateur ?"
                onConfirm={async () => {
                  if (!api) {
                    return;
                  }
                  const res = await deleteUser({api, userId: record.id});
                  if (!res.count || res.count !== 1) {
                    void message.error(
                      `L'utilisateur ${record.firstName} ${record.lastName} n'a pas pu être supprimé, veuillez réessayer.`,
                    );
                  }
                  void message.success(
                    `L'utilisateur ${record.firstName} ${record.lastName} a été supprimé avec succès !`,
                  );
                  void getUsers();
                }}
              >
                <Button danger icon={<DeleteOutlined />} />
              </Popconfirm>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const getUsers = useCallback(
    async (_companyId?: number) => {
      if (!api) {
        return;
      }
      setLoading(true);
      const data = await getPluralUsers({
        api,
        pagination,
        searchText,
        sort: currentSort,
      });
      setUsers(data.entities);
      setTotalUsers(data.total);
      setLoading(false);
    },
    [api, pagination, searchText, currentSort],
  );

  useEffect(() => {
    void getUsers(companyId);
  }, [getUsers, companyId]);

  return (
    <>
      <Row style={{marginBottom: 16}} justify="space-between" align="middle">
        <Col>
          <Typography.Title style={{margin: 0}}>
            Gestion des utilisateurs
          </Typography.Title>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              setCurrentId('new');
            }}
          >
            {isAdmin && !companyId
              ? 'Créer un administrateur global'
              : 'Créer un nouvel utilisateur'}
          </Button>
        </Col>
      </Row>

      <Row>
        <GenericTable
          columns={columns}
          loading={loading}
          total={totalUsers}
          dataSource={users}
          setCurrentSort={setCurrentSort}
          usePagination={usePagination}
        />
      </Row>
      <UsersModal
        id={currentId}
        onCancel={() => {
          setCurrentId(undefined);
          setEditedUser(undefined);
        }}
        onOk={() => {
          setCurrentId(undefined);
          setEditedUser(undefined);
          void getUsers();
        }}
        editedUser={editedUser}
      />
    </>
  );
};
