import {FC, ReactElement} from 'react';
import {Navigate, useLocation, useSearchParams} from 'react-router-dom';

import {useIsSigningUp, useUser} from '../../util/auth';

export const AuthGuard: FC<{guarded: ReactElement; invert?: boolean}> = ({
  guarded,
  invert = false,
}) => {
  const user = useUser();
  const isSigningUp = useIsSigningUp();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const isAuthenticated = !!(user && !user.challengeName && !isSigningUp);

  if (!isAuthenticated && !invert) {
    let loginUrl = '/login';

    const redirect = location.pathname + location.search;
    if (redirect !== '/') {
      loginUrl += `?redirect=${encodeURIComponent(redirect)}`;
    }

    return <Navigate to={loginUrl} replace />;
  }

  if (isAuthenticated && invert) {
    return <Navigate to={searchParams.get('redirect') ?? '/'} replace />;
  }

  return guarded;
};
