import {
  CloseCircleOutlined,
  EditOutlined,
  LoginOutlined,
  LogoutOutlined,
  SettingOutlined,
  SwapOutlined,
  UploadOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Button,
  Col,
  Form,
  Layout,
  Menu,
  MenuProps,
  message,
  Modal,
  ModalProps,
  Row,
  Typography,
  Upload,
  UploadFile,
} from 'antd';
import {RcFile} from 'antd/es/upload';
import {
  CompanySchemaType,
  Role,
  UpdateCompanyRequestSchemaType,
  UploadFileSchemaType,
} from 'holo-api';
import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {updateCompany} from '../../client/company';
import {userGetSelf, userUpdateSelf} from '../../client/user';
import logo from '../../images/holodiag_logo.svg';
import {useCreatorStore} from '../../stores/creator';
import {usePackStore} from '../../stores/pack';
import {useUserStore} from '../../stores/user';
import {useApi, useUser, useUserRole} from '../../util/auth';
import {DynamicParams, DynamicSelect} from './DynamicSelect';
import {LazyImage} from './LazyImage';

interface CompanySelectForm {
  companyId: number;
}

const COMPANIES_PARAMS: DynamicParams = [
  {command: 'sort', value: 'name'},
  {command: 'direction', value: 'asc'},
];

export const Header: FC = () => {
  const navigate = useNavigate();
  const cognitoUser = useUser();
  const api = useApi();
  const [modalOpen, setModalOpen] = useState(false);
  const [companyModalOpen, setCompanyModalOpen] = useState(false);
  const [reloadImg, setReloadImg] = useState(0);
  const [form] = Form.useForm<CompanySelectForm>();
  const loadPack = usePackStore((state) => state.loadPack);
  const setUserInfo = useUserStore((state) => state.setUserInfo);
  const userInfo = useUserStore((state) => state.userInfo);
  const setCompanyId = useUserStore((state) => state.setCompanyId);
  const companyId = useUserStore((state) => state.companyId);
  const userRole = useUserRole();
  const isAdmin = userRole === Role.ADMIN;
  const isConsultant = userRole === Role.CONSULTANT;
  const isCompanyAdmin = userRole === Role.COMPANY_ADMIN;
  const title = useCreatorStore((state) => state.initialForm?.title);

  const [companyLoading, setCompanyLoading] = useState(false);

  const loadUserInfo = useCallback(async () => {
    if (!cognitoUser || !api) {
      return;
    }
    const info = await userGetSelf(api);
    setUserInfo(info);

    setCompanyId(info.companyId ?? undefined);
    form.setFieldsValue({
      companyId: info.companyId ?? undefined,
    });
  }, [api, cognitoUser, form, setCompanyId, setUserInfo]);

  useEffect(() => {
    void loadUserInfo();
  }, [loadUserInfo]);

  const items = useMemo(() => {
    const items: MenuProps['items'] = [];
    if (!cognitoUser || !userInfo) {
      items.push({
        icon: <LoginOutlined />,
        key: 'login',
        label: 'Se connecter',
        onClick: () => navigate('/login'),
      });
      return items;
    } else {
      if (userInfo.role === Role.ADMIN || userInfo.role === Role.CONSULTANT) {
        items.push({
          icon: <SwapOutlined />,
          key: 'company',
          label: companyId ? (
            <Badge
              style={{paddingLeft: '20px'}}
              count={
                <CloseCircleOutlined
                  onClick={(e) => {
                    e.stopPropagation();
                    form.setFieldValue('companyId', null);
                    form.submit();
                  }}
                  style={{color: 'red'}}
                />
              }
            >
              <Typography style={{color: 'rgba(255, 255, 255, 0.65)'}}>
                {userInfo?.Company?.name}
              </Typography>
            </Badge>
          ) : (
            <span style={{fontWeight: 'bold'}}>Choisir entreprise</span>
          ),
          onClick: () => {
            setModalOpen(true);
          },
        });
      }

      items.push({
        icon: <UserOutlined />,
        key: 'user',
        label: [userInfo?.firstName, userInfo?.lastName].join(' ').trim(),
        children: [
          {
            icon: <SettingOutlined />,
            key: 'settings',
            label: 'Paramètres',
            onClick: () => navigate('/settings'),
          },
          {
            icon: <LogoutOutlined />,
            key: 'logout',
            label: 'Se déconnecter',
            onClick: () => navigate('/logout'),
          },
        ],
      });

      return items;
    }
  }, [navigate, cognitoUser, userInfo, companyId, form]);

  return (
    <Layout.Header
      style={{
        position: 'sticky',
        top: 0,
        zIndex: 999,
        width: '100%',
        padding: '0px 15px',
      }}
    >
      <Row
        justify="space-between"
        align="middle"
        style={{flexWrap: 'nowrap', maxHeight: '64px'}}
      >
        <Col flex={1}>
          <Typography.Title
            level={3}
            style={{
              margin: 0,
              color: 'white',
              whiteSpace: 'nowrap',
            }}
          >
            {
              // TODO: remove ternary when calixte is separated
              process.env.REACT_APP_APP_NAME !== 'Holo Diag'
                ? 'Évaluation de la qualité des soins '
                : 'Pilotage du capital immatériel'
            }
          </Typography.Title>
        </Col>
        <Col flex={1}>
          <Row justify="center" gutter={16} align="middle">
            {companyId ? (
              <>
                <Col
                  style={{
                    maxHeight: 64,
                    maxWidth: 64,
                    paddingLeft: 0,
                    paddingRight: 0,
                    marginLeft: 8,
                    marginRight: 8,
                  }}
                >
                  <LazyImage
                    src={`/company/${companyId}/photo?v=${reloadImg}`}
                    alt="logo"
                    width={64}
                    height={64}
                    style={{
                      objectFit: 'contain',
                      maxHeight: 64,
                      maxWidth: 64,
                    }}
                  />
                </Col>
                <Col>
                  {isAdmin || isConsultant || isCompanyAdmin ? (
                    <EditOutlined
                      style={{color: 'white', cursor: 'pointer'}}
                      onClick={() => {
                        setCompanyModalOpen(true);
                      }}
                    />
                  ) : null}
                </Col>
              </>
            ) : null}
            {!isAdmin && !isConsultant && userInfo && userInfo.Company?.name ? (
              <Col>
                <Typography.Title
                  level={3}
                  style={{
                    margin: 0,
                    color: 'white',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {userInfo.Company?.name}
                </Typography.Title>
              </Col>
            ) : null}
            {isAdmin && title ? (
              <Col>
                <Typography.Title
                  level={3}
                  style={{
                    margin: 0,
                    color: 'white',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {title}
                </Typography.Title>
              </Col>
            ) : null}
          </Row>
        </Col>
        <Col flex={1}>
          <Menu
            style={{
              justifyContent: 'flex-end',
            }}
            theme="dark"
            mode="horizontal"
            selectedKeys={[]}
            items={items}
          />
        </Col>
        <Col>
          <img src={logo} style={{width: '50px', marginTop: 25}} />
        </Col>
      </Row>
      <Modal
        centered
        title="Changer d'entreprise"
        forceRender
        open={modalOpen}
        okButtonProps={{loading: companyLoading}}
        onOk={() => {
          form.submit();
        }}
        onCancel={() => {
          if (userInfo) {
            form.setFieldsValue({
              companyId: userInfo.companyId ?? undefined,
            });
          }
          setModalOpen(false);
        }}
      >
        <Form
          form={form}
          onFinish={async (values) => {
            if (!api) {
              return;
            }
            setCompanyLoading(true);
            await userUpdateSelf(api, {companyId: values.companyId ?? null});
            await loadUserInfo();
            await loadPack(api);
            setCompanyLoading(false);
            setModalOpen(false);
          }}
        >
          <Form.Item label="Entreprise" name="companyId">
            <DynamicSelect
              api={api}
              url="/companies"
              params={COMPANIES_PARAMS}
              enabled={
                userInfo
                  ? userInfo.role === Role.ADMIN ||
                    userInfo.role === Role.CONSULTANT
                  : false
              }
            />
          </Form.Item>
        </Form>
      </Modal>
      <CompanyModal
        id={companyId}
        editedCompany={userInfo?.Company}
        open={companyModalOpen}
        onOk={() => {
          setReloadImg(reloadImg + 1);
          setCompanyModalOpen(false);
        }}
        onCancel={() => {
          setCompanyModalOpen(false);
        }}
      />
    </Layout.Header>
  );
};

interface CompanyModalProps extends ModalProps {
  id: number | undefined;
  editedCompany: Pick<CompanySchemaType, 'name'> | null | undefined;
}

interface FormData {
  company: {
    photoFiles?: {
      file: UploadFile;
      fileList: Array<UploadFile>;
    };
  };
}

const CompanyModal: FC<CompanyModalProps> = ({id, editedCompany, ...props}) => {
  const [form] = Form.useForm<FormData>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const api = useApi();

  return (
    <Modal
      title={`Édition de l'entreprise ${editedCompany?.name}`}
      centered
      confirmLoading={isLoading}
      forceRender
      maskClosable={false}
      open={!!id}
      width="600px"
      {...props}
      onOk={async (e) => {
        setIsLoading(true);
        try {
          const value = await form.validateFields();
          if (!api || !id) {
            return;
          }
          const {photoFiles} = value.company;
          let photoFileMetadata: UploadFileSchemaType | undefined;
          let photoFile: UploadFile | undefined;
          if (photoFiles) {
            photoFile = photoFiles?.file;
            photoFileMetadata = {name: photoFile.name, type: photoFile.type};
          }

          const body: UpdateCompanyRequestSchemaType = {
            photoFileMetadata,
          };
          const res = await updateCompany({
            api,
            companyId: id,
            input: body,
          });

          const photoSignedUrl = res.photoSignedUrl;

          if (photoFile && photoSignedUrl) {
            const headers: HeadersInit = {
              'Content-Disposition': `attachment; filename=${encodeURIComponent(photoFile.name)}`,
            };
            if (photoFile.type) {
              headers['Content-Type'] = photoFile.type;
            }

            await fetch(photoSignedUrl, {
              method: 'PUT',
              body: photoFile.originFileObj,
              headers,
            });
          }

          form.resetFields();
          setIsLoading(false);
          props.onOk?.(e);
        } catch (err) {
          console.error(err);
          setIsLoading(false);
        }
      }}
      onCancel={(e) => {
        form.resetFields();
        props.onCancel?.(e);
      }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Logo de l'entreprise"
          name={['company', 'photoFiles']}
        >
          <Upload
            accept="image/png, image/jpg, image/jpeg"
            showUploadList={{
              showRemoveIcon: true,
              showDownloadIcon: false,
              showPreviewIcon: false,
            }}
            maxCount={1}
            customRequest={({onSuccess}) => onSuccess?.(true)}
            beforeUpload={(file: RcFile) => {
              const fileSizeLimit = 2 * 1024 * 1024;
              if (file.size > fileSizeLimit) {
                void message.error(
                  `Image must smaller than 2MB! not ${file.size}`,
                );
                return false;
              }
              return true;
            }}
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};
