import {
  FormSummaryWithTemplates,
  isDefaultChapter,
  isSelectQuestion,
  ItemType,
} from '../schema/rest/form';
import {
  isAnswerNoAnswer,
  isAnswerToSelectQuestion,
  isAnswerToTextQuestion,
} from '../schema/schema';
import {filterNewestAnswers} from './filterNewestAnswers';

export interface FormAggregation {
  formId: number;
  formSummary: FormSummaryWithTemplates;
  nbrQuestions: number;
  nbrAnsweredQuestions: number;
}

export const aggregateFormSummary = (formSummary: FormSummaryWithTemplates) => {
  const formAggregation: FormAggregation = {
    formId: formSummary.formId,
    formSummary: formSummary,
    nbrQuestions: 0,
    nbrAnsweredQuestions: 0,
  };

  const {answers} = filterNewestAnswers(formSummary.answers);

  let nbrQuestionsMainForm = 0;

  for (const chapter of formSummary.contents.chapters) {
    if (!isDefaultChapter(chapter)) {
      continue;
    }
    for (const item of chapter.items) {
      switch (item.type) {
        case ItemType.FORMULA:
          break;
        case ItemType.QUESTION: {
          nbrQuestionsMainForm++;
          if (item.prefill) {
            formAggregation.nbrAnsweredQuestions++;
            continue;
          }
          if (answers[item.uuid]) {
            const answer = answers[item.uuid];
            if (
              !answer ||
              (!isAnswerToTextQuestion(answer) && // answer object may have both a valid answer and willNotAnswer === false so we need to make sure it only has willNotAnswer === false to not count it towards completion
                !isAnswerToSelectQuestion &&
                isAnswerNoAnswer(answer) &&
                answer.willNotAnswer === false) ||
              (!chapter.answeringIsOptional &&
                !item.optional &&
                isAnswerNoAnswer(answer) &&
                answer.willNotAnswer === true)
            ) {
              continue;
            }
            if (isSelectQuestion(item) && isAnswerToSelectQuestion(answer)) {
              const answerDup = Array.isArray(answer.answer)
                ? answer.answer
                : [answer.answer];
              if (
                !item.answers.some((item) =>
                  answerDup.find((answer) => answer === item.uuid),
                )
              ) {
                continue;
              }
            }
            formAggregation.nbrAnsweredQuestions++;
          }
          break;
        }
        case ItemType.SEPARATOR:
          break;
        case ItemType.TITLE:
          break;
      }
    }
  }

  formAggregation.nbrQuestions += nbrQuestionsMainForm;

  if (formSummary.templates) {
    let oneInstanceCreatedOverall = false;
    for (const template of formSummary.templates) {
      const templateQuestionsUuids: Record<string, boolean> = {};
      const instances = Object.values(template.instances);
      const nbrInstances = instances.length;
      if (nbrInstances > 0) {
        oneInstanceCreatedOverall = true;
      }

      // skipping rest of code if main form has questions && company did not create instances of template, no need to count template towards completion
      if (nbrQuestionsMainForm > 0 && nbrInstances === 0) {
        continue;
      }

      for (const chapter of template.contents.chapters) {
        if (!isDefaultChapter(chapter)) {
          continue;
        }
        for (const item of chapter.items) {
          switch (item.type) {
            case ItemType.FORMULA:
              break;
            case ItemType.QUESTION:
              if (item.prefill) {
                formAggregation.nbrQuestions += nbrInstances;
                formAggregation.nbrAnsweredQuestions += nbrInstances;
              } else {
                templateQuestionsUuids[item.uuid] = true;
              }
              break;
            case ItemType.SEPARATOR:
              break;
            case ItemType.TITLE:
              break;
          }
        }
      }
      const nbrQuestionsInTemplate = Object.keys(templateQuestionsUuids).length;

      if (nbrInstances > 0) {
        formAggregation.nbrQuestions += nbrQuestionsInTemplate * nbrInstances;
      } else if (
        nbrQuestionsMainForm === 0 &&
        oneInstanceCreatedOverall === false
      ) {
        // if main form has no questions and no instances were created for all linked templates, simulating one instance to show overall progression at 0%
        formAggregation.nbrQuestions += nbrQuestionsInTemplate;
      }

      for (const instance of Object.values(template.instances)) {
        const {answers} = filterNewestAnswers(instance);
        for (const questionUuid in answers) {
          if (templateQuestionsUuids[questionUuid]) {
            formAggregation.nbrAnsweredQuestions++;
          }
        }
      }
    }
  }

  return formAggregation;
};
