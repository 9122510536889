import {Rule} from 'antd/es/form';
import escapeStringRegexp from 'escape-string-regexp';

const COGNITO_PASSWORD_SYMBOL_REGEX = new RegExp(
  `[${escapeStringRegexp('$^*.[]{}()?"!@#%&/\\,><\':;|_~`=+-')}]`,
);
const PASSWORD_MIN_LENGTH = Number(
  process.env.REACT_APP_PASSWORD_MIN_LENGTH ?? '0',
);

export const getPasswordRules = (): Rule[] => [
  () => ({
    validator(_, password) {
      if (!password) return Promise.reject();

      if (password.length < PASSWORD_MIN_LENGTH)
        return Promise.reject(
          new Error(
            `Votre mot de passe doit comporter au moins ${PASSWORD_MIN_LENGTH} caractères`,
          ),
        );

      return Promise.resolve();
    },
  }),
  ...[
    process.env.REACT_APP_PASSWORD_REQUIRE_LOWERCASE,
    process.env.REACT_APP_PASSWORD_REQUIRE_UPPERCASE,
    process.env.REACT_APP_PASSWORD_REQUIRE_DIGITS,
    process.env.REACT_APP_PASSWORD_REQUIRE_SYMBOLS,
  ].map(
    (requirement, index): Rule =>
      () => ({
        validator(_, password) {
          if (requirement !== 'true') return Promise.resolve();
          if (!password || password.length < PASSWORD_MIN_LENGTH)
            return Promise.reject();

          switch (index) {
            case 0:
              if (!password.match(/[a-z]/))
                return Promise.reject(
                  'Votre mot de passe doit comporter une lettre minuscule',
                );
              break;
            case 1:
              if (!password.match(/[A-Z]/))
                return Promise.reject(
                  'Votre mot de passe doit comporter une lettre majuscule',
                );
              break;
            case 2:
              if (!password.match(/[0-9]/))
                return Promise.reject(
                  'Votre mot de passe doit comporter un number',
                );
              break;
            case 3:
              if (!password.match(COGNITO_PASSWORD_SYMBOL_REGEX))
                return Promise.reject(
                  'Votre mot de passe doit comporter une caractère spécial',
                );
              break;
          }

          return Promise.resolve();
        },
      }),
  ),
];

export const getConfirmPasswordRule =
  ({passwordField = 'password'}: {passwordField?: string}): Rule =>
  ({getFieldValue}) => ({
    validator(_, value) {
      if (!value || getFieldValue(passwordField) === value) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error('Les deux mots de passe entrés ne correspondent pas'),
      );
    },
  });
