import {Button, Form, Input, message, Typography} from 'antd';
import {filterNewestAnswers, Role, UserAnswers} from 'holo-api';
import {useCallback, useEffect, useState} from 'react';

import {getFormAnswer, postFormAnswer} from './client/form';
import {usePackStore} from './stores/pack';
import {useUserStore} from './stores/user';
import {useApi, useUserRole} from './util/auth';

export const ActionPlan = () => {
  const api = useApi();
  const userRole = useUserRole();
  const isUserAllowed = userRole === Role.CONSULTANT || userRole === Role.ADMIN;
  const userInfo = useUserStore((state) => state.userInfo);
  const [form] = Form.useForm<UserAnswers>();
  const {preliminaryForm} = usePackStore();
  const [loading, setLoading] = useState(false);

  const getAnswers = useCallback(async () => {
    if (!api || !preliminaryForm) {
      return;
    }
    const allAnswers = await getFormAnswer(api, preliminaryForm.id);
    const {answers} = filterNewestAnswers(allAnswers);

    form.setFieldsValue(answers);
  }, [api, preliminaryForm, form]);

  useEffect(() => {
    void getAnswers();
  }, [getAnswers]);

  return (
    <>
      <Typography.Title>{`Plan d'action pour l'entreprise ${userInfo?.Company?.name}`}</Typography.Title>
      <Form
        form={form}
        layout="vertical"
        onFinish={async (value) => {
          if (!api) {
            return;
          }
          if (!preliminaryForm) {
            throw new Error(
              "Impossible de sauvegarder ces commentaires, le formulaire Informations Préalables n'est pas disponible.",
            );
          }
          try {
            setLoading(true);
            await postFormAnswer(api, preliminaryForm.id, value);
            setLoading(false);
            void message.success(`Commentaires sauvegardés avec succès !`);
          } catch (err) {
            console.error(err);
            void message.error(
              `Une erreur est survenue au moment de l'enregistrement des commentaires.`,
            );
          }
        }}
      >
        <Form.Item
          name={['develop', 'consultantComment']}
          label="Ressources immatérielles stratégiques à développer"
          rules={[{required: true}]}
        >
          <Input.TextArea
            disabled={!isUserAllowed}
            autoSize={{minRows: 2, maxRows: 10}}
          />
        </Form.Item>
        <Form.Item
          name={['preserve', 'consultantComment']}
          label="Ressources immatérielles stratégiques à préserver"
          rules={[{required: true}]}
        >
          <Input.TextArea
            disabled={!isUserAllowed}
            autoSize={{minRows: 2, maxRows: 10}}
          />
        </Form.Item>
        <Button
          type="primary"
          loading={loading}
          onClick={() => {
            form.submit();
          }}
        >
          Enregistrer
        </Button>
      </Form>
    </>
  );
};
