import {APIClass} from 'aws-amplify';
import {
  ChapterType,
  CompanyPackContents,
  FormTitleWithSubForms,
  GetSingularForm,
  ItemType,
} from 'holo-api';
import {create} from 'zustand';

import {getCompanyPack} from '../client/companyPack';
import {formGetActivePackFormTitles, formGetSingular} from '../client/form';

const filterForm = ({
  viewAsAdmin,
  form,
}: {
  viewAsAdmin: boolean;
  form: GetSingularForm | undefined;
}) => {
  if (form) {
    if (viewAsAdmin) {
      const filteredForm = {...form};
      filteredForm.contents.chapters = form.contents.chapters.filter(
        (chapter) => chapter.chapterType === ChapterType.DEFAULT,
      );
      for (const chapter of filteredForm.contents.chapters) {
        if (chapter.chapterType === ChapterType.DEFAULT) {
          chapter.items = chapter.items.filter(
            (item) => item.type !== ItemType.FORMULA,
          );
        }
      }
      return filteredForm;
    } else {
      return form;
    }
  }
};

export interface FormTemplate {
  id: number;
  title: string;
  formulas: {uuid: string; title: string; tag: string}[];
}

export interface PackStoreProps {
  packIsLoaded: boolean;
  preliminaryForm: FormTitleWithSubForms | null;
  forms: FormTitleWithSubForms[];
  loadPack: (api: APIClass | null) => Promise<void>;
  getForm: (
    api: APIClass | null,
    id: number,
    viewAsAdmin: boolean,
  ) => Promise<GetSingularForm | undefined>;
  packId: number | null;
  companyId: number | null;
  readOnly: boolean;
  setReadOnly: (readOnly: boolean) => void;
  setPackIsLoaded: (packIsLoaded: boolean) => void;
  backupForms: CompanyPackContents | null;
}

export const usePackStore = create<PackStoreProps>((set, get) => ({
  packIsLoaded: false,
  preliminaryForm: null,
  forms: [],
  backupForms: null,
  packId: null,
  companyId: null,
  readOnly: false,

  loadPack: async (api: APIClass | null) => {
    if (!api) {
      return;
    }
    set((state) => ({
      ...state,
      preliminaryForm: null,
      forms: [],
      backupForms: null,
      packIsLoaded: false,
    }));

    const {preliminaryForm, formTitles, packId, companyId, readOnly} =
      await formGetActivePackFormTitles(api);

    if (readOnly && companyId && packId) {
      try {
        const {contents, isClosed} = await getCompanyPack({
          api,
          params: {companyId: companyId.toString(), packId: packId.toString()},
        });
        if (!isClosed) {
          throw new Error(`pack is not closed: ${packId}`);
        }
        if (!contents) {
          throw new Error(`closed pack has no backup: ${packId}`);
        }
        set((state) => ({...state, backupForms: contents}));
      } catch (err) {
        console.error(err);
      }
    }

    set((state) => ({
      ...state,
      preliminaryForm,
      forms: formTitles,
      packId,
      companyId,
      readOnly,
      packIsLoaded: true,
    }));
  },

  getForm: async (api, id, viewAsAdmin) => {
    if (!api) {
      return;
    }
    const {readOnly, backupForms, packId, companyId} = get();
    if (readOnly && companyId) {
      if (!backupForms) {
        if (!packId) {
          throw new Error(`No packId for readOnly pack`);
        }
        if (!companyId) {
          throw new Error(`No companyId for readOnly pack`);
        }
        const {contents, isClosed} = await getCompanyPack({
          api,
          params: {companyId: companyId.toString(), packId: packId.toString()},
        });
        if (!isClosed) {
          throw new Error(`pack is not closed: ${packId}`);
        }
        if (!contents) {
          throw new Error(`closed pack has no backup: ${packId}`);
        }
        set((state) => ({...state, backupForms: contents}));
        return filterForm({viewAsAdmin, form: contents.savedFormMap[id]});
      } else {
        return filterForm({viewAsAdmin, form: backupForms.savedFormMap[id]});
      }
    } else {
      return formGetSingular(api, id, {viewAsAdmin});
    }
  },

  setReadOnly: (readOnly) => {
    set((state) => ({...state, readOnly}));
  },
  setPackIsLoaded: (packIsLoaded) => {
    set((state) => ({...state, packIsLoaded}));
  },
}));
