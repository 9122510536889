import {CopyrightCircleOutlined} from '@ant-design/icons';
import {Col, Layout, Row, Space, Typography} from 'antd';
import {FC} from 'react';

export const Footer: FC = () => {
  return (
    <Layout.Footer>
      <Row justify="center">
        <Col>
          <Space>
            <CopyrightCircleOutlined style={{color: 'grey'}} />
            <Typography.Text type="secondary">
              {new Date().getFullYear()} - {process.env.REACT_APP_APP_NAME}
            </Typography.Text>
          </Space>
        </Col>
      </Row>
    </Layout.Footer>
  );
};
