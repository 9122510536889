import {MailOutlined} from '@ant-design/icons';
import {Button, ButtonProps, message, Tooltip} from 'antd';
import {AxiosError} from 'axios';
import {FC, useState} from 'react';

import {resendTempPassword} from '../../../client/user';
import {useApi} from '../../../util/auth';

interface ResendTempPasswordProps extends ButtonProps {
  resourceId: number;
}

export const ResendTempPasswordButton: FC<ResendTempPasswordProps> = ({
  resourceId,
}) => {
  const api = useApi();
  const [isTempPasswordSending, setIsTempPasswordSending] = useState(false);
  return (
    <Tooltip title="Renvoyer un mot de passe temporaire">
      <Button
        icon={<MailOutlined />}
        loading={isTempPasswordSending}
        onClick={async () => {
          if (!api) {
            return;
          }
          setIsTempPasswordSending(true);
          try {
            await resendTempPassword({api, userId: resourceId});
            void message.success('Email envoyé avec succès.');
          } catch (e) {
            const err = e as AxiosError;
            if (err.response?.status === 406) {
              return message.error(
                "Cet utilisateur est déjà enregistré, il n'est pas nécéssaire de lui envoyer cet email",
              );
            }
            console.error(err);
            void message.error(
              "Une erreur est survenue lors de l'envoi de l'email.",
            );
          } finally {
            setIsTempPasswordSending(false);
          }
        }}
      />
    </Tooltip>
  );
};
