import {FormSummarySimple} from 'holo-api';
import {useEffect, useState} from 'react';

import {getFormsSummaries} from '../client/form';
import {usePackStore} from '../stores/pack';
import {useApi} from '../util/auth';

export const useFormsSummaries = () => {
  const preliminaryForm = usePackStore((state) => state.preliminaryForm);
  const forms = usePackStore((state) => state.forms);
  const readOnly = usePackStore((state) => state.readOnly);
  const backupForms = usePackStore((state) => state.backupForms);
  const packLoaded = usePackStore((state) => state.packIsLoaded);
  const api = useApi();
  const [formsSummaries, setFormsSummaries] = useState<
    Array<FormSummarySimple> | undefined
  >(undefined);

  useEffect(() => {
    if (!api || !packLoaded) {
      setFormsSummaries(undefined);
      return;
    }
    if (forms.length === 0) {
      setFormsSummaries([]);
    }
    void (async () => {
      const summaries: FormSummarySimple[] = [];

      if (readOnly && backupForms) {
        const output = await getFormsSummaries(api, {
          formIds: backupForms.savedMainFormIds,
        });

        if (preliminaryForm) {
          const form = backupForms.savedFormMap[preliminaryForm.id];
          if (!form) {
            throw new Error();
          }

          summaries.push({
            formId: preliminaryForm.id,
            contents: form.contents,
            answers:
              output.forms.find((f) => f.formId === preliminaryForm.id)
                ?.answers ?? [],
          });
        }

        const forms = backupForms.savedMainFormIds.map(
          (id) => backupForms.savedFormMap[id],
        );

        forms
          .sort((a, b) => (b?.priority ?? 0) - (a?.priority ?? 0))
          .forEach((form) => {
            if (!form) {
              return;
            }

            summaries.push({
              formId: form.id,
              contents: form.contents,
              answers:
                output.forms.find((f) => f.formId === form.id)?.answers ?? [],
            });
          });

        setFormsSummaries(summaries);
        return;
      }

      const formIds: number[] = [];
      if (preliminaryForm) {
        formIds.push(preliminaryForm.id);
      }
      formIds.push(...forms.map((form) => form.id));

      const output = await getFormsSummaries(api, {
        formIds,
      });
      summaries.push(...output.forms);
      setFormsSummaries(summaries);
    })();
  }, [forms, preliminaryForm, api, readOnly, backupForms, packLoaded]);

  return {formsSummaries};
};
