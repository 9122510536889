import {InfoCircleTwoTone} from '@ant-design/icons';
import {Col, Collapse, Row, Space, theme, Typography} from 'antd';
import {FormContentsType} from 'holo-api';
import Linkify from 'linkify-react';
import {FC} from 'react';

interface ViewerDescriptionCollapseProps {
  form: FormContentsType;
  formCategory: string | null | undefined;
}

export const ViewerDescriptionCollapse: FC<ViewerDescriptionCollapseProps> = ({
  form,
  formCategory,
}) => {
  const {token} = theme.useToken();
  return (
    <Row style={{marginTop: 16}}>
      <Collapse
        defaultActiveKey={1}
        style={{
          width: '100%',
          boxShadow: `0 0 5px ${form.color ?? '#eee'}40`,
          border: `2px solid ${form.color ?? '#eee'}40`,
        }}
        size="small"
        items={[
          {
            key: 1,
            label: (
              <Row gutter={16} align="middle" justify="space-between">
                <Col>
                  <Typography style={{fontSize: '1.2em'}}>
                    <Space wrap={false} direction="horizontal">
                      <InfoCircleTwoTone twoToneColor={token.colorPrimary} />
                      Description, objectifs et thématiques clés de l'actif
                    </Space>
                  </Typography>
                </Col>
                {formCategory && (
                  <Col>
                    <Typography>{formCategory}</Typography>
                  </Col>
                )}
              </Row>
            ),
            children: (
              <Space direction="vertical" size="large">
                {form?.description && (
                  <Space direction="vertical" size="small">
                    <Typography.Title level={3} style={{margin: 0}}>
                      Description
                    </Typography.Title>
                    <Typography.Text style={{whiteSpace: 'pre-wrap'}}>
                      <Linkify
                        options={{
                          target: '_blank',
                        }}
                      >
                        {form.description}
                      </Linkify>
                    </Typography.Text>
                  </Space>
                )}
                {form?.objectives && (
                  <Space direction="vertical" size="small">
                    <Typography.Title level={3} style={{margin: 0}}>
                      Objectifs
                    </Typography.Title>
                    <Typography.Text style={{whiteSpace: 'pre-wrap'}}>
                      <Linkify
                        options={{
                          target: '_blank',
                        }}
                      >
                        {form.objectives}
                      </Linkify>
                    </Typography.Text>
                  </Space>
                )}
                {form?.themes && (
                  <Space direction="vertical" size="small">
                    <Typography.Title level={3} style={{margin: 0}}>
                      Thématiques Clés
                    </Typography.Title>
                    <Typography.Text style={{whiteSpace: 'pre-wrap'}}>
                      <Linkify
                        options={{
                          target: '_blank',
                        }}
                      >
                        {form.themes}
                      </Linkify>
                    </Typography.Text>
                  </Space>
                )}
                {form?.contributors && (
                  <Space direction="vertical" size="small">
                    <Typography.Title level={3} style={{margin: 0}}>
                      Contributeurs
                    </Typography.Title>
                    <Typography.Paragraph>
                      {form?.contributors}
                    </Typography.Paragraph>
                  </Space>
                )}
              </Space>
            ),
          },
        ]}
      />
    </Row>
  );
};
