import {useDebounceFn} from 'ahooks';
import {Form, Input} from 'antd';
import {CreationDurableChartReportPage} from 'holo-api';
import {useEffect} from 'react';

import {RichTextEditor, TemplateTag} from './shared/RichTextEditor';

interface CreationDurableFormType {
  title: string;
  subTitle?: string;
}

export const CreationDurableChart = ({
  index,
  chartPage,
  setChartPage,
  templateTags,
}: {
  index: number;
  chartPage: CreationDurableChartReportPage;
  setChartPage: (page: CreationDurableChartReportPage) => void;
  templateTags: Array<TemplateTag>;
}) => {
  const [form] = Form.useForm<CreationDurableFormType>();

  useEffect(() => {
    form.setFieldsValue({
      title: chartPage.title,
      subTitle: chartPage.subTitle,
    });
  }, [form, chartPage]);

  const {run: onChangedPage} = useDebounceFn(
    async (page: CreationDurableChartReportPage) => {
      try {
        const validated = await form.validateFields();
        const newChart = {...chartPage};
        newChart.title = validated.title;
        newChart.subTitle = validated.subTitle;
        setChartPage(newChart);
      } catch (e) {
        console.warn('onChangedPage', page);
        console.error('Not a valid form');
      }
    },
    {wait: 500},
  );

  const onRichTextChanged = (richText: string) => {
    const newChart = {...chartPage};
    newChart.richExplain = richText;
    setChartPage(newChart);
  };

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div>
        <Form
          form={form}
          onValuesChange={(_changedValues, values) => onChangedPage(values)}
        >
          <Form.Item label="Titre" name="title">
            <Input />
          </Form.Item>
          <Form.Item label="Sous Titre" name="subTitle">
            <Input />
          </Form.Item>
        </Form>
      </div>
      <div style={{flex: 1}}>
        <RichTextEditor
          uniqueId={`page_${index}`}
          richText={chartPage.richExplain}
          setRichText={onRichTextChanged}
          templateTags={templateTags}
        ></RichTextEditor>
      </div>
    </div>
  );
};
