import {JSONSchemaType} from 'ajv';

import {Role} from '../../database/types';
import {UploadFileSchema, UploadFileSchemaType} from './util';

export interface CompanySchemaType {
  id: number;
  name: string;
  activePackId: number | null;
  siret?: string;
}

export interface CreateCompanySchemaType {
  name: string;
  siret: string;
  activePackId?: number;
  photoFileMetadata?: UploadFileSchemaType;
}

export const CreateCompanySchema: JSONSchemaType<CreateCompanySchemaType> = {
  type: 'object',
  additionalProperties: false,
  required: ['name'],
  properties: {
    name: {
      type: 'string',
    },
    siret: {
      type: 'string',
    },
    activePackId: {type: 'number', nullable: true},
    photoFileMetadata: {...UploadFileSchema, nullable: true},
  },
} as const;

export interface UpdateCompanyRequestSchemaType {
  name?: string;
  siret?: string;
  photoFileMetadata?: UploadFileSchemaType;
}

export interface CreateCompanyResponseSchemaType {
  id: number;
  photoSignedUrl?: string;
}

export const CreateCompanyResponseSchema: JSONSchemaType<CreateCompanyResponseSchemaType> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['id'],
    properties: {
      id: {
        type: 'number',
      },
      photoSignedUrl: {
        type: 'string',
        nullable: true,
      },
    },
  } as const;

export const UpdateCompanyRequestSchema: JSONSchemaType<UpdateCompanyRequestSchemaType> =
  {
    type: 'object',
    additionalProperties: false,
    properties: {
      name: {
        type: 'string',
        nullable: true,
      },
      siret: {
        type: 'string',
        nullable: true,
      },
      photoFileMetadata: {...UploadFileSchema, nullable: true},
    },
  } as const;

export interface UpdateCompanyResponseSchemaType {
  id: number;
  photoSignedUrl?: string;
}

export const UpdateCompanyResponseSchema: JSONSchemaType<UpdateCompanyResponseSchemaType> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['id'],
    properties: {
      id: {
        type: 'number',
      },
      photoSignedUrl: {
        type: 'string',
        nullable: true,
      },
    },
  } as const;

export interface GetCompanyUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  role: Role;
}
export interface GetCompanyUsersO {
  entities: Array<GetCompanyUser>;
  total: number;
}

export interface GetCompanyAvailablePacksElem {
  id: number;
  name: string;
}

export interface GetCompanyAvailablePacksO {
  entities: Array<GetCompanyAvailablePacksElem>;
  total: number;
}

export interface GetCompanyGroup {
  id: number;
  name: string;
  description: string;
  _count: {
    users: number;
  };
}

export interface GetCompanyGroups0 {
  entities: GetCompanyGroup[];
  total: number;
}

export interface GetGlobalAverages {
  globalAverage: number;
  globalWeightedAverage: number;
}

// *******************************************************

export const getCurrentPackPath = '/company/getCurrentPack';
export interface GetCurrentPackI {}
export interface GetCurrentPackO {
  packId: number;
  readonly: boolean;
}
export const GetCurrentPackISchema: JSONSchemaType<GetCurrentPackI> = {
  type: 'object',
  additionalProperties: false,
  required: [],
  properties: {},
};
export const GetCurrentPackOSchema: JSONSchemaType<GetCurrentPackO> = {
  type: 'object',
  additionalProperties: false,
  required: ['packId', 'readonly'],
  properties: {
    packId: {type: 'integer'},
    readonly: {type: 'boolean'},
  },
};

export const openPackPath = '/company/openPack';
export interface OpenPackI {}
export interface OpenPackO {}
export const OpenPackISchema: JSONSchemaType<OpenPackI> = {
  type: 'object',
  additionalProperties: false,
  required: [],
  properties: {},
};
export const OpenPackOSchema: JSONSchemaType<OpenPackO> = {
  type: 'object',
  additionalProperties: false,
  required: [],
  properties: {},
};

export const closePackPath = '/company/closePack';
export interface ClosePackI {}
export interface ClosePackO {}
export const ClosePackISchema: JSONSchemaType<ClosePackI> = {
  type: 'object',
  additionalProperties: false,
  required: [],
  properties: {},
};
export const ClosePackOSchema: JSONSchemaType<ClosePackO> = {
  type: 'object',
  additionalProperties: false,
  required: [],
  properties: {},
};
