import {APIClass} from 'aws-amplify';
import {
  CreateReportI,
  CreateReportO,
  createReportPath,
  DeleteReportI,
  DeleteReportO,
  deleteReportPath,
  DuplicateReportI,
  DuplicateReportO,
  duplicateReportPath,
  GenerateReportI,
  GenerateReportO,
  generateReportPath,
  GetAllReportsI,
  GetAllReportsO,
  getAllReportsPath,
  GetReportI,
  GetReportO,
  getReportPath,
  PollReportI,
  PollReportO,
  pollReportPath,
  ReportContextI,
  ReportContextO,
  reportContextPath,
  UpdateReportI,
  UpdateReportO,
  updateReportPath,
} from 'holo-api';

import {apiPost} from '../util/api';

export const generateReport = async (api: APIClass, input: GenerateReportI) =>
  apiPost<GenerateReportO, GenerateReportI>(api, generateReportPath, {
    body: input,
  });

export const pollReport = async (api: APIClass, input: PollReportI) =>
  apiPost<PollReportO, PollReportI>(api, pollReportPath, {
    body: input,
  });

export const createReport = (api: APIClass, input: CreateReportI) =>
  apiPost<CreateReportO, CreateReportI>(api, createReportPath, {body: input});

export const getReport = (api: APIClass, input: GetReportI) =>
  apiPost<GetReportO, GetReportI>(api, getReportPath, {body: input});

export const getAllReports = (api: APIClass, input: GetAllReportsI) =>
  apiPost<GetAllReportsO, GetAllReportsI>(api, getAllReportsPath, {
    body: input,
  });

export const updateReport = (api: APIClass, input: UpdateReportI) =>
  apiPost<UpdateReportO, UpdateReportI>(api, updateReportPath, {
    body: input,
  });

export const deleteReport = (api: APIClass, input: DeleteReportI) =>
  apiPost<DeleteReportO, DeleteReportI>(api, deleteReportPath, {
    body: input,
  });

export const duplicateReport = (api: APIClass, input: DuplicateReportI) =>
  apiPost<DuplicateReportO, DuplicateReportI>(api, duplicateReportPath, {
    body: input,
  });

export const reportContext = (api: APIClass, input: ReportContextI) =>
  apiPost<ReportContextO, ReportContextI>(api, reportContextPath, {
    body: input,
  });
