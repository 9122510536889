import {JSONSchemaType} from 'ajv';

import {FormCategory} from '../../util';
import {keyValuesMatch} from '../../util/keyValuesMatch';
import {ScoreTag, ScoreTagSchema} from './form';

export enum ReportPageType {
  static = 'static',
  richtext = 'richtext',
  chart = 'chart', // deprecated as well as chartType
  radar = 'radar',
  poidsActif = 'poidsActif',
  weightedCapital = 'weightedCapital',
  forceFaiblesse = 'forceFaiblesse',
  formulaTree = 'formulaTree',
  cover = 'cover',
  indexDurable = 'indexDurable',
  tagRadar = 'tagRadar',
  tagDetails = 'tagDetails',
  competitivite = 'competitivite',
  equlibreForce = 'equlibreForce',
  conseilConsultant = 'conseilConsultant',
}
keyValuesMatch(ReportPageType);

export interface StaticReportPage {
  readonly pageType: ReportPageType.static;
  pdfS3Key: string;
  pageName: string;
}

const StaticReportPageSchema: JSONSchemaType<StaticReportPage> = {
  type: 'object',
  required: ['pageType', 'pdfS3Key', 'pageName'],
  additionalProperties: false,
  properties: {
    pageType: {
      type: 'string',
      const: ReportPageType.static,
    },
    pdfS3Key: {type: 'string'},
    pageName: {type: 'string'},
  },
};

export interface RichTextReportPage {
  readonly pageType: ReportPageType.richtext;
  title: string;
  subTitle?: string;
  richExplain: string;
}

const RichTextReportPageSchema: JSONSchemaType<RichTextReportPage> = {
  type: 'object',
  required: ['pageType', 'title', 'richExplain'],
  additionalProperties: false,
  properties: {
    pageType: {
      type: 'string',
      const: ReportPageType.richtext,
    },
    title: {type: 'string'},
    subTitle: {type: 'string', nullable: true},
    richExplain: {type: 'string'},
  },
};

interface CoverChartReportPageV1 {
  readonly pageType: ReportPageType.chart | ReportPageType.cover;
  readonly chartType?: ReportPageType.cover;
  title?: string; // deprecatred
  richTitle?: string;
}

const CoverChartReportPageSchema: JSONSchemaType<CoverChartReportPageV1> = {
  type: 'object',
  required: ['pageType'],
  additionalProperties: false,
  properties: {
    pageType: {
      type: 'string',
      enum: [ReportPageType.chart, ReportPageType.cover],
    },
    chartType: {
      type: 'string',
      enum: [ReportPageType.cover],
      nullable: true,
    },
    title: {type: 'string', nullable: true}, // deprecatred
    richTitle: {type: 'string', nullable: true},
  },
};

export interface TagRadar {
  tag: ScoreTag;
  label: string;
}

const TagRadarSchema: JSONSchemaType<TagRadar> = {
  type: 'object',
  additionalProperties: false,
  required: ['tag', 'label'],
  properties: {
    tag: ScoreTagSchema,
    label: {type: 'string'},
  },
};

interface CompetitiviteChartPageV1 {
  readonly pageType: ReportPageType.chart | ReportPageType.competitivite;
  readonly chartType?: ReportPageType.competitivite;
  title: string;
  subTitle?: string;
  barTitle?: string;
  formId?: number;
  radarTitle?: string; // deprecated
  tags?: Array<TagRadar>; // deprecated
  richExplain: string;
}

const CompetitiviteChartReportPageSchema: JSONSchemaType<CompetitiviteChartPageV1> =
  {
    type: 'object',
    required: ['pageType', 'title', 'richExplain'],
    additionalProperties: false,
    properties: {
      pageType: {
        type: 'string',
        enum: [ReportPageType.chart, ReportPageType.competitivite],
      },
      chartType: {
        type: 'string',
        enum: [ReportPageType.competitivite],
        nullable: true,
      },
      title: {type: 'string'},
      subTitle: {type: 'string', nullable: true},
      barTitle: {type: 'string', nullable: true},
      formId: {type: 'number', nullable: true},
      radarTitle: {type: 'string', nullable: true}, // deprecated
      richExplain: {type: 'string'},
      tags: {type: 'array', items: TagRadarSchema, nullable: true}, // deprecated
    },
  };

export interface ActifRatio {
  numeratorFormId: number | undefined;
  denominatorFormId: number | undefined;
}

interface EquilibreForceChartPageV1 {
  readonly pageType: ReportPageType.chart | ReportPageType.equlibreForce;
  readonly chartType?: ReportPageType.equlibreForce;
  title: string;
  subTitle?: string;
  chartTitle?: string;
  ratios: Array<ActifRatio>;
  richExplain: string;
}

const EquilibreForceChartReportPageSchema: JSONSchemaType<EquilibreForceChartPageV1> =
  {
    type: 'object',
    required: ['pageType', 'title', 'ratios', 'richExplain'],
    additionalProperties: false,
    properties: {
      pageType: {
        type: 'string',
        enum: [ReportPageType.chart, ReportPageType.equlibreForce],
      },
      chartType: {
        type: 'string',
        enum: [ReportPageType.equlibreForce],
        nullable: true,
      },
      title: {type: 'string'},
      subTitle: {type: 'string', nullable: true},
      chartTitle: {type: 'string', nullable: true},
      ratios: {
        type: 'array',
        items: {
          type: 'object',
          additionalProperties: false,
          properties: {
            numeratorFormId: {type: 'integer', nullable: true},
            denominatorFormId: {type: 'integer', nullable: true},
          },
        },
      },
      richExplain: {type: 'string'},
    },
  };

interface CreationDurableChartPageV1 {
  readonly pageType: ReportPageType.chart | ReportPageType.indexDurable;
  readonly chartType?: ReportPageType.indexDurable;
  title: string;
  subTitle?: string;
  richExplain: string;
}

const CreationDurableChartReportPageSchema: JSONSchemaType<CreationDurableChartPageV1> =
  {
    type: 'object',
    required: ['pageType', 'title', 'richExplain'],
    additionalProperties: false,
    properties: {
      pageType: {
        type: 'string',
        enum: [ReportPageType.chart, ReportPageType.indexDurable],
      },
      chartType: {
        type: 'string',
        enum: [ReportPageType.indexDurable],
        nullable: true,
      },
      title: {type: 'string'},
      subTitle: {type: 'string', nullable: true},
      richExplain: {type: 'string'},
    },
  };

interface RadarGlobalChartPageV1 {
  readonly pageType: ReportPageType.chart | ReportPageType.radar;
  readonly chartType?: ReportPageType.radar;
  title: string;
  subTitle?: string;
  richExplain: string;
  explainPonderation?: string;
}

export const RadarGlobalChartReportPageSchema: JSONSchemaType<RadarGlobalChartPageV1> =
  {
    type: 'object',
    required: ['pageType', 'title', 'richExplain'],
    additionalProperties: false,
    properties: {
      pageType: {
        type: 'string',
        enum: [ReportPageType.chart, ReportPageType.radar],
      },
      chartType: {
        type: 'string',
        enum: [ReportPageType.radar],
        nullable: true,
      },
      title: {type: 'string'},
      subTitle: {type: 'string', nullable: true},
      richExplain: {type: 'string'},
      explainPonderation: {type: 'string', nullable: true},
    },
  };

interface WeightedCapitalChartPageV1 {
  readonly pageType: ReportPageType.chart | ReportPageType.weightedCapital;
  readonly chartType?: ReportPageType.weightedCapital;
  title?: string;
  subTitle?: string;
  category: FormCategory;
  explainTable?: string;
  explainBar?: string;
}

const WeightedCapitalChartReportPageSchema: JSONSchemaType<WeightedCapitalChartPageV1> =
  {
    type: 'object',
    required: ['pageType', 'category'],
    additionalProperties: false,
    properties: {
      pageType: {
        type: 'string',
        enum: [ReportPageType.chart, ReportPageType.weightedCapital],
      },
      chartType: {
        type: 'string',
        enum: [ReportPageType.weightedCapital],
        nullable: true,
      },
      title: {type: 'string', nullable: true},
      subTitle: {type: 'string', nullable: true},
      category: {
        type: 'string',
        enum: [
          FormCategory.HUMAN,
          FormCategory.RELATIONAL,
          FormCategory.STRUCTURAL,
        ],
      },
      explainTable: {type: 'string', nullable: true},
      explainBar: {type: 'string', nullable: true},
    },
  };

interface ForceFaiblesseChartPageV1 {
  readonly pageType: ReportPageType.chart | ReportPageType.forceFaiblesse;
  readonly chartType?: ReportPageType.forceFaiblesse;
  title?: string;
  subTitle?: string;
  formId?: number;
}
const ForceFaiblesseChartReportPageSchema: JSONSchemaType<ForceFaiblesseChartPageV1> =
  {
    type: 'object',
    required: ['pageType'],
    additionalProperties: false,
    properties: {
      pageType: {
        type: 'string',
        enum: [ReportPageType.chart, ReportPageType.forceFaiblesse],
      },
      chartType: {
        type: 'string',
        enum: [ReportPageType.forceFaiblesse],
        nullable: true,
      },
      title: {type: 'string', nullable: true},
      subTitle: {type: 'string', nullable: true},
      formId: {type: 'number', nullable: true},
    },
  };

export interface TagRadarGroup {
  groupTitle?: string;
  groupColor: string;
  tags: Array<TagRadar>;
}

const TagRadarGroupSchema: JSONSchemaType<TagRadarGroup> = {
  type: 'object',
  additionalProperties: false,
  required: ['groupColor', 'tags'],
  properties: {
    groupTitle: {type: 'string', nullable: true},
    groupColor: {type: 'string'},
    tags: {
      type: 'array',
      items: TagRadarSchema,
    },
  },
};

interface TagRadarChartReportPageV1 {
  readonly pageType: ReportPageType.chart | ReportPageType.tagRadar;
  readonly chartType?: ReportPageType.tagRadar;
  title: string;
  subTitle?: string;
  chartTitle?: string;
  radarZoomPercent: number;
  groups: Array<TagRadarGroup>;
  richExplain: string;
}

export const TagRadarChartReportPageSchema: JSONSchemaType<TagRadarChartReportPageV1> =
  {
    type: 'object',
    required: ['title', 'pageType', 'groups', 'richExplain'],
    additionalProperties: false,
    properties: {
      pageType: {
        type: 'string',
        enum: [ReportPageType.chart, ReportPageType.tagRadar],
      },
      chartType: {
        type: 'string',
        enum: [ReportPageType.tagRadar],
        nullable: true,
      },
      title: {type: 'string'},
      subTitle: {type: 'string', nullable: true},
      chartTitle: {type: 'string', nullable: true},
      radarZoomPercent: {type: 'integer', minimum: 50, maximum: 200},
      groups: {type: 'array', items: TagRadarGroupSchema},
      richExplain: {type: 'string'},
    },
  };

export enum ConseilConsultant {
  developper = 'developper',
  preserver = 'preserver',
  strategy = 'strategy',
}
keyValuesMatch(ReportPageType);

export const conseilConsultantLabel = (
  conseilConsultant: ConseilConsultant,
) => {
  switch (conseilConsultant) {
    case ConseilConsultant.developper:
      return "Plan d'action à développer";
    case ConseilConsultant.preserver:
      return "Plan d'action à préserver";
    case ConseilConsultant.strategy:
      return 'Stratégie par capital';
  }
};

export const orderedConseilConsultant: Array<ConseilConsultant> = [
  ConseilConsultant.strategy,
  ConseilConsultant.developper,
  ConseilConsultant.preserver,
];

interface ConseilConsultantCPageV1 {
  readonly pageType: ReportPageType.chart | ReportPageType.conseilConsultant;
  readonly chartType?: ReportPageType.conseilConsultant;
  title: string;
  subTitle?: string;
  richExplain?: string;
  conseilConsultant?: ConseilConsultant;
  category?: FormCategory;
}

const ConseilConsultantChartReportPageSchema: JSONSchemaType<ConseilConsultantCPageV1> =
  {
    type: 'object',
    required: ['pageType', 'title'],
    additionalProperties: false,
    properties: {
      pageType: {
        type: 'string',
        enum: [ReportPageType.chart, ReportPageType.conseilConsultant],
      },
      chartType: {
        type: 'string',
        enum: [ReportPageType.conseilConsultant],
        nullable: true,
      },
      title: {type: 'string'},
      subTitle: {type: 'string', nullable: true},
      richExplain: {type: 'string', nullable: true},
      conseilConsultant: {
        type: 'string',
        enum: [
          ConseilConsultant.developper,
          ConseilConsultant.preserver,
          ConseilConsultant.strategy,
        ],
        nullable: true,
      },
      category: {
        type: 'string',
        enum: [
          FormCategory.HUMAN,
          FormCategory.RELATIONAL,
          FormCategory.STRUCTURAL,
        ],
        nullable: true,
      },
    },
  };

interface FormulaTreeChartReportPageV1 {
  readonly pageType: ReportPageType.chart | ReportPageType.formulaTree;
  readonly chartType?: ReportPageType.formulaTree;
  title?: string;
  subTitle?: string;
  richExplain?: string;
  formId?: number;
}

const FormulaTreeChartReportPageSchema: JSONSchemaType<FormulaTreeChartReportPageV1> =
  {
    type: 'object',
    required: ['pageType'],
    additionalProperties: false,
    properties: {
      pageType: {
        type: 'string',
        enum: [ReportPageType.chart, ReportPageType.formulaTree],
      },
      chartType: {
        type: 'string',
        enum: [ReportPageType.formulaTree],
        nullable: true,
      },
      title: {type: 'string', nullable: true},
      subTitle: {type: 'string', nullable: true},
      richExplain: {type: 'string', nullable: true},
      formId: {type: 'number', nullable: true},
    },
  };

interface TagDetailsChartReportPageV1 {
  readonly pageType: ReportPageType.chart | ReportPageType.tagDetails;
  readonly chartType?: ReportPageType.tagDetails;
  title: string;
  subTitle?: string;
  tags: Array<TagRadar>;
  richExplain?: string;
}

export const TagDetailsChartReportPageSchema: JSONSchemaType<TagDetailsChartReportPageV1> =
  {
    type: 'object',
    required: ['title', 'pageType', 'tags'],
    additionalProperties: false,
    properties: {
      pageType: {
        type: 'string',
        enum: [ReportPageType.chart, ReportPageType.tagDetails],
      },
      chartType: {
        type: 'string',
        enum: [ReportPageType.tagDetails],
        nullable: true,
      },
      title: {type: 'string'},
      subTitle: {type: 'string', nullable: true},
      tags: {
        type: 'array',
        items: TagRadarSchema,
      },
      richExplain: {type: 'string', nullable: true},
    },
  };

interface PoidsActifChartPageV1 {
  readonly pageType: ReportPageType.chart | ReportPageType.poidsActif;
  readonly chartType?: ReportPageType.poidsActif;
  title?: string;
  subTitle?: string;
  barTitle?: string;
}

const PoidsActifChartPageSchema: JSONSchemaType<PoidsActifChartPageV1> = {
  type: 'object',
  required: ['pageType'],
  additionalProperties: false,
  properties: {
    pageType: {
      type: 'string',
      enum: [ReportPageType.chart, ReportPageType.poidsActif],
    },
    chartType: {
      type: 'string',
      enum: [ReportPageType.poidsActif],
      nullable: true,
    },
    title: {type: 'string', nullable: true},
    subTitle: {type: 'string', nullable: true},
    barTitle: {type: 'string', nullable: true},
  },
};

type ChartReportPage =
  | WeightedCapitalChartPageV1
  | ForceFaiblesseChartPageV1
  | FormulaTreeChartReportPageV1
  | ConseilConsultantCPageV1
  | TagRadarChartReportPageV1
  | TagDetailsChartReportPageV1
  | CompetitiviteChartPageV1
  | EquilibreForceChartPageV1
  | CreationDurableChartPageV1
  | RadarGlobalChartPageV1
  | CoverChartReportPageV1
  | PoidsActifChartPageV1;

const ChartReportPageSchema: JSONSchemaType<ChartReportPage> = {
  oneOf: [
    WeightedCapitalChartReportPageSchema,
    ForceFaiblesseChartReportPageSchema,
    FormulaTreeChartReportPageSchema,
    ConseilConsultantChartReportPageSchema,
    TagRadarChartReportPageSchema,
    TagDetailsChartReportPageSchema,
    CompetitiviteChartReportPageSchema,
    EquilibreForceChartReportPageSchema,
    CreationDurableChartReportPageSchema,
    RadarGlobalChartReportPageSchema,
    CoverChartReportPageSchema,
    PoidsActifChartPageSchema,
  ],
};

export type ReportPage =
  | StaticReportPage
  | RichTextReportPage
  | ChartReportPage;

export const ReportPageSchema: JSONSchemaType<ReportPage> = {
  anyOf: [
    ChartReportPageSchema,
    RichTextReportPageSchema,
    StaticReportPageSchema,
  ],
};

export interface PdfReport {
  pages: Array<ReportPage>;
}

export const PdfReportSchema: JSONSchemaType<PdfReport> = {
  type: 'object',
  additionalProperties: false,
  required: ['pages'],
  properties: {
    pages: {
      type: 'array',
      items: ReportPageSchema,
    },
  },
};

// ----------------------------- labels -----------------------

export const reportLabel = (reportType: ReportPageType): string => {
  switch (reportType) {
    case ReportPageType.chart:
      return 'Graphique';
    case ReportPageType.richtext:
      return 'Texte riche';
    case ReportPageType.static:
      return 'Page fixe';
    case ReportPageType.forceFaiblesse:
      return 'Forces/Faiblesses';
    case ReportPageType.poidsActif:
      return 'Poids Actifs';
    case ReportPageType.radar:
      return 'Radar global';
    case ReportPageType.weightedCapital:
      return 'Capital pondéré';
    case ReportPageType.formulaTree:
      return 'Arbre des dépendances';
    case ReportPageType.cover:
      return 'Page de garde';
    case ReportPageType.indexDurable:
      return 'Indice de création durable';
    case ReportPageType.tagRadar:
      return 'Tag Radar';
    case ReportPageType.tagDetails:
      return 'Tag Détails';
    case ReportPageType.competitivite:
      return 'Comparaison Actifs';
    case ReportPageType.equlibreForce:
      return 'Equilibre de forces';
    case ReportPageType.conseilConsultant:
      return 'Stratégie';
  }
};

type V1ToV2<T extends ReportPage> = Omit<T, 'chartType' | 'pageType'> &
  Record<'pageType', Exclude<T['pageType'], ReportPageType.chart>>;

export type PoidsActifChartReportPage = V1ToV2<PoidsActifChartPageV1>;

export type CoverChartReportPage = Omit<
  V1ToV2<CoverChartReportPageV1>,
  'title'
>;

export type CompetitiviteChartReportPage = Omit<
  V1ToV2<CompetitiviteChartPageV1>,
  'radarTitle' | 'tags'
>;
export type EquilibreForceChartReportPage = V1ToV2<EquilibreForceChartPageV1>;
export type CreationDurableChartReportPage = V1ToV2<CreationDurableChartPageV1>;
export type RadarGlobalChartReportPage = V1ToV2<RadarGlobalChartPageV1>;
export type WeightedCapitalChartReportPage = V1ToV2<WeightedCapitalChartPageV1>;
export type ForceFaiblesseChartReportPage = V1ToV2<ForceFaiblesseChartPageV1>;
export type TagRadarChartReportPage = V1ToV2<TagRadarChartReportPageV1>;
export type ConseilConsultantChartReportPage = V1ToV2<ConseilConsultantCPageV1>;
export type FormulaTreeChartReportPage = V1ToV2<FormulaTreeChartReportPageV1>;
export type TagDetailsChartReportPage = V1ToV2<TagDetailsChartReportPageV1>;

export type ReportPageV2 =
  | StaticReportPage
  | RichTextReportPage
  | WeightedCapitalChartReportPage
  | ForceFaiblesseChartReportPage
  | FormulaTreeChartReportPage
  | ConseilConsultantChartReportPage
  | TagRadarChartReportPage
  | TagDetailsChartReportPage
  | CompetitiviteChartReportPage
  | EquilibreForceChartReportPage
  | CreationDurableChartReportPage
  | RadarGlobalChartReportPage
  | CoverChartReportPage
  | PoidsActifChartReportPage;

export const undeprecateReportPageIterable = function* (
  pages: Array<ReportPage>,
): Iterable<ReportPageV2> {
  for (const page of pages) {
    if (page.pageType === ReportPageType.chart) {
      const undeprecatePage = {...page};
      if (page.chartType) {
        undeprecatePage.pageType = page.chartType;
      } else {
        throw new Error(
          `Illegal page format, pageType: ${page.pageType} , chartType:${page.chartType}`,
        );
      }
      yield undeprecatePage as ReportPageV2;
    } else {
      yield page as ReportPageV2;
    }
  }
};
