import {
  ArrowDownOutlined,
  CheckOutlined,
  RollbackOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  DividerProps,
  Drawer,
  Form,
  FormInstance,
  Input,
  InputRef,
  Row,
  Space,
  Tag,
  TagProps,
  theme,
  Tooltip,
  Typography,
} from 'antd';
import {CreatorForm, isDefaultChapter} from 'holo-api';
import {FC, Fragment, useEffect, useRef, useState} from 'react';

import {useInsertCurrentFormulaValue} from '../../../Creator';
import {useCreatorStore} from '../../../stores/creator';
import {colors, highlightItem} from '../../../util/form';
import {operands} from '../../../util/math';
import {MatrixSelectModal} from '../modals/MatrixSelectModal';

const TitleDivider: FC<DividerProps> = (props) => (
  <Divider orientation="left" style={{margin: 0}} {...props}>
    {props.children}
  </Divider>
);
const FormulaTag: FC<TagProps & {index: number}> = ({index, ...props}) => (
  <Tag color={colors[index]} style={{cursor: 'pointer'}} {...props}>
    {props.children}
  </Tag>
);

type FieldError = {errorFields: {errors: string[]}[]};

interface Props {
  form: FormInstance<CreatorForm>;
}

export const FormulaDrawer: FC<Props> = ({form}) => {
  const {token} = theme.useToken();
  const [matrixDimension, setMatrixDimension] = useState<'1d' | '2d' | ''>('');
  const [currentFormulaTitle, setCurrentFormulaTitle] = useState('');

  const [collapsed, setCollapsed] = useState(true);

  const currentFormulaRef = useRef<InputRef>(null);

  const formTemplates = useCreatorStore((state) => state.formTemplates);
  const clearError = useCreatorStore((state) => state.clearError);

  const currentFormulaName = useCreatorStore(
    (state) => state.currentFormulaName,
  );
  const currentFormulaCursorStart = useCreatorStore(
    (state) => state.currentFormulaCursorStart,
  );
  const currentFormulaCursorEnd = useCreatorStore(
    (state) => state.currentFormulaCursorEnd,
  );
  const setCurrentFormulaName = useCreatorStore(
    (state) => state.setCurrentFormulaName,
  );
  const setCurrentFormulaRef = useCreatorStore(
    (state) => state.setCurrentFormulaRef,
  );
  const addCurrentActiveKey = useCreatorStore(
    (state) => state.addCurrentActiveKey,
  );
  const initialForm = useCreatorStore((state) => state.initialForm);
  const setIsTouched = useCreatorStore((state) => state.setIsTouched);

  useEffect(() => {
    setCurrentFormulaRef(currentFormulaRef);
  }, [currentFormulaRef, setCurrentFormulaRef]);

  const insertCurrentFormulaValue = useInsertCurrentFormulaValue(form);

  useEffect(() => {
    if (!currentFormulaName || !Array.isArray(currentFormulaName)) {
      return;
    }

    const formulaItemName = currentFormulaName.slice(0, -1);
    const formulaItemValue = form.getFieldValue(formulaItemName);
    setCurrentFormulaTitle(formulaItemValue?.title);

    const to = setTimeout(() => {
      if (!currentFormulaName) {
        return;
      }

      currentFormulaRef?.current?.focus();

      currentFormulaRef?.current?.setSelectionRange(
        currentFormulaCursorStart,
        currentFormulaCursorEnd,
      );
    }, 100);

    return () => {
      clearTimeout(to);
    };
  }, [
    currentFormulaName,
    currentFormulaCursorStart,
    currentFormulaCursorEnd,
    currentFormulaRef,
    form,
  ]);

  return (
    <div
      style={{
        position: 'fixed',
        top: 60,
        height: collapsed ? 190 : 500,
        left: 200,
        width: 'calc(100vw - 200px)',
        zIndex: 100,
        pointerEvents: currentFormulaName ? undefined : 'none',
      }}
    >
      <div style={{position: 'relative'}}>
        <Drawer
          className="formula-drawer"
          placement="top"
          getContainer={false}
          mask={false}
          open={!!currentFormulaName}
          closable={false}
          height={collapsed ? 190 : 500}
          forceRender
        >
          <Form form={form} layout="vertical">
            <Form.Item
              label={currentFormulaTitle}
              required
              style={{marginBottom: 0}}
            >
              <Row align="top" gutter={8}>
                <Col flex={1}>
                  <Form.Item name={currentFormulaName}>
                    <Input
                      ref={currentFormulaRef}
                      suffix={
                        initialForm ? (
                          <Tooltip title="Revenir à la formule initiale">
                            <Button
                              size="small"
                              onClick={async (e) => {
                                e.stopPropagation();
                                if (!Array.isArray(currentFormulaName)) {
                                  return;
                                }

                                const [, chapterIndex, , itemIndex] =
                                  currentFormulaName;
                                const targetChapter =
                                  initialForm?.chapters[Number(chapterIndex)];
                                if (!isDefaultChapter(targetChapter)) {
                                  throw new Error(
                                    'Wrong chapter targeted for this formula',
                                  );
                                }
                                const curItem =
                                  targetChapter.items[Number(itemIndex)]; // JFS allows .formula to be valid in MS code + removes dangerous copy/paste
                                if (curItem.type === 'formula') {
                                  form.setFieldValue(
                                    currentFormulaName,
                                    curItem.formula,
                                  );
                                }

                                await form.validateFields([currentFormulaName]);
                                clearError(currentFormulaName);
                                form.setFields([
                                  {
                                    name: currentFormulaName,
                                    errors: [],
                                  },
                                ]);
                              }}
                            >
                              <RollbackOutlined />
                            </Button>
                          </Tooltip>
                        ) : null
                      }
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Tooltip title="Vérifier la validité de la formule">
                    <Button
                      type="primary"
                      icon={<CheckOutlined />}
                      onClick={async () => {
                        const name = currentFormulaName;
                        try {
                          if (!name) {
                            return;
                          }
                          await form.validateFields([name]);
                          clearError(currentFormulaName);
                          setCurrentFormulaName(undefined);
                          const backdrop = document.getElementById('backdrop');
                          backdrop?.classList.remove('active');

                          if (!Array.isArray(name)) {
                            return;
                          }
                          const [, chapterIndex, , itemIndex] = name;
                          const {chapterKey} = form.getFieldValue([
                            'chapters',
                            chapterIndex,
                          ]);
                          const key = chapterIndex.toString();
                          const currentActiveKeys =
                            useCreatorStore.getState().currentActiveKeys;

                          highlightItem(`C${chapterKey}I${itemIndex}`, {
                            scrollIntoView: true,
                            scrollDelay: currentActiveKeys?.includes(key)
                              ? 0
                              : 500,
                          });

                          setIsTouched(true);

                          if (!currentActiveKeys) {
                            addCurrentActiveKey(key);
                            return;
                          } else {
                            if (currentActiveKeys.includes(key)) {
                              return;
                            }
                            addCurrentActiveKey(key);
                          }
                        } catch (err) {
                          if (!name) {
                            console.error(
                              "Aucune formule n'est actuellement active",
                            );
                            return;
                          }
                          form.setFields([
                            {
                              name,
                              errors: (err as FieldError).errorFields[0].errors,
                            },
                          ]);
                        }
                      }}
                      style={{marginTop: 1}}
                    />
                  </Tooltip>
                </Col>
              </Row>
              <Row
                gutter={[8, 8]}
                justify="center"
                style={{
                  height: 48,
                  overflowY: 'auto',
                }}
              >
                {[
                  ...operands.map((operand, index) => {
                    if (operand === 'MAT1()') {
                      return (
                        <Col key={index}>
                          <Button
                            shape="round"
                            onClick={() => {
                              setMatrixDimension('1d');
                            }}
                          >
                            {operand}
                          </Button>
                        </Col>
                      );
                    }
                    if (operand === 'MAT2()') {
                      return (
                        <Col key={index}>
                          <Button
                            shape="round"
                            onClick={() => {
                              setMatrixDimension('2d');
                            }}
                          >
                            {operand}
                          </Button>
                        </Col>
                      );
                    }
                    return (
                      <Col key={index}>
                        <Button
                          shape="round"
                          onClick={() => {
                            if (operand === 'SOMME()') {
                              return insertCurrentFormulaValue(
                                'SOMME(arg1, arg2, …)',
                              );
                            }
                            if (operand === 'MOYENNE()') {
                              return insertCurrentFormulaValue(
                                'MOYENNE(arg1, arg2, …)',
                              );
                            }
                            if (operand === 'IF()') {
                              return insertCurrentFormulaValue(
                                'IF(arg1==value, valVrai, valFaux)',
                              );
                            }
                            insertCurrentFormulaValue(operand);
                          }}
                        >
                          {operand}
                        </Button>
                      </Col>
                    );
                  }),
                ]}
              </Row>
            </Form.Item>
            <Divider style={{marginTop: 4}}>
              {formTemplates.length > 0 ? (
                <Space
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    setCollapsed((c) => !c);
                  }}
                >
                  <ArrowDownOutlined rotate={collapsed ? 0 : 180} />
                  <div style={{width: 300, textAlign: 'center'}}>
                    Formules des templates
                  </div>
                  <ArrowDownOutlined rotate={collapsed ? 0 : 180} />
                </Space>
              ) : null}
            </Divider>
            <Row style={{marginBottom: 8}}>
              Les notes suivantes seront calculées selon les moyennes (pondérées
              par le coefficient) de toutes les instances du template ciblé.
            </Row>
            {formTemplates.length > 0 ? (
              <Space
                direction="vertical"
                style={{
                  padding: 8,
                  backgroundColor: '#fafafa',
                  border: '1px solid #eee',
                  maxHeight: 250,
                  width: '100%',
                  overflowY: 'auto',
                }}
              >
                {formTemplates.map((template) => {
                  if (template.formulas.length === 0) {
                    return (
                      <Fragment key={template.id}>
                        <TitleDivider>{template.title}</TitleDivider>
                        <Typography.Text style={{color: token.colorPrimary}}>
                          Ce template ne remonte aucune formule.
                        </Typography.Text>
                      </Fragment>
                    );
                  }
                  return (
                    <Fragment key={template.id}>
                      <TitleDivider>{template.title}</TitleDivider>
                      {template.formulas.map((formula, index) => (
                        <span key={formula.uuid}>
                          <FormulaTag
                            index={index}
                            onClick={() => {
                              insertCurrentFormulaValue(formula.tag);
                            }}
                          >
                            {formula.tag}
                          </FormulaTag>{' '}
                          {formula.title}
                        </span>
                      ))}
                    </Fragment>
                  );
                })}
              </Space>
            ) : null}
          </Form>
          <MatrixSelectModal
            open={!!matrixDimension}
            dimension={matrixDimension}
            insertValue={insertCurrentFormulaValue}
            onOk={() => {
              setMatrixDimension('');
            }}
            onCancel={() => {
              setMatrixDimension('');
            }}
          />
        </Drawer>
      </div>
    </div>
  );
};
