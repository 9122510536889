import {useDebounceFn} from 'ahooks';
import {Form, InputNumber} from 'antd';
import {
  ColsPA,
  findSecteurQuestion,
  FormActualAnswer,
  GetAllActifsO,
  GetSingularForm,
  ItemPA,
  MatrixPA,
  MatType,
} from 'holo-api';
import {useEffect, useState} from 'react';

import {formGetSingular, getAllActifs} from '../../../client/form';
import {useApi} from '../../../util/auth';
import {MatrixShared} from './MatrixShared';

export const matrixPATableCellStyle = {
  border: '1px solid #d9d9d9',
  padding: '4px 4px',
};

type FormItemPA = {
  value: number;
  //formId: number;
};
type FormColsPA = Array<{
  //secteurUuid: string;
  rows: Array<FormItemPA>;
}>;

interface FormMatrixPA {
  name: string;
  description?: string;
  cols: FormColsPA;
}

export const FormMatPA = ({
  matrixPA,
  onMatrixPAChanged,
}: {
  matrixPA: MatrixPA | undefined;
  onMatrixPAChanged: (matrixPA: MatrixPA) => void;
}) => {
  const api = useApi();
  const [formPA] = Form.useForm<FormMatrixPA>();
  const [allActifs, setAllActifs] = useState<GetAllActifsO>();
  const [preliminaryForm, setPreliminaryForm] = useState<GetSingularForm>();
  const [secteurAnswers, setSecteurAnswers] =
    useState<Array<FormActualAnswer>>();

  useEffect(() => {
    if (api) {
      void (async () => {
        setAllActifs(await getAllActifs(api));
      })();
    }
  }, [api, setAllActifs]);

  useEffect(() => {
    if (api && allActifs && allActifs.preliminaryForm) {
      const preliminaryFormId = allActifs.preliminaryForm.id;
      void (async () => {
        const form = await formGetSingular(api, preliminaryFormId, {
          viewAsAdmin: true,
        });
        setPreliminaryForm(form);
      })();
    }
  }, [api, setPreliminaryForm, allActifs]);

  useEffect(() => {
    if (preliminaryForm) {
      void (async () => {
        const secteurQuestion = findSecteurQuestion({
          preliminarySurveyForm: preliminaryForm.contents,
        });
        if (secteurQuestion) {
          setSecteurAnswers(secteurQuestion.answers);
        }
      })();
    }
  }, [setSecteurAnswers, preliminaryForm]);

  useEffect(() => {
    if (!matrixPA || !secteurAnswers || !allActifs) {
      return;
    }
    formPA.setFieldValue(['name'], matrixPA.name);
    formPA.setFieldValue(['description'], matrixPA.description);

    const rowsBySecteurMap = new Map<string, Map<number, ItemPA>>();
    for (const col of matrixPA.cols) {
      const itemMap = new Map<number, ItemPA>();
      for (const row of col.rows) {
        itemMap.set(row.formId, row); // we make sure we have no duplicates
      }
      rowsBySecteurMap.set(col.secteurUuid, itemMap); // we make sure we have no duplicates
    }

    secteurAnswers.forEach((secteurAnswer, colIndex) => {
      const secteurItemMap = rowsBySecteurMap.get(secteurAnswer.uuid);
      allActifs.formTitles.forEach((formTitle, rowIndex) => {
        formPA.setFieldValue(
          ['cols', colIndex, 'rows', rowIndex, 'value'],
          secteurItemMap?.get(formTitle.id)?.value ?? 0,
        );
      });
    });
  }, [secteurAnswers, allActifs, formPA, matrixPA]);

  const {run: onChangedForm} = useDebounceFn(
    async () => {
      try {
        const formMatrixPA = await formPA.validateFields();
        if (secteurAnswers && allActifs && formMatrixPA) {
          const cols: ColsPA = [];
          secteurAnswers.forEach((secteurAnswer, colIndex) => {
            const rows: Array<ItemPA> = [];
            allActifs.formTitles.forEach((formTitle, rowIndex) => {
              rows.push({
                formId: formTitle.id,
                value: formMatrixPA.cols[colIndex].rows[rowIndex].value,
              });
            });
            cols.push({
              secteurUuid: secteurAnswer.uuid,
              rows,
            });
          });
          onMatrixPAChanged({
            mat2d: true,
            matType: MatType.POIDSACTIFS,
            name: formMatrixPA.name,
            description: formMatrixPA.description,
            cols,
          });
        }
      } catch (e) {
        console.error('onChangedForm', e);
      }
    },
    {wait: 500},
  );

  return (
    <div style={{overflowWrap: 'break-word'}}>
      <Form
        form={formPA}
        initialValues={{
          mat2d: true,
          matType: MatType.POIDSACTIFS,
          name: 'Poids Actifs',
          description: 'Matrice des poids actifs',
        }}
        onValuesChange={() => onChangedForm()}
        size="small"
      >
        <MatrixShared disabled></MatrixShared>
        <table style={{borderCollapse: 'collapse'}}>
          <thead>
            <tr>
              <th></th>
              {secteurAnswers?.map((secteurAnswer, colIndex) => {
                return (
                  <th
                    key={colIndex}
                    style={{...matrixPATableCellStyle, maxWidth: '110px'}}
                  >
                    {secteurAnswer.label}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {allActifs?.formTitles.map((formTitle, rowIndex) => {
              return (
                <tr key={rowIndex}>
                  <th style={{...matrixPATableCellStyle, maxWidth: '110px'}}>
                    {formTitle.title}
                  </th>
                  {secteurAnswers?.map((secteurAnswer, colIndex) => {
                    return (
                      <td key={colIndex} style={matrixPATableCellStyle}>
                        <Form.Item
                          initialValue={15} // TODO is zero or empty better ?
                          noStyle
                          name={['cols', colIndex, 'rows', rowIndex, 'value']}
                          rules={[
                            {
                              validator: (_r, value) => {
                                if (typeof value !== 'number') {
                                  return Promise.reject();
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <InputNumber
                            variant={'borderless'}
                            controls={false}
                            wheel={false}
                            min="0"
                            max="100"
                            prefix="%"
                            style={{width: '50px'}}
                          />
                        </Form.Item>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Form>
    </div>
  );
};
