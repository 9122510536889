import {InfoCircleTwoTone} from '@ant-design/icons';
import {
  Form,
  Input,
  message,
  Modal,
  ModalProps,
  Select,
  Typography,
} from 'antd';
import {Role} from 'holo-api';
import {FC, useEffect, useState} from 'react';

import {
  preRegisterCompanyUser,
  userCreationByAdmin,
  userUpdate,
} from '../../../client/user';
import {useUserStore} from '../../../stores/user';
import {UsersTableDataType} from '../../../Users';
import {useApi, useUserRole} from '../../../util/auth';
import {DynamicSelect} from '../DynamicSelect';

interface UserModalProps extends ModalProps {
  id: number | 'new' | undefined;
  editedUser?: UsersTableDataType;
}

export const UsersModal: FC<UserModalProps> = ({id, editedUser, ...props}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const api = useApi();
  const companyId = useUserStore((state) => state.companyId);
  const userRole = useUserRole();
  const isAdmin = userRole === Role.ADMIN;
  const currentRole = Form.useWatch('role', form);

  useEffect(() => {
    if (!id) {
      return;
    }
    if (id === 'new') {
      form.setFieldsValue({
        firstName: '',
        lastName: '',
        email: '',
      });
    }
    if (typeof id === 'number') {
      form.setFieldsValue({
        ...editedUser,
        companyGroups: editedUser?.companyGroups.map(
          (group) => group.companyGroup.id,
        ),
      });
    }
  }, [id, form, editedUser]);

  return (
    <Modal
      title={
        id === 'new'
          ? "Création d'un utilisateur"
          : typeof id === 'number'
            ? `Édition de l'utilisateur ${editedUser?.firstName} ${editedUser?.lastName}`
            : undefined
      }
      centered
      confirmLoading={isLoading}
      forceRender
      maskClosable={false}
      open={!!id}
      width="600px"
      {...props}
      onOk={async (e) => {
        setIsLoading(true);
        try {
          const value = await form.validateFields();
          if (!api) {
            return;
          }
          if (id === 'new') {
            if ([Role.USER, Role.COMPANY_ADMIN].includes(value.role)) {
              if (!companyId) {
                void message.error(
                  'Veuillez vous assigner une entreprise afin de créer un utilisateur',
                );
                throw new Error(`No companyId`);
              }
              await preRegisterCompanyUser(api, companyId, {
                ...value,
                companyGroups: value.companyGroups,
              });
            } else {
              await userCreationByAdmin({api, input: value});
            }
          }
          if (typeof id === 'number') {
            await userUpdate(
              api,
              {
                ...value,
                companyGroups: value.companyGroups,
              },
              id,
            );
          }
          form.resetFields();
          setIsLoading(false);
          props.onOk?.(e);
        } catch (err) {
          console.info(err);
          setIsLoading(false);
        }
      }}
      onCancel={(e) => {
        form.resetFields();
        props.onCancel?.(e);
      }}
    >
      {!companyId && (
        <Typography.Text style={{fontSize: '.9em'}}>
          <InfoCircleTwoTone twoToneColor="green" style={{paddingRight: 5}} />
          Si vous souhaitez créer un utilisateur ou un administrateur
          d'entreprise, vous devez vous assigner l'entreprise associée
        </Typography.Text>
      )}
      <Form form={form} layout="vertical" style={{marginTop: 20}}>
        <Form.Item
          label="Prénom"
          name="firstName"
          required
          rules={[
            {
              validateTrigger: 'onOk',
              validator: (_r, value) => {
                if (value.trim() === '') {
                  return Promise.reject(
                    'Veuillez renseigner un prénom pour cet utilisateur',
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Nom"
          name="lastName"
          required
          rules={[
            {
              validateTrigger: 'onOk',
              validator: (_r, value) => {
                if (value.trim() === '') {
                  return Promise.reject(
                    'Veuillez renseigner un nom pour cet utilisateur',
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
        {id === 'new' && (
          <Form.Item
            label="Email"
            name="email"
            required
            rules={[
              {
                validateTrigger: 'onOk',
                validator: (_r, value) => {
                  if (value.trim() === '') {
                    return Promise.reject(
                      'Veuillez renseigner un email pour cet utilisateur',
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input />
          </Form.Item>
        )}
        <Form.Item
          name="role"
          label="Rôle"
          required
          rules={[
            {
              validateTrigger: 'onOk',
              validator: (_r, value) => {
                if (!value) {
                  return Promise.reject(
                    'Veuillez choisir un rôle pour cet utilisateur',
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Select
            placeholder="Veuillez choisir un rôle pour cet utilisateur"
            options={[
              ...(isAdmin && !companyId
                ? [
                    {
                      label: 'Administrateur global',
                      value: Role.ADMIN,
                    },
                  ]
                : []),
              ...(companyId
                ? [
                    {
                      label: "Administrateur de l'entreprise",
                      value: Role.COMPANY_ADMIN,
                    },
                    {
                      label: 'Utilisateur',
                      value: Role.USER,
                    },
                  ]
                : []),
            ]}
          />
        </Form.Item>
        <Form.Item
          name="companyGroups"
          label="Groupes"
          required={currentRole === Role.USER}
          rules={[
            {
              validateTrigger: 'onOk',
              validator: (_r, value: string[]) => {
                if (currentRole !== Role.USER) {
                  return Promise.resolve();
                }
                if (!value || value.length === 0) {
                  return Promise.reject(
                    'Veuillez choisir un groupe pour cet utilisateur',
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <DynamicSelect
            disabled={currentRole !== Role.USER || !companyId}
            mode="multiple"
            placeholder="Veuillez choisir un groupe pour cet utilisateur"
            api={api}
            url={`/company/${companyId}/groups`}
            enabled={companyId !== undefined}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
