import {
  ExclamationCircleFilled,
  LoadingOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import {FloatButton, FormInstance} from 'antd';
import {CreatorForm} from 'holo-api';
import {FC, useState} from 'react';

import {useCreatorStore} from '../../../stores/creator';
import {highlightItem} from '../../../util/form';

interface CreatorSaveButtonProps {
  isTouched: boolean;
  form: FormInstance<CreatorForm>;
  handleFinish: (values: CreatorForm) => Promise<void>;
}

export const CreatorSaveButton: FC<CreatorSaveButtonProps> = ({
  isTouched,
  form,
  handleFinish,
}) => {
  const [loading, setLoading] = useState(false);

  const activeKeys = useCreatorStore((state) => state.currentActiveKeys);
  const addActiveKey = useCreatorStore((state) => state.addCurrentActiveKey);
  const errors = useCreatorStore((state) => state.errors);
  const setErrors = useCreatorStore((state) => state.setErrors);

  return (
    <FloatButton
      icon={
        errors.length > 0 ? (
          <ExclamationCircleFilled style={{color: 'red'}} />
        ) : loading ? (
          <LoadingOutlined />
        ) : (
          <SaveOutlined />
        )
      }
      type={errors.length === 0 && isTouched ? 'primary' : undefined}
      tooltip={
        errors.length > 0
          ? 'Des erreurs sont présentes'
          : isTouched
            ? 'Sauvegarder formulaire'
            : 'Aucun changement à sauvegarder'
      }
      onClick={async () => {
        if (loading) {
          return;
        }

        if (errors.length > 0) {
          const firstError = errors[0];
          if (firstError.name.length < 3) {
            return;
          }
          const [, chapterKey, , fieldKey] = firstError.name;
          let identifier = `C${chapterKey}`;
          if (fieldKey != undefined) {
            identifier = `C${chapterKey}I${fieldKey}`;
          }
          const key = chapterKey.toString();
          highlightItem(identifier, {
            scrollIntoView: true,
            scrollDelay: activeKeys.includes(key) ? 0 : 500,
          });

          if (!activeKeys) {
            addActiveKey(key);
            return;
          } else {
            if (activeKeys.includes(key)) {
              return;
            }
            addActiveKey(key);
          }
          return;
        }
        try {
          setLoading(true);
          const values = await form.validateFields();
          await handleFinish(values);
        } catch (err) {
          setErrors(
            // @ts-expect-error err is unknown, any triggers lint
            err.errorFields as ReturnType<FormInstance['getFieldsError']>,
          );
        } finally {
          setLoading(false);
        }
      }}
    />
  );
};
