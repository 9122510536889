import {Form, Input, Modal, ModalProps} from 'antd';
import {FC, useEffect, useState} from 'react';

import {createCompanyGroup, updateCompanyGroup} from '../../../client/company';
import {CompanyGroupsTableDataType} from '../../../CompanyGroups';
import {useApi} from '../../../util/auth';

interface CompanyGroupModalProps extends ModalProps {
  id: number | 'new' | undefined;
  editedCompanyGroup?: CompanyGroupsTableDataType;
}

export const CompanyGroupsModal: FC<CompanyGroupModalProps> = ({
  id,
  editedCompanyGroup,
  ...props
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const api = useApi();
  useEffect(() => {
    if (!id) {
      return;
    }
    if (id === 'new') {
      form.setFieldsValue({
        name: '',
        description: '',
      });
    }
    if (typeof id === 'number') {
      form.setFieldsValue({...editedCompanyGroup});
    }
  }, [id, editedCompanyGroup, form]);
  return (
    <Modal
      title={
        id === 'new'
          ? "Création d'un groupe"
          : typeof id === 'number'
            ? `Édition du groupe  ${editedCompanyGroup?.name}`
            : undefined
      }
      centered
      confirmLoading={isLoading}
      maskClosable={false}
      forceRender
      open={!!id}
      width="600px"
      {...props}
      onOk={async (e) => {
        setIsLoading(true);
        try {
          const value = await form.validateFields();
          if (!api) {
            return;
          }
          if (id === 'new') {
            await createCompanyGroup({api, input: value});
          }
          if (typeof id === 'number') {
            await updateCompanyGroup({
              api,
              companyGroupId: editedCompanyGroup?.id || 0,
              input: value,
            });
          }
          form.resetFields();
          setIsLoading(false);
          props.onOk?.(e);
        } catch (err) {
          console.error(err);
          setIsLoading(false);
        }
      }}
      onCancel={(e) => {
        form.resetFields();
        props.onCancel?.(e);
      }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Nom"
          name="name"
          required
          rules={[
            {
              validateTrigger: 'onOk',
              validator: (_r, value) => {
                if (value.trim() === '') {
                  return Promise.reject(
                    'Veuillez renseigner un nom pour ce groupe',
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          required
          rules={[
            {
              validateTrigger: 'onOk',
              validator: (_r, value) => {
                if (value.trim() === '') {
                  return Promise.reject(
                    'Veuillez renseigner une description pour ce groupe',
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input.TextArea autoSize />
        </Form.Item>
      </Form>
    </Modal>
  );
};
