import {CognitoUser} from 'amazon-cognito-identity-js';
import {Amplify, API, Auth} from 'aws-amplify';
import {Role} from 'holo-api';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export const DEFAULT_API_NAME = 'APIGateway';
export const DEFAULT_ANONYMOUS_API_NAME = 'APIGatewayAnon';

/* export interface CognitoUser {
  Session: string;
  authenticationFlowType: 'USER_SRP_AUTH';
  challengeName: 'NEW_PASSWORD_REQUIRED';
  challengeParam: any;
  client: any;
  keyPrefix: string;
  pool: any;
  signInUserSession: null;
  storage: any;
  userDataKey: string;
  username: '885f6882-823a-4a09-b272-abe28765cdf6';
} */

const getAmplifyConfig = (env: {[key: string]: string} = {}) => ({
  Auth: {
    userPoolId:
      process.env.REACT_APP_USER_POOL_ID || env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId:
      process.env.REACT_APP_USER_POOL_CLIENT_ID ||
      env.REACT_APP_USER_POOL_CLIENT_ID,
    region: process.env.REACT_APP_AWS_REGION,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,
    signUpVerificationMethod: 'code', // 'code' | 'link'

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    cookieStorage: {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      path: '/',
      expires: 365,
      sameSite: 'strict',
      secure: !process.env.REACT_APP_COOKIE_INSECURE,
    },
  },
  API: {
    endpoints: [
      {
        name: DEFAULT_API_NAME,
        endpoint: process.env.REACT_APP_API_ENDPOINT,
        region: process.env.REACT_APP_AWS_REGION,
        // custom_header: async () => ({Authorization: 'token'}),
        // FIXME shouldn't be necessary
        custom_header: async () => ({
          Authorization: [
            'Bearer',
            (await Auth.currentSession()).getIdToken().getJwtToken(),
          ].join(' '),
        }),
      },
      {
        name: DEFAULT_ANONYMOUS_API_NAME,
        endpoint: process.env.REACT_APP_API_ENDPOINT,
        region: process.env.REACT_APP_AWS_REGION,
      },
    ],
  },
});

export interface AuthUser extends CognitoUser {
  attributes?: {
    email: string;
    email_verified: boolean;
    name: string;
  };
}

export interface SignInInput {
  username: string;
  password: string;
  signingUp?: boolean;
}
export interface SignUpInput {
  name: string;
  email: string;
  password: string;
}
export interface ConfirmSignUpInput {
  email: string;
  code: string;
}
export interface ResendSignUpInput {
  email: string;
}
export interface ForgotPasswordInput {
  email: string;
}
export interface ResetPasswordInput {
  email: string;
  code: string;
  password: string;
}
export interface CompleteNewPasswordInput {
  newPassword: string;
}

let initated = false;
let initPromise: Promise<void> | null = null;
const initAmplify = async () => {
  if (initated) return;
  if (initPromise) return initPromise;

  return (initPromise = (async () => {
    let env;
    try {
      env = await (await fetch('/deploy-time-env.json')).json();
    } catch (err) {
      console.warn(`Unable to fetch /deploy-time-env.json`);
    }

    Amplify.configure(getAmplifyConfig(env));

    initated = true;
    initPromise = null;
  })());
};

export const useAuth = () => {
  const [user, setUser] = useState<AuthUser | null>(null);
  const [hasAttemptedSessionRestore, setHasAttemptedSessionRestore] =
    useState(false);
  const [anonymousApi, setAnonymousApi] = useState<typeof API | null>(null);
  const [api, setApi] = useState<typeof API | null>(null);

  const [isSigningUp, setIsSigningUp] = useState(false);

  useEffect(() => {
    let active = true;

    const check = async () => {
      await initAmplify();
      setAnonymousApi(API);
      try {
        const user = await Auth.currentAuthenticatedUser();
        if (active) {
          setUser(user);
          setApi(API);
        }
      } catch (error) {
        if (active) {
          setUser(null);
          setApi(null);
        }
      }
      setHasAttemptedSessionRestore(true);
    };

    void check();

    return () => {
      active = false;
    };
  }, [setUser, setApi]);

  const signIn = useCallback(
    async ({
      username,
      password,
      signingUp = false,
    }: SignInInput): Promise<CognitoUser | null> => {
      await initAmplify();
      const user = await Auth.signIn(username, password.trim());
      if (!user) return null;
      setIsSigningUp(signingUp);
      setUser(user);
      const session = user.getSignInUserSession();
      if (session) {
        // otherwise api calls with fail with exception
        setApi(API);
      }

      return user;
    },
    [setUser, setApi],
  );

  const completeNewPassword = useCallback(
    async ({newPassword}: CompleteNewPasswordInput): Promise<CognitoUser> => {
      if (!user) throw new Error('not logged in');

      return new Promise((resolve, reject) =>
        user.completeNewPasswordChallenge(
          newPassword,
          {},
          {
            onSuccess: () => resolve(user),
            onFailure: reject,
          },
        ),
      );
    },
    [user],
  );

  const signOut = useCallback(async () => {
    await initAmplify();
    await Auth.signOut();
    setUser(null);
    setApi(null);
  }, [setUser, setApi]);

  const deleteUser = useCallback(async () => {
    await initAmplify();
    user?.deleteUser((error?: Error) => {
      if (error) throw error;

      setUser(null);
      setApi(null);
    });
  }, [user, setUser, setApi]);

  return {
    api,
    anonymousApi,
    user,
    isSigningUp,
    hasAttemptedSessionRestore,
    signIn,
    completeNewPassword,
    signOut,
    deleteUser,
  };
};

export const useUser = () => useContext(AuthContext).user;
export const useIsSigningUp = () => useContext(AuthContext).isSigningUp;
export const useConnectedUser = (): AuthUser => {
  const auth = useContext(AuthContext);
  const user = auth.user;

  if (!user || !auth.api) {
    throw new Error('Expected connected user');
  }

  return user;
};

export const useHasAttemptedSessionRestore = () =>
  useContext(AuthContext).hasAttemptedSessionRestore;
export const useApi = () => useContext(AuthContext).api;
export const useAnonymousApi = () => useContext(AuthContext).anonymousApi;
export const useSignIn = () => useContext(AuthContext).signIn;
/** in app, please use `useSignOutAndDeleteCookies` hook instead. */
export const useSignOut = () => useContext(AuthContext).signOut;
export const useCompleteNewPassword = () =>
  useContext(AuthContext).completeNewPassword;
export const useSignOutAndDeleteCookies = () => {
  const signOut = useSignOut();

  const deleteAllCookies = () => {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const [cookieName] = cookie.split('=');
      if (cookieName?.startsWith('CognitoIdentityServiceProvider')) {
        document.cookie =
          cookieName + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
      }
    }
  };

  return async () => {
    await signOut();
    deleteAllCookies();
  };
};

/** returns user groups. Low-level hook that returns what the cognito payloads
 * sends. You probably want to use `useUserRole` */
export const useUserGroups = (): string[] | null => {
  const user = useUser();

  const payload = useMemo(() => {
    const session = user?.getSignInUserSession();
    const accessToken = session?.getAccessToken();
    const payload = accessToken?.decodePayload();
    return payload;
  }, [user]);

  // just after registration, user is connected but has no payload, so we
  // return "null" (important for useUserRole())
  if (!payload) {
    return null;
  }

  return payload?.['cognito:groups'] ?? null;
};

/** retrives the current user role. Null is returned if an error happened. */
export const useUserRole = (): Role | null => {
  const user = useUser();
  const userGroups = useUserGroups();

  // user not connected, return null
  if (!user) {
    return null;
  }

  // if the user has no "cognito:groups" in payload OR payload is non-existent
  // (which happens just after registration), user is unverified
  if (userGroups === null) {
    return Role.UNVERIFIED;
  }

  // "userGroups" should always be NULL or have a length > 0
  if (userGroups.length === 0) {
    return null;
  }

  if (userGroups.includes(Role.UNVERIFIED)) {
    return Role.UNVERIFIED;
  }
  if (userGroups.includes(Role.ADMIN)) {
    return Role.ADMIN;
  }
  if (userGroups.includes(Role.CONSULTANT)) {
    return Role.CONSULTANT;
  }
  if (userGroups.includes(Role.COMPANY_ADMIN)) {
    return Role.COMPANY_ADMIN;
  }

  return null;
};

export const useSignUp =
  () =>
  async ({name, email, password}: SignUpInput) => {
    await initAmplify();
    await Auth.signUp({
      username: email.trim(),
      password: password.trim(),
      attributes: {name, email},
    });
  };

export const useConfirmSignUp =
  () =>
  async ({email, code}: ConfirmSignUpInput) => {
    await initAmplify();
    await Auth.confirmSignUp(email.trim(), code.trim());
  };

export const useResendSignUp =
  () =>
  async ({email}: ResendSignUpInput) => {
    await initAmplify();
    await Auth.resendSignUp(email.trim());
  };

export const useForgotPassword =
  () =>
  async ({email}: ForgotPasswordInput) => {
    await initAmplify();
    await Auth.forgotPassword(email.trim());
  };

export const useResetPassword =
  () =>
  async ({email, code, password}: ResetPasswordInput) => {
    await initAmplify();
    await Auth.forgotPasswordSubmit(email.trim(), code.trim(), password.trim());
  };

export const useDeleteUser = () => useContext(AuthContext).deleteUser;

interface AuthState {
  api: typeof API | null;
  anonymousApi: typeof API | null;
  user: AuthUser | null;
  isSigningUp: boolean;
  hasAttemptedSessionRestore: boolean;
  signIn(input: SignInInput): Promise<CognitoUser | null>;
  completeNewPassword(
    input: CompleteNewPasswordInput,
  ): Promise<CognitoUser | null>;
  signOut(): Promise<void>;
  deleteUser(): Promise<void>;
}

export const AuthContext = createContext<AuthState>({
  api: null,
  anonymousApi: null,
  user: null,
  isSigningUp: false,
  hasAttemptedSessionRestore: false,
  signIn: async () => null,
  completeNewPassword: async () => null,
  signOut: async () => {},
  deleteUser: async () => {},
});

export interface AmplifyError {
  name: string;
  code: string;
}

export const isAmplifyError = (err: unknown): err is AmplifyError => {
  const maybe = err as AmplifyError;
  return typeof maybe.name == 'string' && typeof maybe.code == 'string';
};
