import {Role} from '../database/types';

export const COGNITO_GROUP_PRECEDENCE: {[group in Role]: number} = {
  [Role.ADMIN]: 5,
  [Role.AUDITOR]: 10,
  [Role.CONSULTANT]: 11,
  [Role.COMPANY_ADMIN]: 12,
  [Role.USER]: 15,
  [Role.UNVERIFIED]: 20,
};
