import {Checkbox, Form} from 'antd';
import {
  DefaultChapter,
  isAnswerNoAnswer,
  isFormula,
  isQuestion,
  UserAnswers,
} from 'holo-api';
import {useEffect, useState} from 'react';

import {useViewerStore} from '../../../stores/viewer';

export const ChapterWideNoAnswer = ({
  chapter,
  formId,
  isTemplate,
}: {
  chapter: DefaultChapter;
  formId: number;
  isTemplate: boolean;
}) => {
  const form = Form.useFormInstance<UserAnswers>();
  const [isChecked, setIsChecked] = useState(false);
  const updatePendingUserAnswers = useViewerStore(
    (state) => state.updatePendingUserAnswers,
  );
  const formulaValues = useViewerStore((state) => state.formulaValues);
  const setFormulaValues = useViewerStore((state) => state.setFormulaValues);
  const readOnly = useViewerStore((state) => state.readOnly);
  const templateInstancesAnswers = useViewerStore(
    (state) => state.templateInstancesAnswers,
  );
  const formAnswers = useViewerStore((state) => state.formAnswers);

  useEffect(() => {
    const userAnswers = isTemplate
      ? templateInstancesAnswers[formId]
      : formAnswers;
    let allAnswersAreWillNotAnswer = true;
    for (const item of chapter.items) {
      if (isQuestion(item)) {
        const answer = userAnswers![item.uuid];

        if (
          !answer ||
          (answer && (!isAnswerNoAnswer(answer) || !answer.willNotAnswer))
        ) {
          allAnswersAreWillNotAnswer = false;
        }
      }
      setIsChecked(allAnswersAreWillNotAnswer);
    }
  }, [
    chapter,
    setIsChecked,
    formId,
    templateInstancesAnswers,
    formAnswers,
    isTemplate,
  ]);

  const onChange = async (checked: boolean) => {
    const pendingAnswers: UserAnswers = {};
    for (const item of chapter.items) {
      if (isQuestion(item)) {
        form.setFieldValue(item.uuid, {willNotAnswer: checked});
        pendingAnswers[item.uuid] = {willNotAnswer: checked};
      }
      if (
        checked &&
        isFormula(item) &&
        Object.hasOwn(formulaValues[formId], item.uuid)
      ) {
        formulaValues[formId][item.uuid].result = null;
      }
    }
    setIsChecked(checked);

    updatePendingUserAnswers(pendingAnswers);

    if (checked) {
      setFormulaValues(formulaValues);
    }

    try {
      await form.validateFields({validateOnly: true});
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <Checkbox
        checked={isChecked}
        onChange={(event) => onChange(event.target.checked)}
        disabled={readOnly}
      >
        Non concerné par le chapitre
      </Checkbox>
    </div>
  );
};
