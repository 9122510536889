import {
  FormContentsType,
  isDefaultChapter,
  isQuestion,
  QuestionType,
  SelectQuestion,
} from '../schema/schema';
import {ReportTag} from './reportTag';

export const findSecteurQuestion = ({
  preliminarySurveyForm,
}: {
  preliminarySurveyForm: FormContentsType;
}): SelectQuestion | undefined => {
  for (const chapter of preliminarySurveyForm.chapters) {
    if (isDefaultChapter(chapter)) {
      for (const item of chapter.items) {
        if (isQuestion(item) && item.questionType === QuestionType.SELECT) {
          const tag = item.prelimTag;
          if (tag === ReportTag.UNIVERS_METIER) {
            return item;
          }
        }
      }
    }
  }
  return;
};
