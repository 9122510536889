import {JSONSchemaType} from 'ajv';

export interface CreateFormPackSchemaType {
  formId: number;
  packId: number;
}

export const CreateFormPackSchema: JSONSchemaType<CreateFormPackSchemaType> = {
  type: 'object',
  additionalProperties: false,
  required: ['formId', 'packId'],
  properties: {
    formId: {type: 'number'},
    packId: {type: 'number'},
  },
} as const;

export interface FormPackGetRightPluralElem {
  id: number;
  minorVersion: number;
  patchVersion: number;
  title: string;
  updatedAt: Date;
}

export interface FormPackGetRightPluralO {
  entities: FormPackGetRightPluralElem[];
}
