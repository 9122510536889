import {
  Checkbox,
  Form,
  FormListFieldData,
  Input,
  Radio,
  Space,
  Tooltip,
} from 'antd';
import {NamePath} from 'antd/es/form/interface';
import {findAllFormulas, Formula, Visibility} from 'holo-api';
import {Dispatch, SetStateAction, useMemo} from 'react';

import {useCreatorStore} from '../../../stores/creator';
import {mergeNamePath, verifyFormulaUsedInMainForm} from '../../../util/form';
import {CreatorFormulaInput} from './CreatorFormulaInput';
import {ScoreTagList} from './QuestionFormList';

export const CreatorFormula = ({
  prefixName,
  field,
  chapterIndex,
  chapterKey,
  name,
  currentChapterFormula,
  setCurrentChapterFormula,
  setCurrentFormFormula,
  value,
  isTemplate,
}: {
  prefixName: NamePath;
  field: FormListFieldData;
  chapterIndex: number;
  chapterKey: number;
  name: [number, string];
  currentChapterFormula: string | undefined;
  setCurrentChapterFormula: Dispatch<SetStateAction<string | undefined>>;
  setCurrentFormFormula: Dispatch<string | undefined>;
  value: Formula;
  isTemplate: boolean;
}) => {
  const currentFormFormula = useCreatorStore(
    (state) => state.currentFormFormula,
  );
  const templateFormulasMapping = useCreatorStore(
    (state) => state.templateFormulasMapping,
  );
  const mainForm = useCreatorStore((state) => state.mainForm);
  const allMainFormFormulas = useMemo(
    () => (mainForm ? findAllFormulas(mainForm?.contents) : []),
    [mainForm],
  );
  const setFormulasToFix = useCreatorStore((state) => state.setFormulasToFix);
  return (
    <>
      <Form.Item label="Info-bulle" name={[field.name, 'tooltip']}>
        <Input />
      </Form.Item>
      <CreatorFormulaInput
        prefixName={mergeNamePath(prefixName, name)}
        name={[field.name, 'formula']}
        required
        formulaKey={field.key}
        chapterKey={chapterKey}
      />
      <Form.Item
        name={[field.name, 'visibility']}
        valuePropName="checked"
        normalize={
          (value: boolean) =>
            value ? Visibility.HIDEN_FOR_CONSULTANT : undefined // or Visibility.VISIBLE
        }
      >
        <Checkbox>Cette formule est invisible pour les consultants</Checkbox>
      </Form.Item>
      {!isTemplate && <ScoreTagList fieldName={field.name} />}
      <Form.Item name={[field.name, 'formulaType']} required>
        <Radio.Group
          buttonStyle="solid"
          onChange={(e) => {
            if (
              currentChapterFormula &&
              currentChapterFormula === value.title
            ) {
              setCurrentChapterFormula(undefined);
            }
            if (e.target.value === 'chapter' && !currentChapterFormula) {
              setCurrentChapterFormula(value.title);
            }
            if (currentFormFormula && currentFormFormula === value.title) {
              setCurrentFormFormula(undefined);
            }
            if (e.target.value === 'form' && !currentFormFormula) {
              setCurrentFormFormula(value.title);
            }
          }}
        >
          <Radio.Button value="default">Formule basique</Radio.Button>
          {currentChapterFormula ? (
            <Tooltip
              title={
                currentChapterFormula !== value.title &&
                `Une formule est déjà définie pour ce chapitre: ${currentChapterFormula}`
              }
            >
              <Radio.Button
                value="chapter"
                disabled={
                  !!currentChapterFormula &&
                  currentChapterFormula !== value.title
                }
              >
                Formule du chapitre
              </Radio.Button>
            </Tooltip>
          ) : (
            <Radio.Button value="chapter">Formule du chapitre</Radio.Button>
          )}
          {currentFormFormula ? (
            <Tooltip
              title={
                currentFormFormula !== value.title &&
                `Une formule est déjà définie pour ce formulaire: ${currentFormFormula}`
              }
            >
              <Radio.Button
                value="form"
                disabled={
                  !!currentFormFormula && currentFormFormula !== value.title
                }
              >
                Formule du formulaire
              </Radio.Button>
            </Tooltip>
          ) : (
            <Radio.Button value="form">Formule du formulaire</Radio.Button>
          )}
        </Radio.Group>
      </Form.Item>
      {isTemplate && (
        <Space>
          <span>Formule exploitable par formulaire principal</span>
          <Form.Item
            name={[field.name, 'usedInMainForm']}
            valuePropName="checked"
            noStyle
          >
            <Checkbox
              onChange={(e) => {
                if (!e.target.checked) {
                  const formulas = verifyFormulaUsedInMainForm(
                    [value.uuid],
                    allMainFormFormulas,
                    templateFormulasMapping,
                  );
                  setFormulasToFix(
                    formulas,
                    mergeNamePath(
                      'chapters',
                      chapterIndex,
                      'items',
                      field.name,
                      'usedInMainForm',
                    ),
                  );
                }
              }}
            />
          </Form.Item>
        </Space>
      )}
    </>
  );
};
