import {JSONSchemaType} from 'ajv';

export interface CreateCompanyConsultantUserSchemaType {
  companyId: number;
  userId: number;
}

export const CreateCompanyConsultantUserSchema: JSONSchemaType<CreateCompanyConsultantUserSchemaType> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['companyId', 'userId'],
    properties: {
      companyId: {type: 'number'},
      userId: {type: 'number'},
    },
  } as const;

export interface CreateCompanyConsultantUserO {
  companyId: number;
  userId: number;
}

export interface GetConsultantLinkedCompany {
  companyId: number;
  company: {name: string};
}

export interface GetConsultantLinkedCompanies0 {
  entities: GetConsultantLinkedCompany[];
}

export interface GetConsultantAvailableCompany {
  id: number;
  name: string;
}

export interface GetConsultantLinkedCompaniesO {
  entities: GetConsultantLinkedCompany[];
  total: number;
}
