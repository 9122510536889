import {JSONSchemaType} from 'ajv';

import {UploadFileSchema, UploadFileSchemaType} from './util';

export interface DbSharedFile {
  id: number;
  name: string;
  description: string;
  order: number;
}
export interface SharedFile extends DbSharedFile {
  fileSignedUrl: string;
}

const DbSharedFileSchema: JSONSchemaType<DbSharedFile> = {
  type: 'object',
  additionalProperties: false,
  required: ['id', 'name', 'description', 'order'],
  properties: {
    id: {type: 'number'},
    name: {type: 'string'},
    description: {type: 'string'},
    order: {type: 'number'},
  },
};

const SharedFileSchema: JSONSchemaType<SharedFile> = {
  type: 'object',
  additionalProperties: false,
  required: ['id', 'name', 'description', 'order', 'fileSignedUrl'],
  properties: {
    id: {type: 'number'},
    name: {type: 'string'},
    description: {type: 'string'},
    order: {type: 'number'},
    fileSignedUrl: {type: 'string'},
  },
};

export const getAllSharedFilesPath = '/rpc/getAllSharedFiles';
export interface GetAllSharedFilesI {}
export interface GetAllSharedFilesO {
  sharedFiles: SharedFile[];
}
export const GetAllSharedFilesISchema: JSONSchemaType<GetAllSharedFilesI> = {
  type: 'object',
  additionalProperties: false,
  required: [],
  properties: {},
};
export const GetAllSharedFilesOSchema: JSONSchemaType<GetAllSharedFilesO> = {
  type: 'object',
  additionalProperties: false,
  required: ['sharedFiles'],
  properties: {
    sharedFiles: {type: 'array', items: SharedFileSchema},
  },
};

export const createSharedFilePath = '/rpc/createSharedFile';
export interface CreateSharedFileI {
  name: string;
  description: string;
  order: number;
  sharedFileMetadata: UploadFileSchemaType;
}
export interface CreateSharedFileO {
  fileSignedUrl?: string;
}
export const CreateSharedFileISchema: JSONSchemaType<CreateSharedFileI> = {
  type: 'object',
  additionalProperties: false,
  required: ['name', 'description', 'order', 'sharedFileMetadata'],
  properties: {
    name: {type: 'string'},
    description: {type: 'string'},
    order: {type: 'number'},
    sharedFileMetadata: UploadFileSchema,
  },
};
export const CreateSharedFileOSchema: JSONSchemaType<CreateSharedFileO> = {
  type: 'object',
  additionalProperties: false,
  required: [],
  properties: {
    fileSignedUrl: {type: 'string', nullable: true},
  },
};

export const updateSharedFilePath = '/rpc/updateSharedFile';
export interface UpdateSharedFileI {
  id: number;
  data: {
    name?: string;
    description?: string;
    order?: number;
  };
  sharedFileMetadata: UploadFileSchemaType | undefined;
}
export interface UpdateSharedFileO {
  fileSignedUrl: string | undefined;
}
export const UpdateSharedFileISchema: JSONSchemaType<UpdateSharedFileI> = {
  type: 'object',
  additionalProperties: false,
  required: ['id'],
  properties: {
    id: {type: 'number'},
    data: {
      type: 'object',
      additionalProperties: false,
      required: [],
      properties: {
        name: {type: 'string', nullable: true},
        description: {type: 'string', nullable: true},
        order: {type: 'number', nullable: true},
      },
    },
    sharedFileMetadata: {
      type: 'object',
      required: ['name'],
      properties: {
        name: {type: 'string'},
        type: {type: 'string', nullable: true},
      },
      nullable: true,
    },
  },
};
export const UpdateSharedFileOSchema: JSONSchemaType<UpdateSharedFileO> = {
  type: 'object',
  additionalProperties: false,
  required: [],
  properties: {
    fileSignedUrl: {type: 'string', nullable: true},
  },
};

export const updateSharedFilesOrderPath = '/rpc/updateSharedFilesOrder';
export interface UpdateSharedFilesOrderI {
  // DB ids of the files in an ordered array
  fileIds: number[];
}
export interface UpdateSharedFilesOrderO {
  count: number;
}
export const UpdateSharedFilesOrderISchema: JSONSchemaType<UpdateSharedFilesOrderI> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['fileIds'],
    properties: {
      fileIds: {
        type: 'array',
        items: {type: 'number'},
      },
    },
  };
export const UpdateSharedFilesOrderOSchema: JSONSchemaType<UpdateSharedFilesOrderO> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['count'],
    properties: {
      count: {type: 'number'},
    },
  };

export const deleteSharedFilePath = '/rpc/deleteSharedFile';
export interface DeleteSharedFileI {
  id: number;
}
export interface DeleteSharedFileO {
  count: number;
}
export const DeleteSharedFileISchema: JSONSchemaType<DeleteSharedFileI> = {
  type: 'object',
  additionalProperties: false,
  required: ['id'],
  properties: {id: {type: 'number'}},
};
export const DeleteSharedFileOSchema: JSONSchemaType<DeleteSharedFileO> = {
  type: 'object',
  additionalProperties: false,
  required: ['count'],
  properties: {
    count: {type: 'number'},
  },
};
