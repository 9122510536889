import {EditOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, message, Row, Space, Tabs} from 'antd';
import {
  Chapter,
  DefaultChapter,
  Formula,
  FormulaValues,
  Role,
  UserAnswers,
} from 'holo-api';
import {FC, useMemo, useState} from 'react';

import {LoadingState} from '../../../hooks/useLoading';
import {useUserStore} from '../../../stores/user';
import {useViewerStore} from '../../../stores/viewer';
import {useUserRole} from '../../../util/auth';
import {TemplateModal} from '../modals/TemplateModal';

type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

interface ViewerTemplateViewProps {
  templateInstances: {name: string; id: number; weight: number}[];
  transferredFormulas: Formula[];
  formFormula: Formula | undefined;
  formId: number;
  activeTabKey: string | undefined;
  loadForm: (templateInstanceId?: number) => Promise<void>;
  setActiveTabKey: (tabKey: string) => void;
  setLoading: (loading: LoadingState) => void;
  setSummaryIsLoading: (loading: boolean) => void;
  calculateAverages: (
    formulaValues: {[key: string]: FormulaValues},
    templateInstances: {name: string; id: number; weight: number}[],
    formulas: Formula[],
    formFormula?: Formula,
  ) => void;
  handleStatesOnFormChange: (
    newestAnswers: UserAnswers,
    chapters: Chapter[],
  ) => void;
}

export interface instanceData {
  name: string;
  weight: number;
}

export const ViewerTemplateView: FC<ViewerTemplateViewProps> = ({
  templateInstances,
  transferredFormulas,
  formFormula,
  formId,
  activeTabKey,
  loadForm,
  setLoading,
  setActiveTabKey,
  setSummaryIsLoading,
  calculateAverages,
  handleStatesOnFormChange,
}) => {
  const {companyId} = useUserStore();
  const userRole = useUserRole();
  const isAdmin = userRole === Role.ADMIN;
  const isConsultant = userRole === Role.CONSULTANT;
  const form = useViewerStore((state) => state.form);
  const chapters = useMemo(() => {
    if (!form?.chapters) {
      return [] as DefaultChapter[];
    }
    return form.chapters;
  }, [form]);

  const [instanceId, setInstanceId] = useState<number | 'new' | undefined>(
    undefined,
  );
  const [editedInstance, setEditedInstance] = useState<
    instanceData | undefined
  >(undefined);
  const setTemplateInstanceId = useViewerStore(
    (state) => state.setTemplateInstanceId,
  );
  const formulaValues = useViewerStore((state) => state.formulaValues);
  const templateInstancesAnswers = useViewerStore(
    (state) => state.templateInstancesAnswers,
  );

  const onTabChange = (newActiveKey: string) => {
    setActiveTabKey(newActiveKey);
    if (newActiveKey === 'summary') {
      setSummaryIsLoading(true);
      calculateAverages(
        formulaValues,
        templateInstances,
        transferredFormulas,
        formFormula,
      );
      setTemplateInstanceId(undefined);
      setSummaryIsLoading(false);
      return;
    }
    const activeTemplateInstanceAnswers =
      templateInstancesAnswers[Number(newActiveKey)];
    handleStatesOnFormChange(activeTemplateInstanceAnswers, chapters);
    setTemplateInstanceId(Number(newActiveKey));
  };

  const addTemplateInstance = async () => {
    if (!companyId) {
      void message.error(
        'Veuillez vous lier à une entreprise pour créer un template',
      );
      return;
    }
    setInstanceId('new');
  };

  const editTemplateInstance = async (targetKey: TargetKey) => {
    if (targetKey !== 'summary') {
      const templateInstanceId = Number(targetKey);
      if (isNaN(templateInstanceId)) {
        return;
      }
      const templateInstance = templateInstances.find(
        (templateInstance) => templateInstanceId === templateInstance.id,
      );
      if (!templateInstance) {
        return;
      }
      setEditedInstance({
        name: templateInstance.name,
        weight: templateInstance.weight,
      });
      setInstanceId(templateInstanceId);
    }
  };

  const onEdit = (
    targetKey: React.MouseEvent | React.KeyboardEvent | string,
    action: 'add' | 'remove',
  ) => {
    if (action === 'add') {
      void addTemplateInstance();
    } else {
      void editTemplateInstance(targetKey);
    }
  };

  return (
    <>
      {templateInstances?.length === 0 ? (
        <Row justify="center" align="middle" style={{marginTop: 100}}>
          <Button type="primary" size="large" onClick={addTemplateInstance}>
            {`Ajouter «${form?.title}»`}
          </Button>
        </Row>
      ) : (
        <Tabs
          items={[
            ...templateInstances.map((templateInstance) => ({
              label: templateInstance.name,
              closeIcon: <EditOutlined />,
              key: templateInstance.id.toString(),
            })),
            ...(isAdmin || isConsultant
              ? [
                  {
                    label: 'Synthèse',
                    key: 'summary',
                    closeIcon: <></>,
                  },
                ]
              : []),
          ]}
          activeKey={activeTabKey}
          type="editable-card"
          onEdit={onEdit}
          onChange={onTabChange}
          style={{marginTop: 16}}
          addIcon={
            <Space>
              <PlusOutlined />
              <p>Ajouter</p>
            </Space>
          }
        />
      )}
      {form && (
        <TemplateModal
          form={form}
          formId={formId}
          id={instanceId}
          setViewerLoading={setLoading}
          open={!!instanceId}
          editedInstance={editedInstance}
          closeModal={() => {
            setInstanceId(undefined);
            setEditedInstance(undefined);
          }}
          loadForm={loadForm}
        />
      )}
    </>
  );
};
