import {SortOrder} from 'antd/es/table/interface';
import {Key} from 'react';

export interface PaginationQuery {
  pageNumber: number;
  pageSize: number;
}

export interface SortQuery {
  column: Key | readonly Key[] | undefined;
  order: SortOrder | undefined;
}

export interface SearchQuery {
  [key: string]: string;
}

export const queryParameters = ({
  sort,
  searchText,
  pagination,
}: {
  sort?: SortQuery;
  searchText?: SearchQuery;
  pagination: PaginationQuery;
}) => {
  const params = new URLSearchParams();
  if (sort) {
    params.append('sort', sort.column as string);
    params.append('direction', sort.order === 'ascend' ? 'asc' : 'desc');
  }
  if (searchText) {
    for (const column in searchText) {
      params.append('filters', `${column}:${searchText[column]}`);
    }
  }
  params.append(
    'skip',
    ((pagination.pageNumber - 1) * pagination.pageSize).toString(),
  );
  params.append('take', pagination.pageSize.toString());
  return params;
};
