import {JSONSchemaType} from 'ajv';

import {UserAnswers, UserAnswersSchema} from './answer';

export interface CreatePatentAnswerSchemaType {
  formId: number;
  userId: number;
  patentId: number;
  contents: UserAnswers;
}

export const CreatePatentAnswerSchema: JSONSchemaType<CreatePatentAnswerSchemaType> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['formId', 'userId', 'patentId', 'contents'],
    properties: {
      formId: {type: 'number'},
      userId: {type: 'number'},
      patentId: {type: 'number'},
      contents: UserAnswersSchema,
    },
  };

export interface UpdatePatentAnswerSchemaType {
  contents: UserAnswers;
}

export const UpdatePatentAnswerSchema: JSONSchemaType<UpdatePatentAnswerSchemaType> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['contents'],
    properties: {
      contents: UserAnswersSchema,
    },
  };
