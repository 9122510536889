import {EditOutlined, PlusCircleOutlined} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Form,
  List,
  message,
  Row,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import {
  ColumnsType,
  FilterDropdownProps,
  SortOrder,
} from 'antd/es/table/interface';
import {UploadChangeParam} from 'antd/es/upload';
import dayjs from 'dayjs';
import {Role} from 'holo-api';
import {Key, useCallback, useEffect, useState} from 'react';

import {
  getCompanyGroups,
  getCompanyUsers,
  getPluralCompanies,
} from './client/company';
import {createCompanyPack} from './client/companyPack';
import {packGetSingular} from './client/pack';
import {DeleteButton} from './components/ui/DeleteButton';
import {DynamicSelect} from './components/ui/DynamicSelect';
import {FilterSeachText} from './components/ui/FilterSearchText';
import {GenericTable} from './components/ui/GenericTable';
import {LazyImage} from './components/ui/LazyImage';
import {AssignModal} from './components/ui/modals/AssignModal';
import {CompanyModal} from './components/ui/modals/CompanyModal';
import {TablePopOver} from './components/ui/TablePopOver';
import {apiDel} from './util/api';
import {useApi} from './util/auth';

export interface CompaniesTableDataType {
  id: number;
  name: string;
  siret: string;
  _count: {groups: number; users: number};
  activePackId: number | null;
  packs: {packId: number}[];
  createdAt: string;
  updatedAt: string;
}

export interface CompaniesFormType {
  company: {
    name: string;
    photoFiles?: UploadChangeParam;
  };
  admin: {
    email: string;
    firstName: string;
    lastName: string;
    role: Role;
  };
}

export const Companies = () => {
  const api = useApi();
  const [assignForm] = Form.useForm();
  const [companies, setCompanies] = useState<CompaniesTableDataType[]>([]);
  const [totalCompanies, setTotalCompanies] = useState<number | undefined>();
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState<
    {[key: string]: string} | undefined
  >(undefined);
  const [currentSort, setCurrentSort] = useState<
    | {
        column: Key | readonly Key[] | undefined;
        order: SortOrder | undefined;
      }
    | undefined
  >({column: 'name', order: 'ascend'});
  const usePagination = useState<{
    pageNumber: number;
    pageSize: number;
  }>({pageNumber: 1, pageSize: 10});
  const [pagination] = usePagination;
  const [currentId, setCurrentId] = useState<number | 'new' | undefined>(
    undefined,
  );
  const [editedCompany, setEditedCompany] = useState<
    CompaniesTableDataType | undefined
  >(undefined);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);

  const getCompanies = useCallback(async () => {
    if (!api) {
      return;
    }
    setLoading(true);

    const data = await getPluralCompanies({
      api,
      pagination,
      sort: currentSort,
      searchText,
    });
    setCompanies(data.entities);
    setTotalCompanies(data.total);
    setLoading(false);
  }, [api, pagination, searchText, currentSort]);

  useEffect(() => {
    void getCompanies();
  }, [getCompanies]);

  const columns: ColumnsType<CompaniesTableDataType> = [
    {
      title: 'Nom',
      key: 'name',
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sorter: true,
      filterDropdown: (props: FilterDropdownProps) => (
        <FilterSeachText
          dataIndex="name"
          setSearchText={setSearchText}
          {...props}
        />
      ),
    },
    {
      title: 'Logo',
      key: 'photoSignedUrl',
      dataIndex: 'id',
      width: 96,
      className: 'companyLogoInTable',
      render: (id) => {
        return (
          <LazyImage
            src={`/company/${id}/photo`}
            alt="logo"
            width={64}
            height={64}
            style={{objectFit: 'contain'}}
          />
        );
      },
    },
    {
      title: 'Groupes',
      key: 'groups',
      dataIndex: ['_count', 'groups'],
      render: (value, record) => {
        if (value === 0) {
          return <Typography>Aucun groupe associé</Typography>;
        }
        return (
          <TablePopOver
            title={`Liste des groupes associés à l'entreprise ${record.name}`}
            onOpen={async () => {
              if (!api) {
                return;
              }
              const groups = await getCompanyGroups({
                api,
                companyId: record.id,
              });
              return (
                <List>
                  {groups.entities.map((group, index: number) => (
                    <List.Item key={index}>
                      <Row style={{width: '100%'}}>
                        <Col span={12}>
                          <Typography>{group.name}</Typography>
                        </Col>
                        <Col span={12}>
                          <Typography>
                            {group._count.users} utilisateurs
                          </Typography>
                        </Col>
                      </Row>
                    </List.Item>
                  ))}
                </List>
              );
            }}
          >
            <Typography.Link>{`${value} groupe${
              value === 1 ? '' : 's'
            }`}</Typography.Link>
          </TablePopOver>
        );
      },
    },
    {
      title: 'Utilisateurs',
      dataIndex: ['_count', 'users'],
      render: (value, record) => {
        if (!api) {
          return;
        }
        if (value === 0) {
          return <Typography>Aucun utilisateur associé</Typography>;
        }
        return (
          <TablePopOver
            title={`Liste des utilisateurs associés à l'entreprise ${record.name}`}
            onOpen={async () => {
              if (value === 0) {
                return <></>;
              }
              const users = await getCompanyUsers({
                api,
                companyId: record.id,
                includeAdmins: false,
              });
              return (
                <List>
                  {users.entities.map(
                    (
                      user: {
                        firstName: string;
                        lastName: string;
                        email: string;
                      },
                      index: number,
                    ) => (
                      <List.Item key={index}>
                        <Row style={{width: '100%'}}>
                          <Col span={12}>
                            <Typography>
                              {user.lastName + ' ' + user.firstName}
                            </Typography>
                          </Col>
                          <Col span={12}>
                            <Typography>{user.email}</Typography>
                          </Col>
                        </Row>
                      </List.Item>
                    ),
                  )}
                </List>
              );
            }}
          >
            <Typography.Link>{`${value} utilisateur${
              value === 1 ? '' : 's'
            }`}</Typography.Link>
          </TablePopOver>
        );
      },
    },
    {
      title: 'Pack',
      dataIndex: ['packs', '0', 'packId'],
      render: (value, record) => {
        if (!api) {
          return;
        }
        if (!value) {
          return <Typography>Aucun pack associé</Typography>;
        }
        return (
          <TablePopOver
            title={`Détails du pack associé à l'entreprise ${record.name}`}
            onOpen={async () => {
              const pack = await packGetSingular(api, value);
              return (
                <List>
                  <List.Item>
                    <Row style={{width: '100%'}} gutter={8} align="middle">
                      <Col flex={1}>
                        <Space>
                          <Typography.Text>{pack.name}</Typography.Text>
                        </Space>
                      </Col>
                      <Col>
                        <Typography.Text type="secondary" italic>
                          {`MàJ: ${dayjs(pack.updatedAt).format('DD/MM/YYYY')}`}
                        </Typography.Text>
                      </Col>
                    </Row>
                  </List.Item>
                </List>
              );
            }}
          >
            <Typography.Link>Détails du pack</Typography.Link>
          </TablePopOver>
        );
      },
    },
    {
      title: 'Actions',
      width: 150,
      align: 'center',
      render: (_v, record) => {
        const packClosed =
          record.activePackId === null && record.packs.length > 0;
        return (
          <Space>
            <Tooltip
              title={
                packClosed
                  ? 'Le pack de cette entreprise a été cloturé'
                  : 'Assigner un pack à cet entreprise'
              }
            >
              <Button
                icon={<PlusCircleOutlined />}
                disabled={packClosed}
                onClick={() => {
                  if (!api) {
                    return;
                  }
                  setEditedCompany(record);
                  setIsAssignModalOpen(true);
                }}
              />
            </Tooltip>
            <Tooltip title="Éditer l'entreprise">
              <Button
                icon={<EditOutlined />}
                onClick={async () => {
                  setCurrentId(record.id);
                  setEditedCompany(record);
                }}
              />
            </Tooltip>
            <Tooltip
              title={
                record.activePackId
                  ? "Cette entreprise est associée à un pack actif, il n'est pas possible de la supprimer"
                  : ''
              }
            >
              <DeleteButton
                disabled={!!record.activePackId}
                onConfirm={async () => {
                  if (!api) {
                    return;
                  }
                  try {
                    await apiDel<{count: 1}>(api, `/company/${record.id}`);
                    void message.success(
                      `Entreprise "${record.name}" supprimée !`,
                    );
                    await getCompanies();
                  } catch (err) {
                    console.error(err);
                    void message.error(
                      'Une erreur est survenue lors de la suppression.',
                    );
                  }
                }}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Row style={{marginBottom: 16}} justify="space-between" align="middle">
        <Col>
          <Typography.Title style={{margin: 0}}>
            Gestion des entreprises
          </Typography.Title>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              setCurrentId('new');
            }}
          >
            Créer une nouvelle entreprise
          </Button>
        </Col>
      </Row>
      <Row>
        <GenericTable
          columns={columns}
          dataSource={companies}
          loading={loading}
          total={totalCompanies}
          setCurrentSort={setCurrentSort}
          usePagination={usePagination}
        />
      </Row>
      <CompanyModal
        id={currentId}
        editedCompany={editedCompany}
        onCancel={() => {
          setCurrentId(undefined);
          setEditedCompany(undefined);
        }}
        onOk={() => {
          setCurrentId(undefined);
          setEditedCompany(undefined);
          void getCompanies();
        }}
      />
      <AssignModal
        title={`Assignation d'un pack à l'entreprise ${editedCompany?.name}`}
        open={isAssignModalOpen}
        content={
          <Form
            form={assignForm}
            onFinish={async (value) => {
              if (!api) {
                return;
              }
              await createCompanyPack(api, {
                ...value,
                companyId: editedCompany?.id,
              });
              assignForm.resetFields();
              void getCompanies();
            }}
          >
            <Form.Item required label="Packs" name="packId">
              <DynamicSelect
                api={api}
                url={`/company/${editedCompany?.id}/availablePacks`}
              />
            </Form.Item>
            <Form.Item
              hidden
              name="setAsActivePack"
              valuePropName="checked"
              initialValue={true}
            >
              <Checkbox>Activer ce pack pour l'entreprise</Checkbox>
            </Form.Item>
          </Form>
        }
        onOk={async () => {
          await assignForm.validateFields();
          assignForm.submit();
          setEditedCompany(undefined);
          setIsAssignModalOpen(false);
        }}
        onCancel={() => {
          assignForm.resetFields();
          setEditedCompany(undefined);
          setIsAssignModalOpen(false);
        }}
      />
    </>
  );
};
