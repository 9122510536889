import {useDebounceFn} from 'ahooks';
import {Form, Input} from 'antd';
import {RadarGlobalChartReportPage} from 'holo-api';
import {useEffect} from 'react';

import {RichTextEditor, TemplateTag} from './shared/RichTextEditor';

interface RadarGlobalFormType {
  title: string;
  subTitle?: string;
  explainPonderation?: string;
}

export const RadarGlobalChart = ({
  index,
  chartPage,
  setChartPage,
  templateTags,
}: {
  index: number;
  chartPage: RadarGlobalChartReportPage;
  setChartPage: (page: RadarGlobalChartReportPage) => void;
  templateTags: Array<TemplateTag>;
}) => {
  const [form] = Form.useForm<RadarGlobalFormType>();

  useEffect(() => {
    form.setFieldsValue({
      title: chartPage.title,
      subTitle: chartPage.subTitle,
      explainPonderation: chartPage.explainPonderation,
    });
  }, [form, chartPage]);

  const {run: onChangedPage} = useDebounceFn(
    async (values: RadarGlobalFormType) => {
      try {
        const validated = await form.validateFields();
        const newChartPage: RadarGlobalChartReportPage = {...chartPage};
        newChartPage.title = validated.title;
        newChartPage.subTitle = validated.subTitle;
        newChartPage.explainPonderation = validated.explainPonderation;
        setChartPage(newChartPage);
      } catch (err) {
        console.warn('onChangedPage', values);
        console.error('Not a valid form', err);
      }
    },
    {wait: 500},
  );

  const onRichTextChanged = (richText: string) => {
    const newChart = {...chartPage};
    newChart.richExplain = richText;
    setChartPage(newChart);
  };

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div>
        <Form
          form={form}
          onValuesChange={(_changedValues, values) => onChangedPage(values)}
        >
          <Form.Item label="Titre" name="title" required={true}>
            <Input />
          </Form.Item>
          <Form.Item label="Sous Titre" name="subTitle">
            <Input />
          </Form.Item>
          <Form.Item label="Description" name="explainPonderation">
            <Input />
          </Form.Item>
        </Form>
      </div>
      <div style={{flex: 1}}>
        <RichTextEditor
          uniqueId={`page_${index}`}
          richText={chartPage.richExplain}
          setRichText={onRichTextChanged}
          templateTags={templateTags}
        ></RichTextEditor>
      </div>
    </div>
  );
};
