import {Modal, ModalProps, Select} from 'antd';
import {FC, useState} from 'react';

import {useMatrixStore} from '../../../stores/matrix';

interface MatrixSelectModalProps extends ModalProps {
  dimension: '1d' | '2d' | '';
  insertValue: (value: string) => void;
}

export const MatrixSelectModal: FC<MatrixSelectModalProps> = ({
  dimension,
  insertValue,
  ...props
}) => {
  const {matrix1d, matrix2d} = useMatrixStore();
  const [selectedMatrix, setSelectedMatrix] = useState<string>('');
  return (
    <Modal
      {...props}
      width="600px"
      destroyOnClose
      title={`Insertion d'une matrice ${dimension}`}
      onCancel={(e) => {
        props.onCancel?.(e);
      }}
      onOk={(e) => {
        if (dimension === '1d') {
          insertValue(`MAT1("${selectedMatrix}", COL)`);
        }
        if (dimension === '2d') {
          insertValue(`MAT2("${selectedMatrix}", COL, LIGNE)`);
        }
        props.onOk?.(e);
      }}
    >
      <Select
        showSearch
        allowClear
        style={{width: '100%'}}
        listHeight={200}
        placeholder={`Chercher une matrice ${dimension} à insérer`}
        options={(dimension === '1d' ? matrix1d : matrix2d).map((matrice) => ({
          label: matrice.name,
          value: matrice.name,
        }))}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        onSelect={(value) => {
          setSelectedMatrix(value);
        }}
      />
    </Modal>
  );
};
