import {useDebounceFn} from 'ahooks';
import {Form, Input, Select} from 'antd';
import {CompetitiviteChartReportPage} from 'holo-api';
import {useEffect} from 'react';

import {ActifSelectorOptions} from './shared/reportInterfaces';
import {RichTextEditor, TemplateTag} from './shared/RichTextEditor';

interface CompetitiviteFormType {
  title: string;
  subTitle?: string;
  barTitle?: string;
  formId?: number;
  richExplain: string;
}

export const CompetitiviteChart = ({
  index,
  chartPage,
  setChartPage,
  actifSelectorOptions,
  templateTags,
}: {
  index: number;
  chartPage: CompetitiviteChartReportPage;
  setChartPage: (page: CompetitiviteChartReportPage) => void;
  actifSelectorOptions: ActifSelectorOptions;
  templateTags: Array<TemplateTag>;
}) => {
  const [form] = Form.useForm<CompetitiviteFormType>();

  useEffect(() => {
    form.setFieldsValue({
      title: chartPage.title,
      subTitle: chartPage.subTitle,
      barTitle: chartPage.barTitle,
      formId: chartPage.formId,
      richExplain: chartPage.richExplain,
    });
  }, [form, chartPage]);

  const {run: onChangedPage} = useDebounceFn(
    async (page: CompetitiviteChartReportPage) => {
      try {
        const validated = await form.validateFields();
        const newChart = {...chartPage};
        newChart.title = validated.title;
        newChart.subTitle = validated.subTitle;
        newChart.barTitle = validated.barTitle;
        newChart.formId = validated.formId;
        setChartPage(newChart);
      } catch (e) {
        console.warn('onChangedPage', page);
        console.error('Not a valid form');
      }
    },
    {wait: 500},
  );

  const onRichTextChanged = (richText: string) => {
    const newChart = {...chartPage};
    newChart.richExplain = richText;
    setChartPage(newChart);
  };

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div>
        <Form
          form={form}
          onValuesChange={(_changedValues, values) => onChangedPage(values)}
        >
          <Form.Item label="Titre" name="title">
            <Input />
          </Form.Item>
          <Form.Item label="Sous Titre" name="subTitle">
            <Input />
          </Form.Item>
          <Form.Item label="Titre du graphique en barre" name="barTitle">
            <Input />
          </Form.Item>
          <Form.Item label="Formulaire" name="formId">
            <Select
              style={{width: '100%'}}
              options={actifSelectorOptions}
              placeholder="Recherche de formulaire"
            />
          </Form.Item>
        </Form>
      </div>
      <div style={{flex: 1}}>
        <RichTextEditor
          uniqueId={`page_${index}`}
          richText={chartPage.richExplain}
          setRichText={onRichTextChanged}
          templateTags={templateTags}
        ></RichTextEditor>
      </div>
    </div>
  );
};
