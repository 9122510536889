import {useDebounceFn} from 'ahooks';
import {Form, Input} from 'antd';
import {PoidsActifChartReportPage} from 'holo-api';
import {useEffect} from 'react';

interface PoidsActifFormType {
  title: string;
  subTitle?: string;
  barTitle?: string;
}

export const PoidsActifChart = ({
  chartPage,
  setChartPage,
}: {
  chartPage: PoidsActifChartReportPage;
  setChartPage: (page: PoidsActifChartReportPage) => void;
}) => {
  const [form] = Form.useForm<PoidsActifFormType>();

  useEffect(() => {
    form.setFieldsValue({
      title: chartPage.title,
      subTitle: chartPage.subTitle,
      barTitle: chartPage.barTitle,
    });
  }, [form, chartPage]);

  const {run: onChangedPage} = useDebounceFn(
    async (page: PoidsActifChartReportPage) => {
      try {
        const validated = await form.validateFields();
        const newChart = {...chartPage};
        newChart.title = validated.title;
        newChart.subTitle = validated.subTitle;
        newChart.barTitle = validated.barTitle;
        setChartPage(newChart);
      } catch (e) {
        console.warn('onChangedPage', page);
        console.error('Not a valid form');
      }
    },
    {wait: 500},
  );

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div>
        <Form
          form={form}
          onValuesChange={(_changedValues, values) => onChangedPage(values)}
        >
          <Form.Item label="Titre" name="title">
            <Input />
          </Form.Item>
          <Form.Item label="Sous Titre" name="subTitle">
            <Input />
          </Form.Item>
          <Form.Item label="Titre du graphique en barre" name="barTitle">
            <Input />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
