import {Form, Input, Modal, ModalProps} from 'antd';
import {Role} from 'holo-api';
import {FC, useEffect, useState} from 'react';

import {userCreationByAdmin, userPostSingular} from '../../../client/user';
import {ConsultantsTableDataType} from '../../../Consultants';
import {useApi} from '../../../util/auth';

interface ConsultantModalProps extends ModalProps {
  id: number | 'new' | undefined;
  editedConsultant?: ConsultantsTableDataType;
}

export const ConsultantsModal: FC<ConsultantModalProps> = ({
  id,
  editedConsultant,
  ...props
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const api = useApi();
  useEffect(() => {
    if (!id) {
      return;
    }
    if (id === 'new') {
      form.setFieldsValue({
        firstName: '',
        lastName: '',
        email: '',
        role: Role.CONSULTANT,
      });
    }
    if (typeof id === 'number') {
      form.setFieldsValue({...editedConsultant});
    }
  }, [id, form, editedConsultant]);
  return (
    <Modal
      title={
        id === 'new'
          ? "Création d'un consultant"
          : typeof id === 'number'
            ? `Édition du consultant ${editedConsultant?.firstName} ${editedConsultant?.lastName}`
            : undefined
      }
      centered
      confirmLoading={isLoading}
      forceRender
      open={!!id}
      width="600px"
      {...props}
      onOk={async (e) => {
        setIsLoading(true);
        try {
          const value = await form.validateFields();
          if (!api) {
            return;
          }
          if (id === 'new') {
            await userCreationByAdmin({api, input: value});
          }
          if (typeof id === 'number') {
            await userPostSingular(api, id, value);
          }
          form.resetFields();
          setIsLoading(false);
          props.onOk?.(e);
        } catch (err) {
          console.error(err);
          setIsLoading(false);
        }
      }}
      onCancel={(e) => {
        form.resetFields();
        props.onCancel?.(e);
      }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Prénom"
          name="firstName"
          required
          rules={[
            {
              validateTrigger: 'onOk',
              validator: (_r, value) => {
                if (value.trim() === '') {
                  return Promise.reject(
                    'Veuillez renseigner un prénom pour ce consultant',
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Nom"
          name="lastName"
          required
          rules={[
            {
              validateTrigger: 'onOk',
              validator: (_r, value) => {
                if (value.trim() === '') {
                  return Promise.reject(
                    'Veuillez renseigner un nom pour ce consultant',
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
        {id === 'new' && (
          <>
            <Form.Item
              label="Email"
              name="email"
              required
              rules={[
                {
                  validateTrigger: 'onOk',
                  validator: (_r, value) => {
                    if (value.trim() === '') {
                      return Promise.reject(
                        'Veuillez renseigner un email pour ce consultant',
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item hidden name="role">
              <Input />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
};
