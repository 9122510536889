import {
  assertUnreachable,
  ConseilConsultant,
  FormCategory,
  ReportPageType,
  ReportPageV2,
} from 'holo-api';
import {v4} from 'uuid';

const radarGlobalTitre = `VUE D’ENSEMBLE DES RESULTATS`;

const radarGlobalExplain = `
<p>
A partir de 14/20, nous estimons que la qualité du capital immatériel contribue au processus de</p><p>création de valeur.
</p>
<ul>
<li>
Une répartition harmonieuse des résultats (en forme de cercle) signifie que l’ensemble du capital immatériel est cohérent.
</li>
<li>
En revanche, une répartition présentant de nombreuses arrêtes (des notes élevées pour certains actifs et des notes très basses pour d’autres) signifie que le capital immatériel présente plusieurs ruptures au sein même de l’organisation. Cela peut venir d’un management dysfonctionnel dans certains services, ou d’une mise en lumière d’une faiblesse isolée et jusqu’alors non identifiée.
</li>
</ul>
`;

const radarGlobalPonderation = `Les résultats présentés ci-dessus sont pondérés en fonction du poids de chaque actifs correspondants au secteur d’activité de l’entreprise.`;

const creationDurableTitre = `Indice de création durable`;

const creationDurableExplain = `
<p>
A partir de 14/20, un actif immatériel crée de la valeur. Plus il est activé au sein de l’entreprise,
</p>
<p>
plus il enclenche des synergies avec d’autres ressources immatérielles.
</p>
<ul>
<li>
Si votre maturité immatérielle est évaluée au-delà de 14/20, l’indice corrélé témoigne du <strong>bon potentiel de création de valeur durable de l’entreprise. Celle-ci peut s’appuyer sur ses ressources immatérielles pour se développer dans le temps long face aux enjeux sociétaux, environnementaux et écologiques</strong>.</li><li>Si votre maturité immatérielle est évaluée en dessous de 14/20, l’indice corrélé témoigne d’une faiblesse plus ou moins importante à créer de la valeur. Il est probable que<strong> ’entreprise soit limitée dans sa capacité à soutenir son développement ou engager un projet compétitif durable, celle-ci n’ayant pas une maturité immatérielle suffisante.</strong>
</li>
</ul>
`;

const competitiviteExplain = `
<p>
Analyse du positionnement des actifs immatériels évalués par rapport à la note de votre actif organisation.
</p>
<p>
Ce graphique vous permettent de visualiser vos risques et leviers potentiels par rapport à votre compétitivité.
</p>
`;

const coverGlobalTitre = `RAPPORT D' ANALYSE DE LA PERFORMANCE IMMATÉRIELLE`;

export const defaultChartValues = (pageType: ReportPageType): ReportPageV2 => {
  switch (pageType) {
    case ReportPageType.weightedCapital:
      return {
        pageType,
        category: FormCategory.HUMAN,
      };
    case ReportPageType.formulaTree:
      return {
        pageType,
        formId: undefined,
      };
    case ReportPageType.tagRadar:
      return {
        pageType,
        title: 'Radar global',
        radarZoomPercent: 100,
        groups: [{groupTitle: undefined, groupColor: '000000', tags: []}],
        richExplain: '',
      };
    case ReportPageType.tagDetails:
      return {
        pageType,
        title: 'Détails',
        tags: [],
      };

    case ReportPageType.competitivite:
      return {
        pageType,
        title: 'COMPETITIVITE',
        subTitle: 'RISQUES ET LEVIERS',
        barTitle: 'Organisation et Parties Prenantes',
        richExplain: competitiviteExplain,
      };

    case ReportPageType.equlibreForce:
      return {
        pageType,
        title: 'Equilibre des forces',
        ratios: [],
        richExplain: '',
      };

    case ReportPageType.indexDurable:
      return {
        pageType,
        title: creationDurableTitre,
        richExplain: creationDurableExplain,
      };

    case ReportPageType.radar:
      return {
        pageType,
        title: radarGlobalTitre,
        richExplain: radarGlobalExplain,
        explainPonderation: radarGlobalPonderation,
      };

    case ReportPageType.conseilConsultant:
      return {
        pageType,
        title: 'Stratégie',
        conseilConsultant: ConseilConsultant.developper,
      };

    case ReportPageType.cover:
      return {
        pageType,
        richTitle: coverGlobalTitre,
      };
    case ReportPageType.poidsActif:
      return {
        pageType,
      };
    case ReportPageType.forceFaiblesse:
      return {
        pageType,
      };
    case ReportPageType.static:
      return {
        pageType,
        pageName: 'Page PDF Statique',
        pdfS3Key: `staticPdf/${v4()}.pdf`,
      };
    case ReportPageType.richtext:
      return {
        pageType,
        richExplain: '',
        title: 'Texte Riche',
      };
    case ReportPageType.chart:
      throw new Error(`deprecated ReportPageType.chart`);
    default:
      assertUnreachable(pageType);
  }
};
