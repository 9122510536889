import dayjs from 'dayjs';

import {
  isAnswerNoAnswer,
  isAnswerToChapterValidated,
  isAnswerToSelectQuestion,
  isAnswerToTextQuestion,
  UserAnswerNoAnswer,
  UserAnswers,
} from '../schema/rest/answer';

export const filterNewestAnswers = (
  userAnswers: {
    id: number;
    userId: number;
    patentId?: number;
    contents: UserAnswers;
  }[],
): {answers: UserAnswers; questionUuidToUserId: Record<string, number>} => {
  const answers: UserAnswers = {};
  const questionUuidToUserId: Record<string, number> = {};

  userAnswers.forEach((answer) => {
    if (answer.id === -1 && answer.userId === -1) {
      //  https://gitlab.com/logicgram/holo-way/holo-diag/-/issues/251
      const contents = answer.contents;
      for (const key of Object.keys(contents)) {
        const value = contents[key];
        if (
          value &&
          ((isAnswerNoAnswer(value) && value.willNotAnswer) ||
            isAnswerToSelectQuestion(value) ||
            isAnswerToTextQuestion(value))
        ) {
          value.date = dayjs(Date.now()).toISOString();
        }
        answers[key] = value;
      }
      return;
    }
    Object.keys(answer.contents)
      .sort((aKey, bKey) => {
        const a = answer.contents[aKey];
        const b = answer.contents[bKey];

        if (!a && !b) {
          return 0;
        }
        if (!a) {
          return 1;
        }
        if (!b) {
          return -1;
        }

        if (
          (isAnswerNoAnswer(a) ||
            isAnswerToTextQuestion(a) ||
            isAnswerToChapterValidated(a) ||
            isAnswerToSelectQuestion(a)) &&
          (isAnswerNoAnswer(b) ||
            isAnswerToChapterValidated(b) ||
            isAnswerToTextQuestion(b) ||
            isAnswerToSelectQuestion(b))
        ) {
          if (!a.date && !b.date) {
            return 0;
          }
          if (!a.date) {
            return -1;
          }
          if (!b.date) {
            return 1;
          }
          return dayjs(b.date).diff(dayjs(a.date)) > 0 ? -1 : 1;
        }

        return 0;
      })
      .forEach((key) => {
        const currentAnswer = answer.contents[key];
        if (!currentAnswer) {
          return;
        }

        if (!answers[key]) {
          answers[key] = currentAnswer;
          questionUuidToUserId[key] = answer.userId;
          return;
        }

        const reducedAnswer = answers[key];

        if (!reducedAnswer) {
          return;
        }

        let reducedAnswerDate: string | undefined = undefined;
        if (
          isAnswerNoAnswer(reducedAnswer) ||
          isAnswerToTextQuestion(reducedAnswer) ||
          isAnswerToSelectQuestion(reducedAnswer)
        ) {
          reducedAnswerDate = reducedAnswer.date;
        }

        if (
          (isAnswerNoAnswer(reducedAnswer) ||
            isAnswerToTextQuestion(reducedAnswer) ||
            isAnswerToSelectQuestion(reducedAnswer) ||
            (!isAnswerToChapterValidated(reducedAnswer) &&
              (reducedAnswer.commentDate ||
                reducedAnswer.consultantCommentDate))) &&
          isAnswerNoAnswer(currentAnswer)
        ) {
          if (
            !reducedAnswerDate ||
            (currentAnswer.date &&
              reducedAnswerDate &&
              dayjs(currentAnswer.date).isAfter(dayjs(reducedAnswerDate)))
          ) {
            if (
              isAnswerToTextQuestion(reducedAnswer) &&
              currentAnswer.willNotAnswer
            ) {
              delete (reducedAnswer as UserAnswerNoAnswer).text;
            }
            if (
              isAnswerToSelectQuestion(reducedAnswer) &&
              currentAnswer.willNotAnswer
            ) {
              delete (reducedAnswer as UserAnswerNoAnswer).answer;
            }
            // @ts-expect-error definite type of reducedAnswer may be determined by currentAnswer, so unknown by TS
            reducedAnswer.willNotAnswer = currentAnswer.willNotAnswer;
            // @ts-expect-error definite type of reducedAnswer may be determined by currentAnswer, so unknown by TS
            reducedAnswer.date = currentAnswer.date;
            questionUuidToUserId[key] = answer.userId;
          }
        }

        if (
          (isAnswerToTextQuestion(reducedAnswer) ||
            (isAnswerNoAnswer(reducedAnswer) && !reducedAnswer.willNotAnswer) ||
            (!isAnswerToChapterValidated(reducedAnswer) &&
              (reducedAnswer.commentDate ||
                reducedAnswer.consultantCommentDate))) &&
          isAnswerToTextQuestion(currentAnswer)
        ) {
          if (
            !reducedAnswerDate ||
            (currentAnswer.date &&
              reducedAnswerDate &&
              dayjs(currentAnswer.date).isAfter(dayjs(reducedAnswerDate)))
          ) {
            // @ts-expect-error definite type of reducedAnswer may be determined by currentAnswer, so unknown by TS
            reducedAnswer.text = currentAnswer.text;
            // @ts-expect-error definite type of reducedAnswer may be determined by currentAnswer, so unknown by TS
            reducedAnswer.date = currentAnswer.date;
            questionUuidToUserId[key] = answer.userId;
          }
        }

        if (
          (isAnswerToSelectQuestion(reducedAnswer) ||
            (isAnswerNoAnswer(reducedAnswer) && !reducedAnswer.willNotAnswer) ||
            (!isAnswerToChapterValidated(reducedAnswer) &&
              (reducedAnswer.commentDate ||
                reducedAnswer.consultantCommentDate))) &&
          isAnswerToSelectQuestion(currentAnswer)
        ) {
          if (
            !reducedAnswerDate ||
            (currentAnswer.date &&
              reducedAnswerDate &&
              dayjs(currentAnswer.date).isAfter(dayjs(reducedAnswerDate)))
          ) {
            // @ts-expect-error definite type of reducedAnswer may be determined by currentAnswer, so unknown by TS
            reducedAnswer.answer = currentAnswer.answer;
            // @ts-expect-error definite type of reducedAnswer may be determined by currentAnswer, so unknown by TS
            reducedAnswer.date = currentAnswer.date;
            questionUuidToUserId[key] = answer.userId;
          }
        }

        if (
          !isAnswerToChapterValidated(currentAnswer) &&
          !isAnswerToChapterValidated(reducedAnswer)
        ) {
          if (
            (!reducedAnswer.commentDate && currentAnswer.commentDate) ||
            (currentAnswer.commentDate &&
              reducedAnswer.commentDate &&
              dayjs(currentAnswer.commentDate).isAfter(
                dayjs(reducedAnswer.commentDate),
              ))
          ) {
            reducedAnswer.comment = currentAnswer.comment;
          }

          if (
            (!reducedAnswer.consultantCommentDate &&
              currentAnswer.consultantCommentDate) ||
            (currentAnswer.consultantCommentDate &&
              reducedAnswer.consultantCommentDate &&
              dayjs(currentAnswer.consultantCommentDate).isAfter(
                dayjs(reducedAnswer.consultantCommentDate),
              ))
          ) {
            reducedAnswer.consultantComment = currentAnswer.consultantComment;
          }
        }

        if (
          isAnswerToChapterValidated(reducedAnswer) &&
          isAnswerToChapterValidated(currentAnswer)
        ) {
          reducedAnswer.validated = currentAnswer.validated;
        }
      });
  });
  return {answers, questionUuidToUserId};
};
