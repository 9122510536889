import {FC, HTMLProps, useCallback, useEffect, useState} from 'react';

import {apiGet} from '../../util/api';
import {useApi} from '../../util/auth';
import {Spinner} from './Spinner';

interface LazyImageProps extends HTMLProps<HTMLImageElement> {
  src: string;
  alt: string;
}

export const LazyImage: FC<LazyImageProps> = (props) => {
  const api = useApi();
  const [loading, setLoading] = useState(true);

  const [src, setSrc] = useState<string | undefined>(undefined);

  const load = useCallback(async () => {
    if (!api) {
      return;
    }

    const {found, url} = await apiGet<{found: boolean; url: string}>(
      api,
      props.src,
    );

    if (found) {
      setSrc(url);
    } else {
      setSrc(undefined);
    }

    setLoading(false);
  }, [api, props.src]);

  useEffect(() => {
    void load();
  }, [load]);

  if (loading) {
    return <Spinner size="small" />;
  }

  return src ? <img {...props} src={src} /> : null;
};
