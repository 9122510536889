import {FC, ReactNode} from 'react';

import {AuthContext, useAuth} from '../../util/auth';
import {Spinner} from '../ui/Spinner';

export const AuthProvider: FC<{children?: ReactNode}> = ({children}) => {
  const auth = useAuth();

  if (!auth.hasAttemptedSessionRestore) {
    return <Spinner tip="Chargement des données utilisateur" />;
  }

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
