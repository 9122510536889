import {JSONSchemaType} from 'ajv';

export interface UploadFileSchemaType {
  name: string;
  type?: string;
}

export const UploadFileSchema: JSONSchemaType<UploadFileSchemaType> = {
  type: 'object',
  additionalProperties: false,
  required: ['name'],
  properties: {
    name: {
      type: 'string',
    },
    type: {
      type: 'string',
      nullable: true,
    },
  },
} as const;
