import {UploadFile} from 'antd/es/upload';
import {
  AccessReportFile,
  ReportPageType,
  ReportPageV2,
  StaticReportPage,
} from 'holo-api';
import {assertUnreachable} from 'holo-api';

import {CompetitiviteChart} from './charts/CompetitiviteChart';
import {ConseilConsultantChart} from './charts/ConseilConsultantChart';
import {CoverChart} from './charts/CoverChart';
import {CreationDurableChart} from './charts/CreationDurableChart';
import {EquilibreForceChart} from './charts/EquilibreForceChart';
import {ForceFaiblesseChart} from './charts/ForceFaiblessChart';
import {FormulaTreeChart} from './charts/FormulaTreeChart';
import {PoidsActifChart} from './charts/PoidsActifChart';
import {RadarGlobalChart} from './charts/RadarGlobal';
import {RichTextPage} from './charts/RichTextPage';
import {ActifSelectorOptions} from './charts/shared/reportInterfaces';
import {TemplateTag} from './charts/shared/RichTextEditor';
import {StaticPdfPage} from './charts/StaticPdfPage';
import {TagDetailsChart} from './charts/TagDetailsChart';
import {TagRadarChart} from './charts/TagRadarChart';
import {WeightedCapitalChart} from './charts/WeightedCapitalChart';

export const AnyReportPage = ({
  index,
  page,
  setPage,
  accessFiles,
  onUploadStaticPage,
  actifSelectorOptions,
  templateTags,
}: {
  index: number;
  page: ReportPageV2;
  setPage: (page: ReportPageV2) => void;
  accessFiles: Array<AccessReportFile> | null;
  onUploadStaticPage: (
    uploadFile: UploadFile<unknown>,
    page: StaticReportPage,
  ) => void;
  actifSelectorOptions: ActifSelectorOptions;
  templateTags: Array<TemplateTag>;
}) => {
  const pageType = page.pageType;
  switch (pageType) {
    case ReportPageType.static:
      return (
        <StaticPdfPage
          page={page}
          onUploadStaticPage={onUploadStaticPage}
          accessFile={accessFiles?.find(
            (accessFile) => accessFile.s3Key === page.pdfS3Key,
          )}
        ></StaticPdfPage>
      );
    case ReportPageType.richtext:
      return (
        <RichTextPage
          index={index}
          richTextPage={page}
          setRichTextPage={setPage}
          templateTags={templateTags}
        ></RichTextPage>
      );
    case ReportPageType.cover:
      return (
        <CoverChart
          index={index}
          coverPage={page}
          setCoverPage={setPage}
          templateTags={templateTags}
        ></CoverChart>
      );

    case ReportPageType.weightedCapital:
      return (
        <WeightedCapitalChart
          chartPage={page}
          setChartPage={setPage}
        ></WeightedCapitalChart>
      );

    case ReportPageType.forceFaiblesse:
      return (
        <ForceFaiblesseChart
          chartPage={page}
          setChartPage={setPage}
          actifSelectorOptions={actifSelectorOptions}
        ></ForceFaiblesseChart>
      );

    case ReportPageType.formulaTree:
      return (
        <FormulaTreeChart
          index={index}
          chartPage={page}
          setChartPage={setPage}
          actifSelectorOptions={actifSelectorOptions}
          templateTags={templateTags}
        ></FormulaTreeChart>
      );

    case ReportPageType.tagRadar:
      return (
        <TagRadarChart
          index={index}
          chartPage={page}
          setChartPage={setPage}
          templateTags={templateTags}
        ></TagRadarChart>
      );

    case ReportPageType.equlibreForce:
      return (
        <EquilibreForceChart
          index={index}
          chartPage={page}
          setChartPage={setPage}
          actifSelectorOptions={actifSelectorOptions}
          templateTags={templateTags}
        ></EquilibreForceChart>
      );

    case ReportPageType.tagDetails:
      return (
        <TagDetailsChart
          chartPage={page}
          setChartPage={setPage}
          index={index}
          templateTags={templateTags}
        ></TagDetailsChart>
      );

    case ReportPageType.competitivite:
      return (
        <CompetitiviteChart
          index={index}
          chartPage={page}
          setChartPage={setPage}
          actifSelectorOptions={actifSelectorOptions}
          templateTags={templateTags}
        ></CompetitiviteChart>
      );

    case ReportPageType.indexDurable:
      return (
        <CreationDurableChart
          index={index}
          chartPage={page}
          setChartPage={setPage}
          templateTags={templateTags}
        ></CreationDurableChart>
      );

    case ReportPageType.radar:
      return (
        <RadarGlobalChart
          index={index}
          chartPage={page}
          setChartPage={setPage}
          templateTags={templateTags}
        ></RadarGlobalChart>
      );

    case ReportPageType.conseilConsultant:
      return (
        <ConseilConsultantChart
          index={index}
          chartPage={page}
          setChartPage={setPage}
          templateTags={templateTags}
        ></ConseilConsultantChart>
      );

    case ReportPageType.poidsActif:
      return (
        <PoidsActifChart
          chartPage={page}
          setChartPage={setPage}
        ></PoidsActifChart>
      );

    default:
      assertUnreachable(pageType);
  }
};
