import {JSONSchemaType} from 'ajv';

export type EmptyObjectT = object;
export const EmptyObjectSchema: JSONSchemaType<EmptyObjectT> = {
  type: 'object',
  additionalProperties: false,
};

export interface IdPathParamsT {
  id: string;
}
export const IdPathParamsSchema: JSONSchemaType<IdPathParamsT> = {
  type: 'object',
  additionalProperties: false,
  required: ['id'],
  properties: {
    id: {type: 'string'},
  },
};

export interface DeleteO {
  count: number;
}
export const DeleteOSchema: JSONSchemaType<DeleteO> = {
  type: 'object',
  additionalProperties: false,
  required: ['count'],
  properties: {
    count: {type: 'integer'},
  },
};

export interface PaginateQueryParamsT {
  skip: string;
}
export const PaginateQueryParamsSchema: JSONSchemaType<PaginateQueryParamsT> = {
  type: 'object',
  additionalProperties: false,
  required: ['skip'],
  properties: {
    skip: {type: 'string'},
  },
};

export const IdSchema: JSONSchemaType<number> = {
  type: 'integer',
};
