import {ColorPicker as AntColorPicker, ColorPickerProps} from 'antd';
import {FC} from 'react';

interface Props extends Omit<ColorPickerProps, 'onChange'> {
  onChange?: (value: string) => void;
}

export const ColorPicker: FC<Props> = ({onChange, ...props}) => {
  return (
    <AntColorPicker
      {...props}
      onChangeComplete={(value) => onChange?.(value.toHex())}
    />
  );
};
