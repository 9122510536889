import {Progress} from 'antd';
import React from 'react';

interface progressBarProps {
  percent: number;
}

const ProgressBar: React.FC<progressBarProps> = (props) => {
  const {percent} = props;

  return (
    <div>
      <Progress percent={percent} showInfo={false} status="normal" />
      <p style={{textAlign: 'center', margin: 0}}>{percent}%</p>
    </div>
  );
};

export default ProgressBar;
