import {APIClass} from 'aws-amplify';
import {
  CreateFormPackCompanyGroupRightSchemaType,
  CreatePackSchemaType,
  FormPackCompanyGroupRightGetRightPluralO,
  formPackCompanyGroupRightRoutes,
  FormPackGetRightPluralO,
  GetPackAvailableFormsO,
  GetSingularPack,
  PackGetPluralO,
  packGetPluralPath,
  PackGetPluralQ,
  UpdatePackReportI,
  UpdatePackReportO,
  updatePackReportPath,
  UpdatePackSchemaType,
} from 'holo-api';

import {apiDel, apiGet, apiPost, apiPut} from '../util/api';

export const packGetSingular = async (api: APIClass, packId: number) => {
  const pack = await apiGet<GetSingularPack>(api, `/pack/${packId}`);
  return pack;
};

export const packGetPlural = async (api: APIClass, query: PackGetPluralQ) => {
  const params = new URLSearchParams();
  query.sort && params.set('sort', query.sort);
  query.direction && params.set('direction', query.direction);
  query.filters && params.set('filters', query.filters);
  query.skip && params.set('skip', query.skip);
  query.take && params.set('take', query.take);
  return apiGet<PackGetPluralO>(
    api,
    `${packGetPluralPath}?${params.toString()}`,
  );
};

export const packPutSingular = async (
  api: APIClass,
  input: CreatePackSchemaType,
) =>
  apiPut<number, CreatePackSchemaType>(api, '/pack', {
    body: input,
  });

export const packPostSingular = async (
  api: APIClass,
  packId: number,
  input: UpdatePackSchemaType,
) =>
  apiPost<number, UpdatePackSchemaType>(api, `/pack/${packId}`, {
    body: input,
  });

export const packDelSingular = async (api: APIClass, packId: number) =>
  apiDel<{count: number}>(api, `/pack/${packId}`);

export const getPackAvailableForms = async (api: APIClass, packId: number) =>
  apiGet<GetPackAvailableFormsO>(api, `/pack/${packId}/availableForms`);

export const packUpdateReport = async (
  api: APIClass,
  input: UpdatePackReportI,
) =>
  apiPost<UpdatePackReportO, UpdatePackReportI>(api, updatePackReportPath, {
    body: input,
  });

export const getFormPackGetRightPlural = ({
  api,
  packId,
}: {
  api: APIClass;
  packId: number;
}) => apiGet<FormPackGetRightPluralO>(api, `/pack/${packId}/forms`);

export const formPackCompanyGroupRightGetRightPlural = async (input: {
  companyGroupId: number;
  api: APIClass;
}) =>
  apiGet<FormPackCompanyGroupRightGetRightPluralO>(
    input.api,
    formPackCompanyGroupRightRoutes.pluralRight.replace(
      '{companyGroupId}',
      `${input.companyGroupId}`,
    ),
  );

export const createFormPackCompanyGroupRight = async (input: {
  data: CreateFormPackCompanyGroupRightSchemaType;
  api: APIClass;
}) =>
  apiPut<
    {formId: number; packId: number; companyGroupId: number},
    CreateFormPackCompanyGroupRightSchemaType
  >(
    input.api,

    formPackCompanyGroupRightRoutes.singular,
    {body: input.data},
  );

export const deleteFormPackCompanyGroupRight = async (input: {
  formId: number;
  packId: number;
  companyGroupId: number;
  api: APIClass;
}) =>
  apiDel<{formId: number; packId: number; companyGroupId: number}>(
    input.api,
    formPackCompanyGroupRightRoutes.singularId
      .replace('{formId}', `${input.formId}`)
      .replace('{packId}', `${input.packId}`)
      .replace('{companyGroupId}', `${input.companyGroupId}`),
  );

export const getFormPackCompanyGroupRight = async (input: {
  formId: number;
  packId: number;
  companyGroupId: number;
  api: APIClass;
}) =>
  apiGet<FormPackCompanyGroupRightGetRightPluralO>(
    input.api,
    formPackCompanyGroupRightRoutes.singularId
      .replace('{formId}', `${input.formId}`)
      .replace('{packId}', `${input.packId}`)
      .replace('{companyGroupId}', `${input.companyGroupId}`),
  );
