import {APIClass} from 'aws-amplify';
import {
  CreateFormSchemaType,
  CreateSingularO,
  CreateTemplateInstanceSchemaType,
  GetActivePackFormTitlesO,
  GetAllActifsO,
  getAllActifsPath,
  GetAllFormPatentAnswersO,
  GetAllGroupsWithAccessToFormO,
  GetFormsSummariesI,
  GetFormsSummariesO,
  getFormsSummariesPath,
  GetPluralFormO,
  GetPluralPatentO,
  GetSearchedFormTitlesO,
  GetSingularForm,
  GetTemplateInstanceCompanyO,
  UpdateAnswerSchemaType,
  UpdatePatentAnswerSchemaType,
  UpdateSingularO,
  UpdateSingularPatentO,
  UpdateTemplateInstanceSchemaType,
  UserAnswers,
} from 'holo-api';

import {apiDel, apiGet, apiPost, apiPut} from '../util/api';

export const getAllActifs = async (api: APIClass) =>
  apiGet<GetAllActifsO>(api, getAllActifsPath);

export const formGetActivePackFormTitles = async (api: APIClass) =>
  apiGet<GetActivePackFormTitlesO>(api, `/forms/titles/activePack`);

export const formGetSingular = async (
  api: APIClass,
  formId: number,
  input: {viewAsAdmin: boolean},
) =>
  apiGet<GetSingularForm>(
    api,
    `/form/${formId}${input.viewAsAdmin ? '?role=ADMIN' : ''}`,
  );

export const formGetPlural = async (api: APIClass) =>
  apiGet<GetPluralFormO>(api, '/forms');

export const formDeleteSingular = async (api: APIClass, formId: number) =>
  apiDel<{linkedPacks: {id: number; name: string}[]} | {count: number}>(
    api,
    `/form/${formId}`,
  );

export const duplicateForm = async (
  api: APIClass,
  formId: number,
  title: string,
) =>
  apiPost<{newFormId: number}, {title: string}>(
    api,
    `/form/${formId}/duplicate`,
    {body: {title}},
  );

export const getFormAnswer = async (api: APIClass, formId: number) =>
  apiGet<{id: number; userId: number; contents: UserAnswers}[]>(
    api,
    `/form/${formId}/answer`,
  );

export const postFormAnswer = async (
  api: APIClass,
  formId: number,
  userAnswers: UserAnswers,
) => {
  const input: UpdateAnswerSchemaType = {
    contents: userAnswers,
  };

  const answerId: number = await apiPost<number, UpdateAnswerSchemaType>(
    api,
    `/form/${formId}/answer`,
    {
      body: input,
    },
  );
  return answerId;
};

// not all queryString for now and limit required
export const getSearchedFormTitles = async (
  api: APIClass,
  input: {limit: number},
) =>
  apiGet<GetSearchedFormTitlesO>(
    api,
    `/forms/titles/search?limit=${input.limit}`,
  );

export const createSingularForm = async (
  api: APIClass,
  input: CreateFormSchemaType,
) =>
  apiPut<CreateSingularO, CreateFormSchemaType>(api, '/form', {
    body: input,
  });

export const updateSingularForm = async (
  api: APIClass,
  formId: number,
  input: CreateFormSchemaType,
) =>
  apiPost<UpdateSingularO, CreateFormSchemaType>(api, `/form/${formId}`, {
    body: input,
  });

export const getAllFormPatentAnswers = async (api: APIClass, formId: number) =>
  apiGet<GetAllFormPatentAnswersO>(api, `/form/${formId}/allPatentAnswers`);

export const getAllGroupsWithAccessToForm = async (
  api: APIClass,
  {formId, companyId}: {formId: number; companyId: number},
) =>
  apiGet<GetAllGroupsWithAccessToFormO>(
    api,
    `/form/${formId}/${companyId}/groups`,
  );

export const formPackDelSingular = async (
  api: APIClass,
  input: {formId: number; packId: number},
) => apiDel<{count: number}>(api, `/formPack/${input.formId}/${input.packId}`);

export const getTemplateInstanceCompany = async (
  api: APIClass,
  formId: number,
) => apiGet<GetTemplateInstanceCompanyO>(api, `/patents/${formId}`);

export const getTemplateInstanceAnswer = async (
  api: APIClass,
  templateInstanceId: number,
) =>
  apiGet<{id: number; userId: number; contents: UserAnswers}[]>(
    api,
    `/patent/${templateInstanceId}/answer`,
  );

export const updatePatentAnswer = async (
  api: APIClass,
  patentId: number,
  input: UserAnswers,
) =>
  apiPost<number, UpdatePatentAnswerSchemaType>(
    api,
    `/patent/${patentId}/answer`,
    {
      body: {contents: input},
    },
  );

export const deleteSingularPatent = async (api: APIClass, patentId: number) =>
  apiDel<void>(api, `/patent/${patentId}`);

export const updateSingularPatent = async (
  api: APIClass,
  patentId: number,
  input: UpdateTemplateInstanceSchemaType,
) =>
  apiPost<UpdateSingularPatentO, UpdateTemplateInstanceSchemaType>(
    api,
    `/patent/${patentId}`,
    {body: input},
  );

export const patentGetPlural = async (api: APIClass) =>
  apiGet<GetPluralPatentO>(api, '/patents');

export const createTemplateInstance = ({
  api,
  input,
}: {
  api: APIClass;
  input: CreateTemplateInstanceSchemaType;
}) =>
  apiPut<{id: number}, CreateTemplateInstanceSchemaType>(api, `/patent`, {
    body: input,
  });

export const getFormsSummaries = async (
  api: APIClass,
  input: GetFormsSummariesI,
) =>
  apiPost<GetFormsSummariesO, GetFormsSummariesI>(api, getFormsSummariesPath, {
    body: input,
  });
