import {APIClass} from 'aws-amplify';
import {
  CompanyPackDeleteSingularIdP,
  CompanyPackGetLeftPluralO,
  CompanyPackGetRightPluralO,
  CompanyPackGetSingularIdO,
  CompanyPackGetSingularIdP,
  CompanyPackPutSingularI,
  CompanyPackPutSingularO,
  CompanyPackSetActiveP,
} from 'holo-api';

import {apiDel, apiGet, apiPost, apiPut} from '../util/api';

export const getCompanyPack = ({
  api,
  params,
}: {
  api: APIClass;
  params: CompanyPackGetSingularIdP;
}) =>
  apiGet<CompanyPackGetSingularIdO>(
    api,
    `/companyPack/${params.companyId}/${params.packId}`,
  );

export const deleteCompanyPack = ({
  api,
  params,
}: {
  api: APIClass;
  params: CompanyPackDeleteSingularIdP;
}) => apiDel(api, `/companyPack/${params.companyId}/${params.packId}`);

export const createCompanyPack = (
  api: APIClass,
  input: CompanyPackPutSingularI,
) =>
  apiPut<CompanyPackPutSingularO, CompanyPackPutSingularI>(
    api,
    '/companyPack',
    {
      body: input,
    },
  );

export const setActivePack = ({
  api,
  params,
}: {
  api: APIClass;
  params: CompanyPackSetActiveP;
}) =>
  apiPost(api, `/companyPack/${params.companyId}/${params.packId}/setActive`, {
    body: {},
  });

export const getCompanyPackGetRightPlural = ({
  api,
  packId,
}: {
  api: APIClass;
  packId: number;
}) => apiGet<CompanyPackGetRightPluralO>(api, `/pack/${packId}/companies`);

export const companyPackGetLeftPlural = ({
  api,
  companyId,
}: {
  api: APIClass;
  companyId: number;
}) => apiGet<CompanyPackGetLeftPluralO>(api, `/company/${companyId}/packs`);
