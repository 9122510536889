import {HistoryOutlined} from '@ant-design/icons';
import {
  Button,
  Collapse,
  Form,
  Input,
  message,
  Row,
  Select,
  SelectProps,
  Space,
  Tag,
  theme,
  Typography,
} from 'antd';
import {DefaultOptionType} from 'antd/es/select';
import {GetCompanyUser, Role, SelectQuestion} from 'holo-api';
import {FC, useEffect, useMemo, useState} from 'react';

import {useViewerStore} from '../../../stores/viewer';
import {useUserRole} from '../../../util/auth';
import {colors} from '../../../util/form';
import {ConsultantComment} from './ConsultantComment';
import {CustomTooltip} from './CustomTooltip';
import {NoAnswerCheckbox} from './NoAnswerCheckbox';
import {ProofFactorTooltip} from './ProofFactorTooltip';

const MaxSelect: FC<SelectProps & {max?: number}> = ({
  onChange,
  max,
  ...props
}) => {
  const handleChange = (
    value: string,
    option: DefaultOptionType | DefaultOptionType[],
  ) => {
    if (props.mode === 'multiple' && max && value.length > max) {
      void message.error(`Nombre de réponses maximum atteint (${max})`);
      return;
    }
    onChange?.(value, option);
  };

  return (
    <Select
      style={{width: '100%'}}
      onChange={handleChange}
      className="select-adaptive-height"
      {...props}
    />
  );
};

interface ViewerSelectInputProps extends SelectQuestion {
  id: number;
  chapterIdx: number;
  viewAsUser: boolean;
  onHistory: () => void;
}

export const ViewerSelectInput: FC<ViewerSelectInputProps> = ({
  uuid,
  answers,
  title,
  placeholder = 'Type or select an option.',
  optional = false,
  multiple,
  tooltip,
  proofFactor,
  logo,
  id,
  chapterIdx,
  viewAsUser,
  onHistory,
}) => {
  const userRole = useUserRole();
  const isAdmin = userRole === Role.ADMIN;
  const {token} = theme.useToken();
  const primaryColor = token.colorPrimary;

  const isPreliminaryForm = useViewerStore((state) => state.isPreliminaryForm);
  const templateInstanceId = useViewerStore(
    (state) => state.templateInstanceId,
  );
  const questionUuidToUserId = useViewerStore(
    (state) => state.questionUuidToUserId,
  );
  const templateQuestionUuidToUserId = useViewerStore(
    (state) => state.templateQuestionUuidToUserId,
  );
  const companyUsers = useViewerStore((state) => state.companyUsers);
  const readOnly = useViewerStore((state) => state.readOnly);
  const isTemplate = useViewerStore((state) => state.isTemplate);

  const [answerUser, setAnswerUser] = useState<GetCompanyUser | undefined>(
    undefined,
  );

  useEffect(() => {
    if (isTemplate && !templateInstanceId) {
      return;
    }
    if (templateInstanceId) {
      setAnswerUser(
        companyUsers[templateQuestionUuidToUserId[templateInstanceId][uuid]],
      );
    } else {
      setAnswerUser(companyUsers[questionUuidToUserId[uuid]]);
    }
  }, [
    isTemplate,
    templateInstanceId,
    companyUsers,
    uuid,
    templateQuestionUuidToUserId,
    questionUuidToUserId,
  ]);

  const form = Form.useFormInstance();
  const comment =
    Form.useWatch([uuid, 'comment'], {form, preserve: true}) ??
    form.getFieldValue([uuid, 'comment']);
  const willNotAnswer = Form.useWatch([uuid, 'willNotAnswer'], {
    form,
  });

  if (!answers) throw new Error('No answers provided for this question');

  const options = useMemo(
    () =>
      answers.map((answer) => ({
        value: answer.uuid,
        label:
          answer.label +
          (isAdmin && !viewAsUser && answer.value !== null
            ? ` (${answer.value})`
            : ''),
      })),
    [answers, isAdmin, viewAsUser],
  );

  return (
    <Row id={`C${chapterIdx}I${id}`}>
      <Space
        direction="vertical"
        style={{
          width: '100%',
          marginBottom: 10,
        }}
      >
        <Form.Item
          name={[uuid, 'answer']}
          label={
            <Space style={{width: '100%', justifyContent: 'space-between'}}>
              <Space>
                {logo ? (
                  <img src={logo} alt="Logo for current question" />
                ) : null}
                <span style={{fontWeight: 'bold'}}>{title}</span>
                {isAdmin && !viewAsUser && (
                  <Tag color={colors[(chapterIdx + id) % 12]}>
                    {`C${chapterIdx + 1}S${id + 1}`}
                  </Tag>
                )}
              </Space>
              <Space>
                {tooltip && (
                  <CustomTooltip tooltipText={tooltip} itemTitle={title} />
                )}
                {proofFactor && (
                  <ProofFactorTooltip
                    tooltipText={proofFactor}
                    questionTitle={title}
                  />
                )}
                <Button icon={<HistoryOutlined />} onClick={onHistory}></Button>
                {answerUser && answerUser.role === Role.CONSULTANT ? (
                  <Typography.Text
                    type="secondary"
                    italic
                    style={{color: 'royalblue'}}
                  >
                    Réponse apportée par{' '}
                    {answerUser.firstName + ' ' + answerUser.lastName}
                  </Typography.Text>
                ) : null}
              </Space>
            </Space>
          }
          rules={[
            {
              required: optional ? !willNotAnswer : true,
              message: 'Veuillez sélectionner une réponse',
            },
          ]}
          style={
            optional
              ? {marginBottom: 0, padding: '5px', borderRadius: '10px'}
              : {padding: '5px', borderRadius: '10px', marginBottom: 0}
          }
        >
          <MaxSelect
            mode={multiple > 1 ? 'multiple' : undefined}
            max={multiple > 1 ? multiple : undefined}
            placeholder={placeholder}
            optionFilterProp="label"
            options={options}
            disabled={willNotAnswer || readOnly}
          />
        </Form.Item>
        {optional && <NoAnswerCheckbox uuid={uuid} />}
        {isPreliminaryForm ? null : (
          <Collapse
            size="small"
            items={[
              {
                label: (
                  <Typography.Text
                    style={
                      comment && comment !== ''
                        ? {color: primaryColor, textDecoration: 'underline'}
                        : undefined
                    }
                  >
                    Commentaires pour les facteurs de preuve
                  </Typography.Text>
                ),
                key: 1,
                children: (
                  <Form.Item style={{marginBottom: 0}} name={[uuid, 'comment']}>
                    <Input.TextArea
                      autoSize={{minRows: 1, maxRows: 5}}
                      disabled={readOnly}
                    />
                  </Form.Item>
                ),
              },
            ]}
          />
        )}
        {!isPreliminaryForm && <ConsultantComment uuid={uuid} />}
      </Space>
    </Row>
  );
};
