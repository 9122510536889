import {DownloadOutlined, FilePdfOutlined} from '@ant-design/icons';
import {useInterval} from 'ahooks';
import {Badge, Button, Tooltip} from 'antd';
import {useEffect, useState} from 'react';

import {generateReport, pollReport} from '../../client/pdf';
import {useUserStore} from '../../stores/user';
import {useApi} from '../../util/auth';

interface ReportInfo {
  signedGet: string;
  date: Date;
}

export const GenerateReport = ({
  preview,
  reportId,
  disabled,
}: {
  preview?: boolean;
  reportId?: number;
  disabled?: boolean;
}) => {
  const companyId = useUserStore((state) => state.companyId);
  const [buzy, setBuzy] = useState(companyId !== undefined); // if you cannot generate a report, there is no report
  const [generate, setGenerate] = useState(false);
  const api = useApi();
  const [reportInfo, setReportInfo] = useState<ReportInfo | undefined>();
  const pollInterval = 5000; // in ms

  useInterval(
    async () => {
      if (api && companyId) {
        try {
          const {generating, signedGet, hasReport, reportDate} =
            await pollReport(api, {
              reportId,
            });
          if (hasReport) {
            setReportInfo({date: new Date(reportDate), signedGet});
          }
          setBuzy(generating);
        } catch (err) {
          console.error('error in polling', err);
          setBuzy(false); // avoid looping errors
        }
      }
    },
    buzy ? pollInterval : undefined,
    {immediate: true}, // call at least once immidiatly at page load
  );

  const doGenerateReport = async () => {
    if (api && companyId) {
      try {
        setGenerate(true);
        await generateReport(api, {
          reportId,
          outputHtml: preview,
        });
        setBuzy(true);
      } finally {
        setGenerate(false);
      }
    }
  };

  useEffect(() => {
    setReportInfo(undefined);
    if (companyId) {
      setBuzy(true);
    }
  }, [companyId]);

  return (
    <Tooltip
      title={
        companyId
          ? ''
          : 'Veuillez vous assigner une entreprise avant de générer ce rapport'
      }
    >
      <Button.Group>
        <Button
          icon={<FilePdfOutlined />}
          onClick={() => {
            void doGenerateReport();
          }}
          loading={buzy || generate}
          disabled={!companyId || disabled}
        >
          Générer le rapport
        </Button>
        <Button
          icon={
            <Badge dot={!!reportInfo}>
              <DownloadOutlined style={{color: 'white'}} />
            </Badge>
          }
          type="primary"
          loading={buzy || generate}
          href={reportInfo?.signedGet}
          target="_blank"
          disabled={!reportInfo}
        >
          {reportInfo
            ? `Version du ${reportInfo.date.toLocaleDateString()}`
            : ''}
        </Button>
      </Button.Group>
    </Tooltip>
  );
};
