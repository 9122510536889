import {customMath} from 'holo-api';

const {parse} = customMath;

export const operands = [
  '+',
  '-',
  '*',
  '/',
  '(',
  ')',
  'null',
  'SOMME()',
  'MOYENNE()',
  'IF()',
  'MAT1()',
  'MAT2()',
];

/**
 * The function goes through a formula, parses it to manipulate each of its elements via the callback provided
 *
 * @param formula expression being worked on
 *
 * @param  callbackfn A function that accepts an HTML element as an argument, which has properties related to the element of the formula being parsed (e.g child.textContent contains the text representation of the formula element)
 */
export const parseAndTraverseFormula = (
  formula: string,
  callback: (child: Element, index: number) => void,
) => {
  const parsed = parse(formula);
  const html = document.createElement('div');
  html.innerHTML = parsed.toHTML();
  Array.from(html.children).forEach((child, index) => {
    callback(child, index);
  });
  html.remove();
};
