import {Form, Input, Modal, ModalProps, Select} from 'antd';
import {GetPackAvailableFormsO} from 'holo-api';
import {FC, useEffect, useState} from 'react';

import {packPostSingular, packPutSingular} from '../../../client/pack';
import {PackTableDataType} from '../../../Packs';
import {useApi} from '../../../util/auth';
import {emptyUUID} from '../../../util/validator';
import {DynamicSelect} from '../DynamicSelect';

interface PackModalProps extends ModalProps {
  id: number | 'new' | undefined;
  formOptions: GetPackAvailableFormsO | undefined;
  editedPack?: PackTableDataType;
}

interface FormData {
  uuid: string;
  version: string;
  name: string;
  description: string;
  formIds: Array<number>;
}

export const PackModal: FC<PackModalProps> = ({
  id,
  formOptions,
  editedPack,
  ...props
}) => {
  const [form] = Form.useForm<FormData>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const api = useApi();

  useEffect(() => {
    if (!id) {
      return;
    }
    if (id === 'new') {
      form.setFieldsValue({
        uuid: emptyUUID,
        version: '1.1.0',
        name: '',
        description: '',
        formIds: [],
      });
    }
    if (typeof id === 'number') {
      form.setFieldsValue({
        ...editedPack,
        formIds: formOptions?.linkedForms
          .filter((form) => !form.isPreliminary)
          .map((form) => form.id),
      });
    }
  }, [id, editedPack, form, formOptions]);
  return (
    <Modal
      title={
        id === 'new'
          ? "Création d'un pack"
          : typeof id === 'number'
            ? `Édition du pack ${editedPack?.name}`
            : undefined
      }
      centered
      confirmLoading={isLoading}
      forceRender
      maskClosable={false}
      open={!!id}
      width="600px"
      {...props}
      onOk={async (e) => {
        setIsLoading(true);
        try {
          const value = await form.validateFields();
          if (!api) {
            return;
          }
          if (id === 'new') {
            await packPutSingular(api, {
              name: value.name,
              description: value.description,
              formIds: value.formIds,
              minorVersion: 1,
              patchVersion: 1,
            });
          }
          if (typeof id === 'number') {
            await packPostSingular(api, id, value);
          }
          form.resetFields();
          setIsLoading(false);
          props.onOk?.(e);
        } catch (err) {
          console.error(err);
          setIsLoading(false);
        }
      }}
      onCancel={(e) => {
        form.resetFields();
        props.onCancel?.(e);
      }}
    >
      <Form form={form} layout="vertical">
        {id === 'new' && (
          <Form.Item label="Version" name="version" hidden>
            <Input hidden />
          </Form.Item>
        )}
        <Form.Item label="Nom" name="name" rules={[{required: true}]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[{required: true}]}
        >
          <Input.TextArea autoSize />
        </Form.Item>
        <Form.Item
          label="Formulaires"
          name="formIds"
          rules={[{required: true}]}
        >
          {id === 'new' && (
            <DynamicSelect
              url="/forms/titles/search"
              api={api}
              label="title"
              mode="multiple"
              placeholder="Recherche de formulaire"
            />
          )}
          {typeof id === 'number' && (
            <Select
              mode="multiple"
              allowClear
              style={{width: '100%'}}
              placeholder="Sélection de formulaires"
              options={
                formOptions &&
                [...formOptions.linkedForms, ...formOptions.availableForms].map(
                  (form) => ({
                    label: form.title,
                    value: form.id,
                  }),
                )
              }
            />
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};
