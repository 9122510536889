import {JSONSchemaType} from 'ajv';

export interface CreateCompanyGroupSchemaType {
  name: string;
  description?: string;
  companyId: number;
  userIds?: number[];
}

export const CreateCompanyGroupSchema: JSONSchemaType<CreateCompanyGroupSchemaType> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['name', 'companyId'],
    properties: {
      name: {
        type: 'string',
      },
      description: {
        type: 'string',
        nullable: true,
      },
      companyId: {
        type: 'number',
      },
      userIds: {
        type: 'array',
        nullable: true,
        items: {
          type: 'number',
        },
      },
    },
  } as const;

export interface UpdateCompanyGroupSchemaType {
  name?: string;
  description?: string;
  userIds?: number[];
}

export const UpdateCompanyGroupSchema: JSONSchemaType<UpdateCompanyGroupSchemaType> =
  {
    type: 'object',
    additionalProperties: false,
    properties: {
      name: {
        type: 'string',
        nullable: true,
      },
      description: {
        type: 'string',
        nullable: true,
      },
      userIds: {
        type: 'array',
        nullable: true,
        items: {
          type: 'number',
        },
      },
    },
  } as const;
