import {DeleteOutlined, PlusSquareOutlined} from '@ant-design/icons';
import {Button, Col, Form, Input, Row} from 'antd';

import {
  formulaTagNormalize,
  formulaTagRules,
} from '../../../../util/formulaTagRules';

export const TagList = ({
  subNamePath,
}: {
  subNamePath: Array<number | string>;
}) => {
  return (
    <Form.Item label="Tags">
      <Form.List name={[...subNamePath, 'tags']}>
        {(subFields, subOpt) => (
          <div style={{display: 'flex', flexDirection: 'column', rowGap: 16}}>
            {subFields.map((subField) => (
              <div
                key={subField.key}
                style={{display: 'flex', flexDirection: 'row', columnGap: 8}}
              >
                <Form.Item
                  name={[subField.name, 'tag']}
                  normalize={formulaTagNormalize}
                  rules={formulaTagRules}
                >
                  <Input placeholder="tag" />
                </Form.Item>
                <Form.Item name={[subField.name, 'label']} style={{flex: 1}}>
                  <Input placeholder="label" />
                </Form.Item>
                <Button
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => subOpt.remove(subField.name)}
                ></Button>
              </div>
            ))}

            <Row>
              <Col>
                <Button.Group>
                  <Button
                    icon={<PlusSquareOutlined />}
                    type="dashed"
                    onClick={() => {
                      subOpt.add({
                        tag: '#NO_TAG',
                        label: 'No Label',
                      });
                    }}
                  >
                    Ajouter un tag
                  </Button>
                </Button.Group>
              </Col>
            </Row>
          </div>
        )}
      </Form.List>
    </Form.Item>
  );
};
