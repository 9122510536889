import {CoverChartReportPage} from 'holo-api';

import {RichTextEditor, TemplateTag} from './shared/RichTextEditor';

export const CoverChart = ({
  index,
  coverPage,
  setCoverPage,
  templateTags,
}: {
  index: number;
  coverPage: CoverChartReportPage;
  setCoverPage: (page: CoverChartReportPage) => void;
  templateTags: Array<TemplateTag>;
}) => {
  const onRichTextChanged = (text: string) => {
    const newPage = {...coverPage};
    newPage.richTitle = text;
    setCoverPage(newPage);
  };

  return (
    <RichTextEditor
      uniqueId={`page_${index}`}
      richText={coverPage.richTitle ?? ''}
      setRichText={onRichTextChanged}
      templateTags={templateTags}
    ></RichTextEditor>
  );
};
