import {QuestionCircleOutlined} from '@ant-design/icons';
import {Modal, theme, Typography} from 'antd';
import Linkify from 'linkify-react';
import {FC} from 'react';

interface CustomTooltipProps {
  tooltipText: string;
  itemTitle?: string;
}

export const CustomTooltip: FC<CustomTooltipProps> = ({
  tooltipText,
  itemTitle,
}) => {
  const {token} = theme.useToken();
  const [modal, contextHolder] = Modal.useModal();
  return (
    <span>
      {contextHolder}
      <QuestionCircleOutlined
        style={{
          cursor: 'pointer',
          fontSize: '1.3em',
          color: token.colorPrimary,
        }}
        onClick={() => {
          void modal.info({
            closable: true,
            icon: null,
            centered: true,
            width: '600px',
            content: (
              <>
                {itemTitle && (
                  <Typography.Title
                    level={4}
                    style={{marginTop: 0, marginBottom: 20}}
                  >
                    Informations au sujet de l'élément «{itemTitle}»
                  </Typography.Title>
                )}
                <Typography.Text style={{whiteSpace: 'pre-wrap'}}>
                  <Linkify
                    options={{
                      target: '_blank',
                    }}
                  >
                    {tooltipText}
                  </Linkify>
                </Typography.Text>
              </>
            ),
          });
        }}
      />
    </span>
  );
};
