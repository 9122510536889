import {APIClass} from 'aws-amplify';

import {DEFAULT_ANONYMOUS_API_NAME, DEFAULT_API_NAME} from './auth';

export const apiGet = <TResponse>(
  api: APIClass,
  path: string,
  init: {headers?: Record<string, string>} = {},
): Promise<TResponse> => api.get(DEFAULT_API_NAME, path, init);

export const apiPost = <TResponse, TRequest>(
  api: APIClass,
  path: string,
  init: {body: TRequest; headers?: Record<string, string>},
): Promise<TResponse> => api.post(DEFAULT_API_NAME, path, init);

export const apiPut = <TResponse, TRequest>(
  api: APIClass,
  path: string,
  init: {body: TRequest; headers?: Record<string, string>},
): Promise<TResponse> => api.put(DEFAULT_API_NAME, path, init);

export const apiDel = <TResponse>(
  api: APIClass,
  path: string,
  init: {headers?: Record<string, string>} = {},
): Promise<TResponse> => api.del(DEFAULT_API_NAME, path, init);

export const anonApiDel = <TResponse>(
  api: APIClass,
  path: string,
  init: {headers?: Record<string, string>} = {},
): Promise<TResponse> => api.del(DEFAULT_ANONYMOUS_API_NAME, path, init);
