import {
  FormContentsType,
  FormulaValues,
  GetCompanyUser,
  TemplateFormulaResults,
  UserAnswers,
} from 'holo-api';
import {create} from 'zustand';

import {ChapterAnswersCount, ValidatedChapters} from '../Viewer';

export interface FormulaAverages {
  sum: number;
  totalWeight: number;
  average: number;
}

export interface CompanyUsers {
  [key: number]: GetCompanyUser;
}

export interface QuestionUuidToUserId {
  [key: string]: number;
}

export interface ViewerStoreProps {
  // Form-related
  form: FormContentsType | undefined;
  setForm: (form: FormContentsType | undefined) => void;
  initialFormAnswers: UserAnswers | undefined;
  setInitialFormAnswers: (answers: UserAnswers | undefined) => void;
  formAnswers: UserAnswers | undefined;
  setFormAnswers: (answers: UserAnswers | undefined) => void;
  updateFormAnswers: (answers: UserAnswers | undefined) => void;
  isPreliminaryForm: boolean;
  setIsPreliminaryForm: (value: boolean) => void;
  questionUuidToUserId: QuestionUuidToUserId;
  setQuestionUuidToUserId: (questionUuidToUserId: QuestionUuidToUserId) => void;

  // Template-related
  isTemplate: boolean;
  setIsTemplate: (isTemplate: boolean) => void;
  templateInstanceId: number | undefined;
  setTemplateInstanceId: (templateInstanceId: number | undefined) => void;
  templateInstancesAnswers: {[key: number]: UserAnswers};
  setTemplateInstancesAnswers: (answers: {[key: number]: UserAnswers}) => void;
  templateQuestionUuidToUserId: {[key: number]: QuestionUuidToUserId};
  setTemplateQuestionUuidToUserId: (templateQuestionUuidToUserId: {
    [key: number]: QuestionUuidToUserId;
  }) => void;
  templateFormulasResults: TemplateFormulaResults;
  setTemplateFormulasResults: (
    templateFormulasResults: TemplateFormulaResults,
  ) => void;

  formulaValues: {[key: string]: FormulaValues};
  setFormulaValues: (values: {[key: string]: FormulaValues}) => void;
  activeKeys: string[];
  setActiveKeys: (keys: string[]) => void;
  answersCount: ChapterAnswersCount;
  setAnswersCount: (answersCount: ChapterAnswersCount) => void;
  updateAnswersCount: (answersCount: ChapterAnswersCount) => void;
  pendingUserAnswers: UserAnswers;
  setPendingUserAnswers: (answers: UserAnswers) => void;
  updatePendingUserAnswers: (answers: UserAnswers) => void;
  validatedChapters: ValidatedChapters;
  setValidatedChapters: (chapters: ValidatedChapters) => void;
  updateValidatedChapters: (chapters: ValidatedChapters) => void;
  usersLoaded: boolean;
  companyUsers: CompanyUsers;
  setCompanyUsers: (companyUsers: CompanyUsers) => void;
  readOnly: boolean;
  setReadOnly: (readOnly: boolean) => void;
}

export const useViewerStore = create<ViewerStoreProps>((set) => ({
  form: undefined,
  setForm: (form) =>
    set((state) => ({...state, form: form ? {...form} : undefined})),
  formAnswers: undefined,
  setFormAnswers: (answers) =>
    set((state) => ({...state, formAnswers: {...answers}})),
  updateFormAnswers: (answers) =>
    set((state) => ({
      ...state,
      formAnswers: {...state.formAnswers, ...answers},
    })),
  questionUuidToUserId: {},
  setQuestionUuidToUserId: (questionUuidToUserId) =>
    set((state) => ({
      ...state,
      questionUuidToUserId: {...questionUuidToUserId},
    })),
  initialFormAnswers: undefined,
  setInitialFormAnswers: (answers) =>
    set((state) => ({...state, initialFormAnswers: {...answers}})),
  templateInstancesAnswers: {},
  setTemplateInstancesAnswers: (answers) =>
    set((state) => ({...state, templateInstancesAnswers: {...answers}})),
  templateQuestionUuidToUserId: {},
  setTemplateQuestionUuidToUserId: (templateQuestionUuidToUserId) =>
    set((state) => ({
      ...state,
      templateQuestionUuidToUserId: {...templateQuestionUuidToUserId},
    })),
  formulaValues: {},
  setFormulaValues: (values) =>
    set((state) => ({
      ...state,
      formulaValues: {...values},
    })),
  activeKeys: [],
  setActiveKeys: (keys) => set((state) => ({...state, activeKeys: [...keys]})),
  answersCount: {},
  setAnswersCount: (answersCount) =>
    set((state) => ({...state, answersCount: {...answersCount}})),
  updateAnswersCount: (answersCount) =>
    set((state) => ({
      ...state,
      answersCount: {...state.answersCount, ...answersCount},
    })),
  pendingUserAnswers: {},
  setPendingUserAnswers: (answers) =>
    set((state) => ({...state, pendingUserAnswers: {...answers}})),
  updatePendingUserAnswers: (answers) =>
    set((state) => ({
      ...state,
      pendingUserAnswers: {...state.pendingUserAnswers, ...answers},
    })),
  validatedChapters: {},
  setValidatedChapters: (chapters) =>
    set((state) => ({...state, validatedChapters: {...chapters}})),
  updateValidatedChapters: (chapters) =>
    set((state) => ({
      ...state,
      validatedChapters: {...state.validatedChapters, ...chapters},
    })),
  isPreliminaryForm: false,
  setIsPreliminaryForm: (value) =>
    set((state) => ({...state, isPreliminaryForm: value})),
  templateFormulasResults: {},
  setTemplateFormulasResults: (templateFormulasResults) =>
    set((state) => ({
      ...state,
      templateFormulasResults: {...templateFormulasResults},
    })),
  usersLoaded: false,
  companyUsers: {},
  setCompanyUsers: (companyUsers) =>
    set((state) => ({
      ...state,
      companyUsers: {...companyUsers},
      usersLoaded: true,
    })),
  templateInstanceId: undefined,
  setTemplateInstanceId: (templateInstanceId) =>
    set((state) => ({...state, templateInstanceId})),
  readOnly: true,
  setReadOnly: (readOnly) => set((state) => ({...state, readOnly})),
  isTemplate: false,
  setIsTemplate: (isTemplate) => set((state) => ({...state, isTemplate})),
}));
