import {AimOutlined, CalculatorOutlined} from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Skeleton,
  Space,
  Tag,
  theme,
  Tooltip,
} from 'antd';
import {
  Formula,
  getKeysFromReference,
  isDefaultChapter,
  isFormulaReference,
  isNumericQuestionReference,
  isSelectQuestion,
  isSelectQuestionReference,
  isTemplateFormulaReference,
  isTextQuestion,
  Item,
  ItemType,
  Role,
  UserAnswerSelect,
  UserAnswerText,
  Visibility,
} from 'holo-api';
import {FC, ReactNode, useEffect, useState} from 'react';

import {useViewerStore} from '../../../stores/viewer';
import {useUserRole} from '../../../util/auth';
import {colors, highlightItem} from '../../../util/form';
import {parseAndTraverseFormula} from '../../../util/math';
import {CustomTooltip} from './CustomTooltip';

interface ViewerFormulaProps extends Formula {
  chapterIdx: number;
  formId: number;
  templateInstanceId: number | undefined;
  id: number;
  viewAsUser: boolean;
}

export const ViewerFormula: FC<ViewerFormulaProps> = ({
  uuid,
  formId,
  templateInstanceId,
  formula,
  title,
  tooltip,
  chapterIdx,
  id,
  viewAsUser,
  visibility,
}) => {
  const userRole = useUserRole();
  const isAdmin = userRole === Role.ADMIN;
  const [loading, setLoading] = useState(false);
  const [tokens, setTokens] = useState<ReactNode[]>([]);
  const [formulaValue, setFormulaValue] = useState<number | null | undefined>(
    undefined,
  );
  const [unansweredQuestions, setUnansweredQuestions] = useState<string[]>([]);
  const [currentFormulaUuid, setCurrentFormulaUuid] = useState('');

  const form = useViewerStore((state) => state.form);
  const answers = useViewerStore((state) => state.formAnswers);
  const activeKeys = useViewerStore((state) => state.activeKeys);
  const setActiveKeys = useViewerStore((state) => state.setActiveKeys);
  const formulaValues = useViewerStore((state) => state.formulaValues);
  const templateFormulaResults = useViewerStore(
    (state) => state.templateFormulasResults,
  );
  const isTemplate = useViewerStore((state) => state.isTemplate);

  useEffect(() => {
    if (!formula) {
      setTokens([]);
      if (loading) {
        setLoading(false);
      }
      return;
    }

    if (!loading) {
      setLoading(true);
    }

    if (
      !form ||
      !answers ||
      !formulaValues ||
      !formulaValues[templateInstanceId ?? formId]
    ) {
      return;
    }

    const to = setTimeout(() => {
      try {
        const tempTokens: JSX.Element[] = [];
        parseAndTraverseFormula(formula, (child, index) => {
          const textContent = child.textContent;
          if (!textContent) {
            throw new Error('Un élément de la formule est erroné');
          }
          let tooltip = textContent ?? '';
          const isReferenceToSelectionQuestion =
            isSelectQuestionReference(tooltip);
          const isReferenceToNumericQuestion =
            isNumericQuestionReference(tooltip);
          const isReferenceToFormula = isFormulaReference(tooltip);
          const isReferenceToTemplateFormula =
            isTemplateFormulaReference(tooltip);
          const [chapterIndex, itemIndex] = getKeysFromReference(tooltip);
          const targetChapter = form.chapters[chapterIndex];
          if (isReferenceToFormula) {
            if (!isDefaultChapter(targetChapter)) {
              throw new Error(
                `Reference ${textContent} is not poiting to the correct chapter`,
              );
            }
            const item: Item = targetChapter.items[itemIndex];
            if (item.type === ItemType.FORMULA) {
              const formulaResult =
                formulaValues[templateInstanceId ?? formId][item.uuid].result;
              tooltip = item.title + ` (${formulaResult ?? 'Pas de résultat'})`;
            }
          }
          if (isReferenceToSelectionQuestion) {
            if (!isDefaultChapter(targetChapter)) {
              throw new Error(
                `Reference ${textContent} is not poiting to the correct chapter`,
              );
            }
            const item: Item = targetChapter.items[itemIndex];
            if (item.type === ItemType.QUESTION && isSelectQuestion(item)) {
              const userAnswer = answers[item.uuid] as UserAnswerSelect;
              let answerValue = 'Pas de réponse';
              if (userAnswer && userAnswer.answer && item.multiple === 1) {
                const answer = item.answers.find(
                  (answer) => answer.uuid === userAnswer.answer,
                );
                if (
                  answer &&
                  answer.value !== null &&
                  answer.value !== undefined
                ) {
                  answerValue = `${answer.value}`;
                }
              }
              tooltip = item.title + ` (${answerValue})`;
            }
          }
          if (isReferenceToNumericQuestion) {
            if (!isDefaultChapter(targetChapter)) {
              throw new Error(
                `Reference ${textContent} is not poiting to the correct chapter`,
              );
            }
            const item: Item = targetChapter.items[itemIndex];
            if (item.type === ItemType.QUESTION && isTextQuestion(item)) {
              const userAnswer = answers[item.uuid] as UserAnswerText;
              let answerValue = 'Pas de réponse';
              if (userAnswer) {
                answerValue = userAnswer.text;
              }
              tooltip = item.title + ` (${answerValue})`;
            }
          }
          if (isReferenceToTemplateFormula) {
            const templateFormula = templateFormulaResults[tooltip];
            const formulaResult = templateFormula.result
              ? templateFormula.result / templateFormula.totalWeight
              : undefined;
            tooltip =
              templateFormula.title +
              ` (${formulaResult ?? 'Pas de résultat'})`;
          }
          const color = child.classList.contains('math-symbol')
            ? colors[(chapterIndex + itemIndex) % 12]
            : child.classList.contains('math-number')
              ? '#15c'
              : child.classList.contains('math-parenthesis') ||
                  child.classList.contains('math-paranthesis')
                ? '#555'
                : child.classList.contains('math-function')
                  ? 'pink'
                  : undefined;

          const tag = (
            <Tag
              color={color}
              style={
                isReferenceToSelectionQuestion ||
                isReferenceToNumericQuestion ||
                isReferenceToFormula
                  ? {cursor: 'pointer'}
                  : {}
              }
              onClick={() => {
                if (
                  !isReferenceToSelectionQuestion &&
                  !isReferenceToNumericQuestion &&
                  !isReferenceToFormula
                ) {
                  return;
                }
                const key = form.chapters[chapterIndex].uuid;
                highlightItem(`C${chapterIndex}I${itemIndex}`, {
                  scrollIntoView: true,
                  scrollDelay: activeKeys?.includes(key) ? 0 : 500,
                });

                const keys = activeKeys ?? [];
                if (!keys.includes(key)) {
                  keys.push(key);
                }

                setActiveKeys(keys);
              }}
            >
              {textContent}
            </Tag>
          );

          tempTokens.push(
            isReferenceToSelectionQuestion ||
              isReferenceToNumericQuestion ||
              isReferenceToFormula ||
              isReferenceToTemplateFormula ? (
              <Tooltip title={tooltip} key={index}>
                {tag}
              </Tooltip>
            ) : (
              <span key={index}>{tag}</span>
            ),
          );
        });
        setTokens(tempTokens);
      } catch (err) {
        console.error(err);
      }
      setLoading(false);
    }, 1000);

    return () => clearTimeout(to);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formula, answers, formulaValues]);

  useEffect(() => {
    if (
      !form ||
      !formulaValues ||
      (isTemplate && !templateInstanceId) ||
      !formulaValues[
        isTemplate && templateInstanceId ? templateInstanceId : formId
      ]
    ) {
      return;
    }
    const formulaData = formulaValues[templateInstanceId ?? formId][uuid];
    setFormulaValue(formulaData.result);
    setUnansweredQuestions(formulaData.unansweredQuestions);
  }, [form, formulaValues, formId, uuid, templateInstanceId, isTemplate]);

  const {token} = theme.useToken();

  if (visibility === Visibility.HIDEN_FOR_CONSULTANT && !isAdmin) {
    return null;
  }

  const errorMessage = isAdmin
    ? 'Cette formule contient des éléments erronés : ' +
      unansweredQuestions.join(', ')
    : `Il manque ${unansweredQuestions.length} réponse(s) pour ce calcul`;

  return (
    <Form.Item
      label={
        <Space direction="horizontal">
          <CalculatorOutlined style={{color: token.colorPrimary}} />
          <span>{title}</span>
          {isAdmin && !viewAsUser ? (
            <Tag color={colors[(chapterIdx + id) % 12]}>{`C${chapterIdx + 1}F${
              id + 1
            }`}</Tag>
          ) : null}
          {tooltip && <CustomTooltip tooltipText={tooltip} itemTitle={title} />}
        </Space>
      }
      style={{padding: '5px', borderRadius: '10px'}}
    >
      {isAdmin ? (
        <>
          <Row
            gutter={8}
            id={`C${chapterIdx}I${id}`}
            style={{
              fontWeight: 'bold',
            }}
          >
            <Col flex={1}>
              <Form.Item name={uuid} initialValue={formula}>
                <Input readOnly />
              </Form.Item>
            </Col>
            <Col>
              <Tooltip title={formulaValue && 'Afficher la formule'}>
                <Button
                  icon={<AimOutlined />}
                  type="default"
                  onClick={() => {
                    if (currentFormulaUuid === uuid) {
                      setCurrentFormulaUuid('');
                      return;
                    }
                    setCurrentFormulaUuid(uuid);
                  }}
                />
              </Tooltip>
            </Col>
          </Row>
          {currentFormulaUuid === uuid && (
            <Row style={{marginBottom: 24}}>
              {loading ? (
                <Skeleton title={false} paragraph={{rows: 1}} active />
              ) : (
                tokens
              )}
            </Row>
          )}
        </>
      ) : null}
      <Row>
        <Input
          readOnly
          style={{textAlign: 'start'}}
          addonBefore={title}
          value={
            unansweredQuestions.length > 0 ? errorMessage : formulaValue ?? ''
          }
          status={unansweredQuestions.length > 0 ? 'error' : ''}
        />
      </Row>
    </Form.Item>
  );
};
