import {
  EditOutlined,
  FilePdfOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Form,
  List,
  message,
  Modal,
  Row,
  Space,
  TableProps,
  Tooltip,
  Typography,
} from 'antd';
import {FilterDropdownProps, SortOrder} from 'antd/es/table/interface';
import dayjs from 'dayjs';
import {
  AllReportsItem,
  getAllReportsPath,
  GetPackAvailablecompaniesElem,
  GetPackAvailableFormsO,
  PackGetPluralQ,
} from 'holo-api';
import {Key, useCallback, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import {
  createCompanyPack,
  getCompanyPackGetRightPlural,
} from './client/companyPack';
import {
  getFormPackGetRightPlural,
  getPackAvailableForms,
  packDelSingular,
  packGetPlural,
  packUpdateReport,
} from './client/pack';
import {DeleteButton} from './components/ui/DeleteButton';
import {DynamicSelect} from './components/ui/DynamicSelect';
import {FilterSeachText} from './components/ui/FilterSearchText';
import {GenericTable} from './components/ui/GenericTable';
import {AssignModal} from './components/ui/modals/AssignModal';
import {PackModal} from './components/ui/modals/PackModal';
import {TablePopOver} from './components/ui/TablePopOver';
import {useApi} from './util/auth';

export interface PackTableDataType {
  id: number;
  name: string;
  description: string;
  forms: number;
  companies: {
    companies: number;
    activeCompanies: number;
  };
  minorVersion: number;
  patchVersion: number;
  report?: {
    id: number;
    name: string;
  };
}

export interface PackCreateForm {
  name: string;
  uuid: string;
  version: string;
  description: string;
  formIds: Array<number>;
  note?: string;
}

export interface PackUpdateForm {
  name: string;
  description: string;
  formIds: Array<number>;
  note?: string;
}

export interface AssignCompaniesToPackForm {
  companyIds: number[];
  setAsActivePack: boolean;
}

export interface AssignModeleToPackForm {
  modeleId: number | undefined;
}

export const Packs = () => {
  const [assignForm] = Form.useForm<AssignCompaniesToPackForm>();
  const [modeleForm] = Form.useForm<AssignModeleToPackForm>();

  const [loading, setLoading] = useState<boolean>(true);
  const api = useApi();
  const [modal, contextHolder] = Modal.useModal();
  const [packs, setPacks] = useState<PackTableDataType[]>([]);
  const [currentSort, setCurrentSort] = useState<
    | {
        column: Key | readonly Key[] | undefined;
        order: SortOrder | undefined;
      }
    | undefined
  >({column: 'name', order: 'ascend'});
  const usePagination = useState<{
    pageNumber: number;
    pageSize: number;
  }>({pageNumber: 1, pageSize: 10});
  const [searchText, setSearchText] = useState<
    {[key: string]: string} | undefined
  >(undefined);
  const [totalPacks, setTotalPacks] = useState<number>();
  const [pagination] = usePagination;

  const [currentId, setCurrentId] = useState<number | 'new' | undefined>(
    undefined,
  );
  const [formOptions, setFormOptions] = useState<
    GetPackAvailableFormsO | undefined
  >(undefined);
  const [editedPack, setEditedPack] = useState<PackTableDataType | undefined>(
    undefined,
  );
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);

  const getPacks = useCallback(async () => {
    if (!api) {
      return;
    }
    setLoading(true);
    const query: PackGetPluralQ = {};
    if (currentSort) {
      query.sort = currentSort.column as string;
      query.direction = currentSort.order === 'ascend' ? 'asc' : 'desc';
    }
    if (searchText) {
      const filters: Array<string> = [];
      for (const column in searchText) {
        filters.push(`${column}:${searchText[column]}`);
      }
      query.filters = filters.join(',');
    }
    query.skip = ((pagination.pageNumber - 1) * pagination.pageSize).toString();
    query.take = pagination.pageSize.toString();

    const data = await packGetPlural(api, query);

    const packs = data.entities.map((entity) => {
      const {forms, ...companies} = entity._count;
      return {...entity, forms, companies};
    });
    setPacks(packs);
    setTotalPacks(data.total);
    setLoading(false);
  }, [api, pagination, searchText, currentSort]);

  useEffect(() => {
    void getPacks();
  }, [getPacks]);

  const onChooseModele = async (record: PackTableDataType) => {
    const onModeleChosen = async (value: AssignModeleToPackForm) => {
      if (api) {
        const output = await packUpdateReport(api, {
          packId: record.id,
          reportId: value.modeleId,
        });
        if (output.reportId === value.modeleId) {
          void message.success(
            `Le modèle ${value.modeleId} à été assigné au pack ${record.name}`,
          );
        }
        modeleForm.resetFields();
        void getPacks();
      }
    };

    modeleForm.setFieldsValue({
      modeleId: record.report ? record.report.id : undefined,
    });
    void modal.confirm({
      title: `Assignation d'un modèle au pack ${record.name}`,
      width: '600px',
      centered: true,
      cancelText: 'Annuler',
      closable: true,
      content: (
        <Form form={modeleForm} onFinish={onModeleChosen}>
          <Form.Item label="Modèle" name="modeleId">
            <DynamicSelect<AllReportsItem> api={api} url={getAllReportsPath} />
          </Form.Item>
        </Form>
      ),
      onOk: async () => {
        modeleForm.submit();
      },
      onCancel: () => {
        modeleForm.resetFields();
      },
    });
  };

  const columns: TableProps<PackTableDataType>['columns'] = [
    {
      title: 'Nom',
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sorter: true,
      filterDropdown: (props: FilterDropdownProps) => (
        <FilterSeachText
          dataIndex="name"
          setSearchText={setSearchText}
          {...props}
        />
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      sorter: true,
      filterDropdown: (props: FilterDropdownProps) => (
        <FilterSeachText
          dataIndex="description"
          setSearchText={setSearchText}
          {...props}
        />
      ),
    },
    {
      title: 'Formulaires',
      dataIndex: ['_count', 'forms'],
      render: (value, record) => {
        if (!api) {
          return;
        }
        if (value === 0) {
          return <Typography>Aucun formulaire</Typography>;
        }
        return (
          <TablePopOver
            title={`Liste des formulaires associés au pack "${record.name}"`}
            onOpen={async () => {
              if (value === 0) {
                return <></>;
              }
              const forms = await getFormPackGetRightPlural({
                api,
                packId: record.id,
              });
              return (
                <List>
                  {forms.entities.map((form, index: number) => {
                    const date = dayjs(form.updatedAt).format('DD/MM/YYYY');

                    return (
                      <List.Item key={index}>
                        <Row style={{width: '100%'}}>
                          <Col span={12}>
                            <Link to={`/creator/?form=${form.id}`}>
                              {form.title}
                            </Link>
                          </Col>
                          <Col span={4}>
                            <Typography>{`1.${form.minorVersion}.${form.patchVersion}`}</Typography>
                          </Col>
                          <Col span={8}>
                            <Typography>MàJ: {date}</Typography>
                          </Col>
                        </Row>
                      </List.Item>
                    );
                  })}
                </List>
              );
            }}
          >
            <Typography.Link>
              {`${value} formulaire${value === 1 ? '' : 's'}`}
            </Typography.Link>
          </TablePopOver>
        );
      },
    },
    {
      title: 'Entreprises',
      dataIndex: '_count',
      render: (value, record) => {
        if (!api) {
          return;
        }
        if (value.companies === 0) {
          return <Typography>Aucune entreprise associée</Typography>;
        }
        return (
          <TablePopOver
            title={`Liste des entreprises associées au pack "${record.name}"`}
            onOpen={async () => {
              const data = await getCompanyPackGetRightPlural({
                api,
                packId: record.id,
              });
              return (
                <List>
                  {data.entities.map(({company}, index: number) => {
                    return (
                      <List.Item key={index}>
                        <Row style={{width: '100%'}}>
                          <Col span={18}>{company.name}</Col>
                          {company.activePackId === record.id ? (
                            <Col span={6}>Actif</Col>
                          ) : (
                            <Col span={6}>Non actif</Col>
                          )}
                        </Row>
                      </List.Item>
                    );
                  })}
                </List>
              );
            }}
          >
            <Typography.Link>
              {`${value.companies} entreprise${
                value.companies === 1 ? '' : 's'
              } associée${value.companies === 1 ? '' : 's'}, dont ${
                value.activeCompanies
              } avec ce formulaire actif`}
            </Typography.Link>
          </TablePopOver>
        );
      },
    },
    {
      title: 'Modèle',
      align: 'center',
      render: (_value, record) => {
        if (record.report) {
          return (
            <Typography>
              <Link to={`/modele/${record.report.id}`}>
                {record.report.name}
              </Link>
            </Typography>
          );
        } else {
          return <Typography>Aucun modèle</Typography>;
        }
      },
    },
    {
      title: 'Version',
      align: 'center',
      render: (_value, record) => (
        <Typography>{`1.${record.minorVersion}.${record.patchVersion}`}</Typography>
      ),
    },
    {
      title: 'Actions',
      align: 'center',
      render: (_v, record) => {
        return (
          <Space>
            <Tooltip title="Assigner ce pack à des entreprises">
              <Button
                icon={<PlusCircleOutlined />}
                onClick={async () => {
                  if (!api) {
                    return;
                  }
                  setEditedPack(record);
                  setIsAssignModalOpen(true);
                }}
              />
            </Tooltip>
            <Tooltip title="Éditer le pack">
              <Button
                icon={<EditOutlined />}
                onClick={async () => {
                  if (!api) {
                    return;
                  }
                  const formOptions = await getPackAvailableForms(
                    api,
                    record.id,
                  );
                  setFormOptions(formOptions);
                  setEditedPack(record);
                  setCurrentId(record.id);
                }}
              />
            </Tooltip>
            <Tooltip title="Choisir le modèle">
              <Button
                icon={<FilePdfOutlined />}
                onClick={() => onChooseModele(record)}
              />
            </Tooltip>
            <Tooltip
              title={
                record.companies.companies > 0
                  ? 'Ce pack est assigné à des entreprises et ne peut être supprimé'
                  : ''
              }
            >
              <DeleteButton
                disabled={record.companies.companies > 0}
                onConfirm={async () => {
                  if (!api) {
                    return;
                  }
                  try {
                    await packDelSingular(api, record.id);
                    void message.success(
                      `Le pack ${record.name} a bien été supprimé !`,
                    );
                  } catch (err) {
                    void message.error(
                      'Une erreur est survenue lors de la suppression du pack',
                    );
                  } finally {
                    await getPacks();
                  }
                }}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Row style={{marginBottom: 16}} justify="space-between" align="middle">
        <Col>
          <Typography.Title style={{margin: 0}}>
            Gestion des packs
          </Typography.Title>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={async () => {
              if (!api) {
                return;
              }
              setCurrentId('new');
            }}
          >
            Créer un nouveau Pack
          </Button>
        </Col>
      </Row>
      <Row>
        <GenericTable
          columns={columns}
          loading={loading}
          total={totalPacks}
          dataSource={packs}
          setCurrentSort={setCurrentSort}
          usePagination={usePagination}
        />
      </Row>
      <PackModal
        id={currentId}
        formOptions={formOptions}
        editedPack={editedPack}
        onOk={() => {
          setCurrentId(undefined);
          setFormOptions(undefined);
          setEditedPack(undefined);
          void getPacks();
        }}
        onCancel={() => {
          setCurrentId(undefined);
          setFormOptions(undefined);
          setEditedPack(undefined);
        }}
      />
      <AssignModal
        title={`Assignation d'entreprises au pack ${editedPack?.name}`}
        open={isAssignModalOpen}
        content={
          <Form
            form={assignForm}
            onFinish={async (value) => {
              if (!api || !editedPack) {
                return;
              }
              for (const companyId of value.companyIds) {
                await createCompanyPack(api, {
                  setAsActivePack: value.setAsActivePack,
                  packId: editedPack.id,
                  companyId,
                });
              }
              assignForm.resetFields();
              void getPacks();
            }}
          >
            <Form.Item<{companyIds: number[]}>
              label="Entreprises"
              name="companyIds"
            >
              <DynamicSelect<GetPackAvailablecompaniesElem>
                api={api}
                url={`/pack/${editedPack?.id}/availableCompanies`}
                mode="multiple"
              />
            </Form.Item>
            <Form.Item
              name="setAsActivePack"
              valuePropName="checked"
              initialValue={true}
            >
              <Checkbox>Rendre le pack actif pour ces entreprises</Checkbox>
            </Form.Item>
          </Form>
        }
        onOk={async () => {
          await assignForm.validateFields();
          assignForm.submit();
          setEditedPack(undefined);
          setIsAssignModalOpen(false);
        }}
        onCancel={() => {
          assignForm.resetFields();
          setEditedPack(undefined);
          setIsAssignModalOpen(false);
        }}
      />
      {contextHolder}
    </>
  );
};
