import {CheckOutlined} from '@ant-design/icons';
import {Progress, theme} from 'antd';
import {DefaultChapter, ItemType} from 'holo-api';
import {FC} from 'react';

import {useViewerStore} from '../../../stores/viewer';

export const ChapterProgress: FC<{chapter: DefaultChapter}> = ({chapter}) => {
  const {token} = theme.useToken();
  const answersCount = useViewerStore((state) => state.answersCount);
  const validatedChapters = useViewerStore((state) => state.validatedChapters);

  return (
    <Progress
      type="circle"
      percent={Math.floor(
        ((answersCount?.[chapter.uuid] ?? 0) /
          chapter.items.filter((item) => item.type === ItemType.QUESTION)
            .length) *
          100,
      )}
      format={() =>
        validatedChapters[chapter.uuid] ? (
          <CheckOutlined style={{color: token.colorPrimary}} />
        ) : (
          <span style={{fontWeight: 'bold'}}>
            {answersCount?.[chapter.uuid] ?? 0}/
            {
              chapter.items.filter((item) => item.type === ItemType.QUESTION)
                .length
            }
          </span>
        )
      }
      size={38}
      strokeColor={token.colorPrimary}
    />
  );
};
