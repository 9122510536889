import {JSONSchemaType} from 'ajv';

import {MatrixPA, MatrixPASchema, MatType} from '../../util/poidsActifs';

export interface MatrixValue {
  max: number;
  value: number;
}
export const MatrixValueSchema: JSONSchemaType<MatrixValue> = {
  type: 'object',
  additionalProperties: false,
  required: ['max', 'value'],
  properties: {
    max: {type: 'number'},
    value: {type: 'number'},
  },
};

export interface Matrix1D {
  name: string;
  description?: string;
  mat2d: false;
  cols: MatrixValue[];
}
export const Matrix1DSchema: JSONSchemaType<Matrix1D> = {
  type: 'object',
  additionalProperties: false,
  required: ['name', 'mat2d', 'cols'],
  properties: {
    name: {type: 'string'},
    description: {type: 'string', nullable: true},
    mat2d: {type: 'boolean', const: false},
    cols: {type: 'array', items: MatrixValueSchema},
  },
};

export interface Matrix2D {
  name: string;
  description?: string;
  mat2d: true;
  cols: {
    max: number;
    rows: MatrixValue[];
  }[];
}
export const Matrix2DSchema: JSONSchemaType<Matrix2D> = {
  type: 'object',
  additionalProperties: false,
  required: ['name', 'mat2d', 'cols'],
  properties: {
    name: {type: 'string'},
    description: {type: 'string', nullable: true},
    mat2d: {type: 'boolean', const: true},
    cols: {
      type: 'array',
      items: {
        type: 'object',
        additionalProperties: false,
        required: ['max', 'rows'],
        properties: {
          max: {type: 'number'},
          rows: {type: 'array', items: MatrixValueSchema},
        },
      },
    },
  },
};

export interface MatrixID {
  name: string;
  description?: string;
  mat2d: false;
  matType: MatType.INDICE_DURABLE;
  cols: MatrixValue[];
}
export const MatrixIDSchema: JSONSchemaType<MatrixID> = {
  type: 'object',
  additionalProperties: false,
  required: ['name', 'mat2d', 'matType', 'cols'],
  properties: {
    name: {type: 'string'},
    description: {type: 'string', nullable: true},
    mat2d: {type: 'boolean', const: false},
    matType: {type: 'string', const: MatType.INDICE_DURABLE},
    cols: {type: 'array', items: MatrixValueSchema},
  },
};

export type MatrixND = Matrix1D | Matrix2D | MatrixPA | MatrixID;
export const MatrixNDSchema: JSONSchemaType<MatrixND> = {
  oneOf: [Matrix1DSchema, Matrix2DSchema, MatrixPASchema, MatrixIDSchema],
};

export interface AnswerMatrixT {
  id: number;
  name: string;
  description: string;
  mat2d: boolean;
  matrixND: MatrixND;
}
export const AnswerMatrixSchema: JSONSchemaType<AnswerMatrixT> = {
  type: 'object',
  additionalProperties: false,
  required: ['id', 'name', 'description', 'mat2d', 'matrixND'],
  properties: {
    id: {type: 'number'},
    name: {type: 'string'},
    description: {type: 'string'},
    mat2d: {type: 'boolean'},
    matrixND: MatrixNDSchema,
  },
};

export const answerMatrixRoutes = {
  sRoute: '/answerMatrix',
  pRoute: '/answerMatrices',
};

export interface CreateAnswerMatrixT {
  matrixND: MatrixND;
}

export const CreateAnswerMatrixSchema: JSONSchemaType<CreateAnswerMatrixT> = {
  type: 'object',
  additionalProperties: false,
  required: ['matrixND'],
  properties: {
    matrixND: MatrixNDSchema,
  },
};

export interface UpdateAnswerMatrixT {
  matrixND: MatrixND;
}

export const UpdateAnswerMatrixSchema: JSONSchemaType<UpdateAnswerMatrixT> = {
  type: 'object',
  additionalProperties: false,
  required: ['matrixND'],
  properties: {
    matrixND: MatrixNDSchema,
  },
};

// ---------------------------- getPlural  ---------------------
export interface AnswerMatrixGetPlural {
  entities: Array<AnswerMatrixT>;
  total: number;
}
export const AnswerMatrixGetPluralSchema: JSONSchemaType<AnswerMatrixGetPlural> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['entities', 'total'],
    properties: {
      entities: {type: 'array', items: AnswerMatrixSchema},
      total: {type: 'integer'},
    },
  };

export const isMatrixPA = (matrix: {
  mat2d: boolean;
  matType?: MatType;
}): matrix is MatrixPA =>
  matrix.mat2d === true && matrix.matType === MatType.POIDSACTIFS;

export const isMatrix1D = (matrix: {
  mat2d: boolean;
  matType?: MatType;
}): matrix is Matrix1D =>
  matrix.mat2d === false && matrix.matType === undefined;

export const isMatrix2D = (matrix: {
  mat2d: boolean;
  matType?: MatType;
}): matrix is Matrix2D => matrix.mat2d === true && matrix.matType === undefined;

export const isMatrixID = (matrix: {
  mat2d: boolean;
  matType?: MatType;
}): matrix is MatrixID =>
  matrix.mat2d === false && matrix.matType === MatType.INDICE_DURABLE;
