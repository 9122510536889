import {Divider} from 'antd';
import {Separator} from 'holo-api';
import {FC} from 'react';

interface ViewerSeparatorItemProps extends Separator {}

export const ViewerSeparatorItem: FC<ViewerSeparatorItemProps> = ({
  separator,
}) => {
  return <Divider orientation="center">{separator && separator}</Divider>;
};
