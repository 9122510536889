import {
  CheckOutlined,
  CopyOutlined,
  LoadingOutlined,
  SwapOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import Ajv from 'ajv';
import {
  Button,
  Col,
  Divider,
  FloatButton,
  message,
  Modal,
  Row,
  Space,
  Statistic,
  Switch,
  theme,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import {
  AdminFormContentsType,
  AdminFormContentsTypeSchema,
  AnswerMatrixT,
  buildRevertTagMapping,
  Chapter,
  evaluateFormulas,
  filterNewestAnswers,
  findAllFormulas,
  findAllTransferredFormulas,
  findFormFormula,
  formCategoryLabel,
  FormContentsType,
  Formula,
  FormulaType,
  FormulaValues,
  isAnswerToChapterValidated,
  isAnswerToSelectQuestion,
  isDefaultChapter,
  isQuestion,
  preprocessTemplate,
  replaceMatrixIdByName,
  Role,
  SingleUserAnswer,
  TemplateFormulaResults,
  UserAnswers,
  UserAnswersSchema,
  UserFormContentsType,
  UserFormContentsTypeSchema,
} from 'holo-api';
import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';

import {answerMatrixGetPlural} from './client/answerMatrix';
import {getCompanyConsultants, getCompanyUsers} from './client/company';
import {
  formGetSingular,
  getAllFormPatentAnswers,
  getFormAnswer,
  getTemplateInstanceCompany,
  postFormAnswer,
  updatePatentAnswer,
} from './client/form';
import {Spinner} from './components/ui/Spinner';
import {ViewerDescriptionCollapse} from './components/ui/viewer/ViewerDescriptionCollapse';
import {ViewerFormView} from './components/ui/viewer/ViewerFormView';
import {ViewerTemplateSummary} from './components/ui/viewer/ViewerTemplateSummary';
import {ViewerTemplateView} from './components/ui/viewer/ViewerTemplateView';
import {LoadingState, useLoading} from './hooks/useLoading';
import {usePrefill} from './hooks/usePrefill';
import {useMatrixStore} from './stores/matrix';
import {usePackStore} from './stores/pack';
import {useUserStore} from './stores/user';
import {CompanyUsers, FormulaAverages, useViewerStore} from './stores/viewer';
import {useApi, useUserRole} from './util/auth';
import {isAnswerValid} from './util/form';

const ajv = new Ajv();

export interface ChapterAnswersCount {
  [key: string]: number;
}
export interface ValidatedChapters {
  [key: string]: boolean;
}

export const Viewer: FC = () => {
  const userRole = useUserRole();
  const isAdmin = userRole === Role.ADMIN;
  const isConsultant = userRole === Role.CONSULTANT;
  const isCompanyAdmin = userRole === Role.COMPANY_ADMIN;
  const api = useApi();
  const [modal, contextHolder] = Modal.useModal();
  const {companyId} = useUserStore();
  const readOnly = usePackStore((state) => state.readOnly);
  const getForm = usePackStore((state) => state.getForm);

  const {token} = theme.useToken();
  const [params] = useSearchParams();

  const formParam = params.get('form');
  const navigate = useNavigate();

  const [viewAsUser, setViewAsUser] = useState<boolean>(
    !isAdmin && !isConsultant,
  );
  const [formId, setFormId] = useState(-1);
  const [templatesLinksInfo, setTemplatesLinksInfo] = useState<
    {id: number; title: string}[]
  >([]);

  const formAnswers = useViewerStore((state) => state.formAnswers);
  const setFormAnswers = useViewerStore((state) => state.setFormAnswers);
  const setInitialFormAnswers = useViewerStore(
    (state) => state.setInitialFormAnswers,
  );
  const updateFormAnswers = useViewerStore((state) => state.updateFormAnswers);
  const setQuestionUuidToUserId = useViewerStore(
    (state) => state.setQuestionUuidToUserId,
  );
  const templateInstancesAnswers = useViewerStore(
    (state) => state.templateInstancesAnswers,
  );
  const setTemplateInstancesAnswers = useViewerStore(
    (state) => state.setTemplateInstancesAnswers,
  );
  const setTemplateQuestionUuidToUserId = useViewerStore(
    (state) => state.setTemplateQuestionUuidToUserId,
  );
  const form = useViewerStore((state) => state.form);
  const setForm = useViewerStore((state) => state.setForm);
  const setFormulaValues = useViewerStore((state) => state.setFormulaValues);
  const [formulaAverages, setFormulaAverages] = useState<{
    [key: string]: FormulaAverages;
  }>({});
  const setAnswersCount = useViewerStore((state) => state.setAnswersCount);
  const pendingUserAnswers = useViewerStore(
    (state) => state.pendingUserAnswers,
  );
  const setPendingUserAnswers = useViewerStore(
    (state) => state.setPendingUserAnswers,
  );
  const updateValidatedChapters = useViewerStore(
    (state) => state.updateValidatedChapters,
  );
  const isPreliminaryForm = useViewerStore((state) => state.isPreliminaryForm);
  const setIsPreliminaryForm = useViewerStore(
    (state) => state.setIsPreliminaryForm,
  );
  const setTemplateFormulasResults = useViewerStore(
    (state) => state.setTemplateFormulasResults,
  );
  const setCompanyUsers = useViewerStore((state) => state.setCompanyUsers);
  const templateInstanceId = useViewerStore(
    (state) => state.templateInstanceId,
  );
  const setTemplateInstanceId = useViewerStore(
    (state) => state.setTemplateInstanceId,
  );
  const setReadOnly = useViewerStore((state) => state.setReadOnly);
  const isTemplate = useViewerStore((state) => state.isTemplate);
  const setIsTemplate = useViewerStore((state) => state.setIsTemplate);

  const [formCategory, setFormCategory] = useState<string | null | undefined>(
    undefined,
  );

  const packLoaded = usePackStore((state) => state.packIsLoaded);
  const {matrix1d, matrix2d, loading: matricesLoading} = useMatrixStore();
  const allMatrices = useMemo(
    () =>
      [...matrix1d, ...matrix2d].map((m) => {
        const am: AnswerMatrixT = {
          id: m.id,
          name: m.name,
          description: m.description ? m.description : '',
          mat2d: m.mat2d,
          matrixND: m,
        };
        return am;
      }),
    [matrix1d, matrix2d],
  );
  const setMatrices = useMatrixStore((state) => state.setMatrices);

  const [formUpdatedDate, setFormUpdatedDate] = useState<Date | undefined>();
  const [formFormulaResult, setFormFormulaResult] = useState<string>();

  const [transferredFormulas, setTransferredFormulas] = useState<Formula[]>([]);
  const [formFormula, setFormFormula] = useState<Formula | undefined>(
    undefined,
  );

  const [loading, setLoading] = useLoading();
  const [summaryIsLoading, setSummaryIsLoading] = useState<boolean>(false);

  const [mainFormId, setMainFormId] = useState<number | undefined>(undefined);
  const [templateInstances, setTemplateInstances] = useState<
    {name: string; id: number; weight: number}[]
  >([]);
  const [activeTabKey, setActiveTabKey] = useState<string>();

  const [answersSending, setAnswersSending] = useState(false);

  usePrefill({
    companyId,
    form,
  });

  const initializeAnswerCount = useCallback(
    (chapters: Chapter[], answers: UserAnswers) => {
      const initAnswerCount: ChapterAnswersCount = {};
      chapters.forEach((chapter) => {
        if (!isDefaultChapter(chapter)) {
          return;
        }
        const count = chapter.items.filter((item) => {
          if (isQuestion(item) && item.prefill) {
            return true;
          }
          const value = answers[item.uuid];
          if (!value) {
            return false;
          }
          return isAnswerValid(value);
        }).length;
        initAnswerCount[chapter.uuid] = count;
      });
      setAnswersCount(initAnswerCount);
    },
    [setAnswersCount],
  );

  const handleStatesOnFormChange = useCallback(
    (newestAnswers: UserAnswers, chapters: Chapter[]) => {
      setInitialFormAnswers(newestAnswers);
      setFormAnswers(newestAnswers);
      initializeAnswerCount(chapters, newestAnswers);

      const userValidatedChapters: ValidatedChapters = {};
      chapters.forEach((chapter) => {
        const uuid = chapter.uuid;
        const chapterAnswer = newestAnswers[uuid];
        if (chapterAnswer && isAnswerToChapterValidated(chapterAnswer)) {
          userValidatedChapters[uuid] = chapterAnswer.validated;
        } else {
          userValidatedChapters[uuid] = false;
        }
      });
      updateValidatedChapters(userValidatedChapters);
    },
    [
      initializeAnswerCount,
      setFormAnswers,
      setInitialFormAnswers,
      updateValidatedChapters,
    ],
  );

  const loadCompanyUsers = useCallback(async () => {
    if (!api || !companyId) {
      return;
    }
    const companyUsers = await getCompanyUsers({
      api,
      companyId,
      includeAdmins: true,
    });
    const companyConsultants = await getCompanyConsultants({api, companyId});
    const idToUserInfo: CompanyUsers = {};
    for (const user of [
      ...companyUsers.entities,
      ...companyConsultants.entities,
    ]) {
      idToUserInfo[user.id] = user;
    }
    setCompanyUsers(idToUserInfo);
  }, [api, companyId, setCompanyUsers]);

  useEffect(() => {
    void loadCompanyUsers();
  }, [loadCompanyUsers]);

  const loadMatrices = useCallback(async () => {
    if (!api || matricesLoading !== LoadingState.None) {
      return;
    }

    const matrices = await answerMatrixGetPlural(api);
    setMatrices(matrices.entities);
  }, [api, matricesLoading, setMatrices]);

  useEffect(() => {
    void loadMatrices();
  }, [loadMatrices]);

  const calculateAverages = useCallback(
    (
      formulaValues: {[key: string]: FormulaValues},
      templateInstances: {name: string; id: number; weight: number}[],
      formulas: Formula[],
      formFormula?: Formula,
    ) => {
      const allFormulas = [...formulas, formFormula];
      const tempFormulaAverages: Record<string, FormulaAverages> = {};
      allFormulas.forEach((formula) => {
        if (!formula) {
          return;
        }
        let sum = 0;
        let totalWeight = 0;
        let average = 0;
        templateInstances.forEach((templateInstance) => {
          if (
            formulaValues[templateInstance.id] &&
            formulaValues[templateInstance.id][formula.uuid]
          ) {
            sum +=
              formulaValues[templateInstance.id][formula.uuid].result! *
              templateInstance.weight;
            totalWeight += templateInstance.weight;
          }
        });
        if (totalWeight !== 0) {
          average = Number((sum / totalWeight).toFixed(2));
          if (formula.formulaType === FormulaType.FORM) {
            setFormFormulaResult(
              isNaN(average) ? 'Résultat indisponible' : average.toString(),
            );
          }
        }
        tempFormulaAverages[formula.uuid] = {sum, totalWeight, average};
      });
      setFormulaAverages(tempFormulaAverages);
    },
    [setFormulaAverages],
  );

  const loadForm = useCallback(
    async (templateInstanceId?: number) => {
      if (formId === -1) {
        setForm(undefined);
        return;
      }
      if (!api || loading !== LoadingState.None) {
        return;
      }
      try {
        setLoading(LoadingState.Loading);
        const formData = await getForm(
          api,
          formId,
          (isAdmin || isConsultant) && !viewAsUser,
        );
        /*
        const formData = await formGetSingular(api, formId, {
          viewAsAdmin: (isAdmin || isConsultant) && !viewAsUser,
        });
        */
        if (!formData) {
          throw new Error('Form not found');
        }

        if (
          formData.isPreliminary &&
          !isAdmin &&
          !isConsultant &&
          !isCompanyAdmin
        ) {
          await message.error("Impossible d'accéder à cette page.");
          return navigate('/');
        }

        setReadOnly(readOnly || formData.readOnly);

        const form = formData.contents;
        const validator =
          isAdmin || isConsultant
            ? viewAsUser
              ? ajv.compile<UserFormContentsType>(UserFormContentsTypeSchema)
              : ajv.compile<AdminFormContentsType>(AdminFormContentsTypeSchema)
            : ajv.compile<UserFormContentsType>(UserFormContentsTypeSchema);
        if (!validator(form)) {
          void modal.error({
            title: "Le formulaire n'est pas valide",
            content: (
              <Row justify="center">
                <Col>
                  <Button
                    icon={<CopyOutlined />}
                    onClick={() => {
                      void navigator.clipboard.writeText(
                        JSON.stringify(validator.errors, null, 2),
                      );
                    }}
                    danger
                  >
                    Copier dans le presse-papiers
                  </Button>
                </Col>
              </Row>
            ),
            okText: 'Fermer',
          });
          setFormId(-1);
          setTemplateInstanceId(undefined);
          return;
        }

        const loadedMainFormId = formData.mainFormId;
        const allFormulas = findAllFormulas(form);
        allFormulas.forEach((formula) => {
          formula.formula = replaceMatrixIdByName(formula, allMatrices);
        });

        // Object with formula tag as keys (TXFX)
        const transferredFormulaResults: TemplateFormulaResults = {};

        if (form.patent && !companyId) {
          setForm(undefined);
        } else {
          const revertTagMapping: Record<string, string> = {};
          if ((isAdmin || isConsultant) && formData.templates.length > 0) {
            let templateIndex = 1;
            for (const template of formData.templates) {
              const form = await formGetSingular(api, template.id, {
                viewAsAdmin: true,
              });
              const fullTemplate = form;

              const allTransferredFormulas: Formula[] =
                findAllTransferredFormulas(
                  fullTemplate.contents as FormContentsType,
                );
              if (allTransferredFormulas.length === 0) {
                continue;
              }

              const tIndex = templateIndex; // just to avoid typescript error :shrug:

              buildRevertTagMapping({
                allTransferredFormulas,
                templateIndex,
                templateId: template.id,
                transferredFormulaResults,
                revertTagMapping,
              });

              try {
                const linkedTemplateInstances =
                  await getTemplateInstanceCompany(api, template.id);

                const allTemplateInstancesAnswers =
                  await getAllFormPatentAnswers(api, template.id);

                linkedTemplateInstances.entities.forEach((templateInstance) => {
                  const currentTemplateInstanceAnswers =
                    allTemplateInstancesAnswers.entities.filter(
                      (answers) => answers.patentId === templateInstance.id,
                    );
                  const {answers: newestAnswers} = filterNewestAnswers(
                    currentTemplateInstanceAnswers,
                  );
                  const currentTemplateInstance = evaluateFormulas(
                    fullTemplate.contents as FormContentsType,
                    newestAnswers,
                    {},
                    allMatrices,
                  );

                  allTransferredFormulas.forEach((formula, formulaIndex) => {
                    const tag = `T${tIndex}F${formulaIndex + 1}`;
                    const formulaResult =
                      currentTemplateInstance[formula.uuid].result;
                    const weightedResult = formulaResult
                      ? formulaResult * templateInstance.weight
                      : undefined;
                    if (
                      transferredFormulaResults[tag] &&
                      weightedResult &&
                      templateInstance.weight
                    ) {
                      // have an answer
                      if (transferredFormulaResults[tag].result === undefined) {
                        // check in case previous template instances had no answer for this tag
                        transferredFormulaResults[tag].result = 0;
                      }
                      transferredFormulaResults[tag].result! += weightedResult;
                      transferredFormulaResults[tag].totalWeight +=
                        templateInstance.weight;
                    }
                  });
                });
              } catch (err) {
                console.warn(err);
              }
              templateIndex++;
            }
            setTemplateFormulasResults(transferredFormulaResults);
          }

          preprocessTemplate({form, revertTagMapping});
          setForm(form);
        }
        setIsTemplate(!!form.patent);
        setFormUpdatedDate(new Date(formData.updatedAt));
        setTemplatesLinksInfo(formData.templates);
        setFormCategory(
          formData.category ? formCategoryLabel(formData.category) : undefined,
        );

        setIsPreliminaryForm(formData.isPreliminary ?? false);

        const foundFormFormula = findFormFormula(form);

        if (form.patent && loadedMainFormId) {
          setMainFormId(loadedMainFormId);
          const linkedTemplatesInstances = await getTemplateInstanceCompany(
            api,
            formId,
          );
          if (linkedTemplatesInstances.entities.length === 0) {
            setTemplateInstanceId(undefined);
            setTemplateInstances([]);
            setLoading(LoadingState.Loaded);
            return;
          } else {
            const firstTemplateInstanceId =
              templateInstanceId ?? linkedTemplatesInstances.entities[0].id;
            setTemplateInstanceId(firstTemplateInstanceId);
            setTemplateInstances(linkedTemplatesInstances.entities);
            setActiveTabKey(firstTemplateInstanceId.toString());
          }

          const foundTransferredFormulas = findAllTransferredFormulas(form, {
            ignoreFormFormula: true,
          });
          setTransferredFormulas(foundTransferredFormulas);

          setFormFormula(foundFormFormula);

          const allTemplateInstancesAnswers = await getAllFormPatentAnswers(
            api,
            formId,
          );

          const initialTemplateInstanceAnswers: {[key: number]: UserAnswers} =
            {};
          const tempFormulaValues: Record<number, FormulaValues> = {};
          const templatesInstancesQuestionUuidToUserId: Record<
            number,
            Record<string, number>
          > = {};
          linkedTemplatesInstances.entities.forEach(
            (templateInstance, index) => {
              const currentTemplateInstanceAnswers =
                allTemplateInstancesAnswers.entities.filter(
                  (answers) => answers.patentId === templateInstance.id,
                );
              const {answers, questionUuidToUserId} = filterNewestAnswers(
                currentTemplateInstanceAnswers,
              );
              templatesInstancesQuestionUuidToUserId[templateInstance.id] =
                questionUuidToUserId;
              if (index === 0) {
                handleStatesOnFormChange(answers, form.chapters);
              }
              initialTemplateInstanceAnswers[templateInstance.id] = answers;
              const currentFormulaValues = evaluateFormulas(
                form,
                answers,
                {},
                allMatrices,
              );
              tempFormulaValues[templateInstance.id] = currentFormulaValues;
            },
          );
          setTemplateInstancesAnswers(initialTemplateInstanceAnswers);
          setFormulaValues(tempFormulaValues);
          calculateAverages(
            tempFormulaValues,
            linkedTemplatesInstances.entities,
            foundTransferredFormulas,
            foundFormFormula,
          );
          setTemplateQuestionUuidToUserId(
            templatesInstancesQuestionUuidToUserId,
          );
        } else {
          const allAnswers = await getFormAnswer(api, formId);
          const {answers, questionUuidToUserId} =
            filterNewestAnswers(allAnswers);

          handleStatesOnFormChange(answers, form.chapters);
          setQuestionUuidToUserId(questionUuidToUserId);

          const formulaValues = evaluateFormulas(
            form,
            answers,
            transferredFormulaResults,
            allMatrices,
          );
          if (!formData.isPreliminary) {
            if (!foundFormFormula) {
              setFormFormulaResult(
                'Aucune formule globale définie pour ce formulaire',
              );
            } else {
              setFormFormulaResult(
                formulaValues[foundFormFormula.uuid].result?.toString() ??
                  'Note indisponible, certaines réponses sont manquantes.',
              );
            }
          }

          setFormulaValues({
            [formId]: formulaValues,
          });
        }

        setLoading(LoadingState.Loaded);
        void message.success({
          key: 'form_load_success',
          content: 'Formulaire chargé avec succès !',
        });
      } catch (err) {
        console.error(err);
        setLoading(LoadingState.Error);
        void message.error(`Impossible de charger le formulaire...`);
      }
    },
    [
      formId,
      api,
      loading,
      setForm,
      setLoading,
      isAdmin,
      calculateAverages,
      isConsultant,
      viewAsUser,
      setReadOnly,
      setIsPreliminaryForm,
      setTemplateInstanceId,
      allMatrices,
      setTemplateFormulasResults,
      setTemplateInstancesAnswers,
      setFormulaValues,
      setTemplateQuestionUuidToUserId,
      handleStatesOnFormChange,
      setQuestionUuidToUserId,
      modal,
      readOnly,
      getForm,
    ],
  );

  const reset = useCallback(() => {
    setActiveTabKey(undefined);
    setFormFormulaResult(undefined);
    setFormulaAverages({});
    setMainFormId(undefined);
    setLoading(LoadingState.None);
    setForm(undefined);
    setFormulaValues({});
    setTemplateInstanceId(undefined);
  }, [setForm, setFormulaValues, setLoading, setTemplateInstanceId]);

  // on company change by admin / consultant
  useEffect(() => {
    setLoading(LoadingState.None);
    void loadForm();
  }, [companyId]);

  useEffect(() => {
    if (formParam) {
      reset();
      setFormId(Number(formParam));
    }
  }, [formParam, reset]);

  useEffect(() => {
    if (matricesLoading !== LoadingState.Loaded || !packLoaded) {
      return;
    }
    void loadForm();
  }, [loadForm, matricesLoading, packLoaded]);

  const post = useCallback(
    async (pendingUserAnswers: UserAnswers) => {
      if (!api || Object.keys(pendingUserAnswers).length === 0) {
        return;
      }

      const mergedContents = Object.fromEntries(
        Object.entries(pendingUserAnswers).map(([key, value]) => [
          key,
          {...(formAnswers?.[key] ?? {}), ...(value ?? {})} as SingleUserAnswer,
        ]),
      );

      try {
        if (isTemplate && templateInstanceId) {
          await updatePatentAnswer(api, templateInstanceId, pendingUserAnswers);
          templateInstancesAnswers[templateInstanceId] = mergedContents;
          setTemplateInstancesAnswers(templateInstancesAnswers);
        } else {
          await postFormAnswer(api, formId, pendingUserAnswers);
        }
        updateFormAnswers(mergedContents);

        setPendingUserAnswers({});
        setAnswersSending(false);
      } catch (err) {
        void message.error({
          key: 'viewer_answer_save_error',
          content:
            "Une erreur est survenue lors de l'enregistrement de la réponse !",
        });
      }
    },
    [
      api,
      isTemplate,
      templateInstanceId,
      updateFormAnswers,
      setPendingUserAnswers,
      formAnswers,
      setTemplateInstancesAnswers,
      templateInstancesAnswers,
      formId,
    ],
  );

  useEffect(() => {
    if (Object.keys(pendingUserAnswers).length === 0) {
      return;
    }

    const validator = ajv.compile<UserAnswers>(UserAnswersSchema);
    const valid = validator(pendingUserAnswers);
    if (!valid) {
      void modal.error({
        title: 'Les réponses ne sont pas valides',
        content: (
          <Row justify="center">
            <Col>
              <Button
                icon={<CopyOutlined />}
                onClick={() => {
                  void navigator.clipboard.writeText(
                    JSON.stringify(validator.errors, null, 2),
                  );
                }}
                danger
              >
                Copier dans le presse-papiers
              </Button>
            </Col>
          </Row>
        ),
        okText: 'Fermer',
      });
    } else {
      if (
        Object.keys(pendingUserAnswers).filter((questionUuid) => {
          const answer = pendingUserAnswers[questionUuid];
          return (
            answer &&
            isAnswerToSelectQuestion(answer) &&
            answer.answer.length > 0
          );
        }).length > 0
      ) {
        void post(pendingUserAnswers);
      } else if (Object.keys(pendingUserAnswers).length > 0) {
        const timer = setTimeout(async () => {
          void post(pendingUserAnswers);
        }, 1000);
        return () => {
          clearTimeout(timer);
        };
      }
    }
  }, [pendingUserAnswers, post, modal]);

  return (
    <>
      {formId !== -1 && isAdmin ? (
        <>
          <Row justify="center" gutter={16}>
            <Col>
              <Button
                type="primary"
                onClick={() => {
                  navigate(`/creator/?form=${formId}`);
                }}
                disabled={
                  matricesLoading !== LoadingState.Loaded ||
                  !packLoaded ||
                  loading === LoadingState.Loading
                }
              >
                Voir ce formulaire dans le créateur
              </Button>
            </Col>
          </Row>
          <Divider />
        </>
      ) : null}
      {loading === LoadingState.Loaded && formId !== -1 && (
        <>
          <Row justify="space-between" align="middle">
            <Typography.Title style={{margin: '0'}}>
              {form?.title}
            </Typography.Title>
            {(isAdmin || isConsultant) && (
              <>
                {!viewAsUser &&
                  (!isNaN(Number(formFormulaResult)) ? (
                    <Statistic
                      title={
                        isTemplate
                          ? 'Note moyenne des templates'
                          : 'Note du formulaire'
                      }
                      value={formFormulaResult}
                      style={{textAlign: 'center'}}
                    />
                  ) : (
                    <Typography>{formFormulaResult}</Typography>
                  ))}
                <Space>
                  <Typography.Text type="secondary">
                    Date de dernière mise à jour :{' '}
                    {dayjs(formUpdatedDate).format('DD/MM/YYYY')}
                  </Typography.Text>
                  <Switch
                    checkedChildren="Admin"
                    unCheckedChildren="User"
                    checked={!viewAsUser}
                    onChange={() => {
                      setViewAsUser((prevValue) => !prevValue);
                      setLoading(LoadingState.None);
                    }}
                  />
                </Space>
              </>
            )}
          </Row>
          {!isPreliminaryForm ? (
            <>
              {mainFormId && (
                <Row style={{marginBottom: 8, marginTop: 16}}>
                  <Link to={`/viewer/?form=${mainFormId}`}>
                    <Button type="primary">
                      Retour vers le formulaire principal
                    </Button>
                  </Link>
                </Row>
              )}
              {templatesLinksInfo.length > 0 && (
                <Row
                  wrap={false}
                  justify="space-evenly"
                  style={{marginBottom: 16}}
                >
                  {templatesLinksInfo.map((templateInfo, index) => (
                    <Col key={index}>
                      <Link to={`/viewer/?form=${templateInfo.id}`}>
                        <Button type="primary">{`Formulaire(s) «${templateInfo.title}»`}</Button>{' '}
                      </Link>
                    </Col>
                  ))}
                </Row>
              )}
              {(form?.description ||
                form?.objectives ||
                form?.themes ||
                form?.contributors) && (
                <ViewerDescriptionCollapse
                  form={form}
                  formCategory={formCategory}
                />
              )}
            </>
          ) : null}
        </>
      )}
      {packLoaded &&
      matricesLoading === LoadingState.Loaded &&
      loading === LoadingState.Loaded ? (
        <>
          {isTemplate && !companyId && (
            <Row justify="center" style={{marginTop: 16}}>
              <Typography.Title level={2}>
                <WarningOutlined style={{color: 'orange', marginRight: 8}} />
                L'utilisateur courant n'est lié à aucune entreprise !
                <WarningOutlined style={{color: 'orange', marginLeft: 8}} />
              </Typography.Title>
            </Row>
          )}
          {isTemplate && companyId && (
            <ViewerTemplateView
              templateInstances={templateInstances}
              transferredFormulas={transferredFormulas}
              formFormula={formFormula}
              formId={formId}
              activeTabKey={activeTabKey}
              loadForm={loadForm}
              setActiveTabKey={setActiveTabKey}
              setLoading={setLoading}
              setSummaryIsLoading={setSummaryIsLoading}
              calculateAverages={calculateAverages}
              handleStatesOnFormChange={handleStatesOnFormChange}
            />
          )}
          {(!isTemplate ||
            (templateInstances.length > 0 && activeTabKey !== 'summary')) && (
            <ViewerFormView
              isTemplate={isTemplate}
              formId={formId}
              viewAsUser={viewAsUser}
              setAnswersSending={setAnswersSending}
            />
          )}
          {activeTabKey === 'summary' && (
            <ViewerTemplateSummary
              loading={summaryIsLoading}
              formFormula={formFormula}
              templateInstances={templateInstances}
              transferredFormulas={transferredFormulas}
              formulaAverages={formulaAverages}
            />
          )}
          <FloatButton.Group>
            {answersSending ? (
              <FloatButton
                tooltip="Les réponses sont en cours d'enregistrement"
                icon={<LoadingOutlined />}
              />
            ) : (
              <FloatButton
                tooltip="Les réponses sont enregistrées automatiquement"
                icon={<CheckOutlined style={{color: token.colorSuccess}} />}
              />
            )}
            {formId !== -1 && isAdmin ? (
              <FloatButton
                tooltip="Voir ce formulaire dans le créateur"
                onClick={() => {
                  navigate(`/creator/?form=${formId}`);
                }}
                icon={<SwapOutlined />}
              />
            ) : null}
          </FloatButton.Group>
        </>
      ) : (
        <Spinner tip="Chargement du formulaire" />
      )}
      {contextHolder}
    </>
  );
};
