import {Checkbox, Form, Tooltip} from 'antd';
import {FC} from 'react';

import {useViewerStore} from '../../../stores/viewer';
interface NoAnswerCheckboxProps {
  uuid: string;
  disabled?: boolean;
}

export const NoAnswerCheckbox: FC<NoAnswerCheckboxProps> = ({
  uuid,
  disabled,
}) => {
  const form = Form.useFormInstance();
  const readOnly = useViewerStore((state) => state.readOnly);

  const checkbox = (
    <Checkbox
      disabled={disabled || readOnly}
      onChange={({target: {checked}}) => {
        if (checked) {
          form.setFieldValue(uuid, {text: '', willNotAnswer: true});
          form.setFields([{name: [uuid, 'text'], errors: ['']}]);
          return;
        }
        if (!checked) {
          form.setFields([
            {
              name: [uuid, 'text'],
              errors: ['Veuillez donner une réponse à cette question !'],
            },
          ]);
        }
      }}
    >
      Non concerné / Ne sait pas
    </Checkbox>
  );

  return (
    <Form.Item
      valuePropName="checked"
      name={[uuid, 'willNotAnswer']}
      style={{marginBottom: 0}}
    >
      {disabled ? (
        <Tooltip title="Veuillez effacer la réponse entièrement si vous souhaitez cocher cette case.">
          {checkbox}
        </Tooltip>
      ) : (
        checkbox
      )}
    </Form.Item>
  );
};
