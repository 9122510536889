import {
  FileExcelOutlined,
  InfoCircleOutlined,
  LockOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  message,
  Row,
  Space,
  Spin,
  theme,
  Typography,
} from 'antd';
import {
  evaluateFormulas,
  filterNewestAnswers,
  findAllQuestions,
  findAllTransferredFormulas,
  findFormFormula,
  FormAggregation,
  FormContentsType,
  Formula,
  isDefaultChapter,
  ItemType,
  Role,
} from 'holo-api';
import {FC, useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {answerMatrixGetPlural} from './client/answerMatrix';
import {
  closePack,
  getConsultantExport,
  getGlobalAverages,
  openPack,
} from './client/company';
import {
  formGetSingular,
  getAllFormPatentAnswers,
  getTemplateInstanceCompany,
} from './client/form';
import {GenerateReport} from './components/report/GenerateReport';
import {DashboardFormCard} from './components/ui/DashboardFormCard';
import {Spinner} from './components/ui/Spinner';
import {useFormsAggregation} from './hooks/useFormsAggregation';
import {usePackStore} from './stores/pack';
import {useUserStore} from './stores/user';
import {ViewerStoreProps} from './stores/viewer';
import {useApi, useUserRole} from './util/auth';

const percentQuestionAnswered = (aggregation: FormAggregation) => {
  if (aggregation.nbrQuestions === 0) {
    return 100;
  }
  return Math.floor(
    (100 * aggregation.nbrAnsweredQuestions) / aggregation.nbrQuestions,
  );
};

const allQuestionAnswered = (formAggregations: Array<FormAggregation>) => {
  if (formAggregations === undefined) {
    return false;
  }
  const unansweredForm = formAggregations.find(
    (aggregation) =>
      aggregation.nbrAnsweredQuestions !== aggregation.nbrQuestions,
  );
  return unansweredForm === undefined;
};

export const Dashboard: FC = () => {
  const navigate = useNavigate();
  const [formulaResults, setFormulaResults] = useState<
    Record<number, number | null | undefined> | undefined
  >(undefined);
  const userRole = useUserRole();
  const userInfo = useUserStore((state) => state.userInfo);
  const isAdmin = userRole === Role.ADMIN;
  const isConsultant = userRole === Role.CONSULTANT;
  const api = useApi();
  const formAggregations = useFormsAggregation();
  const preliminaryForm = usePackStore((state) => state.preliminaryForm);
  const packIsLoaded = usePackStore((state) => state.packIsLoaded);
  const forms = usePackStore((state) => state.forms);
  const [loadingFormulas, setLoadingFormulas] = useState(false);
  const [consultantExportLoading, setConsultantExportLoading] = useState(false);
  const companyId = useUserStore((state) => state.companyId);
  const {token} = theme.useToken();
  const packId = usePackStore((state) => state.packId);
  const readOnly = usePackStore((state) => state.readOnly);
  const setReadOnly = usePackStore((state) => state.setReadOnly);
  const [updatingPack, setUpdatingPack] = useState(false);
  const backupForms = usePackStore((state) => state.backupForms);

  const [totalNumberQuestions, setTotalNumberQuestions] = useState<
    number | undefined
  >(undefined);

  const [globalAverage, setGlobalAverage] = useState<number | undefined>(
    undefined,
  );
  const [globalWeightedAverage, setGlobalWeightedAverage] = useState<
    number | undefined
  >(undefined);

  const resetDashboardStates = useCallback(() => {
    setTotalNumberQuestions(undefined);
    setFormulaResults(undefined);
    setGlobalAverage(undefined);
    setGlobalWeightedAverage(undefined);
  }, []);

  useEffect(() => {
    if (!packIsLoaded) {
      resetDashboardStates();
    }
  }, [packIsLoaded, resetDashboardStates]);

  const calculateFormulas = useCallback(async () => {
    if (!api || !formAggregations || !packIsLoaded) {
      return;
    }
    if ((!isAdmin && !isConsultant) || formAggregations.length === 0) {
      return;
    }
    setLoadingFormulas(true);
    const matrices = await answerMatrixGetPlural(api);
    const allMatrices = matrices.entities;
    const results: Record<number, number | null | undefined> = {};
    let numberQuestions = 0;
    for (const aggregation of formAggregations) {
      const isPreliminaryForm = preliminaryForm?.id === aggregation.formId;
      const formData = isPreliminaryForm
        ? preliminaryForm
        : forms.find((form) => form.id === aggregation.formId);
      if (!formData) {
        continue;
      }
      if (!isPreliminaryForm) {
        numberQuestions += aggregation.nbrQuestions;
      }
      const form = aggregation.formSummary.contents;
      const foundFormFormula = findFormFormula(form);
      if (!foundFormFormula) {
        continue;
      }
      const allAnswers = aggregation.formSummary.answers;
      const answers = filterNewestAnswers(allAnswers).answers;

      const transferredFormulaResults: ViewerStoreProps['templateFormulasResults'] =
        {};
      const revertTagMapping: Record<string, string> = {};
      if (formData.subForms.length > 0) {
        let templateIndex = 1;
        const tagMapping: Record<string, string> = {};

        for (const template of formData.subForms) {
          const form = await formGetSingular(api, template.id, {
            viewAsAdmin: true,
          });
          const fullTemplate = form;
          const allTransferredFormulas: Formula[] = findAllTransferredFormulas(
            fullTemplate.contents,
          );
          const allQuestions = findAllQuestions(fullTemplate.contents);
          numberQuestions += allQuestions.length;
          if (allTransferredFormulas.length === 0) {
            continue;
          }

          const tIndex = templateIndex; // just to avoid typescript error :shrug:

          allTransferredFormulas.forEach((formula, formulaIndex) => {
            const tag = `T${tIndex}F${formulaIndex + 1}`;
            const targetTag = `T${template.id}F"${formula.uuid}"`;
            tagMapping[tag] = targetTag;
            revertTagMapping[targetTag] = tag;
            if (!transferredFormulaResults[tag]) {
              // found formula but can possibly have no answer
              transferredFormulaResults[tag] = {
                title: formula.title,
                result: undefined,
                totalWeight: 0,
              };
            }
          });

          try {
            const linkedPatents = readOnly
              ? {
                  entities:
                    backupForms?.savedTemplateInstances.filter(
                      (instance) => instance.templateId === template.id,
                    ) ?? [],
                }
              : await getTemplateInstanceCompany(api, template.id);
            const allPatentAnswers = await getAllFormPatentAnswers(
              api,
              template.id,
            );

            linkedPatents.entities.forEach((patent) => {
              const currentPatentAnswers = allPatentAnswers.entities.filter(
                (answers) => answers.patentId === patent.id,
              );
              const newestAnswers =
                filterNewestAnswers(currentPatentAnswers).answers;
              const currentPatentResults = evaluateFormulas(
                fullTemplate.contents as FormContentsType,
                newestAnswers,
                {},
                allMatrices,
              );

              allTransferredFormulas.forEach((formula, formulaIndex) => {
                const tag = `T${tIndex}F${formulaIndex + 1}`;
                const formulaResult = currentPatentResults[formula.uuid].result;
                const weightedResult = formulaResult
                  ? formulaResult * patent.weight
                  : undefined;
                if (
                  transferredFormulaResults[tag] &&
                  weightedResult &&
                  patent.weight
                ) {
                  // have an answer
                  if (transferredFormulaResults[tag].result === undefined) {
                    // check in case previous patents had no answer for this tag
                    transferredFormulaResults[tag].result = 0;
                  }
                  transferredFormulaResults[tag].result! += weightedResult;
                  transferredFormulaResults[tag].totalWeight += patent.weight;
                }
              });
            });
          } catch (err) {
            console.warn(err);
          }
          templateIndex++;
        }
      }
      form.chapters.forEach((chapter) => {
        if (!isDefaultChapter(chapter)) {
          return;
        }
        chapter.items.forEach((item) => {
          if (item.type === ItemType.FORMULA) {
            const matches = item.formula.matchAll(
              /T(?<templateIndex>\d+)F"(?<formulaUuid>[\w-]+)"/g,
            );
            for (const match of matches) {
              if (!match.groups) {
                continue;
              }
              const {templateIndex, formulaUuid} = match.groups;
              if (!templateIndex || !formulaUuid) {
                continue;
              }
              if (!revertTagMapping[match[0]]) {
                item.formula = item.formula.replaceAll(match[0], 'TXFX');
              }
              item.formula = item.formula.replaceAll(
                match[0],
                revertTagMapping[match[0]],
              );
            }
          }
        });
      });

      const formulaValues = evaluateFormulas(
        form,
        answers,
        transferredFormulaResults,
        allMatrices,
      );
      results[aggregation.formId] = formulaValues[foundFormFormula.uuid].result;
    }
    setTotalNumberQuestions(numberQuestions);
    setFormulaResults(results);

    const packAnsweredCompletely = allQuestionAnswered(formAggregations);

    if (packAnsweredCompletely) {
      try {
        const {globalAverage, globalWeightedAverage} = await getGlobalAverages({
          api,
        });
        setGlobalAverage(globalAverage);
        setGlobalWeightedAverage(globalWeightedAverage);
      } catch (err) {
        setGlobalAverage(undefined);
        setGlobalWeightedAverage(undefined);
        console.error(err);
      }
    }

    setLoadingFormulas(false);
  }, [
    api,
    formAggregations,
    packIsLoaded,
    isAdmin,
    isConsultant,
    preliminaryForm,
    forms,
    readOnly,
    backupForms?.savedTemplateInstances,
  ]);

  useEffect(() => {
    void calculateFormulas();
  }, [calculateFormulas]);

  if (!packIsLoaded || !formAggregations) {
    return (
      <>
        <Typography.Title
          color={token.colorPrimary}
          style={{textAlign: 'center', color: token.colorPrimary}}
        >
          TABLEAU DE BORD
        </Typography.Title>
        <Spinner tip="Chargement du tableau de bord" />
      </>
    );
  }

  return (
    <>
      <Typography.Title
        color={token.colorPrimary}
        style={{textAlign: 'center', color: token.colorPrimary}}
      >
        TABLEAU DE BORD
      </Typography.Title>
      {(isAdmin || isConsultant) && packId && (
        <>
          <Row
            justify="space-between"
            gutter={16}
            align="middle"
            style={{marginBottom: 16}}
          >
            <Col>
              <Typography.Text
                style={{color: token.colorPrimary, fontSize: '1.2em'}}
              >
                <InfoCircleOutlined /> Ce pack contient{' '}
                {!totalNumberQuestions ? (
                  <Spin
                    size="small"
                    style={{
                      marginLeft: 4,
                      marginRight: 4,
                    }}
                  />
                ) : (
                  totalNumberQuestions
                )}{' '}
                questions.
              </Typography.Text>
            </Col>
            {packId && formAggregations && (
              <Col>
                <Space>
                  <Button
                    icon={readOnly ? <UnlockOutlined /> : <LockOutlined />}
                    type="dashed"
                    loading={updatingPack}
                    onClick={async () => {
                      if (!api || !companyId || !packId) {
                        return;
                      }

                      setUpdatingPack(true);
                      if (readOnly) {
                        await openPack({
                          api,
                        });
                      } else {
                        await closePack({
                          api,
                        });
                      }
                      setUpdatingPack(false);
                      setReadOnly(!readOnly);
                    }}
                  >
                    {readOnly
                      ? 'Déclôturer le diagnostic'
                      : 'Clôturer le diagnostic'}
                  </Button>
                  <Divider type="vertical" />
                  {isAdmin && (
                    <Button
                      icon={<FileExcelOutlined />}
                      loading={consultantExportLoading}
                      onClick={async () => {
                        if (!api) {
                          return;
                        }
                        if (!companyId) {
                          void message.error(
                            'Veuillez vous assigner une entreprise avant de générer cet export.',
                          );
                          return;
                        }
                        setConsultantExportLoading(true);
                        // https://stackoverflow.com/a/36183085
                        const b64toBlob = (
                          base64: string,
                          type = 'application/octet-stream',
                        ) =>
                          fetch(`data:${type};base64,${base64}`).then((res) =>
                            res.blob(),
                          );

                        const excelFile = await getConsultantExport({api});
                        setConsultantExportLoading(false);
                        const blob = await b64toBlob(
                          excelFile,
                          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        );
                        const url = URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        document.body.appendChild(a);
                        a.style.display = 'none';
                        a.href = url;
                        a.download = `Extraction capital immatériel - ${userInfo?.Company?.name}.xlsx`;
                        a.click();
                        document.body.removeChild(a);
                        window.URL.revokeObjectURL(url);
                      }}
                    >
                      Export Consultant
                    </Button>
                  )}
                  <GenerateReport
                    disabled={!allQuestionAnswered(formAggregations)}
                  />
                </Space>
              </Col>
            )}
          </Row>
          <Row justify="space-evenly" style={{marginBottom: 16}}>
            <Col>
              <Space direction="vertical" align="center" size="small">
                <Typography.Title level={5}>
                  Note globale du diagnostic{' '}
                </Typography.Title>
                <Spinner spinning={loadingFormulas} size="small">
                  <Typography.Title
                    level={4}
                    style={{color: '#021D40', margin: 0}}
                  >
                    {!globalAverage ? 'indisponible' : globalAverage.toFixed(2)}
                  </Typography.Title>
                </Spinner>
              </Space>
            </Col>
            <Col>
              <Space direction="vertical" align="center" size="small">
                <Typography.Title level={5}>
                  Note globale pondérée du diagnostic{' '}
                </Typography.Title>
                <Spinner spinning={loadingFormulas} size="small">
                  <Typography.Title
                    level={4}
                    style={{color: '#021D40', margin: 0}}
                  >
                    {!globalWeightedAverage
                      ? 'indisponible'
                      : globalWeightedAverage.toFixed(2)}
                  </Typography.Title>
                </Spinner>
              </Space>
            </Col>
          </Row>
        </>
      )}

      {formAggregations?.map((aggregation) => (
        <Row
          key={aggregation.formId}
          style={{
            marginBottom: '1em',
          }}
        >
          <Col flex={1}>
            <DashboardFormCard
              form={aggregation.formSummary.contents}
              loadingFormulas={loadingFormulas}
              formFormulaResult={formulaResults?.[aggregation.formId]}
              hideFormFormulaResult={!formulaResults}
              isPreliminaryForm={aggregation.formId === preliminaryForm?.id}
              percent={percentQuestionAnswered(aggregation)}
              onClick={() => {
                navigate(`/viewer/?form=${aggregation.formId}`);
              }}
            />
          </Col>
        </Row>
      ))}
    </>
  );
};
