import {CopyOutlined, DownloadOutlined, SwapOutlined} from '@ant-design/icons';
import Ajv from 'ajv';
import {
  Button,
  Col,
  Descriptions,
  Divider,
  FloatButton,
  Form,
  FormInstance,
  Input,
  InputNumber,
  message,
  Modal,
  notification,
  Popconfirm,
  Row,
  Select,
  Space,
  Switch,
  Tag,
  theme,
  Tooltip,
  Typography,
} from 'antd';
import {NamePath} from 'antd/es/form/interface';
import dayjs from 'dayjs';
import {
  AdminChapter,
  AdminFormContentsType,
  AdminFormContentsTypeSchema,
  AnswerMatrixT,
  ChapterType,
  CreatorForm,
  customMath,
  findAllFormulas,
  findFormFormula,
  findSecteurQuestion,
  formCategoryLabel,
  getKeysFromReference,
  isChapterReference,
  isDefaultChapter,
  Item,
  ItemType,
  orderedFormCategories,
  ReportTag,
  Role,
} from 'holo-api';
import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {flushSync} from 'react-dom';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';

import {answerMatrixGetPlural} from './client/answerMatrix';
import {
  createSingularForm,
  duplicateForm,
  formDeleteSingular,
  formGetSingular,
  updateSingularForm,
} from './client/form';
import {usePrompt} from './components/Prompt';
import {ChapterFormList} from './components/ui/creator/ChapterFormList';
import {ColorPicker} from './components/ui/creator/ColorPicker';
import {CreatorSaveButton} from './components/ui/creator/CreatorSaveButton';
import {FormulaDrawer} from './components/ui/creator/FormulaDrawer';
import {DynamicSelect} from './components/ui/DynamicSelect';
import {MainFormFormulasModal} from './components/ui/modals/MainFormFormulasModal';
import {Spinner} from './components/ui/Spinner';
import {LoadingState, useLoading} from './hooks/useLoading';
import {CreatorStoreProps, useCreatorStore} from './stores/creator';
import {useMatrixStore} from './stores/matrix';
import {usePackStore} from './stores/pack';
import {useApi, useUserRole} from './util/auth';
import {
  buildCreatorTagMapping,
  colors,
  convertFormToCreatorForm,
  deleteKeysOnForm,
  getIndexesFromKeys,
  mergeNamePath,
  rewriteFormulasOnSave,
  verifyFormulaUsedInMainForm,
} from './util/form';
import {parseAndTraverseFormula} from './util/math';
import {emptyUUID} from './util/validator';

const ajv = new Ajv();

export const useOnItemChange = (form: FormInstance<CreatorForm>) => {
  const setChapters = useCreatorStore((state) => state.setChapters);
  const setChapterItems = useCreatorStore((state) => state.setChapterItems);

  const cb = useCallback(() => {
    const chapters = form.getFieldValue('chapters') as AdminChapter[];
    setChapters(chapters);

    const chapterItems: CreatorStoreProps['chapterItems'] = {};
    chapters.forEach((chapter, index) => {
      if (chapter.chapterType === ChapterType.DEFAULT) {
        chapterItems[index] = chapter.items;
      }
    });
    setChapterItems(chapterItems);
  }, [form, setChapterItems, setChapters]);

  return cb;
};

export const useOnItemRemove = (form: FormInstance<CreatorForm>) => {
  const {token} = theme.useToken();
  const cb = useCallback(
    (reference: string, callback?: () => void) => {
      if (reference === '' && callback) {
        callback();
        return;
      }
      const allFormValues = form.getFieldsValue() as CreatorForm;
      const formulas: {name: NamePath; value: string; title: string}[] = [];
      const isChapter = isChapterReference(reference);
      const [referenceChapterKey, referenceItemKey] =
        getKeysFromReference(reference);
      const [referenceChapterIndex, referenceItemIndex] = getIndexesFromKeys(
        allFormValues,
        referenceChapterKey,
        referenceItemKey,
      );
      const itemTitle = isChapter
        ? ''
        : form.getFieldValue(
            mergeNamePath(
              'chapters',
              referenceChapterIndex,
              'items',
              referenceItemIndex,
            ),
          ).title;
      allFormValues.chapters.forEach((chapter, chapterIndex) => {
        if (
          // skipping the chapter being deleted: no need to amend its formulas
          (isChapter && chapter.chapterKey === referenceChapterKey) ||
          !isDefaultChapter(chapter)
        ) {
          return;
        }
        chapter.items.forEach((item: Item, itemIndex) => {
          if (
            item.type === ItemType.FORMULA &&
            item.formula.includes(reference)
          ) {
            const parsed = parse(item.formula);
            const html = document.createElement('div');
            html.innerHTML = parsed.toHTML();
            const children = Array.from(html.children);
            // TODO: use regex to check if the reference is in the formula
            // if reference is chapter, regex using CxS*
            if (
              children.findIndex((child) =>
                isChapter
                  ? child.textContent?.toString().includes(reference)
                  : child.textContent === reference,
              ) !== -1
            ) {
              const name: NamePath = [
                'chapters',
                chapterIndex,
                'items',
                itemIndex,
                'formula',
              ];
              formulas.push({
                name,
                value: form.getFieldValue(name),
                title: item.title,
              });
            }
          }
        });
      });
      if (formulas.length > 0) {
        const modalObject = Modal.confirm({
          width: '70%',
          title: isChapter ? (
            <Typography>{`Des formules sont liées au chapitre ${reference}`}</Typography>
          ) : (
            <Space>
              <Typography>{`Des formules sont liées à l'élément : "${itemTitle}"`}</Typography>
              <Tag
                color={
                  colors[(referenceItemIndex + referenceChapterIndex) % 12]
                }
              >
                {reference}
              </Tag>
            </Space>
          ),
          okButtonProps: {style: {backgroundColor: token.colorPrimary}},
          content: (
            <Form form={form}>
              {formulas.map((formula, keyIndex) => {
                const name = Array.isArray(formula.name)
                  ? formula.name
                  : [formula.name];
                const chapterIndex = Number(name[1]);
                const formulaIndex = Number(name[3]);

                return (
                  <Form.Item
                    key={keyIndex}
                    label={
                      <Space>
                        <Typography>{formula.title}</Typography>
                        <Tag
                          color={
                            colors[
                              (chapterIndex + formulaIndex) % colors.length
                            ]
                          }
                        >{`C${chapterIndex + 1}F${formulaIndex + 1}`}</Tag>
                      </Space>
                    }
                    name={formula.name}
                    rules={[
                      {
                        validateTrigger: 'onChange',
                        validator: (_, value) => {
                          if (!value) {
                            return Promise.reject(
                              'Une formule ne peut pas être vide, veuillez indiquer la valeur 0 pour ignorer',
                            );
                          }
                          if (value.includes(reference)) {
                            let errorMessage = '';
                            parseAndTraverseFormula(value, (child) => {
                              if (errorMessage) {
                                return;
                              }
                              if (
                                child.textContent &&
                                child.classList.contains('math-symbol')
                              ) {
                                const textContent = child.textContent;
                                const [childChapterKey] =
                                  getKeysFromReference(textContent);
                                if (
                                  isChapter &&
                                  childChapterKey === referenceChapterKey
                                ) {
                                  errorMessage = `Les références au chapitre '${allFormValues.chapters[referenceChapterIndex].title}' (${reference}) doivent toutes être retirées.`;
                                }
                                if (textContent === reference) {
                                  errorMessage = `La référence à '${reference}' doit être retirée.`;
                                }
                              }
                            });
                            if (errorMessage) {
                              return Promise.reject(errorMessage);
                            }
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                );
              })}
            </Form>
          ),
          onCancel: () => {
            formulas.forEach((value) => {
              form.setFieldValue(value.name, value.value);
            });
          },
          onOk: async () => {
            const formulaNames = formulas.map(({name}) => name);
            await form.validateFields(formulaNames);
            callback?.();
            modalObject.destroy();
          },
        });
      } else {
        if (!callback) {
          return;
        }
        callback();
      }
    },
    [form, token],
  );

  return cb;
};

export const useInsertCurrentFormulaValue = (
  form: FormInstance<CreatorForm>,
) => {
  const currentFormulaName = useCreatorStore(
    (state) => state.currentFormulaName,
  );
  const currentFormulaRef = useCreatorStore((state) => state.currentFormulaRef);

  const cb = useCallback(
    (value: string) => {
      if (!currentFormulaName || !currentFormulaRef?.current?.input) {
        return;
      }

      const cursorStart = currentFormulaRef.current.input.selectionStart ?? 0;
      let cursorEnd = currentFormulaRef.current.input.selectionEnd ?? 0;
      const currentFormulaValue = form.getFieldValue(currentFormulaName);
      form.setFieldValue(
        currentFormulaName,
        (
          currentFormulaValue.trim().slice(0, cursorStart) +
          value +
          currentFormulaValue.trim().slice(cursorEnd)
        ).trim(),
      );
      currentFormulaRef.current.focus();

      cursorEnd = cursorStart + value.length;
      if (value.endsWith('()')) {
        cursorEnd -= 1;
      }

      setTimeout(
        () =>
          currentFormulaRef.current?.setSelectionRange(cursorEnd, cursorEnd),
        10,
      );
    },
    [currentFormulaName, currentFormulaRef, form],
  );

  return cb;
};

const {parse} = customMath;

export const Creator: FC = () => {
  const isAdmin = useUserRole() === Role.ADMIN;
  const [form] = Form.useForm<CreatorForm>();
  const [duplicateFormInstance] = Form.useForm<{title: string}>();
  const [modal, contextHolder] = Modal.useModal();

  const setChapterItems = useCreatorStore((state) => state.setChapterItems);
  const errors = useCreatorStore((state) => state.errors);
  const setErrors = useCreatorStore((state) => state.setErrors);
  const clearError = useCreatorStore((state) => state.clearError);
  const isTouched = useCreatorStore((state) => state.isTouched);
  const setIsTouched = useCreatorStore((state) => state.setIsTouched);
  const templateFormulasMapping = useCreatorStore(
    (state) => state.templateFormulasMapping,
  );
  const setFormTemplates = useCreatorStore((state) => state.setFormTemplates);
  const formTemplates = useCreatorStore((state) => state.formTemplates);
  const setMainForm = useCreatorStore((state) => state.setMainForm);
  const setIsPreliminaryForm = useCreatorStore(
    (state) => state.setIsPreliminaryForm,
  );
  const isPreliminaryForm = useCreatorStore((state) => state.isPreliminaryForm);

  usePrompt(
    isTouched,
    'Le formulaire a été modifié, souhaitez-vous quitter sans sauvegarder ces changements ?',
  );

  const [params] = useSearchParams();
  const loadPack = usePackStore((state) => state.loadPack);
  const api = useApi();
  const navigate = useNavigate();
  const formParam = params.get('form');

  const [formId, setFormId] = useState(-1);

  const [loading, setLoading] = useLoading();
  const [formSelected, setFormSelected] = useState(false);
  const [formUpdatedDate, setFormUpdatedDate] = useState<Date | undefined>();
  const [formUsedInPacks, setFormUsedInPacks] = useState(false);

  const [isMainForm, setIsMainForm] = useState<boolean>(false);
  const [isTemplate, setIsTemplate] = useState<boolean>(false);
  const mainFormId = Form.useWatch('mainFormId', form);
  const mainForm = useCreatorStore((state) => state.mainForm);
  const formulasToFix = useCreatorStore((state) => state.formulasToFix);
  const setFormulasToFix = useCreatorStore((state) => state.setFormulasToFix);
  const checkboxPath = useCreatorStore((state) => state.checkboxPath);

  const setCurrentFormulaName = useCreatorStore(
    (state) => state.setCurrentFormulaName,
  );
  const setInitialForm = useCreatorStore((state) => state.setInitialForm);
  const setCurrentFormFormula = useCreatorStore(
    (state) => state.setCurrentFormFormula,
  );
  const setChapters = useCreatorStore((state) => state.setChapters);
  const {matrix1d, matrix2d, loading: matricesLoading} = useMatrixStore();
  const allMatrices = useMemo(
    () =>
      [...matrix1d, ...matrix2d].map((m) => {
        const am: AnswerMatrixT = {
          id: m.id,
          name: m.name,
          description: m.description ? m.description : '',
          mat2d: m.mat2d,
          matrixND: m,
        };
        return am;
      }),
    [matrix1d, matrix2d],
  );
  const setMatrices = useMatrixStore((state) => state.setMatrices);

  const loadMatrices = useCallback(async () => {
    if (!api || matricesLoading !== LoadingState.None) {
      return;
    }

    const matrices = await answerMatrixGetPlural(api);
    setMatrices(matrices.entities);
  }, [api, matricesLoading, setMatrices]);

  useEffect(() => {
    if (!isAdmin) {
      navigate('/');
    }
  }, [isAdmin, navigate]);

  useEffect(() => {
    void loadMatrices();
  }, [loadMatrices]);

  const loadForm = useCallback(async () => {
    if (!api || loading !== LoadingState.None) {
      return;
    }
    // after the above return otherwise we step on ourselves
    setCurrentFormFormula(undefined);
    setCurrentFormulaName(undefined);
    setIsTouched(false);

    if (formId === -1) {
      form.resetFields();
      setLoading(LoadingState.Loaded);
      return;
    }

    try {
      setLoading(LoadingState.Loading);
      const formData = await formGetSingular(api, formId, {viewAsAdmin: true});
      if (!formData) {
        setLoading(LoadingState.Error);
        notification.error({
          message: `Le formulaire n'a pas été trouvé, veuillez réessayer ou en charger un autre`,
        });
        return;
      }

      const loadedForm = formData.contents;

      const validator = ajv.compile<AdminFormContentsType>(
        AdminFormContentsTypeSchema,
      );
      const valid = validator(loadedForm);
      if (!valid) {
        void modal.error({
          title: "Le formulaire n'est pas valide",
          content: (
            <Row justify="center">
              <Col>
                <Button
                  icon={<CopyOutlined />}
                  onClick={() => {
                    void navigator.clipboard.writeText(
                      JSON.stringify(validator.errors, null, 2),
                    );
                  }}
                  danger
                >
                  Copier dans le presse-papiers
                </Button>
              </Col>
            </Row>
          ),
          okText: 'Fermer',
        });
        setFormId(-1);
        setLoading(LoadingState.Error);
        return;
      }

      setFormUsedInPacks(formData.nbrLinkedPacks > 0);

      const revertTagMapping: Record<string, string> = {};
      if (formData.templates.length > 0) {
        setIsMainForm(true);
        const {tagMapping, allTemplates} = await buildCreatorTagMapping(
          revertTagMapping,
          formData,
          api,
        );
        setFormTemplates(allTemplates, tagMapping);
      }

      setInitialForm(loadedForm);
      setFormUpdatedDate(new Date(formData.updatedAt));
      setChapters(loadedForm.chapters);
      const {priority, mainFormId, category} = formData;

      loadedForm.priority = formData.priority;
      loadedForm.mainFormId = formData.mainFormId;
      loadedForm.category = formData.category;

      if (
        !convertFormToCreatorForm(
          loadedForm,
          {priority, mainFormId, category},
          revertTagMapping,
          allMatrices,
        )
      ) {
        return;
      }

      setIsTemplate(!!loadedForm.patent);

      if (loadedForm.patent && formData.mainFormId) {
        setIsTemplate(true);
        const mainForm = await formGetSingular(api, formData.mainFormId, {
          viewAsAdmin: true,
        });
        if (!mainForm) {
          throw new Error(
            `Aucun formulaire principal n'a été trouvé pour le template ${formData.contents.title}`,
          );
        }
        const {tagMapping, allTemplates} = await buildCreatorTagMapping(
          revertTagMapping,
          mainForm,
          api,
        );
        if (
          !convertFormToCreatorForm(
            mainForm.contents,
            {
              priority: mainForm.priority,
              mainFormId: mainForm.mainFormId,
              category: mainForm.category,
            },
            revertTagMapping,
            allMatrices,
          )
        ) {
          return;
        }
        setMainForm(mainForm);
        setFormTemplates(allTemplates, tagMapping);
      }

      form.setFieldsValue(loadedForm);
      const globalFormula = findFormFormula(loadedForm);

      const chapterItems: CreatorStoreProps['chapterItems'] = {};
      loadedForm.chapters.forEach((chapter, index) => {
        if (chapter.chapterType === ChapterType.DEFAULT) {
          chapterItems[index] = chapter.items;
        }
      });

      // we use flushSync here to make sure that all those states are updated in only one render pass
      flushSync(() => {
        setInitialForm(loadedForm);
        setFormUpdatedDate(new Date(formData.updatedAt));
        setChapters(loadedForm.chapters);
        setIsTemplate(!!loadedForm.patent);
        setCurrentFormFormula(globalFormula ? globalFormula.title : undefined);
        setChapterItems(chapterItems);
        setIsPreliminaryForm(formData.isPreliminary ?? false);
      });

      // this one state change makes the render ultra slow due to ant render the form, form items, and form lists
      setLoading(LoadingState.Loaded);

      void message.success({
        key: 'form_load_success',
        content: 'Formulaire chargé avec succès !',
      });
    } catch (err) {
      console.error(err);
      setLoading(LoadingState.Error);
      void message.error(`Impossible de charger le formulaire...`);
    }
  }, [
    setCurrentFormFormula,
    setCurrentFormulaName,
    setIsTouched,
    formId,
    api,
    loading,
    form,
    setLoading,
    setInitialForm,
    setChapters,
    setChapterItems,
    setFormTemplates,
    setMainForm,
    setIsPreliminaryForm,
    allMatrices,
    modal,
  ]);

  useEffect(() => {
    if (matricesLoading !== LoadingState.Loaded) {
      return;
    }
    void loadForm();
    setErrors([]);
  }, [loadForm, setErrors, matricesLoading]);

  const reset = useCallback(() => {
    setCurrentFormFormula(undefined);
    setInitialForm(undefined);
    setIsTemplate(false);
    setIsMainForm(false);
    setFormTemplates([], {});
    setLoading(LoadingState.None);
  }, [setCurrentFormFormula, setFormTemplates, setInitialForm, setLoading]);

  useEffect(() => {
    // cleanup when leaving Creator
    return reset;
  }, [reset]);

  useEffect(() => {
    if (formParam) {
      reset();
      setFormId(Number(formParam));
    }
  }, [formParam, reset]);

  const handleFinish = async (values: CreatorForm) => {
    const {priority, mainFormId, category, ...formValues} = JSON.parse(
      JSON.stringify(values),
    ) as CreatorForm;
    try {
      if (isPreliminaryForm) {
        const secteurQuestion = findSecteurQuestion({
          preliminarySurveyForm: values,
        });
        if (!secteurQuestion) {
          const message = `Aucune question taggée ${ReportTag.UNIVERS_METIER}`;
          void modal.error({
            title: "Le formulaire n'est pas valide",
            content: (
              <Row justify="center">
                <Col>{message}</Col>
              </Row>
            ),
            okText: 'Fermer',
          });
          return;
        }
      }

      const allFormulas = findAllFormulas(formValues);

      rewriteFormulasOnSave(
        allFormulas,
        formValues,
        allMatrices,
        templateFormulasMapping,
      );

      deleteKeysOnForm(formValues);
    } catch (error) {
      console.error(error);
    }
    const validator = ajv.compile<AdminFormContentsType>(
      AdminFormContentsTypeSchema,
    );
    // fallback for old color handling
    if (!formValues.color || formValues.color[0] !== '#') {
      formValues.color = `#${formValues.color}`;
    }
    const valid = validator(formValues);
    if (!valid) {
      void modal.error({
        title: "Le formulaire n'est pas valide",
        content: (
          <Row justify="center">
            <Col>
              <Button
                icon={<CopyOutlined />}
                onClick={() => {
                  void navigator.clipboard.writeText(
                    JSON.stringify(validator.errors, null, 2),
                  );
                }}
                danger
              >
                Copier dans le presse-papiers
              </Button>
            </Col>
          </Row>
        ),
        okText: 'Fermer',
      });
    } else {
      if (!api) {
        return;
      }

      const body = {
        contents: formValues,
        minorVersion: 1,
        patchVersion: 1,
        schemaVersion: 1,
        priority: priority ?? null,
        mainFormId: mainFormId ?? null,
        category: category ?? undefined,
      };

      if (formId === -1) {
        const {createdFormId} = await createSingularForm(api, body);
        setFormId(createdFormId);
      } else {
        await updateSingularForm(api, formId, body);
      }

      const itemsLength = formValues.chapters
        .filter((chapter) => chapter.chapterType === ChapterType.DEFAULT)
        .reduce(
          (acc, chapter) =>
            acc +
            chapter.items.filter((item) => item.type === ItemType.QUESTION)
              .length,
          0,
        );

      void modal.success({
        title: 'Le formulaire a été enregistré !',
        content: (
          <Descriptions bordered column={1} style={{marginBottom: 16}}>
            <Descriptions.Item label="Chapitres">
              {formValues.chapters.length}
            </Descriptions.Item>
            <Descriptions.Item label="Questions">
              {itemsLength}
            </Descriptions.Item>
          </Descriptions>
        ),
        okText: 'Fermer',
        onOk: () => {
          setIsTouched(false);
          setLoading(LoadingState.None);
          void loadPack(api);
        },
      });
    }
  };

  return (
    <>
      <FormulaDrawer form={form} />
      {formId !== -1 && LoadingState.Loaded && (
        <>
          <Row justify="center" align="middle" gutter={16}>
            <Col>
              <Button
                onClick={() => {
                  reset();
                  setFormId(-1);
                  setFormSelected(false);
                }}
                disabled={
                  matricesLoading !== LoadingState.Loaded ||
                  loading === LoadingState.Loading
                }
              >
                Créer / Changer de formulaire
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() => {
                  navigate(`/viewer/?form=${formId}`);
                }}
                disabled={
                  matricesLoading !== LoadingState.Loaded ||
                  loading === LoadingState.Loading
                }
              >
                Voir ce formulaire en mode utilisateur
              </Button>
            </Col>
            <Col>
              <Tooltip
                title={
                  isTouched
                    ? 'Veuillez sauvegarder ce formulaire avant de tenter de le dupliquer'
                    : undefined
                }
              >
                <Button
                  onClick={async () => {
                    if (!api) {
                      return;
                    }
                    const duplicateModal = modal.confirm({
                      onOk: async () => {
                        if (!api) {
                          return;
                        }
                        const {title} =
                          await duplicateFormInstance.validateFields();
                        const {newFormId} = await duplicateForm(
                          api,
                          formId,
                          title,
                        );
                        await loadPack(api);
                        duplicateFormInstance.resetFields();
                        duplicateModal.destroy();
                        navigate(`/creator/?form=${newFormId}`);
                      },
                      onCancel: () => {
                        duplicateFormInstance.resetFields();
                        duplicateModal.destroy();
                      },
                      content: (
                        <Form form={duplicateFormInstance}>
                          <Form.Item
                            name="title"
                            label="Nom de la copie"
                            required
                            rules={[
                              {
                                validator: (_r, value) => {
                                  const currentTitle =
                                    form.getFieldValue('title');
                                  if (value.trim() === '') {
                                    return Promise.reject(
                                      'Veuillez donner un titre à la copie du formulaire',
                                    );
                                  }
                                  if (value === currentTitle) {
                                    return Promise.reject(
                                      'Veuillez choisir un nom différent du formulaire original, pour éviter toute confusion.',
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ]}
                          >
                            <Input.TextArea autoSize />
                          </Form.Item>
                        </Form>
                      ),
                    });
                  }}
                  disabled={
                    matricesLoading !== LoadingState.Loaded ||
                    loading === LoadingState.Loading ||
                    isTouched
                  }
                >
                  Dupliquer ce formulaire
                </Button>
              </Tooltip>
            </Col>
            <Col>
              <Popconfirm
                title="Êtes-vous sûr de vouloir supprimer ce formulaire ?"
                onConfirm={async () => {
                  if (!api) {
                    return;
                  }
                  if (isTemplate && mainForm) {
                    const allFormulasUsedInMainForm = findAllFormulas(
                      form.getFieldsValue(),
                    ).filter((formula) => formula.usedInMainForm);
                    const mainFormFormulas = findAllFormulas(mainForm.contents);
                    const formulasToFix = verifyFormulaUsedInMainForm(
                      allFormulasUsedInMainForm.map((formula) => formula.uuid),
                      mainFormFormulas,
                      templateFormulasMapping,
                    );
                    setFormulasToFix(formulasToFix);
                  }
                  try {
                    await formDeleteSingular(api, formId);
                    void message.success('Formulaire supprimé avec succès.');
                    reset();
                    setFormId(-1);
                    setFormSelected(false);
                    void loadPack(api);
                  } catch (err) {
                    void message.error(
                      'Une erreur est survenue lors de la suppression du formulaire',
                    );
                  }
                }}
              >
                <Tooltip
                  title={
                    formUsedInPacks
                      ? "Ce formulaire est utilisé dans un ou plusieurs packs, veuillez l'en retirer avant de le supprimer"
                      : ''
                  }
                >
                  <Button
                    danger
                    disabled={
                      loading !== LoadingState.Loaded ||
                      formUsedInPacks ||
                      (!!mainFormId && mainFormId > 0)
                    }
                  >
                    Supprimer ce formulaire
                  </Button>
                </Tooltip>
              </Popconfirm>
            </Col>
          </Row>
          <Row justify="center" style={{marginTop: 8}}>
            <Col>
              <Typography.Text type="secondary">
                Date de dernière mise à jour :{' '}
                {dayjs(formUpdatedDate).format('DD/MM/YYYY à HH:mm')}
              </Typography.Text>
            </Col>
          </Row>
          {isMainForm && (
            <Row
              wrap={false}
              justify="space-evenly"
              style={{margin: '16px auto'}}
            >
              {formTemplates.map((template, index) => (
                <Col key={index}>
                  <Link to={`/creator/?form=${template.id}`}>
                    <Button type="primary">{`Formulaire(s) «${template.title}»`}</Button>{' '}
                  </Link>
                </Col>
              ))}
            </Row>
          )}
          {mainFormId && (
            <Row style={{marginTop: 16}}>
              <Link to={`/creator/?form=${mainFormId}`}>
                <Button type="primary">
                  Retour vers le formulaire principal
                </Button>
              </Link>
            </Row>
          )}
          <Divider />
        </>
      )}
      {formId === -1 && (
        <Form<{formId: number}>
          onFinish={({formId}) => {
            setFormId(formId);
            setLoading(LoadingState.None);
          }}
        >
          <Form.Item noStyle>
            <Row gutter={16}>
              <Col flex={1}>
                <Form.Item label="Nom du formulaire" name="formId">
                  <DynamicSelect
                    api={api}
                    label="title"
                    url="/forms/titles/search"
                    placeholder="Rechercher un formulaire à charger"
                    onSelect={(value) => {
                      if (!value) {
                        setFormSelected(false);
                        return;
                      }
                      setFormSelected(true);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    icon={<DownloadOutlined />}
                    type="primary"
                    disabled={!formSelected}
                  >
                    Charger
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      )}
      <Form<CreatorForm>
        form={form}
        layout="vertical"
        initialValues={{
          uuid: emptyUUID,
          version: '1.0.0',
          title: '',
          description: '',
          color: '#FFF',
          chapters: [],
        }}
        onValuesChange={async () => {
          if (!isTouched) {
            setIsTouched(true);
          }
          if (errors.length > 0) {
            try {
              await form.validateFields({validateOnly: true});
            } catch (err) {
              setErrors(
                // @ts-expect-error err in unknown, any triggers lint
                err.errorFields as ReturnType<FormInstance['getFieldsError']>,
              );
            }
          }
        }}
        onFinishFailed={(err) => {
          if (!err.errorFields.length) {
            return;
          }

          const name = err.errorFields[0].name.join('_');
          const el = document.getElementById(name);
          if (!el) {
            return;
          }

          el.scrollIntoView({behavior: 'smooth', block: 'center'});
        }}
      >
        {matricesLoading === LoadingState.Loaded &&
        loading === LoadingState.Loaded ? (
          <>
            <Form.Item name="uuid" label="UUID" hidden>
              <Input hidden />
            </Form.Item>
            <Form.Item name="version" label="Version" hidden>
              <Input hidden />
            </Form.Item>
            <Form.Item
              name="title"
              label="Nom du formulaire"
              required
              rules={[{required: true}]}
            >
              <Input.TextArea autoSize />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              required
              rules={[{required: true}]}
            >
              <Input.TextArea autoSize />
            </Form.Item>
            <Form.Item name="category" label="Catégorie">
              <Select
                options={orderedFormCategories.map((category) => ({
                  label: formCategoryLabel(category),
                  value: category,
                }))}
              />
            </Form.Item>
            <Form.Item name="objectives" label="Objectifs">
              <Input.TextArea autoSize={{minRows: 1, maxRows: 4}} />
            </Form.Item>
            <Form.Item name="themes" label="Thématiques Clés">
              <Input.TextArea autoSize={{minRows: 1, maxRows: 4}} />
            </Form.Item>
            <Form.Item name="contributors" label="Contributeurs">
              <Input.TextArea autoSize={{minRows: 1, maxRows: 4}} />
            </Form.Item>
            <Form.Item name="color" label="Couleur associée au formulaire">
              <ColorPicker />
            </Form.Item>
            <Row justify="start" gutter={40}>
              <Col>
                <Form.Item
                  name="priority"
                  label="Ordre du formulaire"
                  tooltip="De 0 (haut) à 1000 (bas)"
                >
                  <InputNumber min={0} max={1000} wheel={false} />
                </Form.Item>
              </Col>
              {!isMainForm && (
                <Col>
                  <Form.Item
                    name="patent"
                    label="Template"
                    valuePropName="checked"
                  >
                    <Switch
                      checked={isTemplate}
                      onChange={() => {
                        setIsTemplate((state) => {
                          if (state === true && errors.length > 0) {
                            clearError(['mainFormId']);
                          }

                          return !state;
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
              )}
              {isTemplate && (
                <>
                  <Col>
                    <Form.Item
                      name="mainFormId"
                      label="Formulaire principal associé"
                      rules={[{required: true}]}
                    >
                      <DynamicSelect
                        label="title"
                        api={api}
                        url="/forms/titles/search"
                        filter={(opt) => opt.id !== formId}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
            <ChapterFormList name="chapters" />
          </>
        ) : loading === LoadingState.Loading ? (
          <Spinner tip="Chargement du formulaire" />
        ) : null}
      </Form>
      <FloatButton.Group>
        <CreatorSaveButton
          isTouched={isTouched}
          form={form}
          handleFinish={handleFinish}
        />
        {formId !== -1 && (
          <FloatButton
            icon={<SwapOutlined />}
            tooltip="Voir ce formulaire en mode utilisateur"
            onClick={() => {
              navigate(`/viewer/?form=${formId}`);
            }}
          />
        )}
      </FloatButton.Group>
      <MainFormFormulasModal
        formulas={formulasToFix}
        onOk={() => {
          setFormulasToFix(undefined);
        }}
        onCancel={() => {
          setFormulasToFix(undefined);
          if (checkboxPath) {
            form.setFieldValue(checkboxPath, true);
          }
        }}
      />
      {contextHolder}
    </>
  );
};
