import {EditOutlined} from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  ModalProps,
  Popconfirm,
  Space,
  Typography,
} from 'antd';
import {FormContentsType} from 'holo-api';
import {FC, useEffect, useState} from 'react';

import {
  createTemplateInstance,
  deleteSingularPatent,
  updateSingularPatent,
} from '../../../client/form';
import {LoadingState} from '../../../hooks/useLoading';
import {useUserStore} from '../../../stores/user';
import {useApi} from '../../../util/auth';

interface TemplateModalProps extends ModalProps {
  form: FormContentsType;
  formId: number;
  id: number | 'new' | undefined;
  editedInstance?: {name: string; weight: number} | undefined;
  closeModal: () => void;
  setViewerLoading: (loading: LoadingState) => void;
  loadForm: (templateInstanceId?: number) => Promise<void>;
}

export const TemplateModal: FC<TemplateModalProps> = ({
  form,
  formId,
  id,
  editedInstance,
  setViewerLoading,
  loadForm,
  closeModal,
  ...props
}) => {
  const [templateInstanceForm] = Form.useForm<{name: string; weight: number}>();
  const [loading, setLoading] = useState<boolean>(false);
  const api = useApi();
  const {companyId} = useUserStore();

  useEffect(() => {
    if (!id) {
      return;
    }
    if (id === 'new') {
      templateInstanceForm.setFieldsValue({
        name: '',
        weight: 100,
      });
    }
    if (typeof id === 'number') {
      templateInstanceForm.setFieldsValue({
        name: editedInstance?.name,
        weight: editedInstance?.weight,
      });
    }
  }, [id, editedInstance, templateInstanceForm]);

  return (
    <Modal
      title={
        id === 'new'
          ? `Ajout de "${form.title}"`
          : `Edition de "${editedInstance?.name}"`
      }
      centered
      confirmLoading={loading}
      forceRender
      maskClosable={false}
      open={!!id}
      width="400px"
      onCancel={closeModal}
      footer={
        <Space direction="horizontal">
          {typeof id === 'number' && (
            <Popconfirm
              title={`Êtes-vous sûr de vouloir supprimer "${editedInstance?.name}"`}
              onConfirm={async () => {
                if (!api) {
                  return;
                }
                setLoading(true);
                try {
                  await deleteSingularPatent(api, id);
                  void message.success('Template supprimé !');
                } catch (err) {
                  void message.error(
                    'Une erreur est survenue au moment de supprimer le template.',
                  );
                  console.error(err);
                } finally {
                  closeModal();
                  setViewerLoading(LoadingState.None);
                  await loadForm();
                  setLoading(false);
                }
              }}
            >
              <Button type="default" danger>
                Supprimer le template
              </Button>
            </Popconfirm>
          )}
          <Button
            onClick={() => {
              closeModal();
            }}
          >
            Annuler
          </Button>
          <Button
            type="primary"
            onClick={async () => {
              if (!api || !companyId || !id) {
                return;
              }
              setLoading(true);
              try {
                const instanceData =
                  await templateInstanceForm.validateFields();
                setViewerLoading(LoadingState.None);
                if (id === 'new') {
                  const newTemplateInstance = await createTemplateInstance({
                    api,
                    input: {
                      formId,
                      companyId,
                      ...instanceData,
                    },
                  });
                  void message.success('Template créé avec succès !');
                  void loadForm(newTemplateInstance.id);
                } else {
                  const editedInstance = await updateSingularPatent(
                    api,
                    id,
                    instanceData,
                  );
                  void message.success('Template mis à jour avec succès !');
                  void loadForm(editedInstance.id);
                }
                closeModal();
              } catch (error) {
                console.error(error);
                void message.error('Une erreur est survenue.');
                void loadForm();
              }
            }}
          >
            Confirmer
          </Button>
        </Space>
      }
      {...props}
    >
      <Form form={templateInstanceForm} layout="vertical">
        <Form.Item
          name="name"
          label="Nom"
          rules={[
            {
              validator: (_r, value) => {
                if (value.trim() === '') {
                  return Promise.reject('Veuillez donner un nom au template');
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="weight"
          label="Pondération"
          tooltip={
            <Typography.Text style={{color: 'white'}}>
              Déterminez un pourcentage significatif pour chaque "{form?.title}"
              analysé(e) afin que l'évaluation de cet actif tienne compte de
              cette particularité lors du calcul de la moyenne globale. La
              pondération de chaque "{form?.title}" peut être modifiée à tout
              moment en cliquant sur le pictogramme <EditOutlined />
            </Typography.Text>
          }
          rules={[
            {
              validator: (_r, value) => {
                if (!value) {
                  return Promise.reject(
                    'Veuillez donner une pondération au template',
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <InputNumber min={0} max={1000} addonAfter="%" wheel={false} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
