import {Typography} from 'antd';
import {Title} from 'holo-api';
import {FC} from 'react';

interface ViewerTitleItemProps extends Title {}

export const ViewerTitleItem: FC<ViewerTitleItemProps> = ({title}) => {
  return (
    <Typography.Title
      level={4}
      underline
      style={{textAlign: 'center', marginBottom: '40px'}}
    >
      {title}
    </Typography.Title>
  );
};
