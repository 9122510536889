import {DownloadOutlined, UploadOutlined} from '@ant-design/icons';
import {
  Badge,
  Button,
  Col,
  message,
  Row,
  Typography,
  Upload,
  UploadFile,
} from 'antd';
import {RcFile} from 'antd/es/upload';
import {AccessReportFile, StaticReportPage} from 'holo-api';

type UploadFileData = unknown; // for lint, not any

export const StaticPdfPage = ({
  page,
  accessFile,
  onUploadStaticPage,
}: {
  page: StaticReportPage;
  accessFile: AccessReportFile | undefined;
  onUploadStaticPage: (
    uploadFile: UploadFile<UploadFileData>,
    page: StaticReportPage,
  ) => void;
}) => {
  return (
    <>
      <Row>
        <Col>
          <Typography.Text>{page.pageName}</Typography.Text>
        </Col>
      </Row>
      <Row justify="end">
        <Col>
          <Button.Group>
            <Upload<UploadFileData>
              accept="application/pdf"
              showUploadList={{
                showRemoveIcon: true,
                showDownloadIcon: false,
                showPreviewIcon: false,
              }}
              maxCount={1}
              customRequest={({onSuccess}) => onSuccess?.(true)}
              beforeUpload={(file: RcFile) => {
                const fileSizeLimit = 2 * 1024 * 1024;
                if (file.size > fileSizeLimit) {
                  void message.error(
                    `Image must smaller than 2MB! not ${file.size}`,
                  );
                  return false;
                }
                return true;
              }}
              onChange={async (pdfFile) => {
                if (accessFile) {
                  onUploadStaticPage(pdfFile.file, page);
                }
              }}
            >
              <Button icon={<UploadOutlined />}>Téléverser</Button>
            </Upload>
            <Button
              icon={
                <Badge dot={accessFile?.hasFile}>
                  <DownloadOutlined style={{color: 'white'}} />
                </Badge>
              }
              title={page.pageName}
              type="primary"
              href={accessFile?.getSignedUrl}
              target="_blank"
              disabled={!accessFile?.hasFile}
            ></Button>
          </Button.Group>
        </Col>
      </Row>
    </>
  );
};
