import {Col, Row, Space, Spin, SpinProps, Typography} from 'antd';
import {FC} from 'react';

export const Spinner: FC<SpinProps> = ({tip, ...props}) => (
  <Row
    style={{flex: 1, height: props.size ? undefined : 200}}
    justify="center"
    align="middle"
  >
    <Col>
      <Space direction="vertical" align="center">
        <Spin size="large" {...props} />
        {tip ? (
          <Typography.Text type="secondary" italic>
            {tip}...
          </Typography.Text>
        ) : null}
      </Space>
    </Col>
  </Row>
);
