import {
  AnswerMatrixT,
  isMatrix1D,
  isMatrix2D,
  isMatrixID,
  isMatrixPA,
  Matrix1D,
  Matrix2D,
  MatrixID,
  MatrixPA,
} from 'holo-api';
import {create} from 'zustand';

import {LoadingState} from '../hooks/useLoading';

interface MatrixStoreProps {
  loading: LoadingState;
  matrix1d: Array<Matrix1D & {id: number}>;
  matrix2d: Array<Matrix2D & {id: number}>;
  matrixPA: Array<MatrixPA & {id: number}>;
  matrixID: Array<MatrixID & {id: number}>;
  setMatrices: (matrices: AnswerMatrixT[]) => void;
}

export const useMatrixStore = create<MatrixStoreProps>((set) => ({
  loading: LoadingState.None,
  matrix1d: [],
  matrix2d: [],
  matrixPA: [],
  matrixID: [],
  setMatrices: (matrices) => {
    const matrix1d = matrices.flatMap((m) =>
      isMatrix1D(m.matrixND) ? [{...m.matrixND, id: m.id}] : [],
    );
    const matrix2d = matrices.flatMap((m) =>
      isMatrix2D(m.matrixND) ? [{...m.matrixND, id: m.id}] : [],
    );
    const matrixPA = matrices.flatMap((m) =>
      isMatrixPA(m.matrixND) ? [{...m.matrixND, id: m.id}] : [],
    );
    const matrixID = matrices.flatMap((m) =>
      isMatrixID(m.matrixND) ? [{...m.matrixND, id: m.id}] : [],
    );
    set({matrix1d, matrix2d, matrixPA, matrixID, loading: LoadingState.Loaded});
  },
}));
