import {Popover} from 'antd';
import {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';

import {Spinner} from './Spinner';

export interface TablePopOverProps extends PropsWithChildren {
  title: string;
  onOpen: () => Promise<ReactNode>;
}

export const TablePopOver = ({title, onOpen, children}: TablePopOverProps) => {
  const [content, setContent] = useState<ReactNode>(null);
  const [open, setOpen] = useState(false);

  const load = useCallback(async () => {
    setContent(await onOpen());
  }, [onOpen]);

  useEffect(() => {
    if (open) {
      void load();
    }
  }, [open, load]);

  return (
    <Popover
      title={title}
      trigger="click"
      content={content || <Spinner tip="Chargement des données" />}
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
      }}
    >
      {children}
    </Popover>
  );
};
