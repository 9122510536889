import {
  AimOutlined,
  CommentOutlined,
  FileTextOutlined,
  SettingTwoTone,
  UserOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Divider,
  Row,
  Space,
  Statistic,
  Typography,
} from 'antd';
import {FormContentsType, Role} from 'holo-api';
import React from 'react';

import {useUserRole} from '../../util/auth';
import ProgressBar from './ProgressBar';
import {Spinner} from './Spinner';

interface formCardProps {
  form: FormContentsType;
  loadingFormulas: boolean;
  formFormulaResult: number | null | undefined;
  hideFormFormulaResult?: boolean;
  percent: number;
  isPreliminaryForm: boolean;
  onClick: () => void;
}

export const DashboardFormCard: React.FC<formCardProps> = ({
  form,
  loadingFormulas,
  formFormulaResult,
  percent,
  hideFormFormulaResult,
  isPreliminaryForm,
  onClick,
}) => {
  const userRole = useUserRole();
  const isAdmin = userRole === Role.ADMIN || userRole === Role.CONSULTANT;

  const {title, description, themes, objectives, contributors, color} = form;

  return (
    <Card
      title={
        <Row
          align="middle"
          justify="space-between"
          style={{marginTop: 8}}
          gutter={8}
        >
          <Col>
            <Row align="bottom" gutter={8}>
              <Col>
                <Typography.Title level={3} style={{margin: 0}}>
                  {title}
                </Typography.Title>
              </Col>
              <Col>
                <SettingTwoTone
                  twoToneColor={color ?? '#ffffff'}
                  style={{fontSize: '20px', marginBottom: 5}}
                />
              </Col>
            </Row>
          </Col>
          {isAdmin && !isPreliminaryForm && (
            <Col>
              {loadingFormulas ? (
                <Spinner size="default" tip="Chargement des formules" />
              ) : hideFormFormulaResult ? null : typeof formFormulaResult ===
                'number' ? (
                <Statistic
                  title="Note du formulaire"
                  value={formFormulaResult}
                  style={{textAlign: 'center'}}
                />
              ) : formFormulaResult === null ? (
                <Typography.Text>
                  Ce formulaire a un résultat nul
                </Typography.Text>
              ) : (
                <Typography.Text>
                  Résultat indisponible (manque de réponses)
                </Typography.Text>
              )}
            </Col>
          )}
        </Row>
      }
      style={{
        width: '100%',
        backgroundColor: '#eee',
        boxShadow: `0 0 5px ${color ?? '#eee'}40`,
        border: `2px solid ${color ?? '#eee'}40`,
      }}
    >
      {description && (
        <>
          <Row
            gutter={8}
            align="top"
            wrap={false}
            style={{
              marginBottom: contributors || objectives || themes ? 0 : 16,
            }}
          >
            <Col>
              <FileTextOutlined style={{fontSize: '1.5em'}} />
            </Col>
            <Col>
              <Typography.Text style={{fontWeight: 'bold', margin: 0}}>
                Description
              </Typography.Text>
              <br />
              <Typography.Text
                style={{margin: 0, maxWidth: '50%', whiteSpace: 'pre-wrap'}}
              >
                {description}
              </Typography.Text>
            </Col>
          </Row>
          {(contributors || objectives || themes) && (
            <Divider type="horizontal" />
          )}
        </>
      )}
      {(contributors || objectives) && (
        <>
          <Space
            split={<Divider type="vertical" style={{minHeight: '3em'}} />}
            style={{marginBottom: themes ? 0 : 16}}
            size={16}
          >
            {contributors && (
              <Space>
                <UserOutlined style={{marginRight: '.5em'}} />
                <Typography.Text style={{whiteSpace: 'pre-wrap'}}>
                  {contributors}
                </Typography.Text>
              </Space>
            )}
            {objectives && (
              <Row gutter={8} align="top" wrap={false}>
                <Col>
                  <AimOutlined style={{fontSize: '1.5em'}} />
                </Col>
                <Col>
                  <Typography.Text style={{fontWeight: 'bold', margin: 0}}>
                    Objectifs
                  </Typography.Text>
                  <br />
                  <Typography.Text style={{margin: 0, whiteSpace: 'pre-wrap'}}>
                    {objectives}
                  </Typography.Text>
                </Col>
              </Row>
            )}
          </Space>
          {themes && <Divider type="horizontal" />}
        </>
      )}
      {themes && (
        <Row gutter={8} align="top" wrap={false}>
          <Col>
            <CommentOutlined />
          </Col>
          <Col>
            <Typography.Text style={{fontWeight: 'bold', margin: 0}}>
              Thématiques clefs
            </Typography.Text>
            <br />
            <Typography.Text style={{margin: 0, whiteSpace: 'pre-wrap'}}>
              {themes}
            </Typography.Text>
          </Col>
        </Row>
      )}
      <Row gutter={16}>
        <Col flex={1}>
          <ProgressBar percent={percent} />
        </Col>
        <Col>
          <Button type="primary" onClick={onClick}>
            Aller vers l'actif
          </Button>
        </Col>
      </Row>
    </Card>
  );
};
