import {CopyOutlined, DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {
  Button,
  Col,
  List,
  message,
  Popconfirm,
  Row,
  Space,
  TableProps,
  theme,
  Tooltip,
  Typography,
} from 'antd';
import {FilterDropdownProps, Key, SortOrder} from 'antd/es/table/interface';
import {AllReportsItem, GetAllReportsI} from 'holo-api';
import {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {packUpdateReport} from './client/pack';
import {deleteReport, duplicateReport, getAllReports} from './client/pdf';
import {CreateReport} from './components/report/CreateReport';
import {FilterSeachText} from './components/ui/FilterSearchText';
import {GenericTable} from './components/ui/GenericTable';
import {TablePopOver} from './components/ui/TablePopOver';
import {useApi} from './util/auth';

export const Modeles = () => {
  const api = useApi();
  const {token} = theme.useToken();
  const [loading, setLoading] = useState(true);
  const [createReportOpen, setCreateReportOpen] = useState(false);
  const usePagination = useState<{
    pageNumber: number;
    pageSize: number;
  }>({pageNumber: 1, pageSize: 10});
  const [currentSort, setCurrentSort] = useState<
    | {
        column: Key | readonly Key[] | undefined;
        order: SortOrder | undefined;
      }
    | undefined
  >({column: 'name', order: 'ascend'});
  const [searchText, setSearchText] = useState<
    {[key: string]: string} | undefined
  >(undefined);
  const [totalModeles, setTotalModeles] = useState<number>();
  const [modeles, setModeles] = useState<AllReportsItem[]>([]);
  const navigate = useNavigate();
  const [pagination] = usePagination;

  const loadReportNames = useCallback(async () => {
    if (!api) {
      return;
    }
    setLoading(true);
    try {
      const input: GetAllReportsI = {};
      if (currentSort) {
        switch (currentSort.column) {
          case 'name':
          case 'description':
            input.sort = currentSort.column;
            break;
        }
        input.direction = currentSort.order === 'ascend' ? 'asc' : 'desc';
      }
      if (searchText) {
        input.filters = [];
        for (const column in searchText) {
          switch (column) {
            case 'name':
            case 'description':
              input.filters.push([column, searchText[column]]);
              break;
          }
        }
      }
      input.skip = (pagination.pageNumber - 1) * pagination.pageSize;
      input.take = pagination.pageSize;

      const {entities, total} = await getAllReports(api, input);
      setModeles(entities);
      setTotalModeles(total);
    } catch (err: unknown) {
      console.error('Error loading all Reports', err);
      void message.error("Les modèles n'ont pas pu être récupérés");
    } finally {
      setLoading(false);
    }
  }, [api, currentSort, searchText, pagination]);

  useEffect(() => {
    void loadReportNames();
  }, [loadReportNames]);

  const onNewModele = () => {
    setCreateReportOpen(true);
  };

  const onReportCreated = async (_reportId: number) => {
    await loadReportNames();
  };

  const onEditModele = (record: AllReportsItem) => {
    navigate(`/modele/${record.id}`);
  };

  const onDuplicateModele = async (record: AllReportsItem) => {
    if (api) {
      await duplicateReport(api, {reportId: record.id});
      await loadReportNames();
    }
  };

  const onDeleteModele = async (record: AllReportsItem) => {
    if (api) {
      try {
        await deleteReport(api, {reportId: record.id});
        await loadReportNames();
        void message.success(
          `Le modèle ${record.name} a été supprimé avec succès !`,
        );
      } catch (err: unknown) {
        void message.error(
          `Le modèle ${record.name} n'a pas pu être supprimé, veuillez réessayer.`,
        );
      }
    }
  };

  const columns: TableProps<AllReportsItem>['columns'] = [
    {
      title: 'Nom',
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sorter: true,
      filterDropdown: (props: FilterDropdownProps) => (
        <FilterSeachText
          dataIndex="name"
          setSearchText={setSearchText}
          {...props}
        />
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      sorter: true,
      filterDropdown: (props: FilterDropdownProps) => (
        <FilterSeachText
          dataIndex="description"
          setSearchText={setSearchText}
          {...props}
        />
      ),
    },
    {
      title: 'Packs',
      dataIndex: 'packs',
      render: (value: AllReportsItem['packs'], record) => {
        if (value.length === 0) {
          return (
            <Typography.Text type="secondary">
              Aucun pack associé
            </Typography.Text>
          );
        }

        return (
          <TablePopOver
            title={`Liste des packs associés au modèle "${record.name}"`}
            onOpen={async () => {
              return (
                <List>
                  {value.map((pack) => (
                    <List.Item key={pack.packId}>
                      <Row style={{width: '100%'}}>
                        <Col flex={1}>
                          <Typography>{pack.packName}</Typography>
                        </Col>
                        <Col>
                          <Popconfirm
                            title={
                              <div>
                                Êtes-vous sûr de vouloir désassocier le pack
                                <span style={{color: token.colorErrorText}}>
                                  {pack.packName}
                                </span>
                                ?
                              </div>
                            }
                            onConfirm={async () => {
                              if (api) {
                                await packUpdateReport(api, {
                                  packId: pack.packId,
                                  reportId: null,
                                });
                              }
                              await loadReportNames();
                            }}
                          >
                            <Tooltip title="Désassocier le pack">
                              <Button icon={<DeleteOutlined />} danger />
                            </Tooltip>
                          </Popconfirm>
                        </Col>
                      </Row>
                    </List.Item>
                  ))}
                </List>
              );
            }}
          >
            <Typography.Link>{record.packs.length} packs</Typography.Link>
          </TablePopOver>
        );
      },
    },
    {
      title: 'Actions',
      align: 'center',
      render: (_v, record) => {
        return (
          <Space>
            <Tooltip title="Éditer le modèle">
              <Button
                icon={<EditOutlined />}
                onClick={() => onEditModele(record)}
              />
            </Tooltip>
            <Tooltip title="Dupliquer le modèle">
              <Button
                icon={<CopyOutlined />}
                onClick={() => onDuplicateModele(record)}
              />
            </Tooltip>
            <Tooltip title="Supprimer ce modèle">
              <Popconfirm
                title="Supprimer le modèle"
                description="Êtes-vous sûr de vouloir supprimer ce modèle ?"
                onConfirm={() => onDeleteModele(record)}
              >
                <Button danger icon={<DeleteOutlined />} />
              </Popconfirm>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <CreateReport
        createReportOpen={createReportOpen}
        setCreateReportOpen={setCreateReportOpen}
        reportCreated={onReportCreated}
      />

      <Row style={{marginBottom: 16}} justify="space-between" align="middle">
        <Col>
          <Typography.Title style={{margin: 0}}>
            Gestion des modèles
          </Typography.Title>
        </Col>
        <Col>
          <Button type="primary" onClick={onNewModele}>
            Créer un nouveau Modèle
          </Button>
        </Col>
      </Row>
      <Row>
        <GenericTable
          columns={columns}
          loading={loading}
          total={totalModeles}
          dataSource={modeles}
          setCurrentSort={setCurrentSort}
          usePagination={usePagination}
        />
      </Row>
    </>
  );
};
