import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';
import {useDebounceFn} from 'ahooks';
import {Button, Col, Form, Input, Row, Select} from 'antd';
import {
  ActifRatio,
  EquilibreForceChartReportPage,
  TagRadarChartReportPage,
} from 'holo-api';
import {useEffect} from 'react';

import {ActifSelectorOptions} from './shared/reportInterfaces';
import {RichTextEditor, TemplateTag} from './shared/RichTextEditor';

interface EquilibreForceFormType {
  title: string;
  subTitle?: string;
  chartTitle?: string;
  ratios: Array<ActifRatio>;
  richExplain: string;
}

export const EquilibreForceChart = ({
  index,
  chartPage,
  setChartPage,
  actifSelectorOptions,
  templateTags,
}: {
  index: number;
  chartPage: EquilibreForceChartReportPage;
  setChartPage: (page: EquilibreForceChartReportPage) => void;
  actifSelectorOptions: ActifSelectorOptions;
  templateTags: Array<TemplateTag>;
}) => {
  const [form] = Form.useForm<EquilibreForceFormType>();

  useEffect(() => {
    form.setFieldsValue({
      title: chartPage.title,
      subTitle: chartPage.subTitle,
      chartTitle: chartPage.chartTitle,
      ratios: chartPage.ratios,
    });
  }, [form, chartPage]);

  const {run: onChangedPage} = useDebounceFn(
    async (page: TagRadarChartReportPage) => {
      try {
        const validated = await form.validateFields();
        const newChart = {...chartPage};
        newChart.title = validated.title;
        newChart.subTitle = validated.subTitle;
        newChart.chartTitle = validated.chartTitle;
        newChart.ratios = validated.ratios;
        setChartPage(newChart);
      } catch (e) {
        console.warn('onChangedPage', page);
        console.error('Not a valid form');
      }
    },
    {wait: 500},
  );

  const onRichTextChanged = (richText: string) => {
    const newChart = {...chartPage};
    newChart.richExplain = richText;
    setChartPage(newChart);
  };

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div>
        <Form
          form={form}
          onValuesChange={(_changedValues, values) => onChangedPage(values)}
        >
          <Form.Item label="Titre" name="title">
            <Input />
          </Form.Item>
          <Form.Item label="Sous Titre" name="subTitle">
            <Input />
          </Form.Item>
          <Form.Item label="Titre du radar" name="chartTitle">
            <Input />
          </Form.Item>

          <Form.Item label="Rapports">
            <Form.List name="ratios">
              {(fields, {add, remove}, {errors}) => {
                return (
                  <>
                    {fields.map((field) => (
                      <Row key={field.key}>
                        <Col offset={1} span={8}>
                          <Form.Item
                            label="Numérateur"
                            name={[field.name, 'numeratorFormId']}
                            required
                          >
                            <Select
                              style={{width: '100%'}}
                              options={actifSelectorOptions}
                              placeholder="Recherche de formulaire"
                            />
                          </Form.Item>
                        </Col>
                        <Col offset={1} span={8}>
                          <Form.Item
                            label="Dénominateur"
                            name={[field.name, 'denominatorFormId']}
                            required
                          >
                            <Select
                              style={{width: '100%'}}
                              options={actifSelectorOptions}
                              placeholder="Recherche de formulaire"
                            />
                          </Form.Item>
                        </Col>
                        <Col offset={4} span={2}>
                          <Button.Group>
                            <Button
                              onClick={() => remove(field.name)}
                              danger
                              icon={<DeleteOutlined />}
                            />
                          </Button.Group>
                        </Col>
                      </Row>
                    ))}
                    <Button
                      type="dashed"
                      onClick={() => add({})}
                      style={{width: '60%'}}
                      icon={<PlusOutlined />}
                    >
                      Ajouter un rapport
                    </Button>

                    <Form.ErrorList errors={errors} />
                  </>
                );
              }}
            </Form.List>
          </Form.Item>
        </Form>
      </div>
      <div style={{flex: 1}}>
        <RichTextEditor
          uniqueId={`page_${index}`}
          richText={chartPage.richExplain}
          setRichText={onRichTextChanged}
          templateTags={templateTags}
        ></RichTextEditor>
      </div>
    </div>
  );
};
