import {all, ArrayNode, create} from 'mathjs';

import {
  AnswerMatrixT,
  FormContentsType,
  Formula,
  isDefaultChapter,
  ItemType,
  Matrix1D,
  Matrix2D,
  SelectQuestion,
  SingleUserAnswer,
  UserAnswers,
  UserAnswerSelect,
  UserAnswerText,
} from '../schema/schema';
import {
  findAllFormulas,
  getKeysFromReference,
  isFormulaReference,
  isNumericQuestionReference,
  isSelectQuestionReference,
  isTemplateFormulaReference,
} from './form';

export interface TemplateFormulaResults {
  [key: string]: {
    title: string;
    result: number | undefined;
    totalWeight: number;
  };
}

export interface FormulaValue {
  result: number | null | undefined;
  unansweredQuestions: string[];
  formula: string; // to keep info on how it was calculated (embeds dependencies)
}

export interface FormulaValues {
  [key: string]: FormulaValue;
}

export type MathFunction = {
  rawArgs: boolean;
  (
    args: ArrayNode[],
    math: object,
    scope: Map<string, SingleUserAnswer | AnswerMatrixT[]>,
  ): number;
};

export const customMath = create(all);

const IF: MathFunction = function (args, _math, scope) {
  // @ts-expect-error mathjs type checking is not working for some reason (expecting "ArrayNode")
  if (args[0].type === 'AssignmentNode') {
    throw new Error(
      "Assignation dans un IF non supportée. Veuillez utiliser '==' à la place de '='",
    );
  }
  return args[0].compile().evaluate(scope)
    ? args[1].compile().evaluate(scope)
    : args[2].compile().evaluate(scope);
};
IF.rawArgs = true;

const SOMME: MathFunction = function (args, _math, scope) {
  const values = args.map((arg) => arg.compile().evaluate(scope));
  const validValues = values.filter((value) => value !== null);
  if (validValues.length === 0) {
    return null;
  }
  return customMath.sum(validValues);
};
SOMME.rawArgs = true;

const MOYENNE: MathFunction = function (args, _math, scope) {
  const values = args.map((arg) => arg.compile().evaluate(scope));
  const validValues = values.filter((value) => value !== null);
  if (validValues.length === 0) {
    return null;
  }
  return customMath.mean(validValues);
};
MOYENNE.rawArgs = true;

const MAT1: MathFunction = function (args, _math, scope) {
  const values = args.map((arg) => arg.compile().evaluate(scope));
  if (values.length !== 2) {
    throw new Error('Paramètres de matrice incorrects (nom, colonne)');
  }
  const matrixId = values[0];
  const allMatrices = scope.get('allMatrices') as AnswerMatrixT[];
  const matrixValue = allMatrices.find(
    (matrix) => matrix.id === matrixId || matrix.name === matrixId,
  )?.matrixND; // matching either id or name as viewer uses name, and for legacy compatibility
  if (!matrixValue) {
    throw new Error(`Matrice "${matrixId}" invalide`);
  }
  const colValue = values[1];
  const col = (matrixValue as Matrix1D).cols.find((v) => v.max >= colValue);
  if (!col) {
    throw new Error('Aucune colonne de matrice correspondante');
  }
  return col.value;
};

MAT1.rawArgs = true;

const MAT2: MathFunction = function (args, _math, scope) {
  const values = args.map((arg) => arg.compile().evaluate(scope));
  if (values.length !== 3) {
    throw new Error('Paramètres de matrice incorrects (nom, colonne, ligne)');
  }
  const matrixId = values[0];
  const allMatrices = scope.get('allMatrices') as AnswerMatrixT[];
  const matrixValue = allMatrices.find(
    (matrix) => matrix.id === matrixId || matrix.name === matrixId,
  )?.matrixND; // matching either id or name as viewer uses name, and for legacy compatibility
  if (!matrixValue) {
    throw new Error(`Matrice "${matrixId}" invalide`);
  }
  const colValue = values[1];
  const col = (matrixValue as Matrix2D).cols.find((v) => v.max >= colValue);
  if (!col) {
    throw new Error('Aucune colonne de matrice correspondante');
  }
  const rowValue = values[2];
  const row = col.rows.find((v) => v.max >= rowValue);
  if (!row) {
    throw new Error('Aucune ligne de matrice correspondante');
  }
  return row.value;
};

MAT2.rawArgs = true;

customMath.import({
  IF,
  MOYENNE,
  SOMME,
  MAT1,
  MAT2,
});

const {parse, evaluate} = customMath;

const parseTextContent = ({
  textContent,
  form,
  allFormulaValues,
  unansweredQuestions,
  answerValues,
  currentAnswers,
  templateFormulasResults,
}: {
  textContent: string;
  form: FormContentsType;
  allFormulaValues: FormulaValues;
  unansweredQuestions: string[];
  answerValues: {
    [key: string]: number | null;
  };
  currentAnswers: UserAnswers;
  templateFormulasResults: TemplateFormulaResults;
}) => {
  if (!textContent) {
    return;
  }
  const isReferenceToSelectionQuestion = isSelectQuestionReference(textContent);
  const isReferenceToNumericQuestion = isNumericQuestionReference(textContent);
  const isReferenceToFormula = isFormulaReference(textContent);
  const isReferenceToTemplateFormula = isTemplateFormulaReference(textContent);
  if (
    !isReferenceToFormula &&
    !isReferenceToNumericQuestion &&
    !isReferenceToSelectionQuestion &&
    !isReferenceToTemplateFormula
  ) {
    return;
  }
  const [chapterIndex, itemIndex] = getKeysFromReference(textContent);

  if (chapterIndex === -1) {
    console.error(`The targeted chapter was not found for ${textContent}`);
    return;
  }
  const targetChapter = form.chapters[chapterIndex];
  if (!targetChapter || !isDefaultChapter(targetChapter)) {
    console.error('The targeted chapter does not contain items to evaluate');
    return;
  }
  if (isReferenceToFormula) {
    const item = targetChapter.items[itemIndex];
    if (
      !Object.hasOwn(allFormulaValues, item.uuid) &&
      !unansweredQuestions.includes(textContent)
    ) {
      unansweredQuestions.push(textContent + ' non répondue');
    } else {
      const formulaValue = allFormulaValues[item.uuid].result;
      if (formulaValue === undefined) {
        unansweredQuestions.push(textContent);
      } else {
        answerValues[textContent] = formulaValue;
      }
    }
  }
  if (isReferenceToSelectionQuestion) {
    const item = targetChapter.items[itemIndex];
    const answers = currentAnswers[item.uuid] as UserAnswerSelect;
    if (unansweredQuestions.includes(textContent)) {
      return;
    }
    if (!answers || (!answers.answer && !answers.willNotAnswer)) {
      unansweredQuestions.push(textContent + ' non répondue');
    } else if (!answers.willNotAnswer) {
      const answerUuid = answers.answer;
      const answer = (item as SelectQuestion).answers.find(
        (answer) => answer.uuid === answerUuid,
      );
      if (!answer) {
        throw new Error(`No answer for ${answerUuid}`);
      }
      answerValues[textContent] = answer.value;
    } else {
      answerValues[textContent] = null;
    }
  }
  if (isReferenceToNumericQuestion) {
    const item = targetChapter.items[itemIndex];
    const answer = currentAnswers[item.uuid] as UserAnswerText;
    if (unansweredQuestions.includes(textContent)) {
      return;
    }
    if (!answer || answer.text === '') {
      unansweredQuestions.push(textContent + ' non répondue');
    } else {
      answerValues[textContent] = Number(answer.text);
    }
  }
  if (isReferenceToTemplateFormula) {
    if (!templateFormulasResults[textContent]) {
      throw new Error(`La formule ${textContent} n'a pas été trouvée`);
    }
    const {result, totalWeight} = templateFormulasResults[textContent];
    if (result === undefined) {
      answerValues[textContent] = null;
    } else {
      answerValues[textContent] = result / totalWeight;
    }
  }
};

export const evaluateFormulas = (
  form: FormContentsType,
  currentAnswers: UserAnswers,
  templateFormulasResults: TemplateFormulaResults,
  allMatrices: AnswerMatrixT[],
) => {
  const allFormulas = findAllFormulas(form);
  const allFormulaValues: FormulaValues = {};
  let changed = true;
  let maxRetries = 50;
  while (changed && maxRetries > 0) {
    changed = false;
    maxRetries--;
    // eslint-disable-next-line no-loop-func
    allFormulas.forEach(({formula, uuid}: Formula) => {
      const unansweredQuestions: string[] = [];
      try {
        const parsed = parse(formula);
        const answerValues: {
          [key: string]: number | null;
        } = {};

        parsed.traverse((node) => {
          const textContent = node.toString();
          switch (node.type) {
            case 'SymbolNode':
              parseTextContent({
                allFormulaValues,
                answerValues,
                currentAnswers,
                form,
                templateFormulasResults,
                textContent,
                unansweredQuestions,
              });
              break;
          }
        });
        if (unansweredQuestions.length > 0) {
          allFormulaValues[uuid] = {
            result: undefined,
            unansweredQuestions,
            formula,
          };
          return;
        }
        let evaluated;
        const scope = {...answerValues, allMatrices};
        try {
          evaluated = evaluate(formula, scope);
        } catch (err: unknown) {
          console.info('formula', formula);
          console.info('scope', JSON.stringify(scope, null, 2));
          console.error('error in math.evaluate', err);
          throw err;
        }
        const result =
          evaluated == null ? null : Number(Number(evaluated).toFixed(2));
        if (
          !allFormulaValues[uuid] ||
          allFormulaValues[uuid].result !== result
        ) {
          changed = true;
        }
        allFormulaValues[uuid] = {
          result,
          unansweredQuestions,
          formula,
        };
      } catch (error) {
        allFormulaValues[uuid] = {
          result: undefined,
          unansweredQuestions: [(error as Error).message],
          formula,
        };
        console.info('formula', formula);
        console.error('evaluateFormulas error', error);
      }
    });
  }
  return allFormulaValues;
};

export const buildRevertTagMapping = ({
  allTransferredFormulas,
  templateIndex,
  templateId,
  transferredFormulaResults,
  revertTagMapping,
}: {
  allTransferredFormulas: Formula[];
  templateIndex: number;
  templateId: number;
  transferredFormulaResults: TemplateFormulaResults;
  revertTagMapping: Record<string, string>;
}) => {
  const tagMapping: Record<string, string> = {};

  allTransferredFormulas.forEach((formula, formulaIndex) => {
    const tag = `T${templateIndex}F${formulaIndex + 1}`;
    const targetTag = `T${templateId}F"${formula.uuid}"`;
    tagMapping[tag] = targetTag;
    revertTagMapping[targetTag] = tag;
    if (!transferredFormulaResults[tag]) {
      // found formula but can possibly have no answer
      transferredFormulaResults[tag] = {
        title: formula.title,
        result: undefined,
        totalWeight: 0,
      };
    }
  });
  return revertTagMapping;
};

export const preprocessTemplate = ({
  form,
  revertTagMapping,
}: {
  form: FormContentsType;
  revertTagMapping: Record<string, string>;
}) => {
  form.chapters.forEach((chapter) => {
    if (!isDefaultChapter(chapter)) {
      return;
    }
    chapter.items.forEach((item) => {
      if (item.type === ItemType.FORMULA) {
        const matches = item.formula.matchAll(
          /T(?<templateIndex>\d+)F"(?<formulaUuid>[\w-]+)"/g,
        );
        for (const match of matches) {
          if (!match.groups) {
            continue;
          }
          const {templateIndex, formulaUuid} = match.groups;
          if (!templateIndex || !formulaUuid) {
            continue;
          }
          if (!revertTagMapping[match[0]]) {
            item.formula = item.formula.replaceAll(match[0], 'TXFX');
          }
          item.formula = item.formula.replaceAll(
            match[0],
            revertTagMapping[match[0]],
          );
        }
      }
    });
  });
};
