import type {ColumnType} from 'kysely';
export type Generated<T> =
  T extends ColumnType<infer S, infer I, infer U>
    ? ColumnType<S, I | undefined, U>
    : ColumnType<T, T | undefined, T>;
export type Timestamp = ColumnType<Date, Date | string, Date | string>;

export const Category = {
  HUMAN: 'HUMAN',
  RELATIONAL: 'RELATIONAL',
  STRUCTURAL: 'STRUCTURAL',
} as const;
export type Category = (typeof Category)[keyof typeof Category];
export const Role = {
  ADMIN: 'ADMIN',
  AUDITOR: 'AUDITOR',
  CONSULTANT: 'CONSULTANT',
  COMPANY_ADMIN: 'COMPANY_ADMIN',
  USER: 'USER',
  UNVERIFIED: 'UNVERIFIED',
} as const;
export type Role = (typeof Role)[keyof typeof Role];
export const Right = {
  NONE: 'NONE',
  READ: 'READ',
  READ_WRITE: 'READ_WRITE',
} as const;
export type Right = (typeof Right)[keyof typeof Right];
export type Answer = {
  id: Generated<number>;
  formId: number;
  userId: number;
  companyId: number | null;
  contents: unknown;
  createdAt: Generated<Timestamp>;
  updatedAt: Timestamp;
  deletedAt: Timestamp | null;
};
export type AnswerMatrix = {
  id: Generated<number>;
  name: string;
  description: string;
  mat2d: boolean;
  contents: unknown;
};
export type Company = {
  id: Generated<number>;
  name: string;
  siret: string | null;
  activePackId: number | null;
  createdAt: Generated<Timestamp>;
  updatedAt: Timestamp;
  deletedAt: Timestamp | null;
};
export type CompanyConsultantUsers = {
  companyId: number;
  userId: number;
  createdAt: Generated<Timestamp>;
  updatedAt: Timestamp;
  deletedAt: Timestamp | null;
};
export type CompanyGroup = {
  id: Generated<number>;
  name: string;
  description: string | null;
  companyId: number;
  createdAt: Generated<Timestamp>;
  updatedAt: Timestamp;
  deletedAt: Timestamp | null;
};
export type CompanyGroupUsers = {
  companyGroupId: number;
  userId: number;
  createdAt: Generated<Timestamp>;
  updatedAt: Timestamp;
  deletedAt: Timestamp | null;
};
export type CompanyPacks = {
  companyId: number;
  packId: number;
  isClosed: Generated<boolean>;
  contents: unknown | null;
  createdAt: Generated<Timestamp>;
  updatedAt: Timestamp;
  deletedAt: Timestamp | null;
};
export type Form = {
  id: Generated<number>;
  initialVersionId: number | null;
  mainFormId: number | null;
  minorVersion: number;
  patchVersion: number;
  schemaVersion: number;
  category: Category | null;
  priority: number | null;
  isPreliminary: Generated<boolean>;
  contents: unknown;
  createdAt: Generated<Timestamp>;
  updatedAt: Timestamp;
  deletedAt: Timestamp | null;
};
export type FormPackCompanyGroupRights = {
  companyGroupId: number;
  formId: number;
  right: Right;
  createdAt: Generated<Timestamp>;
  updatedAt: Timestamp;
  deletedAt: Timestamp | null;
};
export type FormPacks = {
  formId: number;
  packId: number;
  createdAt: Generated<Timestamp>;
  updatedAt: Timestamp;
  deletedAt: Timestamp | null;
};
export type Pack = {
  id: Generated<number>;
  initialVersionId: number | null;
  minorVersion: number;
  patchVersion: number;
  name: string;
  description: string | null;
  reportId: number | null;
  createdAt: Generated<Timestamp>;
  updatedAt: Timestamp;
  deletedAt: Timestamp | null;
};
export type Report = {
  id: Generated<number>;
  name: string;
  description: string | null;
  contents: unknown;
};
export type SharedFiles = {
  id: Generated<number>;
  name: string;
  description: string;
  order: number;
};
export type TemplateInstance = {
  id: Generated<number>;
  name: string;
  weight: number;
  companyId: number;
  formId: number;
  createdAt: Generated<Timestamp>;
  updatedAt: Timestamp;
  deletedAt: Timestamp | null;
};
export type TemplateInstanceAnswer = {
  id: Generated<number>;
  formId: number;
  userId: number;
  patentId: number;
  companyId: number | null;
  contents: unknown;
  createdAt: Generated<Timestamp>;
  updatedAt: Timestamp;
  deletedAt: Timestamp | null;
};
export type User = {
  id: Generated<number>;
  email: string;
  firstName: string;
  lastName: string;
  role: Generated<Role>;
  companyId: number | null;
  createdAt: Generated<Timestamp>;
  updatedAt: Timestamp;
  deletedAt: Timestamp | null;
};
export type DB = {
  Answer: Answer;
  AnswerMatrix: AnswerMatrix;
  Company: Company;
  CompanyConsultantUsers: CompanyConsultantUsers;
  CompanyGroup: CompanyGroup;
  CompanyGroupUsers: CompanyGroupUsers;
  CompanyPacks: CompanyPacks;
  Form: Form;
  FormPackCompanyGroupRights: FormPackCompanyGroupRights;
  FormPacks: FormPacks;
  Pack: Pack;
  Patent: TemplateInstance;
  PatentAnswer: TemplateInstanceAnswer;
  Report: Report;
  SharedFiles: SharedFiles;
  User: User;
};
