import {Collapse, Form, Input, theme, Typography} from 'antd';
import {Role, UserAnswers} from 'holo-api';
import {FC, useEffect} from 'react';

import {useViewerStore} from '../../../stores/viewer';
import {useUserRole} from '../../../util/auth';

interface ConsultantCommentProps {
  uuid: string;
  outsideFormContext?: boolean;
  collapseTitle?: string;
}

export const ConsultantComment: FC<ConsultantCommentProps> = ({
  uuid,
  outsideFormContext,
  collapseTitle = 'Commentaires du consultant',
}) => {
  const formInstance = Form.useFormInstance();
  const readOnly = useViewerStore((state) => state.readOnly);

  const userRole = useUserRole();
  const isAdmin = userRole === Role.ADMIN;
  const isConsultant = userRole === Role.CONSULTANT;

  const updatePendingUserAnswers = useViewerStore(
    (state) => state.updatePendingUserAnswers,
  );

  const {token} = theme.useToken();
  const primaryColor = token.colorPrimary;

  const consultantComment =
    Form.useWatch([uuid, 'consultantComment'], {
      form: formInstance,
      preserve: true,
    }) ?? formInstance?.getFieldValue([uuid, 'consultantComment']);

  const [form] = Form.useForm<UserAnswers>(formInstance);
  const initialFormAnswers = useViewerStore(
    (state) => state.initialFormAnswers,
  );

  useEffect(() => {
    if (
      !initialFormAnswers ||
      !outsideFormContext ||
      !initialFormAnswers[uuid]
    ) {
      return;
    }
    form.resetFields();
    form.setFieldValue(
      [uuid, 'consultantComment'],
      // @ts-expect-error - string | undefined - FIXME: ?
      initialFormAnswers[uuid].consultantComment,
    );
  }, [initialFormAnswers, form, outsideFormContext, uuid]);

  return (
    <Collapse
      size="small"
      items={[
        {
          label: (
            <Typography
              style={
                consultantComment && consultantComment !== ''
                  ? {
                      color: primaryColor,
                      textDecoration: 'underline',
                    }
                  : undefined
              }
            >
              {collapseTitle}
            </Typography>
          ),
          key: 1,
          children: (
            <Form.Item
              style={{marginBottom: 0}}
              name={[uuid, 'consultantComment']}
            >
              <Input.TextArea
                readOnly={!isConsultant && !isAdmin}
                disabled={readOnly} // yes confusing sematics of readOnly and disabled
                autoSize={{minRows: 1, maxRows: 5}}
                style={{
                  background: isConsultant || isAdmin ? undefined : '#eee',
                }}
                onBlur={(event) => {
                  if (outsideFormContext) {
                    const comment = {
                      [uuid]: {consultantComment: event.target.value},
                    };
                    updatePendingUserAnswers(comment);
                  }
                }}
              />
            </Form.Item>
          ),
        },
      ]}
    />
  );
};
