import {FormInstance, InputRef} from 'antd';
import {NamePath} from 'antd/es/form/interface';
import {
  Chapter,
  CreatorItem,
  FormContentsType,
  GetSingularForm,
} from 'holo-api';
import {RefObject} from 'react';
import {create} from 'zustand';

import {compareNamePath, FormulasToFix} from '../util/form';

type FormErrors = ReturnType<FormInstance['getFieldsError']>;

export interface FormTemplate {
  id: number;
  title: string;
  formulas: {uuid: string; title: string; tag: string}[];
}

export interface CreatorStoreProps {
  currentFormulaName: NamePath | undefined;
  setCurrentFormulaName: (
    namePath: NamePath | undefined,
    value?: string,
    cursorStart?: number,
    cursorEnd?: number,
  ) => void;
  currentFormulaValue: string;
  currentFormulaCursorStart: number;
  currentFormulaCursorEnd: number;
  currentFormulaRef: RefObject<InputRef> | null;
  setCurrentFormulaRef: (ref: RefObject<InputRef>) => void;
  activeKey: Record<number, boolean>;
  currentActiveKeys: string[];
  addCurrentActiveKey: (key: string) => void;
  removeCurrentActiveKey: (key: string) => void;
  currentFormFormula: string | undefined;
  setCurrentFormFormula: (formula: string | undefined) => void;
  initialForm: FormContentsType | undefined;
  setInitialForm: (form: FormContentsType | undefined) => void;
  chapters: Chapter[];
  setChapters: (chapters: Chapter[]) => void;
  chapterItems: Record<number, CreatorItem[]>;
  setChapterItems: (chapterItems: Record<number, CreatorItem[]>) => void;
  onItemRemove: (reference: string, callback?: () => void) => void;
  insertCurrentFormulaValue: (
    value: string,
    cursortStart: number,
    cursorEnd: number,
  ) => void;
  errors: FormErrors;
  setErrors: (errors: FormErrors) => void;
  clearError: (name: NamePath) => void;
  isTouched: boolean;
  setIsTouched: (touched: boolean) => void;
  formTemplates: FormTemplate[];
  templateFormulasMapping: Record<string, string>;
  setFormTemplates: (
    templates: CreatorStoreProps['formTemplates'],
    mapping: CreatorStoreProps['templateFormulasMapping'],
  ) => void;
  mainForm: GetSingularForm | undefined;
  setMainForm: (mainForm: GetSingularForm | undefined) => void;
  isPreliminaryForm: boolean;
  setIsPreliminaryForm: (value: boolean) => void;
  chapterAdd: Record<number, (item: CreatorItem) => void | undefined>;
  setChapterAdd: (chapterKey: number, add: (item: CreatorItem) => void) => void;
  formulasToFix: FormulasToFix[] | undefined;
  setFormulasToFix: (
    formulasToFix: FormulasToFix[] | undefined,
    checkboxPath?: NamePath | undefined,
  ) => void;
  checkboxPath: NamePath | undefined;
}

export const useCreatorStore = create<CreatorStoreProps>((set) => ({
  currentFormulaName: undefined,
  setCurrentFormulaName: (
    namePath: NamePath | undefined,
    value?: string,
    cursorStart?: number,
    cursorEnd?: number,
  ) =>
    set((state) => ({
      ...state,
      currentFormulaName: namePath,
      currentFormulaValue: value,
      currentFormulaCursorStart: cursorStart,
      currentFormulaCursorEnd: cursorEnd,
    })),
  currentFormulaRef: null,
  setCurrentFormulaRef: (ref) =>
    set((state) => ({...state, currentFormulaRef: ref})),
  currentFormulaValue: '',
  currentFormulaCursorStart: 0,
  currentFormulaCursorEnd: 0,
  activeKey: {},
  currentActiveKeys: [],
  addCurrentActiveKey: (key) =>
    set((state) => ({
      ...state,
      currentActiveKeys: [...state.currentActiveKeys, key],
      activeKey: {...state.activeKey, [key]: true},
    })),
  removeCurrentActiveKey: (key) =>
    set((state) => ({
      ...state,
      currentActiveKeys: state.currentActiveKeys.filter((k) => k !== key),
      activeKey: {...state.activeKey, [key]: false},
    })),
  currentFormFormula: undefined,
  setCurrentFormFormula: (formula) =>
    set((state) => ({...state, currentFormFormula: formula})),
  initialForm: undefined,
  setInitialForm: (form) =>
    set((state) => ({...state, initialForm: form ? {...form} : undefined})),
  chapters: [],
  setChapters: (chapters) =>
    set((state) => ({...state, chapters: [...chapters]})),
  chapterItems: {},
  setChapterItems: (chapterItems) =>
    set((state) => ({...state, chapterItems: {...chapterItems}})),
  onItemRemove: () => {},
  insertCurrentFormulaValue: () => {},
  errors: [],
  setErrors: (errors) =>
    set((state) => ({
      ...state,
      errors:
        errors.length > 0 || state.errors.length > 0
          ? [...errors]
          : state.errors,
    })),
  clearError: (name: NamePath) =>
    set((state) => ({
      ...state,
      errors: state.errors.filter(
        (error) => !compareNamePath(error.name, name),
      ),
    })),
  isTouched: false,
  setIsTouched: (touched) => set((state) => ({...state, isTouched: touched})),
  formTemplates: [],
  templateFormulasMapping: {},
  setFormTemplates: (templates, mapping) =>
    set((state) => ({
      ...state,
      formTemplates: [...templates],
      templateFormulasMapping: {...mapping},
    })),
  mainForm: undefined,
  setMainForm: (mainForm) =>
    set((state) => ({
      ...state,
      mainForm: mainForm ? {...mainForm} : undefined,
    })),
  isPreliminaryForm: false,
  setIsPreliminaryForm: (value) =>
    set((state) => ({...state, isPreliminaryForm: value})),
  chapterAdd: {},
  setChapterAdd: (chapterKey, add) =>
    set((state) => ({
      ...state,
      chapterAdd: {...state.chapterAdd, [chapterKey]: add},
    })),
  formulasToFix: undefined,
  setFormulasToFix: (formulasToFix, checkboxPath?: NamePath | undefined) =>
    set((state) => ({
      ...state,
      formulasToFix: formulasToFix ? [...formulasToFix] : undefined,
      checkboxPath: checkboxPath ?? undefined,
    })),
  checkboxPath: undefined,
}));
