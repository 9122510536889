import {JSONSchemaType} from 'ajv';

import {Role} from '../../database/types';
import {CompanySchemaType} from './company';
import {UploadFileSchema, UploadFileSchemaType} from './util';

export interface UserSchemaType {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  role: Role;
  companyId: number | null;
  Company: Pick<CompanySchemaType, 'id' | 'name'> | null;
  photoSignedUrl: string;
}

export interface CreateUserSchemaType {
  email: string;
  firstName: string;
  lastName: string;
  role?: Role;
  companyGroups?: number[];
}

export interface CreateUserResponse {
  id: number;
}

export const CreateUserSchema: JSONSchemaType<CreateUserSchemaType> = {
  type: 'object',
  additionalProperties: false,
  required: ['email', 'firstName', 'lastName'],
  properties: {
    email: {
      type: 'string',
    },
    firstName: {
      type: 'string',
    },
    lastName: {
      type: 'string',
    },
    role: {
      type: 'string',
      nullable: true,
    },
    companyGroups: {
      type: 'array',
      items: {
        type: 'number',
      },
      nullable: true,
    },
  },
} as const;

export interface UpdateUserRequestSchemaType {
  firstName?: string;
  lastName?: string;
  companyId?: number | null;
  role?: Role;
  companyGroups?: number[];
  photoFileMetadata?: UploadFileSchemaType;
}

export const UpdateUserRequestSchema: JSONSchemaType<UpdateUserRequestSchemaType> =
  {
    type: 'object',
    additionalProperties: false,
    properties: {
      firstName: {
        type: 'string',
        nullable: true,
      },
      lastName: {
        type: 'string',
        nullable: true,
      },
      companyId: {
        type: 'number',
        nullable: true,
      },
      role: {
        type: 'string',
        nullable: true,
      },
      companyGroups: {
        type: 'array',
        items: {
          type: 'number',
        },
        nullable: true,
      },
      photoFileMetadata: {...UploadFileSchema, nullable: true},
    },
  } as const;

export interface UpdateUserResponseSchemaType {
  id: number;
  photoSignedUrl?: string;
}

export const UpdateUserResponseSchema: JSONSchemaType<UpdateUserResponseSchemaType> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['id'],
    properties: {
      id: {
        type: 'number',
      },
      photoSignedUrl: {
        type: 'string',
        nullable: true,
      },
    },
  } as const;

// https://gitlab.com/logicgram/holo-way/holo-diag/-/issues/251
export const fakeUserId = -1;

export interface PostUserCreationByAdminO {
  id: number;
}

export interface PostUserSingularO {
  id: number;
}
