import {Alert, Button, Form, Input, Modal} from 'antd';
import {
  generate as generatePassword,
  GenerateOptions as GeneratePasswordOptions,
} from 'generate-password-browser';
import {CreateUserSchemaType} from 'holo-api';
import {FC, useCallback, useMemo, useState} from 'react';

import {
  deleteUnassignedCognitoUser,
  userCreateRegister,
} from '../../client/user';
import {
  useAnonymousApi,
  useApi,
  useConfirmSignUp,
  useSignIn,
  useSignOutAndDeleteCookies,
  useSignUp,
} from '../../util/auth';
import {CognitoErrorLabel, isCodedError} from '../../util/error';

export const DEFAULT_PASSWORD_OPTIONS: GeneratePasswordOptions = {
  length: 12,
  numbers: true,
  lowercase: true,
  uppercase: true,
  symbols: false,
  strict: true,
};

interface RegisterFormValues {
  email: string;
  firstName: string;
  lastName: string;

  password: string;
}

interface ConfirmFormValues {
  email: string;
  code: string;
}

export const Register: FC = () => {
  const api = useApi();
  const anonymousApi = useAnonymousApi();

  const signUp = useSignUp();
  const confirmSignUp = useConfirmSignUp();
  const signIn = useSignIn();
  const signOutAndDeleteCookies = useSignOutAndDeleteCookies();

  const [signUpForm] = Form.useForm<RegisterFormValues>();
  const [signUpLoading, setSignUpLoading] = useState(false);
  const [signUpError, setSignUpError] = useState('');

  const [confirmForm] = Form.useForm<ConfirmFormValues>();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [confirmError, setConfirmError] = useState('');

  const [sendApiRegister, setSendApiRegister] = useState(false);

  const handleApiRegister = useCallback(async () => {
    if (!api) return;

    await signUpForm.validateFields();
    setSignUpError('');

    const {email, firstName, lastName} = signUpForm.getFieldsValue();
    const body: CreateUserSchemaType = {
      email,
      firstName,
      lastName,
    };

    setSignUpLoading(true);
    try {
      await userCreateRegister({api, input: body});

      await signOutAndDeleteCookies();
    } catch (err: unknown) {
      console.error(err, JSON.stringify(err));
      let message = 'Erreur de confirmation';
      if (isCodedError(err)) {
        message += `:\n${CognitoErrorLabel[err.code] ?? err.code}`;
      }
      setSignUpError(message);

      return;
    } finally {
      setSignUpLoading(false);
    }
  }, [api, signOutAndDeleteCookies, signUpForm]);

  const handleCognitoSignUp = async (): Promise<void> => {
    if (!anonymousApi) return;
    await signUpForm.validateFields();
    setSignUpError('');

    const values = signUpForm.getFieldsValue();

    await signOutAndDeleteCookies();

    setSignUpLoading(true);

    const {email, lastName, password, firstName} = values;
    try {
      await signUp({
        email,
        password,
        name: `${lastName} ${firstName}`,
      });
      confirmForm.setFieldsValue({email});
    } catch (err) {
      if (isCodedError(err) && err.code === 'UsernameExistsException') {
        try {
          await deleteUnassignedCognitoUser({anonymousApi, email});
          return handleCognitoSignUp();
        } catch (err) {
          console.warn('delete failed');
          console.error(err);
        }
      }

      console.error(err, JSON.stringify(err));
      let message = "Erreur d'inscription";
      if (isCodedError(err)) {
        message += `:\n${CognitoErrorLabel[err.code] ?? err.code}`;
      }
      setSignUpError(message);
      return;
    } finally {
      setSignUpLoading(false);
    }

    setShowConfirmModal(true);
  };

  const handleCognitoConfirm = async () => {
    await confirmForm.validateFields();
    setConfirmLoading(true);
    setConfirmError('');

    const {email, code} = confirmForm.getFieldsValue();

    try {
      await confirmSignUp({
        email,
        code,
      });
    } catch (err) {
      console.error(err, JSON.stringify(err));
      let message = 'Erreur de confirmation';
      if (isCodedError(err)) {
        message += `:\n${CognitoErrorLabel[err.code] ?? err.code}`;
      }
      setConfirmError(message);
      return;
    } finally {
      setConfirmLoading(false);
    }

    setShowConfirmModal(false);

    const {password} = signUpForm.getFieldsValue();
    await signIn({username: email, password, signingUp: true});

    setSendApiRegister(true);
  };

  useMemo(() => {
    if (!api || !sendApiRegister) return;
    setSendApiRegister(false);

    void handleApiRegister();
  }, [api, sendApiRegister, handleApiRegister]);

  return (
    <>
      <Form<RegisterFormValues>
        form={signUpForm}
        name="register"
        onFinish={handleCognitoSignUp}
        initialValues={{
          country: 'FR',
          phoneNumber: '+33',
          password: generatePassword(DEFAULT_PASSWORD_OPTIONS),
        }}
      >
        <Form.Item
          name="lastName"
          label="Nom de famille"
          rules={[{required: true}]}
        >
          <Input autoFocus />
        </Form.Item>

        <Form.Item name="firstName" label="Prénom" rules={[{required: true}]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Adresse email"
          rules={[{required: true}]}
        >
          <Input type="email" />
        </Form.Item>

        <Form.Item name="password" hidden>
          <Input />
        </Form.Item>

        {signUpError ? (
          <Alert
            message={signUpError}
            type="error"
            style={{whiteSpace: 'pre-line'}}
          />
        ) : null}

        <Button
          type="primary"
          htmlType="submit"
          className="register-form-button"
          loading={signUpLoading}
        >
          Inscription
        </Button>
      </Form>

      <Modal
        centered
        forceRender
        open={showConfirmModal}
        onOk={handleCognitoConfirm}
        okButtonProps={{loading: confirmLoading}}
        closable={false}
        cancelButtonProps={{style: {display: 'none'}}}
        title="Vérification"
      >
        <p>Veuillez entrer le code de vérification reçu par email</p>
        <Form<ConfirmFormValues>
          form={confirmForm}
          name="confirm"
          className="confirm-form"
          onFinish={handleCognitoConfirm}
          labelCol={{span: 5}}
          wrapperCol={{span: 24 - 5}}
        >
          <Form.Item name="email" label="Email" rules={[{required: true}]}>
            <Input disabled />
          </Form.Item>
          <Form.Item name="code" label="Code" rules={[{required: true}]}>
            <Input minLength={6} maxLength={6} />
          </Form.Item>

          <Form.Item hidden={true}>
            <Button htmlType="submit">Envoyer</Button>
          </Form.Item>

          {confirmError ? (
            <Alert
              message={confirmError}
              type="error"
              style={{whiteSpace: 'pre-line'}}
            />
          ) : null}
        </Form>
      </Modal>
    </>
  );
};
