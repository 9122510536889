import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  List,
  message,
  Popconfirm,
  Row,
  Space,
  TableProps,
  theme,
  Tooltip,
  Typography,
} from 'antd';
import {FilterDropdownProps, SortOrder} from 'antd/es/table/interface';
import {Key, useCallback, useEffect, useState} from 'react';

import {
  createCompanyGroupUser,
  deleteCompanyGroupUser,
  getPluralCompanyGroups,
  getUsersInGroup,
} from './client/company';
import {DynamicSelect} from './components/ui/DynamicSelect';
import {FilterSeachText} from './components/ui/FilterSearchText';
import {GenericTable} from './components/ui/GenericTable';
import {EditGroupRightsModal} from './components/ui/modals/AddFormToGroupModal';
import {AssignModal} from './components/ui/modals/AssignModal';
import {CompanyGroupsModal} from './components/ui/modals/CompanyGroupsModal';
import {TablePopOver} from './components/ui/TablePopOver';
import {usePackStore} from './stores/pack';
import {useUserStore} from './stores/user';
import {apiDel} from './util/api';
import {useApi} from './util/auth';

export interface CompanyGroupsTableDataType {
  key: number;
  name: string;
  description: string;
  id: number;
}

type AddFormToGroupModalData =
  | {
      isOpen: true;
      groupId: number;
      companyId: number;
      groupName: string;
    }
  | {isOpen: false};

export const CompanyGroups = () => {
  const {token} = theme.useToken();
  const [assignForm] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(true);
  const [editGroupRightsModalData, setEditGroupRightsModalData] =
    useState<AddFormToGroupModalData>({isOpen: false});
  const {packId} = usePackStore();

  const api = useApi();
  const [groups, setGroups] = useState<CompanyGroupsTableDataType[]>([]);
  const [currentSort, setCurrentSort] = useState<
    | {
        column: Key | readonly Key[] | undefined;
        order: SortOrder | undefined;
      }
    | undefined
  >({column: 'name', order: 'ascend'});
  const usePagination = useState<{
    pageNumber: number;
    pageSize: number;
  }>({pageNumber: 1, pageSize: 10});
  const [searchText, setSearchText] = useState<
    {[key: string]: string} | undefined
  >(undefined);
  const [totalGroups, setTotalGroups] = useState<number>();
  const [pagination] = usePagination;

  const companyId = useUserStore((state) => state.companyId);
  const isCompanyIdReady = useUserStore((state) => state.isCompanyIdReady);
  const [currentId, setCurrentId] = useState<number | 'new' | undefined>(
    undefined,
  );
  const [editedCompanyGroup, setEditedCompanyGroup] = useState<
    CompanyGroupsTableDataType | undefined
  >(undefined);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);

  const columns: TableProps<CompanyGroupsTableDataType>['columns'] = [
    {
      title: 'Nom',
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sorter: true,
      filterDropdown: (props: FilterDropdownProps) => (
        <FilterSeachText
          dataIndex="name"
          setSearchText={setSearchText}
          {...props}
        />
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      sorter: true,
      filterDropdown: (props: FilterDropdownProps) => (
        <FilterSeachText
          dataIndex="name"
          setSearchText={setSearchText}
          {...props}
        />
      ),
    },
    {
      title: 'Utilisateurs',
      dataIndex: ['_count', 'users'],
      render: (value, record) => {
        if (!api) {
          return;
        }
        if (value === 0) {
          return <Typography>Aucun utilisateur associé</Typography>;
        }
        return (
          <TablePopOver
            title={`Liste des utilisateurs associés au groupe ${record.name}`}
            onOpen={async () => {
              const groupUsers = await getUsersInGroup({
                api,
                companyGroupId: record.id,
              });
              return (
                <List>
                  {groupUsers.entities.map(({user}) => (
                    <List.Item key={user.id}>
                      <Row style={{width: '100%'}} gutter={8} align="middle">
                        <Col flex={1}>
                          <Space>
                            <Typography.Text>
                              {user.lastName + ' ' + user.firstName}
                            </Typography.Text>
                            <Typography.Text type="secondary">
                              ({user.email})
                            </Typography.Text>
                          </Space>
                        </Col>
                        <Col>
                          <Popconfirm
                            title={
                              <div>
                                Êtes-vous sûr de vouloir retirer{' '}
                                <span style={{color: token.colorErrorText}}>
                                  {user.lastName + ' ' + user.firstName}
                                </span>{' '}
                                du groupe ?
                              </div>
                            }
                            onConfirm={async () => {
                              await deleteCompanyGroupUser({
                                api,
                                companyGroupId: record.id,
                                userId: user.id,
                              });
                              await getGroups();
                            }}
                          >
                            <Button icon={<DeleteOutlined />} danger />
                          </Popconfirm>
                        </Col>
                      </Row>
                    </List.Item>
                  ))}
                </List>
              );
            }}
          >
            <Typography.Link>
              {`${value} utilisateur${value === 1 ? '' : 's'}`}
            </Typography.Link>
          </TablePopOver>
        );
      },
    },
    {
      title: 'Actions',
      align: 'center',
      render: (_v, record) => {
        return (
          <Space>
            <Tooltip
              title={
                companyId
                  ? 'Assigner un utilisateur à ce groupe'
                  : "Veuillez vous assigner l'entreprise associée pour accéder à cette action"
              }
            >
              <Button
                disabled={companyId ? false : true}
                icon={<PlusCircleOutlined />}
                onClick={async () => {
                  if (!api) {
                    return;
                  }
                  setEditedCompanyGroup(record);
                  setIsAssignModalOpen(true);
                }}
              />
            </Tooltip>
            <Tooltip
              title={
                packId
                  ? "Gérer les droits d'accès"
                  : 'Aucun pack actif pour cette entreprise'
              }
            >
              <Button
                icon={<UnlockOutlined />}
                disabled={!packId}
                onClick={() => {
                  if (companyId) {
                    setEditGroupRightsModalData({
                      isOpen: true,
                      groupId: record.id,
                      groupName: record.name,
                      companyId,
                    });
                  }
                }}
              />
            </Tooltip>
            <Tooltip title="Éditer le groupe">
              <Button
                icon={<EditOutlined />}
                onClick={async () => {
                  setEditedCompanyGroup(record);
                  setCurrentId(record.id);
                }}
              />
            </Tooltip>
            <Tooltip title="Supprimer ce groupe">
              <Popconfirm
                title={`Supprimer le groupe ${record.name}`}
                description="Êtes-vous sûr de vouloir supprimer ce groupe ?"
                onConfirm={async () => {
                  if (!api) {
                    return;
                  }
                  const res = await apiDel<{count: number}>(
                    api,
                    `/companyGroup/${record.id}`,
                  );
                  if (!res.count || res.count !== 1) {
                    void message.error(
                      `Le groupe ${record.name} n'a pas pu être supprimé, veuillez réessayer.`,
                    );
                  }
                  void message.success(
                    `Le groupe ${record.name} a été supprimé avec succès !`,
                  );
                  void getGroups();
                }}
              >
                <Button danger icon={<DeleteOutlined />} />
              </Popconfirm>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const getGroups = useCallback(async () => {
    if (!api || !isCompanyIdReady) {
      return;
    }

    if (!companyId) {
      setLoading(false);
      return message.error(
        "Cet utilisateur n'est lié à aucune entreprise, veuillez vous assigner une entreprise avant d'accéder à cette page.",
      );
    }
    setLoading(true);
    const data = await getPluralCompanyGroups({
      api,
      pagination,
      searchText,
      sort: currentSort,
    });
    setGroups(data.entities);
    setTotalGroups(data.total);
    setLoading(false);
  }, [api, pagination, searchText, currentSort, companyId, isCompanyIdReady]);

  useEffect(() => {
    void getGroups();
  }, [getGroups]);
  return (
    <>
      <Row style={{marginBottom: 16}} justify="space-between" align="middle">
        <Col>
          <Typography.Title style={{margin: 0}}>
            Gestion des groupes
          </Typography.Title>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              setCurrentId('new');
            }}
          >
            Créer un nouveau Groupe
          </Button>
        </Col>
      </Row>
      <Row>
        <GenericTable
          columns={columns}
          loading={loading}
          total={totalGroups}
          dataSource={groups}
          setCurrentSort={setCurrentSort}
          usePagination={usePagination}
        />
      </Row>
      <CompanyGroupsModal
        id={currentId}
        editedCompanyGroup={editedCompanyGroup}
        onCancel={() => {
          setCurrentId(undefined);
          setEditedCompanyGroup(undefined);
        }}
        onOk={() => {
          setCurrentId(undefined);
          setEditedCompanyGroup(undefined);
          void getGroups();
        }}
      />
      <EditGroupRightsModal
        open={editGroupRightsModalData.isOpen}
        companyGroupId={
          editGroupRightsModalData.isOpen ? editGroupRightsModalData.groupId : 0
        }
        companyId={
          editGroupRightsModalData.isOpen
            ? editGroupRightsModalData.companyId
            : 0
        }
        groupName={
          editGroupRightsModalData.isOpen
            ? editGroupRightsModalData.groupName
            : ''
        }
        onOk={() => {
          setEditGroupRightsModalData({isOpen: false});
        }}
        onCancel={() => {
          setEditGroupRightsModalData({isOpen: false});
        }}
      />
      <AssignModal
        title={`Assignation d'un utilisateur au groupe ${editedCompanyGroup?.name}`}
        open={isAssignModalOpen}
        content={
          <Form
            form={assignForm}
            onFinish={async (value) => {
              if (!api || !editedCompanyGroup) {
                return;
              }
              await createCompanyGroupUser({
                api,
                input: {...value, companyGroupId: editedCompanyGroup.id},
              });
              void getGroups();
              assignForm.resetFields();
            }}
          >
            <Form.Item required label="Utilisateurs" name="userId">
              <DynamicSelect
                api={api}
                url={`/companyGroup/${editedCompanyGroup?.id}/availableUsers`}
                label={['firstName', 'lastName']}
              />
            </Form.Item>
          </Form>
        }
        onOk={async () => {
          await assignForm.validateFields();
          assignForm.submit();
          setEditedCompanyGroup(undefined);
          setIsAssignModalOpen(false);
        }}
        onCancel={() => {
          assignForm.resetFields();
          setEditedCompanyGroup(undefined);
          setIsAssignModalOpen(false);
        }}
      />
    </>
  );
};
