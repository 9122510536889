import {MenuOutlined} from '@ant-design/icons';
import {Dropdown, Form, FormInstance, Modal, Select} from 'antd';
import {CreatorForm, ItemType, Prefill} from 'holo-api';
import {useEffect, useState} from 'react';

import {mergeNamePath} from '../../../util/form';

interface PrefillProps {
  form: FormInstance<CreatorForm>;
  open: boolean;
  onClose: () => void;
  chapterIdx: number;
  itemIdx: number;
}

interface PrefillData {
  prefill: Prefill | false;
}

const PrefillModal = ({
  form,
  open,
  onClose,
  chapterIdx,
  itemIdx,
}: PrefillProps) => {
  const [prefillForm] = Form.useForm<PrefillData>();

  const onModalOK = () => {
    prefillForm.getFieldsValue();
    const prefill = prefillForm.getFieldsValue().prefill;
    const questionPath = mergeNamePath(
      'chapters',
      chapterIdx,
      'items',
      itemIdx,
    );
    form.setFieldValue(
      mergeNamePath(questionPath, 'disabled'),
      prefill ? true : undefined,
    );
    form.setFieldValue(
      mergeNamePath(questionPath, 'prefill'),
      prefill ? prefill : undefined,
    );
  };

  useEffect(() => {
    if (open) {
      const question =
        form.getFieldsValue().chapters[chapterIdx].items[itemIdx];
      if (question.type === ItemType.QUESTION) {
        prefillForm.setFieldValue(
          'prefill',
          question.prefill ? question.prefill : false,
        );
      }
    }
  }, [open, chapterIdx, itemIdx, prefillForm, form]);

  return (
    <Modal
      title="Questions pré-remplise"
      open={open}
      onOk={() => {
        onModalOK();
        onClose();
      }}
      onCancel={() => {
        onClose();
      }}
    >
      <Form form={prefillForm}>
        <Form.Item name="prefill">
          <Select>
            <Select.Option value={Prefill.COMPANY_NAME}>
              Importer le nom de l'entreprise
            </Select.Option>
            <Select.Option value={Prefill.COMPANY_SIRET}>
              Importer le numéro Siret de l'entreprise
            </Select.Option>
            <Select.Option value={false}>Ne rien importer</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

enum BurgerKeys {
  OPEN_MODAL = 'open-modal',
}

export const QuestionBurgerDropDown = ({
  chapterIdx,
  itemIdx,
}: {
  chapterIdx: number;
  itemIdx: number;
}) => {
  const form = Form.useFormInstance<CreatorForm>();
  const [open, setOpen] = useState<boolean>(false);

  const onBurgerMenu = (key: BurgerKeys) => {
    switch (key) {
      case BurgerKeys.OPEN_MODAL:
        setOpen(true);
        break;
    }
  };

  return (
    <>
      <Dropdown
        trigger={['click']}
        menu={{
          onClick: (menuInfo) => {
            onBurgerMenu(menuInfo.key as BurgerKeys);
          },
          items: [
            {
              label: 'Modifier les imports automatiques',
              key: BurgerKeys.OPEN_MODAL,
            },
          ],
        }}
      >
        <MenuOutlined />
      </Dropdown>
      <PrefillModal
        form={form}
        open={open}
        onClose={() => setOpen(false)}
        chapterIdx={chapterIdx}
        itemIdx={itemIdx}
      ></PrefillModal>
    </>
  );
};
