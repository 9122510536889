import {useRequest} from 'ahooks';
import {Alert, Button, Form, Input, message, Upload, UploadFile} from 'antd';
import {
  UpdateUserRequestSchemaType,
  UploadFileSchemaType,
  UserSchemaType,
} from 'holo-api';
import {FC, useMemo, useState} from 'react';

import {userGetSelf, userUpdateSelf} from '../../client/user';
import {useApi} from '../../util/auth';
import {Spinner} from './Spinner';

interface SettingsFormValues {
  firstName: string;
  lastName: string;
  photoFiles?: UploadFile[];
}

const photoMimeTypes = ['image/jpeg', 'image/jpg', 'image/png'];

export const Settings: FC = () => {
  const api = useApi();
  const [settingsForm] = Form.useForm<SettingsFormValues>();
  const [updateError, setUpdateError] = useState('');

  const fetchUser = async (): Promise<UserSchemaType | undefined> => {
    if (!api) return;
    return userGetSelf(api);
  };
  const {
    data: userData,
    refresh: userRefresh,
    loading: userLoading,
  } = useRequest(fetchUser, {
    refreshDeps: [api],
  });

  useMemo(() => {
    settingsForm.setFieldsValue({...userData});
  }, [settingsForm, userData]);

  if (userLoading) {
    return <Spinner tip="Chargement des données utilisateur" />;
  }

  const handleUpdate = async () => {
    if (!api) return;

    const {photoFiles, ...data} = settingsForm.getFieldsValue();
    const [photoFile] = photoFiles ?? [];

    let photoFileMetadata: UploadFileSchemaType | undefined;
    if (photoFile) {
      const {name, type} = photoFile;
      photoFileMetadata = {name, type};
    }

    const body: UpdateUserRequestSchemaType = {
      ...data,
      photoFileMetadata,
    };

    try {
      const {photoSignedUrl} = await userUpdateSelf(api, body);

      if (!photoSignedUrl) {
        userRefresh();
        return;
      }

      if (photoFile && photoSignedUrl) {
        const headers: HeadersInit = {
          'Content-Disposition': `attachment; filename=${encodeURIComponent(photoFile.name)}`,
        };
        if (photoFile.type) {
          headers['Content-Type'] = photoFile.type;
        }

        await fetch(photoSignedUrl, {
          method: 'PUT',
          body: photoFile.originFileObj,
          headers,
        });
      }

      userRefresh();
    } catch (err) {
      console.error(err);
      setUpdateError(err instanceof Error ? err.message : String(err));
    }
  };

  return (
    <>
      <p>
        Photo existante :
        <img
          src={userData?.photoSignedUrl}
          alt="Utilisateur"
          style={{maxHeight: 100, maxWidth: 100}}
        />
      </p>

      <Form<SettingsFormValues>
        form={settingsForm}
        name="settings"
        onSubmitCapture={handleUpdate}
        onFinish={handleUpdate}
      >
        <Form.Item name="firstName" label="Prénom" rules={[{required: true}]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="lastName"
          label="Nom de famille"
          rules={[{required: true}]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="photoFiles"
          label="Photo"
          valuePropName="fileList"
          getValueFromEvent={(event) => event?.fileList}
        >
          <Upload
            listType="picture-card"
            accept={photoMimeTypes.join(',')}
            maxCount={1}
            showUploadList={{
              showRemoveIcon: true,
              showPreviewIcon: false,
              showDownloadIcon: false,
            }}
            customRequest={({onSuccess}) => onSuccess?.(true)}
            beforeUpload={(file) => {
              if (!photoMimeTypes.includes(file.type)) {
                void message.error('Type de fichier invalide');
                return false;
              }
              return true;
            }}
          >
            Mettre en ligne
          </Upload>
        </Form.Item>

        {updateError ? (
          <Form.Item>
            <Alert message={updateError} type="error" />
          </Form.Item>
        ) : null}

        <Form.Item>
          <Button htmlType="submit">Envoyer</Button>
        </Form.Item>
      </Form>
    </>
  );
};
