import {MinusOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, Flex, Form, FormInstance, InputNumber, Popconfirm} from 'antd';
import {Matrix2D, MatrixValue} from 'holo-api';

import {defaultMatrixTableCellStyle, MatrixShared} from './MatrixShared';

export const FormMat2D = ({
  form2D,
  cols2D,
  setDirty,
}: {
  form2D: FormInstance<Matrix2D>;
  cols2D: Array<{max: number; rows: Array<MatrixValue>}>;
  setDirty: (dirty: boolean) => void;
}) => {
  return (
    <Form
      layout="vertical"
      form={form2D}
      initialValues={{
        mat2d: true,
        name: '',
        description: '',
        cols: [{max: 0, rows: [{max: 0, value: 0}]}],
      }}
      onValuesChange={() => setDirty(true)}
    >
      <MatrixShared></MatrixShared>
      <Flex vertical>
        <Flex>
          <table style={{borderCollapse: 'collapse'}}>
            <thead>
              <tr>
                <th style={defaultMatrixTableCellStyle}>Maximums</th>
                {cols2D?.map((_col, index) => {
                  return (
                    <th key={index} style={defaultMatrixTableCellStyle}>
                      <Form.Item
                        name={['cols', index, 'max']}
                        noStyle
                        rules={[
                          {
                            validator: (_r, value) => {
                              if (typeof value !== 'number') {
                                return Promise.reject();
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <InputNumber
                          variant="borderless"
                          controls={false}
                          wheel={false}
                        />
                      </Form.Item>
                      <Popconfirm
                        title={`Suppression de la colonne`}
                        description="Êtes-vous sûr de vouloir supprimer cette colonne ?"
                        onConfirm={() => {
                          const formData = form2D.getFieldsValue();
                          formData.cols.splice(index, 1);
                          form2D.setFieldsValue(formData);
                        }}
                      >
                        <Button
                          type="default"
                          size="small"
                          icon={<MinusOutlined />}
                        />
                      </Popconfirm>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {cols2D?.length > 0 &&
                cols2D[0].rows?.map((_row, rowIndex) => (
                  <tr key={rowIndex}>
                    <td style={defaultMatrixTableCellStyle}>
                      <Form.Item
                        name={['cols', 0, 'rows', rowIndex, 'max']}
                        noStyle
                        rules={[
                          {
                            validator: (_r, value) => {
                              if (typeof value !== 'number') {
                                return Promise.reject();
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <InputNumber
                          variant="borderless"
                          controls={false}
                          wheel={false}
                        />
                      </Form.Item>
                      <Popconfirm
                        title={`Suppression de la ligne`}
                        description="Êtes-vous sûr de vouloir supprimer cette ligne ?"
                        onConfirm={() => {
                          const formData = form2D.getFieldsValue();
                          formData.cols.forEach((col) =>
                            col.rows.splice(rowIndex, 1),
                          );
                          form2D.setFieldsValue(formData);
                        }}
                      >
                        <Button
                          type="default"
                          size="small"
                          icon={<MinusOutlined />}
                        />
                      </Popconfirm>
                    </td>
                    {cols2D.map((_col, colIndex) => (
                      <td key={colIndex} style={defaultMatrixTableCellStyle}>
                        <Form.Item
                          name={['cols', colIndex, 'rows', rowIndex, 'value']}
                          noStyle
                          rules={[
                            {
                              validator: (_r, value) => {
                                if (typeof value !== 'number') {
                                  return Promise.reject();
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <InputNumber
                            variant="borderless"
                            controls={false}
                            wheel={false}
                          />
                        </Form.Item>
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
          <Button
            type="dashed"
            icon={<PlusOutlined />}
            style={{marginLeft: 8}}
            onClick={() => {
              const formData = form2D.getFieldsValue();
              if (!formData.cols) {
                formData.cols = [];
              }
              const length = formData.cols[0]?.rows.length ?? 0;
              formData.cols.push({
                max: 0,
                rows: Array(length).fill({max: 0, value: 0}),
              });
              form2D.setFieldsValue(formData);
            }}
          />
        </Flex>
        <Button
          type="dashed"
          style={{marginTop: 8}}
          icon={<PlusOutlined />}
          onClick={() => {
            const formData = form2D.getFieldsValue();
            formData.cols.forEach((col) => col.rows.push({max: 0, value: 0}));
            form2D.setFieldsValue(formData);
          }}
        />
      </Flex>
    </Form>
  );
};
