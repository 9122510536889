import {Form, Input} from 'antd';
import {FC} from 'react';

export const defaultMatrixTableCellStyle = {
  border: '1px solid #d9d9d9',
  padding: '4px 8px',
};

interface Props {
  disabled?: boolean;
}

export const MatrixShared: FC<Props> = ({disabled}) => {
  return (
    <>
      <Form.Item
        name="name"
        label="Nom de la matrice"
        required
        rules={[
          {
            validator(_r, value: string) {
              if (value.trim() === '') {
                return Promise.reject('Veuillez donner un nom à cette matrice');
              }
              if (value.includes('"')) {
                return Promise.reject(
                  `Il n'est pas possible d'utiliser le symbole " dans le nom d'une matrice, veuillez le remplacer ou le supprimer.`,
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Input disabled={disabled} />
      </Form.Item>
      <Form.Item
        name="description"
        label="Description"
        required
        rules={[
          {
            validator(_r, value) {
              if (value.trim() === '') {
                return Promise.reject(
                  'Veuillez donner une description à cette matrice',
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Input.TextArea autoSize disabled={disabled} />
      </Form.Item>
      <Form.Item hidden name="mat2d">
        <Input />
      </Form.Item>
      <Form.Item hidden name="cols">
        <Input />
      </Form.Item>

      <Form.Item hidden name="matType">
        <Input />
      </Form.Item>
    </>
  );
};
