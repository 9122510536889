import {DeleteOutlined} from '@ant-design/icons';
import {Button, ButtonProps, Popconfirm, Tooltip} from 'antd';
import {forwardRef} from 'react';

interface DeleteButtonProps {
  onConfirm: () => void;
}

const DeleteButton = forwardRef<
  HTMLButtonElement,
  ButtonProps & DeleteButtonProps
>(({onConfirm, ...props}, ref) => {
  return (
    <Tooltip placement="top" title="Supprimer">
      <Popconfirm
        placement="left"
        title="Êtes-vous sûr ?"
        onConfirm={onConfirm}
      >
        <Button
          ref={ref}
          danger
          type="link"
          icon={<DeleteOutlined />}
          {...props}
        />
      </Popconfirm>
    </Tooltip>
  );
});
// https://stackoverflow.com/questions/67992894/component-definition-is-missing-display-name-for-forwardref
DeleteButton.displayName = 'DeleteButton';
export {DeleteButton};
