import {JSONPath} from 'jsonpath-plus';

import {Right} from '../database/types';
import {
  AnswerMatrixT,
  CreatorDefaultChapter,
  DefaultChapter,
  FormContentsType,
  Formula,
  Question,
} from '../schema/schema';

export const selectQuestionRegex = /^C\d+S\d+$/;
export const numericQuestionRegex = /^C\d+Q\d+$/;
export const formulaRegex = /^C\d+F\d+$/;
export const templateFormulaRegex = /^T\d+F\d+$/;
export const chapterRegex = /^C\d+$/;
export const matrixIdRegex = /MAT[12]\((?<matrixId>\d+),/g;
export const matrixReferenceRegex = /MAT[12]\("(?<name>[\wéèêëàâîôòûù ]+)",/g;

// Needs to match Prisma 'Category' enum
export enum FormCategory {
  HUMAN = 'HUMAN',
  RELATIONAL = 'RELATIONAL',
  STRUCTURAL = 'STRUCTURAL',
}

export const formRightsPrecedence = {
  [Right.READ_WRITE]: 1,
  [Right.READ]: 5,
  [Right.NONE]: 10,
};

export const formCategoryLabel = (formCategory: FormCategory) => {
  switch (formCategory) {
    case FormCategory.HUMAN:
      return 'Capital humain';
    case FormCategory.RELATIONAL:
      return 'Capital relationnel';
    case FormCategory.STRUCTURAL:
      return 'Capital structurel';
  }
};

export const orderedFormCategories: Array<FormCategory> = [
  FormCategory.HUMAN,
  FormCategory.RELATIONAL,
  FormCategory.STRUCTURAL,
];

export const isSelectQuestionReference = (reference: string) => {
  return selectQuestionRegex.test(reference);
};

export const isNumericQuestionReference = (reference: string) => {
  return numericQuestionRegex.test(reference);
};

export const isFormulaReference = (reference: string) => {
  return formulaRegex.test(reference);
};

export const isTemplateFormulaReference = (reference: string) => {
  return templateFormulaRegex.test(reference);
};

export const isChapterReference = (reference: string) => {
  return chapterRegex.test(reference);
};

export const isMatrixIdReference = (reference: string) => {
  return matrixIdRegex.test(reference);
};

export const isMatrixNameReference = (reference: string) => {
  return matrixReferenceRegex.test(reference);
};

export const findFormFormula = (form: FormContentsType) => {
  const foundFormFormula: Formula | undefined = JSONPath({
    path: '$.chapters[*].items[?(@.formulaType==="form")]',
    json: form,
  })[0];
  return foundFormFormula;
};

export const findChapterFormulaAtIndex = (
  form: FormContentsType,
  index: number,
): Formula | undefined => {
  return JSONPath({
    path: `$.chapters[${index}].items[?(@.formulaType === "chapter")]`,
    json: form,
  })[0];
};

export const findChapterFomulas = (form: FormContentsType) => {
  const foundChapterFormulas: Formula[] = JSONPath({
    path: '$.chapters[*].items[?(@.formulaType==="chapter")]',
    json: form,
  });
  return foundChapterFormulas;
};

export const findAllTransferredFormulas = (
  form: FormContentsType,
  opts?: {ignoreFormFormula?: boolean; ignoreChapterFormulas?: boolean},
) => {
  let checkingExpression = '@.usedInMainForm===true';
  if (opts && opts.ignoreFormFormula) {
    checkingExpression += '&&@.formulaType!=="form"';
  }
  if (opts && opts.ignoreChapterFormulas) {
    checkingExpression += '&&@.formulaType!=="chapter"';
  }
  const foundTransferredFormulas: Formula[] = JSONPath({
    path: `$.chapters[*].items[?(${checkingExpression})]`,
    json: form,
  });
  return foundTransferredFormulas;
};

export const findAllFormulas = (form: FormContentsType) => {
  const allFormulas: Formula[] = JSONPath({
    path: '$.chapters[*].items[?(@.type==="formula")]',
    json: form,
  });
  return allFormulas;
};

export const findAllChapterFormulasUsedInMainForm = (
  chapter: DefaultChapter | CreatorDefaultChapter,
) => {
  const allFormulas: Formula[] = JSONPath({
    path: '$.items[?(@.type==="formula"&&@.usedInMainForm===true)]',
    json: chapter,
  });
  return allFormulas;
};

export const findAllQuestions = (form: FormContentsType) => {
  const allFormulas: Question[] = JSONPath({
    path: '$.chapters[*].items[?(@.type==="question")]',
    json: form,
  });
  return allFormulas;
};

export const getKeysFromReference = (reference: string): number[] => {
  if (isSelectQuestionReference(reference)) {
    const indexes = reference.split('C').join('').split('S');
    return indexes.map((index) => Number(index) - 1);
  }
  if (isNumericQuestionReference(reference)) {
    const indexes = reference.split('C').join('').split('Q');
    return indexes.map((index) => Number(index) - 1);
  }
  if (isFormulaReference(reference)) {
    const indexes = reference.split('C').join('').split('F');
    return indexes.map((index) => Number(index) - 1);
  }
  if (isTemplateFormulaReference(reference)) {
    const indexes = reference.split('T').join('').split('F');
    return indexes.map((index) => Number(index) - 1);
  }
  if (isChapterReference(reference)) {
    const chapterIndex = Number(reference.slice(1)) - 1;
    return [chapterIndex, -1];
  }
  return [-1, -1];
};

export const replaceMatrixIdByName = (
  {formula, title}: Formula,
  allMatrices: AnswerMatrixT[],
): string => {
  const matrixIdMatches = [...formula.matchAll(matrixIdRegex)];
  let updatedFormula = formula;

  for (let i = matrixIdMatches.length - 1; i >= 0; i--) {
    const match = matrixIdMatches[i];
    if (!match.groups || match.index === undefined) {
      continue;
    }
    const {matrixId} = match.groups;
    if (!matrixId) {
      continue;
    }
    const matrix = allMatrices.find((matrix) => matrix.id === Number(matrixId));
    if (!matrix) {
      throw new Error(
        `Matrice avec id ${matrixId}: cette matrice n'existe pas, veuillez corriger la formule concernée: ${title}`,
      );
    }
    updatedFormula =
      updatedFormula.slice(0, match.index + 5) +
      `"${matrix.name}"` +
      updatedFormula.slice(match.index + 4 + matrixId.length + 1);
  }
  return updatedFormula;
};

export const replaceMatrixNameById = (
  {formula}: Formula,
  allMatrices: AnswerMatrixT[],
): string => {
  const matrixNameMatches = [...formula.matchAll(matrixReferenceRegex)];
  let updatedFormula = formula;

  for (let i = matrixNameMatches.length - 1; i >= 0; i--) {
    const match = matrixNameMatches[i];
    if (!match.groups || match.index === undefined) {
      continue;
    }

    const {name} = match.groups;
    if (!name) {
      continue;
    }
    const matrix = allMatrices.find((matrix) => matrix.name === name);
    if (!matrix) {
      throw new Error(
        `Matrice "${name}" : cette matrice n'existe pas, veuillez corriger les formules concernées.`,
      );
    }
    updatedFormula =
      updatedFormula.slice(0, match.index + 5) +
      matrix.id.toString() +
      updatedFormula.slice(match.index + 5 + name.length + 2);
  }
  return updatedFormula;
};
