import {useDebounceFn} from 'ahooks';
import {Form, Input, Select} from 'antd';
import {
  ConseilConsultant,
  ConseilConsultantChartReportPage,
  conseilConsultantLabel,
  FormCategory,
  formCategoryLabel,
  orderedConseilConsultant,
  orderedFormCategories,
  TagRadarChartReportPage,
} from 'holo-api';
import {useEffect} from 'react';

import {RichTextEditor, TemplateTag} from './shared/RichTextEditor';

//const percentFormatter = (value: number | undefined) => `${value}%`;

interface ConseilConsultantFormType {
  title: string;
  subTitle?: string;
  conseilConsultant?: ConseilConsultant;
  category?: FormCategory;
}

export const ConseilConsultantChart = ({
  index,
  chartPage,
  setChartPage,
  templateTags,
}: {
  index: number;
  chartPage: ConseilConsultantChartReportPage;
  setChartPage: (page: ConseilConsultantChartReportPage) => void;
  templateTags: Array<TemplateTag>;
}) => {
  const [form] = Form.useForm<ConseilConsultantFormType>();

  useEffect(() => {
    form.setFieldsValue({
      title: chartPage.title,
      subTitle: chartPage.subTitle,
      category: chartPage.category,
      conseilConsultant: chartPage.conseilConsultant,
    });
  }, [form, chartPage]);

  const {run: onChangedPage} = useDebounceFn(
    async (page: TagRadarChartReportPage) => {
      try {
        const validated = await form.validateFields();
        const newChart = {...chartPage};
        newChart.title = validated.title;
        newChart.subTitle = validated.subTitle;
        newChart.conseilConsultant = validated.conseilConsultant;
        newChart.category = validated.category;
        setChartPage(newChart);
      } catch (e) {
        console.warn('onChangedPage', page);
        console.error('Not a valid form');
      }
    },
    {wait: 500},
  );

  const onRichTextChanged = (richText: string) => {
    const newChart = {...chartPage};
    newChart.richExplain = richText || undefined;
    setChartPage(newChart);
  };

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div>
        <Form
          form={form}
          onValuesChange={(_changedValues, values) => onChangedPage(values)}
        >
          <Form.Item label="Titre" name="title">
            <Input />
          </Form.Item>
          <Form.Item label="Sous Titre" name="subTitle">
            <Input />
          </Form.Item>
          <Form.Item label="Type de stratégie" name="conseilConsultant">
            <Select
              style={{width: '100%'}}
              options={orderedConseilConsultant.map((conseilConsultant) => ({
                label: conseilConsultantLabel(conseilConsultant),
                value: conseilConsultant,
              }))}
            />
          </Form.Item>
          <Form.Item
            label="Capital"
            name="category"
            hidden={chartPage.conseilConsultant !== ConseilConsultant.strategy}
            labelCol={{offset: 1}}
          >
            <Select
              style={{width: '100%'}}
              options={orderedFormCategories.map((category) => ({
                label: formCategoryLabel(category),
                value: category,
              }))}
            />
          </Form.Item>
        </Form>
      </div>
      <div style={{flex: 1}}>
        <RichTextEditor
          uniqueId={`page_${index}`}
          richText={chartPage.richExplain ?? ''}
          setRichText={onRichTextChanged}
          templateTags={templateTags}
        ></RichTextEditor>
      </div>
    </div>
  );
};
