import {useDebounceFn} from 'ahooks';
import {Form, Input} from 'antd';
import {RichTextReportPage} from 'holo-api';
import {useEffect} from 'react';

import {RichTextEditor, TemplateTag} from './shared/RichTextEditor';

interface RichTextFormType {
  title: string;
  subTitle?: string;
}

export const RichTextPage = ({
  index,
  richTextPage,
  setRichTextPage,
  templateTags,
}: {
  index: number;
  richTextPage: RichTextReportPage;
  setRichTextPage: (page: RichTextReportPage) => void;
  templateTags: Array<TemplateTag>;
}) => {
  const [form] = Form.useForm<RichTextFormType>();

  useEffect(() => {
    form.setFieldsValue({
      title: richTextPage.title,
      subTitle: richTextPage.subTitle,
    });
  }, [form, richTextPage]);

  const {run: onChangedPage} = useDebounceFn(
    async (page: RichTextReportPage) => {
      try {
        const validated = await form.validateFields();
        const newChart = {...richTextPage};
        newChart.title = validated.title;
        newChart.subTitle = validated.subTitle;
        setRichTextPage(newChart);
      } catch (e) {
        console.warn('onChangedPage', page);
        console.error('Not a valid form');
      }
    },
    {wait: 500},
  );

  const onRichTextChanged = (text: string) => {
    const newPage = {...richTextPage};
    newPage.richExplain = text;
    setRichTextPage(newPage);
  };

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div>
        <Form
          form={form}
          onValuesChange={(_changedValues, values) => onChangedPage(values)}
        >
          <Form.Item label="Titre" name="title">
            <Input />
          </Form.Item>
          <Form.Item label="Sous Titre" name="subTitle">
            <Input />
          </Form.Item>
        </Form>
      </div>
      <div style={{flex: 1}}>
        <RichTextEditor
          uniqueId={`page_${index}`}
          richText={richTextPage.richExplain}
          setRichText={onRichTextChanged}
          templateTags={templateTags}
        ></RichTextEditor>
      </div>
    </div>
  );
};
