import {useState} from 'react';

export enum LoadingState {
  None,
  Loading,
  Loaded,
  Error,
}

export const useLoading = () => {
  const [loading, setLoading] = useState(LoadingState.None);

  return [loading, setLoading] as const;
};
