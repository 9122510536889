import {JSONSchemaType} from 'ajv';

import {UserAnswers, UserAnswersSchema} from './answer';

export interface CreateTemplateInstanceSchemaType {
  name: string;
  weight: number;
  companyId: number;
  formId: number;
}

export const CreateTemplateInstanceSchema: JSONSchemaType<CreateTemplateInstanceSchemaType> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['name', 'weight', 'companyId', 'formId'],
    properties: {
      name: {type: 'string'},
      weight: {type: 'number'},
      companyId: {
        type: 'number',
      },
      formId: {
        type: 'number',
      },
    },
  };

export interface UpdateTemplateInstanceSchemaType {
  name?: string;
  weight?: number;
}

export const UpdateTemplateInstanceSchema: JSONSchemaType<UpdateTemplateInstanceSchemaType> =
  {
    type: 'object',
    additionalProperties: false,
    properties: {
      name: {type: 'string', nullable: true},
      weight: {type: 'number', nullable: true},
    },
  };

export interface GetSingularPatentO {
  id: number;
  name: string;
  weight: number;
  companyId: number;
  formId: number;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string | null;
}

export const GetSingularPatentOSchema: JSONSchemaType<GetSingularPatentO> = {
  type: 'object',
  additionalProperties: false,
  required: [
    'id',
    'name',
    'weight',
    'companyId',
    'formId',
    'createdAt',
    'updatedAt',
  ],
  properties: {
    id: {type: 'integer'},
    name: {type: 'string'},
    weight: {type: 'number'},
    companyId: {type: 'integer'},
    formId: {type: 'integer'},
    createdAt: {type: 'string'},
    updatedAt: {type: 'string'},
    deletedAt: {type: 'string', nullable: true},
  },
};

export interface GetPluralPatentO {
  entities: Array<GetSingularPatentO>;
  total: number;
}

export const GetPluralPatentOSchema: JSONSchemaType<GetPluralPatentO> = {
  type: 'object',
  additionalProperties: false,
  required: ['entities', 'total'],
  properties: {
    entities: {type: 'array', items: GetSingularPatentOSchema},
    total: {type: 'integer'},
  },
};

export interface UpdateSingularPatentO {
  name: string;
  id: number;
  weight: number;
}

export const UpdateSingularPatentOSchema: JSONSchemaType<UpdateSingularPatentO> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['name', 'id', 'weight'],
    properties: {
      name: {type: 'string'},
      id: {type: 'integer'},
      weight: {type: 'number'},
    },
  };

export type GetPatentAnswerHandlerO = Array<{
  id: number;
  contents: UserAnswers;
  userId: number;
}>;

export const GetPatentAnswerHandlerOSchema: JSONSchemaType<GetPatentAnswerHandlerO> =
  {
    type: 'array',
    items: {
      type: 'object',
      additionalProperties: false,
      required: ['id', 'contents', 'userId'],
      properties: {
        id: {type: 'integer'},
        contents: UserAnswersSchema,
        userId: {type: 'integer'},
      },
    },
  };

export interface GetTemplateInstanceCompanyO {
  entities: Array<UpdateSingularPatentO>;
  total: number;
}

export const GetTemplateInstanceCompanyOSchema: JSONSchemaType<GetTemplateInstanceCompanyO> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['entities', 'total'],
    properties: {
      entities: {type: 'array', items: UpdateSingularPatentOSchema},
      total: {type: 'integer'},
    },
  };
