import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {Alert, Button, Form, FormProps, Input, Modal} from 'antd';
import {FC, useCallback, useEffect, useRef, useState} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';

import {
  isAmplifyError,
  useCompleteNewPassword,
  useForgotPassword,
  useResetPassword,
  useSignIn,
  useSignOutAndDeleteCookies,
  useUser,
} from '../../util/auth';
import {CognitoErrorLabel, isCodedError} from '../../util/error';
import {getConfirmPasswordRule, getPasswordRules} from './PasswordInput';

interface LoginValues {
  username: string;
  password: string;
}

interface ResetPasswordValues {
  email: string;
  code: string;
  password: string;
  passwordValidation: string;
}

interface NewPasswordValues {
  email: string;
  password: string;
  passwordValidation: string;
}

const ENCODED_CREDENTIALS_SEPARATOR = '_#_SEP_#_';
const parseEncodedCredentials = (credentials: string): LoginValues | null => {
  let decoded: string = '';
  try {
    decoded = decodeURIComponent(escape(window.atob(credentials)));
  } catch (err) {
    console.warn('Encoded credentials parse error', err);
    return null;
  }

  const [username, password] = decoded.split(ENCODED_CREDENTIALS_SEPARATOR);

  if (!username || !password) return null;

  return {username, password};
};

export const Login: FC = () => {
  const user = useUser();
  const signIn = useSignIn();
  const signOutAndDeleteCookies = useSignOutAndDeleteCookies();
  const forgotPassword = useForgotPassword();
  const resetPassword = useResetPassword();
  const completeNewPassword = useCompleteNewPassword();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {encodedCredentials, resetPasswordEmail} = useParams();
  const attemptedToUseParamCredentials = useRef(false);
  const attemptedToResetPassword = useRef(false);
  const [processRedirect, setProcessRedirect] = useState(false);

  const [loginForm] = Form.useForm<LoginValues>();
  const [loginLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [loginSuccess, setLoginSuccess] = useState('');

  const [resetPasswordRequestLoading, setResetPasswordRequestLoading] =
    useState(false);

  const [resetPasswordForm] = Form.useForm<ResetPasswordValues>();
  const [resetPasswordModalVisible, setResetPasswordModalVisible] =
    useState(false);
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const [resetPasswordError, setResetPasswordError] = useState('');

  const [newPasswordForm] = Form.useForm<NewPasswordValues>();
  const [newPasswordModalVisible, setNewPasswordModalVisible] = useState(false);
  const [newPasswordLoading, setNewPasswordLoading] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState('');

  const [loginUsername, setLoginUsername] = useState(
    process.env.REACT_APP_DEFAULT_USERNAME,
  );
  const [loginPassword, setLoginPassword] = useState(
    process.env.REACT_APP_DEFAULT_PASSWORD,
  );

  const handleRedirect = useCallback(() => {
    if (!user) {
      handleRedirect();
      return;
    }

    navigate(searchParams.get('redirect') ?? '/');
  }, [navigate, searchParams, user]);

  const handleLogin = useCallback(
    async ({username, password}: LoginValues) => {
      if (!username || !password) return;

      await signOutAndDeleteCookies();

      setLoginLoading(true);
      try {
        const user = await signIn({username, password});
        if (user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
          newPasswordForm.setFieldsValue({email: username});
          setNewPasswordModalVisible(true);
          return;
        }
      } catch (err) {
        if (isAmplifyError(err)) {
          setLoginError(CognitoErrorLabel[err.code] ?? err.code);
        } else if (err instanceof Error) {
          setLoginError(err.message);
        } else {
          setLoginError(String(err));
        }
        return;
      } finally {
        setLoginLoading(false);
      }

      setProcessRedirect(true);
    },
    [newPasswordForm, signIn, signOutAndDeleteCookies],
  );

  const handleForgotPassword = useCallback(async () => {
    await loginForm.validateFields();

    const {username} = loginForm.getFieldsValue();

    setResetPasswordRequestLoading(true);
    try {
      await forgotPassword({email: username});
    } catch (err) {
      if (err instanceof Error) {
        setLoginError(err.message);
      } else {
        setLoginError(String(err));
      }
      return;
    } finally {
      setResetPasswordRequestLoading(false);
    }

    resetPasswordForm.setFieldValue('email', username);
    setResetPasswordModalVisible(true);
  }, [forgotPassword, loginForm, resetPasswordForm]);

  const handleResetPasswordModalClose = () => {
    resetPasswordForm.resetFields();
    setResetPasswordModalVisible(false);
  };

  const handleResetPassword: FormProps<ResetPasswordValues>['onFinish'] =
    async (values) => {
      await resetPasswordForm.validateFields();
      setResetPasswordLoading(true);
      setResetPasswordError('');

      const {email, code, password} = values;

      try {
        await resetPassword({
          email,
          code,
          password,
        });
      } catch (err) {
        console.error(err, JSON.stringify(err));
        let message = 'Erreur de confirmation';
        if (isCodedError(err)) {
          message += `:\n${CognitoErrorLabel[err.code] ?? err.code}`;
        }
        setResetPasswordError(message);
        return;
      } finally {
        setResetPasswordLoading(false);
      }

      handleResetPasswordModalClose();
    };

  const handleNewPassword: FormProps<NewPasswordValues>['onFinish'] = async (
    values,
  ) => {
    await newPasswordForm.validateFields();
    setNewPasswordLoading(true);
    setNewPasswordError('');

    const {password} = values;

    try {
      await completeNewPassword({
        newPassword: password,
      });
      await signOutAndDeleteCookies();
      setLoginSuccess(
        'Votre mot de passe a été modifié avec succès. Veuillez vous connecter avec votre nouveau mot de passe',
      );
    } catch (err) {
      console.error(err, JSON.stringify(err));
      let message = 'Erreur de confirmation';
      if (isCodedError(err)) {
        message += `:\n${CognitoErrorLabel[err.code] ?? err.code}`;
      }
      setNewPasswordError(message);
      return;
    } finally {
      setNewPasswordLoading(false);
    }

    newPasswordForm.resetFields();
    setNewPasswordModalVisible(false);
  };

  useEffect(() => {
    if (!encodedCredentials || attemptedToUseParamCredentials.current) return;
    attemptedToUseParamCredentials.current = true;
    const credentials = parseEncodedCredentials(encodedCredentials);
    if (!credentials) return;

    void handleLogin(credentials);
  }, [attemptedToUseParamCredentials, encodedCredentials, handleLogin]);

  useEffect(() => {
    if (!resetPasswordEmail || attemptedToResetPassword.current) return;
    attemptedToResetPassword.current = true;

    loginForm.setFieldValue('username', resetPasswordEmail);
    void handleForgotPassword();
  }, [
    attemptedToResetPassword,
    resetPasswordEmail,
    handleLogin,
    loginForm,
    handleForgotPassword,
  ]);

  useEffect(() => {
    if (!user || !processRedirect) return;
    setProcessRedirect(false);

    handleRedirect();
  }, [handleRedirect, processRedirect, user]);

  return (
    <>
      <Form<LoginValues>
        form={loginForm}
        name="normal_login"
        initialValues={{
          username: process.env.REACT_APP_DEFAULT_USERNAME,
          password: process.env.REACT_APP_DEFAULT_PASSWORD,
        }}
        onFinish={handleLogin}
      >
        <Form.Item name="username" label="Email" rules={[{required: true}]}>
          <Input
            prefix={<UserOutlined />}
            onChange={() =>
              setLoginUsername(loginForm.getFieldValue('username'))
            }
          />
        </Form.Item>
        <Form.Item
          name="password"
          label="Mot de passe"
          style={{marginBottom: 0}}
        >
          <Input.Password
            prefix={<LockOutlined />}
            visibilityToggle={true}
            onChange={() =>
              setLoginPassword(loginForm.getFieldValue('password'))
            }
          />
        </Form.Item>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: 12,
          }}
        >
          <Button
            type="link"
            disabled={!loginUsername}
            loading={resetPasswordRequestLoading}
            onClick={handleForgotPassword}
          >
            Mot de passe oublié
          </Button>
        </div>

        {loginError ? (
          <Alert
            message="Erreur de connexion"
            description={loginError}
            type="error"
            closable
            onClose={() => setLoginError('')}
            style={{marginBottom: 12}}
          />
        ) : null}

        {loginSuccess ? (
          <Alert
            description={loginSuccess}
            type="success"
            closable
            onClose={() => setLoginSuccess('')}
            style={{marginBottom: 12}}
          />
        ) : null}

        <Button
          type="primary"
          htmlType="submit"
          disabled={!loginUsername || !loginPassword}
          loading={loginLoading}
        >
          Connexion
        </Button>
      </Form>

      <Modal
        centered
        forceRender
        open={resetPasswordModalVisible}
        onOk={() => handleResetPassword(resetPasswordForm.getFieldsValue())}
        onCancel={handleResetPasswordModalClose}
        okButtonProps={{loading: resetPasswordLoading}}
        cancelText="Annuler"
        title="Nouveau mot de passe"
      >
        <p>
          Veuillez saisir le code de vérification qui vous a été envoyé par
          mail.
        </p>
        <Form<ResetPasswordValues>
          form={resetPasswordForm}
          name="reset-password"
        >
          <Form.Item name="email" label="Email" rules={[{required: true}]}>
            <Input disabled />
          </Form.Item>
          <Form.Item name="code" label="Code" rules={[{required: true}]}>
            <Input minLength={6} maxLength={6} placeholder="123456" />
          </Form.Item>
          <Form.Item
            name="password"
            label="Nouveau mot de passe"
            rules={[{required: true}, ...getPasswordRules()]}
          >
            <Input.Password visibilityToggle={true} />
          </Form.Item>
          <Form.Item
            name="passwordValidation"
            label="Confirmation du nouveau mot de passe"
            rules={[{required: true}, getConfirmPasswordRule({})]}
          >
            <Input.Password visibilityToggle={true} />
          </Form.Item>

          <Form.Item hidden={true}>
            <Button htmlType="submit">Envoyer</Button>
          </Form.Item>

          {resetPasswordError ? (
            <Alert
              message={resetPasswordError}
              type="error"
              style={{whiteSpace: 'pre-line'}}
            />
          ) : null}
        </Form>
      </Modal>

      <Modal
        centered
        forceRender
        open={newPasswordModalVisible}
        onOk={() => handleNewPassword(newPasswordForm.getFieldsValue())}
        okButtonProps={{loading: newPasswordLoading}}
        closable={false}
        cancelButtonProps={{style: {display: 'none'}}}
        cancelText="Annuler"
        title="Mot de passe"
      >
        <p>Veuillez choisir un mot de passe</p>
        <Form<NewPasswordValues>
          form={newPasswordForm}
          name="new-password"
          onFinish={handleNewPassword}
        >
          <Form.Item name="email" label="Email" rules={[{required: true}]}>
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="password"
            label="Mot de passe"
            rules={[{required: true}, ...getPasswordRules()]}
          >
            <Input.Password visibilityToggle={true} />
          </Form.Item>
          <Form.Item
            name="passwordValidation"
            label="Confirmation du mot de passe"
            rules={[{required: true}, getConfirmPasswordRule({})]}
          >
            <Input.Password visibilityToggle={true} />
          </Form.Item>

          <Form.Item hidden={true}>
            <Button htmlType="submit">Envoyer</Button>
          </Form.Item>

          {newPasswordError ? (
            <Alert
              message={newPasswordError}
              type="error"
              style={{whiteSpace: 'pre-line'}}
            />
          ) : null}
        </Form>
      </Modal>
    </>
  );
};
