import 'react-quill/dist/quill.snow.css';

import {useDebounceFn} from 'ahooks';
import {Tag} from 'antd';
import {FC, useEffect, useState} from 'react';
import ReactQuill, {Quill} from 'react-quill';

// https://gitlab.com/logicgram/valoref/valortool/-/blob/master/packages/valortool-web/src/app/trade/offerModels/components/RichTextField.tsx

function insertTemplateTag(value: string) {
  //@ts-expect-error hard cast. used valoref code as example
  const quill: ReactQuill.Quill = this.quill;
  const selection = quill.getSelection();
  if (!selection) return;

  const suffix = ' ';

  const cursorPosition = selection.index;
  quill.deleteText(cursorPosition, selection.length);
  quill.insertText(cursorPosition, value, {tag: true});
  const end = cursorPosition + value.length;
  quill.insertText(end, suffix, {tag: false});
  quill.setSelection({index: end + suffix.length, length: 0});
}

export interface TemplateTag {
  value: string;
  label: string;
}

const TemplateTags: FC<{templateTags: TemplateTag[]; disabled?: boolean}> = (
  props,
) => {
  const {disabled, templateTags} = props;
  return (
    <div
      style={{width: '100%', display: 'table', paddingTop: 8, paddingLeft: 8}}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 4,
        }}
      >
        <div style={{marginRight: 4, whiteSpace: 'nowrap'}}>
          Substitutions :
        </div>
        {templateTags.map(({value, label}, index) => (
          <button
            key={index}
            className="ql-insertTemplateTag"
            value={value}
            style={{width: 'auto', height: 'auto', padding: 0}}
            disabled={disabled}
          >
            <Tag.CheckableTag key={index} checked={true}>
              {label}
            </Tag.CheckableTag>
          </button>
        ))}
      </div>
    </div>
  );
};

const CustomToolbar: FC<{
  templateTags: TemplateTag[];
  toolbarElementUniqueId: string;
  disabled?: boolean;
}> = ({templateTags, toolbarElementUniqueId, disabled}) => {
  return (
    <div id={toolbarElementUniqueId ?? 'toolbar'}>
      <select
        className="ql-size"
        defaultValue={''}
        onChange={(e) => e.persist()}
      >
        <option value="small"></option>
        <option></option>
        <option value="large"></option>
      </select>
      <button className="ql-bold"></button>
      <button className="ql-italic"></button>
      <button className="ql-underline"></button>
      <select className="ql-color"></select>
      <button className="ql-list" value="ordered"></button>
      <button className="ql-list" value="bullet"></button>
      <button className="ql-clean"></button>
      {!!templateTags.length && (
        <TemplateTags templateTags={templateTags} disabled={disabled} />
      )}
    </div>
  );
};

const Inline = Quill.import('blots/inline');
const ColorStyle = Quill.import('attributors/style/color');
class TagBlot extends Inline {}
TagBlot.blotName = 'tag';
// using a 'bdo' tag because the span HTML is not working for some reason
TagBlot.tagName = 'bdo';
TagBlot.className = 'template-highlight';

Quill.register('formats/tag', TagBlot, true);
Quill.register(ColorStyle, true);

export const RichTextEditor = ({
  richText,
  setRichText,
  uniqueId,
  templateTags,
}: {
  richText: string;
  setRichText: (richText: string) => void;
  uniqueId: string;
  templateTags: Array<TemplateTag>;
}) => {
  const toolbarUniqueId = `toolbar_${uniqueId}`;
  const toolbarContainer = `#${toolbarUniqueId}`;

  const [ready, setReady] = useState(false);

  useEffect(() => {
    const elem = document.querySelector(toolbarContainer);
    if (elem && templateTags.length) {
      setReady(true);
    }
  }, [setReady, toolbarContainer, templateTags]);

  const {run: onRichTextChanged} = useDebounceFn(
    (richText: string) => {
      setRichText(richText);
    },
    {wait: 500},
  );

  return (
    <div className="text-editor">
      <CustomToolbar
        templateTags={templateTags}
        toolbarElementUniqueId={toolbarUniqueId}
      ></CustomToolbar>
      {ready && (
        <ReactQuill
          theme="snow"
          value={richText}
          onChange={(richText) => onRichTextChanged(richText)}
          modules={{
            toolbar: {
              container: `#${toolbarUniqueId}`,
              handlers: {insertTemplateTag},
            },
            clipboard: {
              matchVisual: false,
            },
          }}
          formats={[
            'header',
            'bold',
            'italic',
            'underline',
            'list',
            'clean',
            'size',
            'tag',
            'color',
          ]}
        />
      )}
    </div>
  );
};
