import {Form, Input, Modal, ModalProps, Typography} from 'antd';
import {
  AnswerMatrixT,
  CreatorForm,
  CreatorItem,
  customMath,
  findAllFormulas,
  getKeysFromReference,
  isFormulaReference,
  isNumericQuestionReference,
  isSelectQuestionReference,
  isTemplateFormulaReference,
  isTextQuestion,
  ItemType,
} from 'holo-api';
import {FC, useCallback, useMemo} from 'react';

import {updateSingularForm} from '../../../client/form';
import {useCreatorStore} from '../../../stores/creator';
import {useMatrixStore} from '../../../stores/matrix';
import {useApi} from '../../../util/auth';
import {
  deleteKeysOnForm,
  FormulasToFix,
  getIndexesFromKeys,
  rewriteFormulasOnSave,
} from '../../../util/form';
import {MathScope} from '../creator/CreatorFormulaInput';
const {parse, evaluate} = customMath;

interface MainFormFormulasModalProps extends ModalProps {
  formulas: FormulasToFix[] | undefined;
}

export const MainFormFormulasModal: FC<MainFormFormulasModalProps> = ({
  formulas,
  ...props
}) => {
  const api = useApi();
  const [modalForm] = Form.useForm();
  const mainForm = useCreatorStore((state) => state.mainForm);

  const allMainFormFormulas = mainForm
    ? findAllFormulas(mainForm?.contents)
    : [];

  const formTemplates = useCreatorStore((state) => state.formTemplates);
  const setFormTemplates = useCreatorStore((state) => state.setFormTemplates);
  const templateFormulasMapping = useCreatorStore(
    (state) => state.templateFormulasMapping,
  );

  const {matrix1d, matrix2d} = useMatrixStore();
  const allMatrices = useMemo(
    () =>
      [...matrix1d, ...matrix2d].map((m) => {
        const am: AnswerMatrixT = {
          id: m.id,
          name: m.name,
          description: m.description ? m.description : '',
          mat2d: m.mat2d,
          matrixND: m,
        };
        return am;
      }),
    [matrix1d, matrix2d],
  );

  const validateFormula = useCallback(
    (formula: string, uuid: string): [boolean, string] => {
      const checkingScope: MathScope = {};
      if (!mainForm) {
        return [false, ''];
      }
      try {
        const currentForm = mainForm.contents as CreatorForm;
        const parsed = parse(formula);
        const html = document.createElement('div');
        html.innerHTML = parsed.toHTML();
        Array.from(html.children).forEach((child) => {
          const textContent = child.textContent;
          if (!textContent) {
            if (child.classList.contains('math-implicit-binary-operator')) {
              const nextSibling = child.nextSibling?.textContent;
              const previousSibling = child.previousSibling?.textContent;
              throw new Error(
                `Une multiplication implicite a été repérée : ${previousSibling}${nextSibling}`,
              );
            }
            throw new Error('Un des symboles de la formule est erroné');
          }
          if (child.classList.contains('math-symbol')) {
            if (
              !isSelectQuestionReference(textContent) &&
              !isNumericQuestionReference(textContent) &&
              !isFormulaReference(textContent) &&
              !isTemplateFormulaReference(textContent)
            ) {
              throw new Error(
                'Le format ne correspond pas à une référence (question, formule) ni à un nombre: ' +
                  textContent,
              );
            } else {
              if (isTemplateFormulaReference(textContent)) {
                if (!templateFormulasMapping[textContent]) {
                  throw new Error(
                    `Aucune formule de template ne correspond à cette référence: ${textContent}`,
                  );
                }
                checkingScope[textContent] = Math.random();
                return;
              }

              const [referenceChapterKey, itemKey] =
                getKeysFromReference(textContent);
              const [chapterIndex, itemIndex] = getIndexesFromKeys(
                currentForm,
                referenceChapterKey,
                itemKey,
              );

              const item: CreatorItem =
                currentForm.chapters[chapterIndex].items[itemIndex];

              if (!item) {
                throw new Error(
                  `Aucun élément ne se trouve à cette référence: ${textContent}`,
                );
              }
              if (
                isFormulaReference(textContent) &&
                item.type !== ItemType.FORMULA
              ) {
                throw new Error(
                  `Aucune formule ne correspond à cette description : ${textContent}`,
                );
              }
              if (
                (isSelectQuestionReference(textContent) ||
                  isNumericQuestionReference(textContent)) &&
                item.type !== ItemType.QUESTION
              ) {
                throw new Error(
                  `Aucune question ne correspond à cette description : ${textContent}`,
                );
              }
              if (
                isNumericQuestionReference(textContent) &&
                isTextQuestion(item) &&
                item.numeric !== true
              ) {
                throw new Error(
                  `Des questions ne sont pas de type numérique : ${textContent}`,
                );
              }
              if (isFormulaReference(textContent) && item.uuid === uuid) {
                throw new Error(
                  `Il n'est pas possible de référencer la formule avec elle-même : ${textContent}`,
                );
              }
              checkingScope[textContent] = Math.random();
            }
          }
        });
        evaluate(formula, {...checkingScope, allMatrices});

        return [true, 'Success'];
      } catch (error) {
        let message = 'Erreur inconnue';
        if (error instanceof Error) {
          message = error.message;
        }
        return [false, message];
      }
    },
    [allMatrices, mainForm, templateFormulasMapping],
  );
  return (
    <Modal
      title={
        <Typography>{`Des formules du formulaire principal "${mainForm?.contents.title}" doivent être corrigées (supprimer les références indiquées)`}</Typography>
      }
      open={formulas && formulas.length > 0}
      zIndex={100000}
      onCancel={(e) => {
        modalForm.resetFields();
        props.onCancel?.(e);
      }}
      onOk={async (e) => {
        if (!mainForm || !api || !formulas) {
          return;
        }
        try {
          const editedFormulas = await modalForm.validateFields();
          formulas.forEach((formula) => {
            const foundFormula = allMainFormFormulas.find(
              (mainFormFormula) => mainFormFormula.uuid === formula.uuid,
            );
            if (!foundFormula) {
              return;
            }
            foundFormula.formula = editedFormulas[formula.uuid];
            for (const tag of formula.tags) {
              delete templateFormulasMapping[tag];
            }
          });
          setFormTemplates(formTemplates, templateFormulasMapping);
          const mainFormCopy = JSON.parse(JSON.stringify(mainForm.contents));
          const allMainFormFormulasCopy = findAllFormulas(mainFormCopy);
          rewriteFormulasOnSave(
            allMainFormFormulasCopy,
            mainFormCopy as CreatorForm,
            allMatrices,
            templateFormulasMapping,
          );

          deleteKeysOnForm(mainFormCopy as CreatorForm);

          const body = {
            contents: mainFormCopy,
            minorVersion: 1,
            patchVersion: 1,
            schemaVersion: 1,
            priority: mainForm.priority ?? null,
            mainFormId: mainForm.mainFormId ?? null,
            category: mainForm.category ?? undefined,
          };

          await updateSingularForm(api, mainForm.id, body);

          props.onOk?.(e);
        } catch (err) {
          console.info('Error while updating main form');
          console.error(err);
        }
      }}
    >
      <Form form={modalForm}>
        {formulas?.map(({title, uuid, value, tags}, keyIndex) => {
          return (
            <Form.Item
              key={keyIndex}
              label={<Typography>{`${title} (${tags.join(', ')})`}</Typography>}
              initialValue={value}
              name={uuid}
              rules={[
                {
                  validateTrigger: 'onSubmit',
                  validator: (_, value) => {
                    const [isValid, message] = validateFormula(value, uuid);
                    if (!isValid) {
                      return Promise.reject(message);
                    }
                    return Promise.resolve();
                  },
                },
                {
                  validateTrigger: 'onChange',
                  validator: (_, value) => {
                    if (!value) {
                      return Promise.reject(
                        'Une formule ne peut pas être vide, veuillez indiquer la valeur 0 pour ignorer',
                      );
                    }
                    const parsed = parse(value);
                    const html = document.createElement('div');
                    html.innerHTML = parsed.toHTML();
                    for (const tag of tags) {
                      if (value.includes(tag)) {
                        let errorMessage = '';
                        Array.from(html.children).forEach((child) => {
                          if (errorMessage) {
                            return;
                          }
                          if (
                            child.textContent &&
                            child.classList.contains('math-symbol')
                          ) {
                            const textContent = child.textContent;
                            if (textContent === tag) {
                              errorMessage = `La référence à '${tag}' doit être retirée.`;
                            }
                          }
                        });
                        if (errorMessage) {
                          return Promise.reject(errorMessage);
                        }
                      }
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        })}
      </Form>
    </Modal>
  );
};
