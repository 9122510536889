import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  CardProps,
  Col,
  Form,
  FormListFieldData,
  Input,
  InputNumber,
  message,
  Modal,
  ModalProps,
  notification,
  Popconfirm,
  Row,
  Space,
  theme,
  Tooltip,
  Typography,
  Upload,
} from 'antd';
import {
  CreateSharedFileI,
  CreateSharedFileO,
  createSharedFilePath,
  DbSharedFile,
  DeleteSharedFileI,
  DeleteSharedFileO,
  deleteSharedFilePath,
  GetAllSharedFilesO,
  getAllSharedFilesPath,
  Role,
  SharedFile,
  UpdateSharedFileI,
  UpdateSharedFileO,
  updateSharedFilePath,
  UpdateSharedFilesOrderI,
  UpdateSharedFilesOrderO,
  updateSharedFilesOrderPath,
  UploadFileSchemaType,
} from 'holo-api';
import {FC, useCallback, useEffect, useState} from 'react';

import {Spinner} from './components/ui/Spinner';
import {apiGet, apiPost, apiPut} from './util/api';
import {useApi, useUserRole} from './util/auth';

interface SharedFilesModalProps extends ModalProps {
  id: number | 'new' | undefined;
  editedSharedFile: DbSharedFile | undefined;
  numberOfFiles: number;
}

export const SharedFilesModal: FC<SharedFilesModalProps> = ({
  id,
  editedSharedFile,
  numberOfFiles = 0,
  ...props
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const api = useApi();

  useEffect(() => {
    if (!id) {
      return;
    }
    if (id === 'new') {
      form.setFieldsValue({
        name: '',
        description: '',
        order: numberOfFiles + 1,
      });
    }
    if (typeof id === 'number') {
      form.setFieldsValue({
        ...editedSharedFile,
      });
    }
  }, [id, form, editedSharedFile, numberOfFiles]);

  return (
    <Modal
      title={
        id === 'new'
          ? "Ajout d'un fichier à télécharger"
          : typeof id === 'number'
            ? `Édition du fichier ${editedSharedFile?.name}`
            : undefined
      }
      centered
      confirmLoading={isLoading}
      forceRender
      maskClosable={false}
      open={!!id}
      width="600px"
      {...props}
      onOk={async (e) => {
        setIsLoading(true);
        try {
          const value = await form.validateFields();
          if (!api) {
            return;
          }
          if (id === 'new') {
            const {sharedFile, ...data} = value;

            const {name, type} = sharedFile;
            const sharedFileMetadata = {name, type};

            const body: CreateSharedFileI = {
              ...data,
              sharedFileMetadata,
            };
            const {fileSignedUrl} = await apiPut<
              CreateSharedFileO,
              CreateSharedFileI
            >(api, createSharedFilePath, {
              body,
            });
            if (sharedFile && fileSignedUrl) {
              const headers: HeadersInit = {
                'Content-Disposition': `attachment; filename=${encodeURIComponent(sharedFile.name)}`,
              };
              if (sharedFile.type) {
                headers['Content-Type'] = sharedFile.type;
              }
              await fetch(fileSignedUrl, {
                method: 'PUT',
                body: sharedFile.originFileObj,
                headers,
              });
            }
          }
          if (typeof id === 'number') {
            if (!editedSharedFile) {
              return;
            }
            try {
              const {sharedFile, ...data} = value;
              let sharedFileMetadata: UploadFileSchemaType | undefined;
              if (sharedFile) {
                const {name, type} = sharedFile;
                sharedFileMetadata = {name, type};
              }
              const body: UpdateSharedFileI = {
                id,
                data,
                sharedFileMetadata,
              };
              const {fileSignedUrl} = await apiPost<
                UpdateSharedFileO,
                UpdateSharedFileI
              >(api, updateSharedFilePath, {body});
              if (sharedFile && fileSignedUrl) {
                const headers: HeadersInit = {
                  'Content-Disposition': `attachment; filename=${encodeURIComponent(sharedFile.name)}`,
                };
                if (sharedFile.type) {
                  headers['Content-Type'] = sharedFile.type;
                }
                await fetch(fileSignedUrl, {
                  method: 'PUT',
                  body: sharedFile.originFileObj,
                  headers,
                });
              }
            } catch (err) {
              console.error(err);
              void message.error(
                "Une erreur est survenue au moment de l'édition du fichier.",
              );
            }
          }
          form.resetFields();
          setIsLoading(false);
          props.onOk?.(e);
        } catch (err) {
          console.info(err);
          setIsLoading(false);
        }
      }}
      onCancel={(e) => {
        form.resetFields();
        props.onCancel?.(e);
      }}
    >
      <Form form={form} layout="vertical" style={{marginTop: 20}}>
        <Form.Item
          label="Nom du fichier"
          name="name"
          required={id === 'new'}
          rules={[
            {
              validateTrigger: 'onOk',
              validator: (_r, value) => {
                if (id === 'new' && value.trim() === '') {
                  return Promise.reject(
                    'Veuillez renseigner un nom pour ce fichier',
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description du fichier"
          name="description"
          required={id === 'new'}
          rules={[
            {
              validateTrigger: 'onOk',
              validator: (_r, value) => {
                if (id === 'new' && value.trim() === '') {
                  return Promise.reject(
                    'Veuillez renseigner une description pour ce fichier',
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input.TextArea autoSize />
        </Form.Item>
        <Form.Item hidden name="order">
          <InputNumber hidden />
        </Form.Item>
        <Form.Item
          label="Fichier à partager"
          name="sharedFile"
          valuePropName="file"
          getValueFromEvent={(event) => event?.file}
          required={id === 'new'}
        >
          <Upload
            accept="image/*,.pdf"
            showUploadList={{
              showRemoveIcon: true,
              showDownloadIcon: false,
              showPreviewIcon: false,
            }}
            maxCount={1}
            customRequest={({onSuccess}) => onSuccess?.(true)}
          >
            <Button icon={<UploadOutlined />}>
              Cliquez pour charger un fichier
            </Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};

interface FileCardProps extends CardProps {
  field: FormListFieldData;
  move: (from: number, to: number) => void;
  setId: (id: 'new' | number | undefined) => void;
  setEditedSharedFile: (sharedFile: SharedFile | undefined) => void;
  loadSharedFiles: () => Promise<void>;
  updateFileOrders: (ignoredFiles?: number[]) => Promise<void>;
}

const FileCard: FC<FileCardProps> = ({
  field,
  move,
  setId,
  setEditedSharedFile,
  loadSharedFiles,
  updateFileOrders,
}) => {
  const form = Form.useFormInstance();
  const api = useApi();
  const {sharedFiles} = form.getFieldsValue();
  const file = sharedFiles?.[field.name];

  const role = useUserRole();
  const isAdmin = role === Role.ADMIN;

  const [indexInputValue, setIndexInputValue] = useState<number | null>(
    file.order ?? field.name + 1,
  );

  useEffect(() => {
    setIndexInputValue(field.name + 1);
    form.setFieldValue(['sharedFiles', field.name, 'order'], field.name + 1);
  }, [field.name, form]);

  const moveItem = (previous: number): void => {
    const value = Number(indexInputValue);
    if (isNaN(value)) {
      notification.error({message: 'Il faut renseigner un nombre'});
      setIndexInputValue(previous + 1);
      return;
    }
    if (value < 1) {
      notification.error({
        message: 'La position doit être supérieure ou égale à 1',
      });
      setIndexInputValue(previous + 1);
      return;
    }
    let index = Number(indexInputValue);
    if (index > sharedFiles.length) {
      index = sharedFiles.length;
      setIndexInputValue(index);
    }
    if (index - 1 === field.name) {
      return;
    }
    move(field.name, index - 1);
  };

  return (
    <div key={field.key}>
      <Form.Item name={[field.name, 'name']} hidden>
        <Input hidden />
      </Form.Item>
      <Form.Item name={[field.name, 'description']} hidden>
        <Input hidden />
      </Form.Item>
      <Form.Item name={[field.name, 'fileSignedUrl']} hidden>
        <Input hidden />
      </Form.Item>
      <Form.Item name={[field.name, 'order']} hidden>
        <Input hidden />
      </Form.Item>
      <Card
        style={{width: 400, borderColor: '#F49D37'}}
        hoverable
        extra={
          isAdmin && (
            <InputNumber
              controls={true}
              value={indexInputValue}
              wheel={false}
              onChange={(value) => {
                setIndexInputValue(value);
              }}
              onPressEnter={() => {
                moveItem(field.name);
              }}
              onBlur={() => {
                moveItem(field.name);
              }}
            />
          )
        }
        actions={[
          <Tooltip title="Télécharger" key="download">
            <Button
              type="primary"
              style={{width: isAdmin ? '80%' : '50%'}}
              icon={<DownloadOutlined style={{color: 'white'}} />}
              href={file.fileSignedUrl}
            />
          </Tooltip>,
          ...(isAdmin
            ? [
                <Tooltip key="edit" title="Modifier les infos du fichier">
                  <Button
                    style={{width: '80%'}}
                    icon={<EditOutlined />}
                    onClick={() => {
                      setId(file.id);
                      setEditedSharedFile(file);
                    }}
                  />
                </Tooltip>,
                <Tooltip key="delete" title="Supprimer le fichier">
                  <Popconfirm
                    title={`Êtes-vous sûr de vouloir supprimer le fichier ${file.name} ?`}
                    onConfirm={async () => {
                      if (!api) {
                        return;
                      }
                      try {
                        await updateFileOrders([file.id]);
                        await apiPost<DeleteSharedFileO, DeleteSharedFileI>(
                          api,
                          deleteSharedFilePath,
                          {body: {id: file.id}},
                        );
                        void message.success('Fichier supprimé avec succès !');
                      } catch (err) {
                        console.error(err);
                        void message.error(
                          'Une erreur est survenue au moment de la suppression.',
                        );
                      } finally {
                        await loadSharedFiles();
                      }
                    }}
                  >
                    <Button icon={<DeleteOutlined />} style={{width: '80%'}} />
                  </Popconfirm>
                </Tooltip>,
              ]
            : []),
        ]}
      >
        <Card.Meta title={file?.name} description={file?.description} />
      </Card>
    </div>
  );
};

export const SharedFiles: FC = () => {
  const {token} = theme.useToken();
  const api = useApi();
  const role = useUserRole();
  const isAdmin = role === Role.ADMIN;

  const [form] = Form.useForm<{sharedFiles: SharedFile[]}>();
  const [id, setId] = useState<number | 'new' | undefined>(undefined);
  const [editedSharedFile, setEditedSharedFile] = useState<
    DbSharedFile | undefined
  >(undefined);
  const [filesLoading, setFilesLoading] = useState(false);
  const [numberOfFiles, setNumberOfFiles] = useState(0);

  const loadSharedFiles = useCallback(async () => {
    if (!api) {
      return;
    }
    setFilesLoading(true);
    const allSharedFiles = await apiGet<GetAllSharedFilesO>(
      api,
      getAllSharedFilesPath,
    );

    form.setFieldsValue(allSharedFiles);
    setNumberOfFiles(allSharedFiles.sharedFiles.length);
    setFilesLoading(false);
  }, [api, form]);

  const updateFileOrders = useCallback(
    async (ignoredFiles?: number[]) => {
      if (!api) {
        return;
      }
      const {sharedFiles} = form.getFieldsValue();
      let orderedFiles = [...sharedFiles];
      if (ignoredFiles && ignoredFiles.length > 0) {
        orderedFiles = sharedFiles.filter(
          (file) => !ignoredFiles.includes(file.id),
        );
      }
      await apiPost<UpdateSharedFilesOrderO, UpdateSharedFilesOrderI>(
        api,
        updateSharedFilesOrderPath,
        {body: {fileIds: orderedFiles.map((file) => file.id)}},
      );
    },
    [form, api],
  );

  useEffect(() => {
    void loadSharedFiles();
  }, [loadSharedFiles]);

  return (
    <>
      <Typography.Title
        style={{textAlign: 'center', color: token.colorPrimary}}
      >
        RESSOURCES À TÉLÉCHARGER
      </Typography.Title>
      {isAdmin && (
        <Row justify="end">
          <Col>
            <Button
              type="primary"
              onClick={() => {
                setId('new');
              }}
            >
              Ajouter un fichier
            </Button>
          </Col>
        </Row>
      )}
      {filesLoading && <Spinner tip="Chargement des fichiers" />}
      <Form
        style={{marginTop: 32}}
        form={form}
        onValuesChange={async () => {
          await updateFileOrders();
        }}
      >
        <Form.List name="sharedFiles">
          {(fields, {move}) => (
            <Space direction="horizontal" wrap size="large">
              {fields.map((field) => (
                <FileCard
                  key={field.key}
                  move={move}
                  field={field}
                  setId={setId}
                  setEditedSharedFile={setEditedSharedFile}
                  loadSharedFiles={loadSharedFiles}
                  updateFileOrders={updateFileOrders}
                />
              ))}
            </Space>
          )}
        </Form.List>
      </Form>
      <SharedFilesModal
        id={id}
        numberOfFiles={numberOfFiles}
        editedSharedFile={editedSharedFile}
        onOk={() => {
          setId(undefined);
          void loadSharedFiles();
        }}
        onCancel={() => {
          setId(undefined);
        }}
      />
    </>
  );
};
