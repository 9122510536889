import {HistoryOutlined} from '@ant-design/icons';
import {
  Button,
  Collapse,
  Form,
  Input,
  Row,
  Space,
  Tag,
  theme,
  Typography,
} from 'antd';
import {GetCompanyUser, Role, TextQuestion, UserAnswers} from 'holo-api';
import {FC, useEffect, useState} from 'react';

import {useViewerStore} from '../../../stores/viewer';
import {useUserRole} from '../../../util/auth';
import {ConsultantComment} from './ConsultantComment';
import {CustomTooltip} from './CustomTooltip';
import {NoAnswerCheckbox} from './NoAnswerCheckbox';

interface ViewerTextInputProps extends TextQuestion {
  id: number;
  chapterIdx: number;
  viewAsUser: boolean;
  onHistory: () => void;
}

export const ViewerTextInput: FC<ViewerTextInputProps> = ({
  title,
  uuid,
  placeholder = 'Type your answer here.',
  optional = false,
  logo,
  id,
  tooltip,
  chapterIdx,
  viewAsUser,
  disabled,
  onHistory,
}) => {
  const userRole = useUserRole();
  const isAdmin = userRole === Role.ADMIN;
  const {token} = theme.useToken();
  const primaryColor = token.colorPrimary;

  const isPreliminaryForm = useViewerStore((state) => state.isPreliminaryForm);
  const templateInstanceId = useViewerStore(
    (state) => state.templateInstanceId,
  );
  const questionUuidToUserId = useViewerStore(
    (state) => state.questionUuidToUserId,
  );
  const templateQuestionUuidToUserId = useViewerStore(
    (state) => state.templateQuestionUuidToUserId,
  );
  const companyUsers = useViewerStore((state) => state.companyUsers);
  const readOnly = useViewerStore((state) => state.readOnly);
  const isTemplate = useViewerStore((state) => state.isTemplate);

  const [answerUser, setAnswerUser] = useState<GetCompanyUser | undefined>(
    undefined,
  );

  const form = Form.useFormInstance<UserAnswers>();
  const willNotAnswer = Form.useWatch([uuid, 'willNotAnswer'], {
    form,
  });
  const text =
    Form.useWatch([uuid, 'text'], {
      form,
    }) ?? '';
  const comment =
    Form.useWatch([uuid, 'comment'], {form, preserve: true}) ??
    form.getFieldValue([uuid, 'comment']);

  useEffect(() => {
    if (isTemplate && !templateInstanceId) {
      return;
    }
    if (templateInstanceId) {
      setAnswerUser(
        companyUsers[templateQuestionUuidToUserId[templateInstanceId][uuid]],
      );
    } else {
      setAnswerUser(companyUsers[questionUuidToUserId[uuid]]);
    }
  }, [
    isTemplate,
    templateInstanceId,
    companyUsers,
    uuid,
    templateQuestionUuidToUserId,
    questionUuidToUserId,
  ]);

  return (
    <Row id={`C${chapterIdx}I${id}`}>
      <Space direction="vertical" style={{width: '100%', marginBottom: 10}}>
        <Form.Item
          label={
            <Space style={{width: '100%', justifyContent: 'space-between'}}>
              <Space>
                {logo ? (
                  <img src={logo} alt="Logo for current question" />
                ) : null}
                <span style={{fontWeight: 'bold'}}>{title}</span>
                {isAdmin && !viewAsUser && (
                  <Tag
                    color="grey"
                    style={{
                      opacity: 0.2,
                    }}
                  >
                    {`C${chapterIdx + 1}Q${id + 1}`}
                  </Tag>
                )}
              </Space>
              <Space>
                {tooltip && (
                  <CustomTooltip tooltipText={tooltip} itemTitle={title} />
                )}
                <Button icon={<HistoryOutlined />} onClick={onHistory}></Button>
                {answerUser && answerUser.role === Role.CONSULTANT ? (
                  <Typography.Text
                    type="secondary"
                    italic
                    style={{color: 'royalblue'}}
                  >
                    Réponse apportée par{' '}
                    {answerUser.firstName + ' ' + answerUser.lastName}
                  </Typography.Text>
                ) : null}
              </Space>
            </Space>
          }
          name={[uuid, 'text']}
          rules={[
            {
              required: optional ? !willNotAnswer : true,
              message: 'Veuillez donner une réponse à cette question !',
            },
          ]}
          style={
            optional
              ? {marginBottom: 0, padding: '5px'}
              : {padding: '5px', marginBottom: 0}
          }
        >
          <Input.TextArea
            disabled={disabled || willNotAnswer || readOnly}
            style={{width: '100%'}}
            placeholder={placeholder}
            autoSize={{minRows: 1, maxRows: 4}}
            allowClear
          />
        </Form.Item>
        {optional && (
          <NoAnswerCheckbox
            uuid={uuid}
            disabled={(!!text && !willNotAnswer) || readOnly}
          />
        )}
        {isPreliminaryForm ? null : (
          <Collapse
            size="small"
            items={[
              {
                label: (
                  <Typography
                    style={
                      comment && comment !== ''
                        ? {color: primaryColor, textDecoration: 'underline'}
                        : undefined
                    }
                  >
                    Commentaires pour les facteurs de preuve
                  </Typography>
                ),
                key: 1,
                children: (
                  <Form.Item style={{marginBottom: 0}} name={[uuid, 'comment']}>
                    <Input.TextArea
                      autoSize={{minRows: 1, maxRows: 5}}
                      disabled={readOnly}
                    />
                  </Form.Item>
                ),
              },
            ]}
          />
        )}
        {!isPreliminaryForm && <ConsultantComment uuid={uuid} />}
      </Space>
    </Row>
  );
};
