import dayjs from 'dayjs';
import {
  ChapterType,
  FormContentsType,
  isAnswerToTextQuestion,
  isQuestion,
  Prefill,
} from 'holo-api';
import {useEffect, useState} from 'react';

import {companyGetSingular} from '../client/company';
import {useViewerStore} from '../stores/viewer';
import {useApi} from '../util/auth';

interface PrefillData {
  companyName: string;
  companySiret: string | undefined;
}

interface UsePrefillParams {
  companyId: number | undefined;
  form: FormContentsType | undefined;
}

export const usePrefill = ({companyId, form}: UsePrefillParams) => {
  const api = useApi();
  const [pfData, setPfData] = useState<PrefillData | undefined>();
  const [pfMap, setPfMap] = useState<Map<string, Prefill> | undefined>();
  const initialFormAnswers = useViewerStore(
    (state) => state.initialFormAnswers,
  );
  const setInitialFormAnswers = useViewerStore(
    (state) => state.setInitialFormAnswers,
  );

  useEffect(() => {
    // load prefill info from API: setPfData
    if (pfMap && companyId && api) {
      void (async () => {
        const company = await companyGetSingular(api, companyId);
        setPfData({
          companyName: company.name,
          companySiret: company.siret,
        });
      })();
    }
  }, [api, companyId, pfMap]);

  useEffect(() => {
    // check if forms has any prefills: setPfMap
    let map: Map<string, Prefill> | undefined;
    if (form) {
      for (const chapter of form.chapters) {
        if (
          chapter.chapterType === ChapterType.SEPARATOR ||
          chapter.chapterType === ChapterType.TITLE
        ) {
          return;
        }
        for (const item of chapter.items) {
          if (isQuestion(item)) {
            if (item.prefill) {
              if (!map) {
                map = new Map();
              }
              map.set(item.uuid, item.prefill);
            }
          }
        }
      }
    }
    setPfMap(map);
  }, [form]);

  useEffect(() => {
    // check that answers have been prefilled when necessary: setInitialFormAnswers
    if (pfData && initialFormAnswers && pfMap) {
      let updatePrefill = false;
      const nowDate = dayjs(Date.now()).toISOString();

      for (const [uuid, prefill] of pfMap) {
        const answer = initialFormAnswers[uuid];
        let prefillValue: string | undefined;
        switch (prefill) {
          case Prefill.COMPANY_NAME:
            prefillValue = pfData.companyName;
            break;
          case Prefill.COMPANY_SIRET:
            prefillValue = pfData.companySiret;
            break;
        }

        if (!prefillValue) {
          // if prefill value is not found, skip
          //console.warn(`prefill value for ${prefill} not found`);
          continue;
        }

        if (answer && isAnswerToTextQuestion(answer)) {
          // if there is already an answer, only update it if different from prefill (also avoids infinite loop)
          const text = answer.text;
          if (prefillValue !== text) {
            initialFormAnswers[uuid] = {text: prefillValue, date: nowDate};
            updatePrefill = true;
          }
        } else {
          // if there is no answer yet, force prefill value
          initialFormAnswers[uuid] = {text: prefillValue, date: nowDate};
          updatePrefill = true;
        }
      }
      if (updatePrefill) {
        setInitialFormAnswers(initialFormAnswers);
      }
    }
  }, [pfData, initialFormAnswers, setInitialFormAnswers, pfMap]);
};
