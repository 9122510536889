import {useDebounceFn} from 'ahooks';
import {Form, Input} from 'antd';
import {TagDetailsChartReportPage, TagRadar} from 'holo-api';
import {useEffect} from 'react';

import {RichTextEditor, TemplateTag} from './shared/RichTextEditor';
import {TagList} from './shared/TagList';

interface TagDetailsFormType {
  title: string;
  subTitle?: string;
  tags: Array<TagRadar>;
}

export const TagDetailsChart = ({
  chartPage,
  setChartPage,
  index,
  templateTags,
}: {
  chartPage: TagDetailsChartReportPage;
  setChartPage: (page: TagDetailsChartReportPage) => void;
  index: number;
  templateTags: Array<TemplateTag>;
}) => {
  const [form] = Form.useForm<TagDetailsFormType>();

  useEffect(() => {
    form.setFieldsValue({
      title: chartPage.title,
      subTitle: chartPage.subTitle,
      tags: chartPage.tags,
    });
  }, [form, chartPage]);

  const {run: onChangedPage} = useDebounceFn(
    async (values: TagDetailsFormType) => {
      try {
        const validated = await form.validateFields();
        const newChart = {...chartPage};
        newChart.title = validated.title;
        newChart.subTitle = validated.subTitle;
        newChart.tags = validated.tags;
        setChartPage(newChart);
      } catch (err) {
        console.warn('onChangedPage', values);
        console.error('Not a valid form', err);
      }
    },
    {wait: 500},
  );

  const onRichTextChanged = (richText: string) => {
    const newChart = {...chartPage};
    newChart.richExplain = richText || undefined;
    setChartPage(newChart);
  };

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div>
        <Form
          form={form}
          onValuesChange={(_changedValues, values) => onChangedPage(values)}
        >
          <Form.Item label="Titre" name="title" required={true}>
            <Input />
          </Form.Item>
          <Form.Item label="Sous Titre" name="subTitle">
            <Input />
          </Form.Item>
          <TagList subNamePath={[]}></TagList>
        </Form>
      </div>
      <div style={{flex: 1}}>
        <RichTextEditor
          uniqueId={`page_${index}`}
          richText={chartPage.richExplain ?? ''}
          setRichText={onRichTextChanged}
          templateTags={templateTags}
        ></RichTextEditor>
      </div>
    </div>
  );
};
