import {useDebounceFn} from 'ahooks';
import {Form, Input, Select} from 'antd';
import {ForceFaiblesseChartReportPage} from 'holo-api';
import {useEffect} from 'react';

import {ActifSelectorOptions} from './shared/reportInterfaces';

interface ForceFaiblesseFormType {
  title: string;
  subTitle?: string;
  formId?: number;
}

export const ForceFaiblesseChart = ({
  chartPage,
  setChartPage,
  actifSelectorOptions,
}: {
  chartPage: ForceFaiblesseChartReportPage;
  setChartPage: (page: ForceFaiblesseChartReportPage) => void;
  actifSelectorOptions: ActifSelectorOptions;
}) => {
  const [form] = Form.useForm<ForceFaiblesseFormType>();

  useEffect(() => {
    form.setFieldsValue({
      title: chartPage.title,
      subTitle: chartPage.subTitle,
      formId: chartPage.formId,
    });
  }, [form, chartPage]);

  const {run: onChangedPage} = useDebounceFn(
    async (values: ForceFaiblesseFormType) => {
      try {
        const validated = await form.validateFields();
        const newChartPage: ForceFaiblesseChartReportPage = {...chartPage};
        newChartPage.title = validated.title;
        newChartPage.subTitle = validated.subTitle;
        newChartPage.formId = validated.formId;
        setChartPage(newChartPage);
      } catch (err) {
        console.warn('onChangedPage', values);
        console.error('Not a valid form', err);
      }
    },
    {wait: 500},
  );

  return (
    <Form
      form={form}
      onValuesChange={(_changedValues, values) => onChangedPage(values)}
    >
      <Form.Item label="Titre" name="title">
        <Input />
      </Form.Item>
      <Form.Item label="Sous Titre" name="subTitle">
        <Input />
      </Form.Item>
      <Form.Item label="Formulaire" name="formId">
        <Select
          style={{width: '100%'}}
          options={actifSelectorOptions}
          placeholder="Recherche de formulaire"
        />
      </Form.Item>
    </Form>
  );
};
