import {JSONSchemaType} from 'ajv';

import {Role} from '../../database/types';
import {FormCategory} from '../../util/form';
import {UserAnswers, UserAnswersSchema} from './answer';

export interface FormActualAnswer {
  uuid: string;
  version: string;
  label: string;
  value: number | null;
}

export const FormActualAnswerSchema: JSONSchemaType<FormActualAnswer> = {
  type: 'object',
  additionalProperties: false,
  required: ['uuid', 'label', 'value', 'version'],
  properties: {
    uuid: {
      type: 'string',
    },
    version: {
      type: 'string',
    },
    label: {
      type: 'string',
    },
    value: {anyOf: [{type: 'number'}, {type: 'null', nullable: true}]},
  },
} as const;

export enum Prefill {
  COMPANY_NAME = 'companyName',
  COMPANY_SIRET = 'companySIRET',
}

export enum ChapterType {
  DEFAULT = 'default',
  TITLE = 'title',
  SEPARATOR = 'separator',
}
export enum QuestionType {
  SELECT = 'select',
  TEXT = 'text',
}

export enum ItemType {
  QUESTION = 'question',
  FORMULA = 'formula',
  TITLE = 'title',
  SEPARATOR = 'separator',
}

export enum FormulaType {
  DEFAULT = 'default',
  CHAPTER = 'chapter',
  FORM = 'form',
}

export enum Visibility {
  VISIBLE = 'VISIBLE', // default when absent
  HIDEN_FOR_CONSULTANT = 'HIDEN_FOR_CONSULTANT',
}

export interface GenericChapter {
  uuid: string;
  chapterType?: ChapterType;
}
export interface TitleChapter extends GenericChapter {
  chapterType: ChapterType.TITLE;
  title: string;
}
export const TitleChapterSchema: JSONSchemaType<TitleChapter> = {
  type: 'object',
  additionalProperties: false,
  required: ['uuid', 'chapterType', 'title'],
  properties: {
    uuid: {type: 'string'},
    chapterType: {type: 'string', const: ChapterType.TITLE},
    title: {type: 'string'},
  },
};

export interface SeparatorChapter extends GenericChapter {
  chapterType: ChapterType.SEPARATOR;
  title?: string;
}
export const SeparatorChapterSchema: JSONSchemaType<SeparatorChapter> = {
  type: 'object',
  additionalProperties: false,
  required: ['uuid', 'chapterType'],
  properties: {
    uuid: {type: 'string'},
    chapterType: {type: 'string', const: ChapterType.SEPARATOR},
    title: {type: 'string', nullable: true},
  },
};

export interface Title {
  uuid: string;
  version: string;
  type: ItemType.TITLE;
  title: string;
}
const TitleSchema: JSONSchemaType<Title> = {
  type: 'object',
  additionalProperties: false,
  required: ['uuid', 'version', 'type', 'title'],
  properties: {
    uuid: {type: 'string'},
    version: {type: 'string'},
    type: {type: 'string', const: ItemType.TITLE},
    title: {type: 'string'},
  },
};

export interface Separator {
  uuid: string;
  version: string;
  type: ItemType.SEPARATOR;
  separator: string;
}
const SeparatorSchema: JSONSchemaType<Separator> = {
  type: 'object',
  additionalProperties: false,
  required: ['uuid', 'version', 'type', 'separator'],
  properties: {
    uuid: {type: 'string'},
    version: {type: 'string'},
    type: {type: 'string', const: ItemType.SEPARATOR},
    separator: {type: 'string'},
  },
};

export type ScoreTag = string;

export const ScoreTagSchema: JSONSchemaType<ScoreTag> = {
  type: 'string',
  pattern: '^#[A-Z0-9_]{3,15}$',
};

export type ScoreTags = Array<ScoreTag>;

const ScoreTagsSchema: JSONSchemaType<ScoreTags> = {
  type: 'array',
  items: ScoreTagSchema,
};

export interface Formula {
  uuid: string;
  version: string;
  type: ItemType.FORMULA;
  formula: string;
  title: string;
  tooltip?: string;
  formulaType?: FormulaType;
  usedInMainForm?: boolean;
  tags?: ScoreTags;
  reportLabel?: string;
  visibility?: Visibility;
}

const FormulaSchema: JSONSchemaType<Formula> = {
  type: 'object',
  additionalProperties: false,
  required: ['uuid', 'version', 'type', 'formula', 'title'],
  properties: {
    uuid: {type: 'string'},
    version: {type: 'string'},
    type: {type: 'string', const: ItemType.FORMULA},
    title: {type: 'string'},
    formula: {type: 'string'},
    tooltip: {type: 'string', nullable: true},
    formulaType: {
      type: 'string',
      enum: Object.values(FormulaType),
      nullable: true,
    },
    usedInMainForm: {type: 'boolean', nullable: true},
    tags: {...ScoreTagsSchema, nullable: true},
    reportLabel: {type: 'string', nullable: true},
    visibility: {
      type: 'string',
      nullable: true,
      enum: [Visibility.VISIBLE, Visibility.HIDEN_FOR_CONSULTANT],
    },
  },
};

export interface TextQuestion extends GenericQuestion {
  questionType: QuestionType.TEXT;
  numeric?: boolean;
  prelimTag?: ScoreTag;
  tags?: ScoreTags;
  reportLabel?: string;
}
const TextQuestionSchema: JSONSchemaType<TextQuestion> = {
  type: 'object',
  required: [
    'uuid',
    'version',
    'type',
    'title',
    'placeholder',
    'optional',
    'questionType',
  ],
  additionalProperties: false,
  properties: {
    uuid: {type: 'string'},
    version: {type: 'string'},
    type: {type: 'string', enum: [ItemType.QUESTION]},
    title: {type: 'string'},
    placeholder: {type: 'string'},
    optional: {type: 'boolean'},
    tooltip: {type: 'string', nullable: true},
    proofFactor: {type: 'string', nullable: true},
    logo: {type: 'string', nullable: true},
    disabled: {type: 'boolean', nullable: true},
    prefill: {
      type: 'string',
      nullable: true,
      enum: [Prefill.COMPANY_NAME, Prefill.COMPANY_SIRET],
    },
    questionType: {type: 'string', const: QuestionType.TEXT},
    numeric: {type: 'boolean', nullable: true},
    prelimTag: {...ScoreTagSchema, nullable: true},
    tags: {...ScoreTagsSchema, nullable: true},
    reportLabel: {type: 'string', nullable: true},
  },
};

export interface SelectQuestion extends GenericQuestion {
  questionType: QuestionType.SELECT;
  answers: Array<FormActualAnswer>;
  multiple: number;
  prelimTag?: ScoreTag;
  tags?: ScoreTags;
  reportLabel?: string;
}

const SelectQuestionSchema: JSONSchemaType<SelectQuestion> = {
  type: 'object',
  required: [
    'uuid',
    'version',
    'type',
    'title',
    'placeholder',
    'optional',
    'questionType',
    'answers',
    'multiple',
  ],
  additionalProperties: false,
  properties: {
    uuid: {type: 'string'},
    version: {type: 'string'},
    type: {type: 'string', enum: [ItemType.QUESTION]},
    title: {type: 'string'},
    placeholder: {type: 'string'},
    optional: {type: 'boolean'},
    tooltip: {type: 'string', nullable: true},
    proofFactor: {type: 'string', nullable: true},
    logo: {type: 'string', nullable: true},
    disabled: {type: 'boolean', nullable: true},
    prefill: {
      type: 'string',
      nullable: true,
      enum: [Prefill.COMPANY_NAME, Prefill.COMPANY_SIRET],
    },
    questionType: {type: 'string', const: QuestionType.SELECT},
    answers: {type: 'array', items: FormActualAnswerSchema},
    multiple: {type: 'number'},
    prelimTag: {...ScoreTagSchema, nullable: true},
    tags: {...ScoreTagsSchema, nullable: true},
    reportLabel: {type: 'string', nullable: true},
  },
};

export type Question = TextQuestion | SelectQuestion;
const QuestionSchema: JSONSchemaType<Question> = {
  oneOf: [SelectQuestionSchema, TextQuestionSchema],
};

export interface DefaultChapter extends GenericChapter {
  chapterType?: ChapterType.DEFAULT; // TODO for old data already in DB
  title: string;
  version: string;
  answeringIsOptional?: boolean;
  items: (Question | Formula | Title | Separator)[];
}

export const DefaultChapterSchema: JSONSchemaType<DefaultChapter> = {
  type: 'object',
  additionalProperties: false,
  required: ['uuid', 'version', 'title', 'items'],
  properties: {
    uuid: {type: 'string'},
    chapterType: {
      type: 'string',
      enum: [ChapterType.DEFAULT, null], // JSONSchemaType idiosyncrasy
      nullable: true, // JSONSchemaType idiosyncrasy
    },
    version: {type: 'string'},
    title: {type: 'string'},
    answeringIsOptional: {type: 'boolean', nullable: true},
    items: {
      type: 'array',
      items: {
        anyOf: [TitleSchema, SeparatorSchema, FormulaSchema, QuestionSchema],
      },
    },
  },
};

export interface AdminDefaultChapter extends DefaultChapter {}

export const AdminDefaultChapterSchema: JSONSchemaType<AdminDefaultChapter> = {
  type: 'object',
  additionalProperties: false,
  required: ['uuid', 'version', 'title', 'items'],
  properties: {
    uuid: {type: 'string'},
    chapterType: {
      type: 'string',
      enum: [ChapterType.DEFAULT, null],
      nullable: true,
    },
    version: {type: 'string'},
    title: {type: 'string'},
    answeringIsOptional: {type: 'boolean', nullable: true},
    items: {
      type: 'array',
      items: {
        anyOf: [TitleSchema, SeparatorSchema, FormulaSchema, QuestionSchema],
      },
    },
  },
};

export interface UserDefaultChapter extends DefaultChapter {
  items: (Question | Title | Separator)[];
}

export const UserDefaultChapterSchema: JSONSchemaType<UserDefaultChapter> = {
  type: 'object',
  additionalProperties: false,
  required: ['uuid', 'version', 'title', 'items'],
  properties: {
    uuid: {type: 'string'},
    chapterType: {
      type: 'string',
      enum: [ChapterType.DEFAULT, null], // JSONSchemaType idiosyncrasy
      nullable: true, // JSONSchemaType idiosyncrasy
    },
    version: {type: 'string'},
    title: {type: 'string'},
    answeringIsOptional: {type: 'boolean', nullable: true},
    items: {
      type: 'array',
      items: {
        anyOf: [TitleSchema, SeparatorSchema, QuestionSchema],
      },
    },
  },
};

export type Chapter = TitleChapter | SeparatorChapter | DefaultChapter;
export type AdminChapter =
  | TitleChapter
  | SeparatorChapter
  | AdminDefaultChapter;
export type UserChapter = TitleChapter | SeparatorChapter | UserDefaultChapter;

export const ChapterSchema: JSONSchemaType<Chapter> = {
  oneOf: [TitleChapterSchema, SeparatorChapterSchema, DefaultChapterSchema],
};
export const AdminChapterSchema: JSONSchemaType<AdminChapter> = {
  oneOf: [
    TitleChapterSchema,
    SeparatorChapterSchema,
    AdminDefaultChapterSchema,
  ],
};
export const UserChapterSchema: JSONSchemaType<UserChapter> = {
  oneOf: [UserDefaultChapterSchema],
};

//TODO: Remove check for undefined when all forms are converted to corret schema with chapterTypes on every chapter
export const isDefaultChapter = (chapter: Chapter): chapter is DefaultChapter =>
  !chapter.chapterType || chapter.chapterType === ChapterType.DEFAULT;

export const isTitleChapter = (chapter: Chapter): chapter is TitleChapter =>
  chapter.chapterType === ChapterType.TITLE;

export const isSeparatorChapter = (
  chapter: Chapter,
): chapter is SeparatorChapter => chapter.chapterType === ChapterType.SEPARATOR;

export interface CreatorDefaultChapter extends DefaultChapter {
  items: CreatorItem[];
  chapterKey?: number;
}
export interface CreatorTitleChapter extends TitleChapter {
  items: CreatorItem[];
  chapterKey?: number;
}
export interface CreatorSeparatorChapter extends SeparatorChapter {
  items: CreatorItem[];
  chapterKey?: number;
}

export type CreatorChapter =
  | CreatorDefaultChapter
  | CreatorSeparatorChapter
  | CreatorTitleChapter;

export type Item = Formula | Separator | Title | Question;
export type CreatorItem = Item & {itemKey?: number};

export interface GenericQuestion {
  uuid: string;
  version: string;
  type: ItemType.QUESTION;
  title: string;
  placeholder: string;
  optional: boolean;
  tooltip?: string;
  proofFactor?: string;
  logo?: string;
  disabled?: boolean;
  prefill?: Prefill;
}

export const GenericQuestionSchema: JSONSchemaType<GenericQuestion> = {
  type: 'object',
  required: [],
  properties: {
    uuid: {type: 'string'},
    version: {type: 'string'},
    type: {type: 'string', enum: [ItemType.QUESTION]},
    title: {type: 'string'},
    placeholder: {type: 'string'},
    optional: {type: 'boolean'},
    tooltip: {type: 'string', nullable: true},
    proofFactor: {type: 'string', nullable: true},
    logo: {type: 'string', nullable: true},
    disabled: {type: 'boolean', nullable: true},
    prefill: {
      type: 'string',
      nullable: true,
      enum: [Prefill.COMPANY_NAME, Prefill.COMPANY_SIRET],
    },
  },
};

export interface SelectAnswer {
  uuid: string;
  version: string;
  label: string;
  value: number;
}

export interface FormContentsType {
  uuid: string;
  version: string;
  title: string;
  description: string;
  priority?: number | null;
  patent?: boolean | null;
  mainFormId?: number | null;
  category?: FormCategory | null;
  color?: string;
  objectives?: string;
  themes?: string;
  contributors?: string;
  chapters: Chapter[];
}

const FormContentsTypeSchema: JSONSchemaType<FormContentsType> = {
  type: 'object',
  additionalProperties: false,
  required: ['uuid', 'version', 'title', 'description', 'chapters'],
  properties: {
    uuid: {type: 'string'},
    version: {type: 'string'},
    title: {type: 'string'},
    description: {type: 'string'},
    priority: {type: 'number', nullable: true},
    patent: {type: 'boolean', nullable: true},
    category: {
      type: 'string',
      enum: [
        FormCategory.HUMAN,
        FormCategory.RELATIONAL,
        FormCategory.STRUCTURAL,
        null,
      ],
      nullable: true,
    },
    mainFormId: {type: 'number', nullable: true},
    color: {type: 'string', nullable: true},
    objectives: {type: 'string', nullable: true},
    themes: {type: 'string', nullable: true},
    contributors: {type: 'string', nullable: true},
    chapters: {type: 'array', items: ChapterSchema},
  },
};

export interface AdminFormContentsType
  extends Omit<FormContentsType, 'chapters'> {
  chapters: AdminChapter[];
}

export const AdminFormContentsTypeSchema: JSONSchemaType<AdminFormContentsType> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['uuid', 'version', 'title', 'description', 'chapters'],
    properties: {
      uuid: {type: 'string'},
      version: {type: 'string'},
      title: {type: 'string'},
      description: {type: 'string'},
      priority: {type: 'number', nullable: true},
      patent: {type: 'boolean', nullable: true},
      category: {
        type: 'string',
        enum: [
          FormCategory.HUMAN,
          FormCategory.RELATIONAL,
          FormCategory.STRUCTURAL,
          null,
        ],
        nullable: true,
      },
      mainFormId: {type: 'number', nullable: true},
      color: {type: 'string', nullable: true},
      objectives: {type: 'string', nullable: true},
      themes: {type: 'string', nullable: true},
      contributors: {type: 'string', nullable: true},
      chapters: {type: 'array', items: AdminChapterSchema},
    },
  };

export interface UserFormContentsType
  extends Omit<FormContentsType, 'chapters'> {
  chapters: UserChapter[];
}

export const UserFormContentsTypeSchema: JSONSchemaType<AdminFormContentsType> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['uuid', 'version', 'title', 'description', 'chapters'],
    properties: {
      uuid: {type: 'string'},
      version: {type: 'string'},
      title: {type: 'string'},
      description: {type: 'string'},
      priority: {type: 'number', nullable: true},
      patent: {type: 'boolean', nullable: true},
      category: {
        type: 'string',
        enum: [
          FormCategory.HUMAN,
          FormCategory.RELATIONAL,
          FormCategory.STRUCTURAL,
          null,
        ],
        nullable: true,
      },
      mainFormId: {type: 'number', nullable: true},
      color: {type: 'string', nullable: true},
      objectives: {type: 'string', nullable: true},
      themes: {type: 'string', nullable: true},
      contributors: {type: 'string', nullable: true},
      chapters: {type: 'array', items: UserChapterSchema},
    },
  };

export interface CreatorForm extends FormContentsType {
  chapters: CreatorChapter[];
}

export const isQuestion = (item: Item): item is Question =>
  item.type === ItemType.QUESTION;

export const isFormula = (item: Item): item is Formula =>
  item.type === ItemType.FORMULA;

export const isSelectQuestion = (
  question: Question,
): question is SelectQuestion => question.questionType === QuestionType.SELECT;

export const isTextQuestion = (
  question: Question | CreatorItem,
): question is TextQuestion =>
  (question as TextQuestion).questionType === QuestionType.TEXT;

export interface CreateFormSchemaType {
  initialVersionId?: number;
  minorVersion: number;
  patchVersion: number;
  schemaVersion: number;
  priority?: number | null;
  category?: FormCategory;
  isPreliminary?: boolean;
  mainFormId?: number | null;
  contents: object;
}

export const CreateFormSchema: JSONSchemaType<CreateFormSchemaType> = {
  type: 'object',
  additionalProperties: false,
  required: ['minorVersion', 'patchVersion', 'schemaVersion', 'contents'],
  properties: {
    initialVersionId: {type: 'number', nullable: true},
    minorVersion: {type: 'number'},
    patchVersion: {type: 'number'},
    schemaVersion: {type: 'number'},
    priority: {type: 'number', nullable: true},
    category: {
      type: 'string',
      enum: [
        FormCategory.HUMAN,
        FormCategory.RELATIONAL,
        FormCategory.STRUCTURAL,
        null,
      ],
      nullable: true,
    },
    isPreliminary: {type: 'boolean', nullable: true},
    mainFormId: {type: 'number', nullable: true},
    contents: {type: 'object'},
  },
};

// --------------------------------------------

export interface SubForm {
  id: number;
  title: string;
}
export interface PreliminaryForm {
  id: number;
  title: string;
  description?: string;
  isPreliminary?: boolean;
  color?: string;
  subForms: Array<SubForm>;
}
export const PreliminaryFormSchema: JSONSchemaType<PreliminaryForm> = {
  type: 'object',
  nullable: true,
  additionalProperties: false,
  required: ['id', 'title'],
  properties: {
    id: {type: 'number'},
    title: {type: 'string'},
    description: {type: 'string', nullable: true},
    isPreliminary: {type: 'boolean', nullable: true},
    color: {type: 'string', nullable: true},
    subForms: {
      type: 'array',
      items: {
        type: 'object',
        additionalProperties: false,
        required: ['id', 'title'],
        properties: {
          id: {type: 'number'},
          title: {type: 'string'},
        },
      },
    },
  },
};
export interface FormTitleWithSubForms {
  id: number;
  title: string;
  description?: string;
  isPreliminary?: boolean;
  color?: string;
  subForms: Array<SubForm>;
}
export const FormTitleWithSubFormsSchema: JSONSchemaType<FormTitleWithSubForms> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['id', 'title', 'subForms'],
    properties: {
      id: {type: 'number'},
      title: {type: 'string'},
      description: {type: 'string', nullable: true},
      isPreliminary: {type: 'boolean', nullable: true},
      color: {type: 'string', nullable: true},
      subForms: {
        type: 'array',
        items: {
          type: 'object',
          additionalProperties: false,
          required: ['id', 'title'],
          properties: {
            id: {type: 'number'},
            title: {type: 'string'},
          },
        },
      },
    },
  };

// --------------------------------- getAllActifs ---------
export const getAllActifsPath = '/forms/getAllActifs';

export interface GetAllActifsO {
  preliminaryForm: PreliminaryForm | null;
  formTitles: Array<FormTitleWithSubForms>;
}
export const GetAllActifsOSchema: JSONSchemaType<GetAllActifsO> = {
  type: 'object',
  additionalProperties: false,
  required: ['formTitles', 'preliminaryForm'],
  properties: {
    preliminaryForm: PreliminaryFormSchema,
    formTitles: {type: 'array', items: FormTitleWithSubFormsSchema},
  },
};

// --------------------------------- getFormsSummaries ---------

export const getFormsSummariesPath = '/rpc/getFormsSummaries';
export interface GetFormsSummariesI {
  formIds: Array<number>;
}
export const GetFormsSummariesISchema: JSONSchemaType<GetFormsSummariesI> = {
  type: 'object',
  additionalProperties: false,
  required: ['formIds'],
  properties: {
    formIds: {type: 'array', items: {type: 'number'}},
  },
};

export interface FormSummarySimple {
  formId: number;
  contents: FormContentsType;
  answers: Array<{
    id: number;
    userId: number;
    contents: UserAnswers;
  }>;
}
type TemplateInstancesValue = {
  id: number;
  userId: number;
  contents: UserAnswers;
};
type TemplateInstances = {
  [key: string]: TemplateInstancesValue[];
};
export const TemplateInstanceValueSchema: JSONSchemaType<TemplateInstancesValue> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['id', 'userId', 'contents'],
    properties: {
      id: {type: 'number'},
      patentId: {type: 'number'},
      userId: {type: 'number'},
      contents: UserAnswersSchema,
    },
  };
export const TemplateInstancesSchema: JSONSchemaType<TemplateInstances> = {
  type: 'object',
  required: [],
  propertyNames: {type: 'string'},
  additionalProperties: {
    type: 'array',
    items: TemplateInstanceValueSchema,
  },
};
export interface TemplateSummarySimple {
  formId: number;
  contents: FormContentsType;
  instances: TemplateInstances;
}
export interface FormSummaryWithTemplates extends FormSummarySimple {
  templates?: TemplateSummarySimple[];
}
export const TemplateSummarySimpleSchema: JSONSchemaType<TemplateSummarySimple> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['formId', 'contents'],
    properties: {
      formId: {type: 'number'},
      contents: FormContentsTypeSchema,
      instances: TemplateInstancesSchema,
    },
  };
export const FormSummarySimpleSchema: JSONSchemaType<FormSummarySimple> = {
  type: 'object',
  additionalProperties: false,
  required: ['formId', 'contents'],
  properties: {
    formId: {type: 'number'},
    contents: FormContentsTypeSchema,
    answers: {
      type: 'array',
      items: {
        type: 'object',
        additionalProperties: false,
        required: ['id', 'userId', 'contents'],
        properties: {
          id: {type: 'number'},
          userId: {type: 'number'},
          contents: UserAnswersSchema,
        },
      },
    },
  },
};
const FormSummaryWithTemplatesSchema: JSONSchemaType<FormSummaryWithTemplates> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['formId', 'contents'],
    properties: {
      formId: {type: 'number'},
      contents: FormContentsTypeSchema,
      answers: {
        type: 'array',
        items: {
          type: 'object',
          additionalProperties: false,
          required: ['id', 'userId', 'contents'],
          properties: {
            id: {type: 'number'},
            userId: {type: 'number'},
            contents: UserAnswersSchema,
          },
        },
      },
      templates: {
        type: 'array',
        items: TemplateSummarySimpleSchema,
        nullable: true,
      },
    },
  };

export interface GetFormsSummariesO {
  forms: Array<FormSummaryWithTemplates>;
}

export const GetFormsSummariesOSchema: JSONSchemaType<GetFormsSummariesO> = {
  type: 'object',
  additionalProperties: false,
  required: ['forms'],
  properties: {
    forms: {type: 'array', items: FormSummaryWithTemplatesSchema},
  },
};

// ------------------------------------------

// https://github.com/ajv-validator/ajv/issues/1375#issuecomment-1369252297
const nullable = <T>(input: T): T => {
  return {
    ...input,
    nullable: true,
  } as T;
};

export interface GetActivePackFormTitlesO {
  preliminaryForm: PreliminaryForm | null;
  formTitles: Array<FormTitleWithSubForms>;
  packId: number | null;
  companyId: number | null;
  readOnly: boolean;
}
export const GetActivePackFormTitlesOSchema: JSONSchemaType<GetActivePackFormTitlesO> =
  {
    type: 'object',
    additionalProperties: false,
    required: [
      'preliminaryForm',
      'formTitles',
      'readOnly',
      'packId',
      'companyId',
    ],
    properties: {
      preliminaryForm: PreliminaryFormSchema,
      formTitles: {type: 'array', items: FormTitleWithSubFormsSchema},
      packId: nullable({type: 'integer'}),
      companyId: nullable({type: 'integer'}),
      readOnly: {type: 'boolean'},
    },
  };

export interface GetSingularForm {
  id: number;
  minorVersion: number;
  patchVersion: number;
  schemaVersion: number;
  contents: FormContentsType;
  priority?: number | null;
  isPreliminary?: boolean;
  mainFormId?: number | null;
  category?: FormCategory;
  createdAt: string;
  updatedAt: string;
  nbrLinkedPacks: number;
  readOnly: boolean;
  templates: Array<{id: number; title: string}>;
}

export const GetSingularFormSchema: JSONSchemaType<GetSingularForm> = {
  type: 'object',
  additionalProperties: false,
  required: [
    'id',
    'minorVersion',
    'patchVersion',
    'schemaVersion',
    'contents',
    'createdAt',
    'updatedAt',
    'nbrLinkedPacks',
    'readOnly',
    'templates',
  ],
  properties: {
    id: {type: 'integer'},
    minorVersion: {type: 'integer'},
    patchVersion: {type: 'integer'},
    schemaVersion: {type: 'integer'},
    contents: FormContentsTypeSchema,
    createdAt: {type: 'string'},
    updatedAt: {type: 'string'},
    readOnly: {type: 'boolean'},
    priority: {type: 'integer', nullable: true},
    isPreliminary: {type: 'boolean', nullable: true},
    mainFormId: {type: 'integer', nullable: true},
    category: {
      type: 'string',
      enum: [
        FormCategory.HUMAN,
        FormCategory.RELATIONAL,
        FormCategory.STRUCTURAL,
      ],
      nullable: true,
    },
    templates: {
      type: 'array',
      items: {
        type: 'object',
        additionalProperties: false,
        required: ['id', 'title'],
        properties: {
          id: {type: 'integer'},
          title: {type: 'string'},
        },
      },
    },
    nbrLinkedPacks: {type: 'number'},
  },
};

export interface GetPackAvailableFormsO {
  linkedForms: {title: string; id: number; isPreliminary: boolean}[];
  availableForms: {title: string; id: number; isPreliminary: boolean}[];
}

// ------------------------------------------

export interface GetSearchedFormTitlesO {
  entities: Array<{
    id: number;
    title: string;
  }>;
  total: number;
}

export const GetSearchedFormTitlesOSchema: JSONSchemaType<GetSearchedFormTitlesO> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['entities', 'total'],
    properties: {
      entities: {
        type: 'array',
        items: {
          type: 'object',
          additionalProperties: false,
          required: ['id', 'title'],
          properties: {
            id: {type: 'integer'},
            title: {type: 'string'},
          },
        },
      },
      total: {type: 'integer'},
    },
  };

export interface GetSearchedFormTitlesQuery {
  filters?: string;
  skip?: string;
  limit?: string;
}

export const GetSearchedFormTitlesQuerySchema: JSONSchemaType<GetSearchedFormTitlesQuery> =
  {
    type: 'object',
    additionalProperties: false,
    required: [],
    properties: {
      filters: {type: 'string', nullable: true},
      skip: {type: 'string', nullable: true},
      limit: {type: 'string', nullable: true},
    },
  };

// ------------------------------------------ api ----------------

export interface GetSingularFormQuery {
  role?: Role;
}

export const GetSingularFormQuerySchema: JSONSchemaType<GetSingularFormQuery> =
  {
    type: 'object',
    additionalProperties: false,
    required: [],
    properties: {
      role: {
        type: 'string',
        nullable: true,
        enum: [
          Role.ADMIN,
          Role.AUDITOR,
          Role.COMPANY_ADMIN,
          Role.CONSULTANT,
          Role.USER,
        ],
      },
    },
  };

export interface GetPluralFormO {
  entities: Array<
    Omit<
      GetSingularForm,
      'contents' | 'packId' | 'readOnly' | 'templates' | 'nbrLinkedPacks'
    >
  >;
  total: number;
}

const GetSingularFormSchemaOmitContents: JSONSchemaType<
  Omit<
    GetSingularForm,
    'contents' | 'packId' | 'readOnly' | 'templates' | 'nbrLinkedPacks'
  >
> = {
  type: 'object',
  additionalProperties: false,
  required: [
    'id',
    'minorVersion',
    'patchVersion',
    'schemaVersion',
    'createdAt',
    'updatedAt',
  ],
  properties: {
    id: {type: 'integer'},
    minorVersion: {type: 'integer'},
    patchVersion: {type: 'integer'},
    schemaVersion: {type: 'integer'},
    createdAt: {type: 'string'},
    updatedAt: {type: 'string'},
    priority: {type: 'integer', nullable: true},
    isPreliminary: {type: 'boolean', nullable: true},
    mainFormId: {type: 'integer', nullable: true},
    category: {
      type: 'string',
      enum: [
        FormCategory.HUMAN,
        FormCategory.RELATIONAL,
        FormCategory.STRUCTURAL,
      ],
      nullable: true,
    },
  },
};

export const GetPluralFormOSchema: JSONSchemaType<GetPluralFormO> = {
  type: 'object',
  additionalProperties: false,
  required: ['entities', 'total'],
  properties: {
    entities: {type: 'array', items: GetSingularFormSchemaOmitContents},
    total: {type: 'integer'},
  },
};

export interface CreateSingularO {
  createdFormId: number;
}

export const CreateSingularOSchema: JSONSchemaType<CreateSingularO> = {
  type: 'object',
  additionalProperties: false,
  required: ['createdFormId'],
  properties: {
    createdFormId: {type: 'integer'},
  },
};

export interface UpdateSingularO {
  updatedFormId: number;
}

export const UpdateSingularOSchema: JSONSchemaType<UpdateSingularO> = {
  type: 'object',
  additionalProperties: false,
  required: ['updatedFormId'],
  properties: {
    updatedFormId: {type: 'integer'},
  },
};

interface PatentAnswer {
  id: number;
  contents: UserAnswers;
  userId: number;
  patentId: number;
}

const PatentAnswerSchema: JSONSchemaType<PatentAnswer> = {
  type: 'object',
  additionalProperties: false,
  required: ['id', 'contents', 'userId', 'patentId'],
  properties: {
    id: {type: 'integer'},
    contents: UserAnswersSchema,
    userId: {type: 'integer'},
    patentId: {type: 'integer'},
  },
};

export interface GetAllFormPatentAnswersO {
  entities: Array<PatentAnswer>;
}

export const GetAllFormPatentAnswersOSchema: JSONSchemaType<GetAllFormPatentAnswersO> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['entities'],
    properties: {
      entities: {type: 'array', items: PatentAnswerSchema},
    },
  };

export interface GetAllGroupsWithAccessToFormPath {
  id: string;
  companyId: string;
}

export const GetAllGroupsWithAccessToFormPathSchema: JSONSchemaType<GetAllGroupsWithAccessToFormPath> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['id', 'companyId'],
    properties: {
      id: {type: 'string'},
      companyId: {type: 'string'},
    },
  };

export interface GetAllGroupsWithAccessToFormO {
  entities: Array<string>;
}

export const GetAllGroupsWithAccessToFormOSchema: JSONSchemaType<GetAllGroupsWithAccessToFormO> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['entities'],
    properties: {
      entities: {type: 'array', items: {type: 'string'}},
    },
  };

export interface GetTemplatesLinkedToFormO {
  entities: Array<{id: number; title: string}>;
}

export const GetTemplatesLinkedToFormOSchema: JSONSchemaType<GetTemplatesLinkedToFormO> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['entities'],
    properties: {
      entities: {
        type: 'array',
        items: {
          type: 'object',
          additionalProperties: false,
          required: ['id', 'title'],
          properties: {
            id: {type: 'integer'},
            title: {type: 'string'},
          },
        },
      },
    },
  };
