import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  List,
  message,
  Popconfirm,
  Row,
  Space,
  TableProps,
  theme,
  Tooltip,
  Typography,
} from 'antd';
import {FilterDropdownProps, SortOrder} from 'antd/es/table/interface';
import {Key, useCallback, useEffect, useState} from 'react';

import {
  createCompanyConsultantUser,
  deleteCompanyConsultantUser,
  getConsultantLinkedCompanies,
} from './client/company';
import {deleteUser, getPluralConsultants} from './client/user';
import {DynamicSelect} from './components/ui/DynamicSelect';
import {FilterSeachText} from './components/ui/FilterSearchText';
import {GenericTable} from './components/ui/GenericTable';
import {AssignModal} from './components/ui/modals/AssignModal';
import {ConsultantsModal} from './components/ui/modals/ConsultantsModal';
import {TablePopOver} from './components/ui/TablePopOver';
import {ResendTempPasswordButton} from './components/ui/viewer/ResendEmailButton';
import {useUserStore} from './stores/user';
import {useApi} from './util/auth';

export interface ConsultantsTableDataType {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
}

export const Consultants = () => {
  const [assignForm] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(true);
  const api = useApi();
  const companyId = useUserStore((state) => state.companyId);
  const {token} = theme.useToken();

  const [consultants, setConsultants] = useState<ConsultantsTableDataType[]>(
    [],
  );
  const [currentSort, setCurrentSort] = useState<
    | {
        column: Key | readonly Key[] | undefined;
        order: SortOrder | undefined;
      }
    | undefined
  >({column: 'lastName', order: 'ascend'});
  const usePagination = useState<{
    pageNumber: number;
    pageSize: number;
  }>({pageNumber: 1, pageSize: 10});
  const [searchText, setSearchText] = useState<
    {[key: string]: string} | undefined
  >(undefined);
  const [totalConsultants, setTotalConsultants] = useState<number>();
  const [pagination] = usePagination;
  const [currentId, setCurrentId] = useState<number | 'new' | undefined>(
    undefined,
  );
  const [editedConsultant, setEditedConsultant] = useState<
    ConsultantsTableDataType | undefined
  >(undefined);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);

  const columns: TableProps<ConsultantsTableDataType>['columns'] = [
    {
      title: 'Nom',
      dataIndex: 'lastName',
      defaultSortOrder: 'ascend',
      sorter: true,
      filterDropdown: (props: FilterDropdownProps) => (
        <FilterSeachText
          dataIndex="lastName"
          setSearchText={setSearchText}
          {...props}
        />
      ),
    },
    {
      title: 'Prénom',
      dataIndex: 'firstName',
      sorter: true,
      filterDropdown: (props: FilterDropdownProps) => (
        <FilterSeachText
          dataIndex="firstName"
          setSearchText={setSearchText}
          {...props}
        />
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: true,
      filterDropdown: (props: FilterDropdownProps) => (
        <FilterSeachText
          dataIndex="email"
          setSearchText={setSearchText}
          {...props}
        />
      ),
    },
    {
      title: 'Entreprises Consultées',
      dataIndex: ['_count', 'consultedCompanies'],
      render: (value, record) => {
        if (!api) {
          return;
        }
        if (value === 0) {
          return <Typography>Aucune entreprise</Typography>;
        }
        return (
          <TablePopOver
            title={`Liste des entreprises associées au consultant "${record.firstName} ${record.lastName}"`}
            onOpen={async () => {
              if (value === 0) {
                return;
              }
              const companies = await getConsultantLinkedCompanies({
                api,
                userId: record.id,
              });
              return (
                <List>
                  {companies.entities.map((companyData, index: number) => {
                    return (
                      <List.Item key={index}>
                        <Row style={{width: '100%'}}>
                          <Col flex={1}>{companyData.company.name}</Col>
                          <Col flex={0}>
                            <Popconfirm
                              title={
                                <div>
                                  Êtes-vous sûr de vouloir délier{' '}
                                  <span style={{color: token.colorErrorText}}>
                                    {companyData.company.name}
                                  </span>{' '}
                                  ?
                                </div>
                              }
                              onConfirm={async () => {
                                await deleteCompanyConsultantUser({
                                  api,
                                  companyId: companyData.companyId,
                                  userId: record.id,
                                });
                                void getConsultants();
                              }}
                            >
                              <Button icon={<DeleteOutlined />} danger />
                            </Popconfirm>
                          </Col>
                        </Row>
                      </List.Item>
                    );
                  })}
                </List>
              );
            }}
          >
            <Typography.Link>
              {`${value} entreprise${value === 1 ? '' : 's'}`}
            </Typography.Link>
          </TablePopOver>
        );
      },
    },
    {
      title: 'Actions',
      width: 200,
      align: 'center',
      render: (_v, record) => {
        return (
          <Space>
            <Tooltip title="Assigner ce consultant à une nouvelle entreprise">
              <Button
                icon={<PlusCircleOutlined />}
                onClick={() => {
                  setEditedConsultant(record);
                  setIsAssignModalOpen(true);
                }}
              />
            </Tooltip>
            <Tooltip title="Éditer le consultant">
              <Button
                icon={<EditOutlined />}
                onClick={async () => {
                  setEditedConsultant(record);
                  setCurrentId(record.id);
                }}
              />
            </Tooltip>
            <ResendTempPasswordButton resourceId={record.id} />
            <Tooltip title="Supprimer ce consultant">
              <Popconfirm
                title="Suprimer le consultant"
                description="Êtes-vous sûr de vouloir supprimer ce consultant ?"
                onConfirm={async () => {
                  if (!api) {
                    return;
                  }
                  const res = await deleteUser({api, userId: record.id});
                  if (!res.count || res.count !== 1) {
                    void message.error(
                      `Le consultant ${record.firstName} ${record.lastName} n'a pas pu être supprimé, veuillez réessayer.`,
                    );
                  }
                  void message.success(
                    `Le consultant ${record.firstName} ${record.lastName} a été supprimé avec succès !`,
                  );
                  void getConsultants();
                }}
              >
                <Button danger icon={<DeleteOutlined />} />
              </Popconfirm>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const getConsultants = useCallback(
    async (_companyId?: number) => {
      if (!api) {
        return;
      }
      setLoading(true);
      const data = await getPluralConsultants({
        api,
        pagination,
        searchText,
        sort: currentSort,
      });
      setConsultants(data.entities);
      setTotalConsultants(data.total);
      setLoading(false);
    },
    [api, pagination, searchText, currentSort],
  );

  useEffect(() => {
    void getConsultants(companyId);
  }, [getConsultants, companyId]);

  return (
    <>
      <Row style={{marginBottom: 16}} justify="space-between" align="middle">
        <Col>
          <Typography.Title style={{margin: 0}}>
            Gestion des consultants
          </Typography.Title>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              setCurrentId('new');
            }}
          >
            Créer un nouveau consultant
          </Button>
        </Col>
      </Row>
      <Row>
        <GenericTable
          columns={columns}
          loading={loading}
          total={totalConsultants}
          dataSource={consultants}
          setCurrentSort={setCurrentSort}
          usePagination={usePagination}
        />
      </Row>
      <ConsultantsModal
        id={currentId}
        editedConsultant={editedConsultant}
        onCancel={() => {
          setCurrentId(undefined);
          setEditedConsultant(undefined);
        }}
        onOk={() => {
          setCurrentId(undefined);
          setEditedConsultant(undefined);
          void getConsultants();
        }}
      />
      <AssignModal
        title={`Assignation du consultant ${editedConsultant?.firstName} ${editedConsultant?.lastName} à une entreprise`}
        open={isAssignModalOpen}
        content={
          <Form
            form={assignForm}
            onFinish={async (value) => {
              if (!api) {
                return;
              }
              await createCompanyConsultantUser({
                api,
                input: {...value, userId: editedConsultant?.id},
              });
              void getConsultants();
              assignForm.resetFields();
            }}
          >
            <Form.Item required label="Entreprises" name="companyId">
              <DynamicSelect<{id: number; name: string}>
                api={api}
                url={`/companyConsultantUser/${editedConsultant?.id}/availableCompanies`}
              />
            </Form.Item>
          </Form>
        }
        onOk={() => {
          void assignForm.validateFields();
          assignForm.submit();
          setEditedConsultant(undefined);
          setIsAssignModalOpen(false);
        }}
        onCancel={() => {
          assignForm.resetFields();
          setEditedConsultant(undefined);
          setIsAssignModalOpen(false);
        }}
      />
    </>
  );
};
