import {JSONSchemaType} from 'ajv';

const keyValuesMatch = <T>(_kv: {[K in keyof T]: K}) => {};

export enum Actif {
  Clients = 'Clients',
  Collaborateurs = 'Collaborateurs',
  Gouvernance = 'Gouvernance',
  Actionnariat = 'Actionnariat',
  Partenaires = 'Partenaires',
  Organisation = 'Organisation',
  Savoirs = 'Savoirs',
  Marque = 'Marque',
  EcosystemeSocietal = 'EcosystemeSocietal',
  SystemeInformation = 'SystemeInformation',
  Naturel = 'Naturel',
  MaterielFinancier = 'MaterielFinancier',
}

keyValuesMatch(Actif);

export enum SecteurActivite {
  Luxe = 'Luxe',
  SousTraitanceLuxe = 'SousTraitanceLuxe',
  GrandeConsoSimple = 'GrandeConsoSimple',
  GrandeConsoComnplexe = 'GrandeConsoComnplexe',
  Industrie = 'Industrie',
  Agriculture = 'Agriculture',
  Energie = 'Energie',
  EauDechets = 'EauDechets',
  Construction = 'Construction',
  Commerce = 'Commerce',
  LogistiqueTransport = 'LogistiqueTransport',
  HotellerieRestauration = 'HotellerieRestauration',
  PrestationIntellectuelles = 'PrestationIntellectuelles',
  BanqueAssurances = 'BanqueAssurances',
  Immobilier = 'Immobilier',
  RemonteesMecaniques50 = 'RemonteesMecaniques50',
  RemonteesMecaniques = 'RemonteesMecaniques',
  AutresServices = 'AutresServices',
  Administrations = 'Administrations',
  AutresSecteurs = 'AutresSecteurs',
}

keyValuesMatch(SecteurActivite);

export enum MatType {
  POIDSACTIFS = 'POIDSACTIFS',
  INDICE_DURABLE = 'INDICE_DURABLE',
}
keyValuesMatch(MatType);

export type ItemPA = {
  value: number;
  formId: number;
};

export type ColsPA = Array<{
  secteurUuid: string;
  rows: Array<ItemPA>;
}>;

const ColsPASchema: JSONSchemaType<ColsPA> = {
  type: 'array',
  items: {
    type: 'object',
    required: ['rows', 'secteurUuid'],
    additionalProperties: false,
    properties: {
      secteurUuid: {type: 'string'},
      rows: {
        type: 'array',
        items: {
          type: 'object',
          required: ['value', 'formId'],
          additionalProperties: false,
          properties: {
            value: {type: 'number'},
            formId: {type: 'number'},
          },
        },
      },
    },
  },
};

export interface MatrixPA {
  name: string;
  description?: string;
  mat2d: true;
  matType: MatType.POIDSACTIFS;
  cols: ColsPA;
}

export const MatrixPASchema: JSONSchemaType<MatrixPA> = {
  type: 'object',
  required: ['name', 'mat2d', 'matType', 'cols'],
  properties: {
    name: {type: 'string'},
    description: {type: 'string', nullable: true},
    mat2d: {type: 'boolean', const: true},
    matType: {type: 'string', const: MatType.POIDSACTIFS},
    cols: ColsPASchema,
  },
};

export const actifLabel = (actif: Actif) => {
  switch (actif) {
    case Actif.Actionnariat:
      return 'Actionnariat';
    case Actif.Clients:
      return 'Clients';
    case Actif.Collaborateurs:
      return 'Collaborateurs';
    case Actif.EcosystemeSocietal:
      return 'Ecosystéme Societal-environnemental';
    case Actif.Gouvernance:
      return 'Gouvernance';
    case Actif.Marque:
      return 'Marque';
    case Actif.MaterielFinancier:
      return 'Materiel & financier';
    case Actif.Naturel:
      return 'Naturel';
    case Actif.Organisation:
      return 'Organisation';
    case Actif.Partenaires:
      return 'Partenaires';
    case Actif.Savoirs:
      return 'Savoirs';
    case Actif.SystemeInformation:
      return "Systéme d'information";
  }
};

export const secteurLabel = (secteur: SecteurActivite) => {
  switch (secteur) {
    case SecteurActivite.Administrations:
      return 'Administrations';
    case SecteurActivite.Agriculture:
      return 'Agriculture';
    case SecteurActivite.AutresSecteurs:
      return 'Autres Secteurs';
    case SecteurActivite.AutresServices:
      return 'Autres Services';
    case SecteurActivite.BanqueAssurances:
      return 'Banque Assurances';
    case SecteurActivite.Commerce:
      return 'Commerce';
    case SecteurActivite.Construction:
      return 'Construction';
    case SecteurActivite.EauDechets:
      return 'Eau Déchets';
    case SecteurActivite.Energie:
      return 'Energie';
    case SecteurActivite.GrandeConsoComnplexe:
      return 'Grande Conso Comnplexe';
    case SecteurActivite.GrandeConsoSimple:
      return 'Grande Conso Simple';
    case SecteurActivite.HotellerieRestauration:
      return 'Hotellerie Restauration';
    case SecteurActivite.Immobilier:
      return 'Immobilier';
    case SecteurActivite.Industrie:
      return 'Industrie';
    case SecteurActivite.LogistiqueTransport:
      return 'Logistique Transport';
    case SecteurActivite.Luxe:
      return 'Luxe';
    case SecteurActivite.PrestationIntellectuelles:
      return 'Prestation Intellectuelles';
    case SecteurActivite.RemonteesMecaniques:
      return 'Remontées Mécaniques';
    case SecteurActivite.RemonteesMecaniques50:
      return 'Remontées Mécaniques 50%';
    case SecteurActivite.SousTraitanceLuxe:
      return 'Sous Traitance Luxe';
  }
};
