import {UploadOutlined} from '@ant-design/icons';
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  ModalProps,
  Upload,
  UploadFile,
} from 'antd';
import {RcFile} from 'antd/es/upload';
import {
  CompanySchemaType,
  CreateCompanyResponseSchemaType,
  Role,
  UpdateCompanyRequestSchemaType,
  UpdateCompanyResponseSchemaType,
  UploadFileSchemaType,
} from 'holo-api';
import {FC, useEffect, useState} from 'react';

import {createCompany, updateCompany} from '../../../client/company';
import {useApi} from '../../../util/auth';
import {DynamicSelect} from '../DynamicSelect';

interface CompanyModalProps extends ModalProps {
  id: number | 'new' | undefined;
  editedCompany?: Pick<CompanySchemaType, 'name' | 'siret'> | null | undefined;
}

interface FormData {
  company: {
    name: string;
    siret: string;
    activePackId?: number;
    photoFiles?: {
      file: UploadFile;
      fileList: Array<UploadFile>;
    };
  };
  admin: {
    firstName: string;
    lastName: string;
    email: string;
  };
}

export const CompanyModal: FC<CompanyModalProps> = ({
  id,
  editedCompany,
  ...props
}) => {
  const [form] = Form.useForm<FormData>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const api = useApi();

  useEffect(() => {
    if (!id) {
      return;
    }
    if (id === 'new') {
      form.setFieldsValue({
        company: {
          name: '',
          siret: '',
        },
        admin: {
          firstName: '',
          lastName: '',
          email: '',
        },
      });
    }
    if (typeof id === 'number') {
      form.setFieldsValue({
        company: {
          name: editedCompany?.name,
          siret: editedCompany?.siret,
        },
      });
    }
  }, [id, editedCompany, form]);

  return (
    <Modal
      title={
        id === 'new'
          ? "Création d'une entreprise"
          : typeof id === 'number'
            ? `Édition de l'entreprise ${editedCompany?.name}`
            : undefined
      }
      centered
      confirmLoading={isLoading}
      forceRender
      maskClosable={false}
      open={!!id}
      width="600px"
      {...props}
      onOk={async (e) => {
        setIsLoading(true);
        try {
          const value = await form.validateFields();
          if (!api) {
            return;
          }
          const {photoFiles, ...companyData} = value.company;
          let photoFileMetadata: UploadFileSchemaType | undefined;
          let photoFile: UploadFile | undefined;
          if (photoFiles) {
            photoFile = photoFiles?.file;
            photoFileMetadata = {name: photoFile.name, type: photoFile.type};
          }

          let res:
            | UpdateCompanyResponseSchemaType
            | CreateCompanyResponseSchemaType
            | undefined;
          if (id === 'new') {
            try {
              res = await createCompany({
                api,
                company: {...companyData, photoFileMetadata},
                admin: value.admin,
              });
            } catch (err) {
              void message.error(
                "Une erreur est survenue sur le serveur lors de la création de l'entreprise et du compte de l'administrateur. Veuillez vérifier l'adresse email et réessayer",
                5000,
              );
              throw err;
            }
          } else if (typeof id === 'number') {
            const body: UpdateCompanyRequestSchemaType = {
              name: companyData.name,
              siret: companyData.siret,
              photoFileMetadata,
            };
            res = await updateCompany({api, companyId: id, input: body});
          } else {
            throw new Error(`id is either new or a number not ${id}`);
          }

          const photoSignedUrl = res.photoSignedUrl;

          if (photoFile && photoSignedUrl) {
            const headers: HeadersInit = {
              'Content-Disposition': `attachment; filename=${encodeURIComponent(photoFile.name)}`,
            };
            if (photoFile.type) {
              headers['Content-Type'] = photoFile.type;
            }

            await fetch(photoSignedUrl, {
              method: 'PUT',
              body: photoFile.originFileObj,
              headers,
            });
          }

          form.resetFields();
          setIsLoading(false);
          props.onOk?.(e);
        } catch (err) {
          console.error(err);
          setIsLoading(false);
        }
      }}
      onCancel={(e) => {
        form.resetFields();
        props.onCancel?.(e);
      }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Nom"
          name={['company', 'name']}
          required
          rules={[
            {
              validateTrigger: 'onOk',
              validator: (_r, value) => {
                if (value.trim() === '') {
                  return Promise.reject(
                    "Veuillez donner un nom à l'entreprise",
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Numéro SIRET"
          name={['company', 'siret']}
          required
          rules={[
            {
              validateTrigger: 'onOk',
              validator: (_r, value: string) => {
                // TODO: check that it is a number (only digits) Monaco Siret have letters?
                if (value.trim() === '') {
                  return Promise.reject(
                    "Veuillez remplir le numéro SIRET de l'entreprise",
                  );
                }
                if (value.trim().length !== 14) {
                  return Promise.reject(
                    'Un numéro SIRET doit avoir 14 chiffres',
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Logo de l'entreprise"
          name={['company', 'photoFiles']}
        >
          <Upload
            accept="image/png, image/jpg, image/jpeg"
            showUploadList={{
              showRemoveIcon: true,
              showDownloadIcon: false,
              showPreviewIcon: false,
            }}
            maxCount={1}
            customRequest={({onSuccess}) => onSuccess?.(true)}
            beforeUpload={(file: RcFile) => {
              const fileSizeLimit = 2 * 1024 * 1024;
              if (file.size > fileSizeLimit) {
                void message.error(
                  `Image must smaller than 2MB! not ${file.size}`,
                );
                return false;
              }
              return true;
            }}
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>

        {id === 'new' && (
          <>
            <Form.Item
              label="Adresse email de l'administrateur"
              name={['admin', 'email']}
              required
              rules={[
                {
                  validateTrigger: 'onOk',
                  validator: (_r, value) => {
                    if (value.trim() === '') {
                      return Promise.reject(
                        "Veuillez renseigner l'adresse email de l'aministrateur",
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input type="email" />
            </Form.Item>
            <Form.Item
              label="Nom de l'administrateur"
              name={['admin', 'lastName']}
              required
              rules={[
                {
                  validateTrigger: 'onOk',
                  validator: (_r, value) => {
                    if (value.trim() === '') {
                      return Promise.reject(
                        "Veuillez renseigner le nom de l'aministrateur",
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Prénom de l'administrateur"
              name={['admin', 'firstName']}
              required
              rules={[
                {
                  validateTrigger: 'onOk',
                  validator: (_r, value) => {
                    if (value.trim() === '') {
                      return Promise.reject(
                        "Veuillez renseigner le prénom de l'aministrateur",
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={['company', 'activePackId']}
              label="Activer un pack pour cette entreprise"
            >
              <DynamicSelect api={api} url="/packs" />
            </Form.Item>
            <Form.Item
              hidden
              initialValue={Role.COMPANY_ADMIN}
              name={['admin', 'role']}
            >
              <Input />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
};
