import {Button, Form, FormProps, Row} from 'antd';
import {UserAnswers} from 'holo-api';
import {FC, PropsWithChildren, useEffect} from 'react';

import {useViewerStore} from '../../../stores/viewer';

interface ViewerChapterProps extends PropsWithChildren {
  uuid: string;
}

export const ViewerChapter: FC<FormProps & ViewerChapterProps> = ({
  uuid,
  children,
  ...props
}) => {
  const [form] = Form.useForm<UserAnswers>();
  const initialFormAnswers = useViewerStore(
    (state) => state.initialFormAnswers,
  );

  useEffect(() => {
    if (!initialFormAnswers) {
      return;
    }
    form.resetFields();
    form.setFieldsValue(initialFormAnswers);
  }, [initialFormAnswers, form]);

  return (
    <Form
      form={form}
      name={uuid}
      layout="vertical"
      requiredMark={false}
      {...props}
    >
      {children}
      <Row>
        <Button type="primary" htmlType="submit">
          Valider ce chapitre
        </Button>
      </Row>
    </Form>
  );
};
