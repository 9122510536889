import {JSONSchemaType} from 'ajv';

export interface CreateSingleCompanyGroupUserSchemaType {
  companyGroupId: number;
  userId: number;
}

export const CreateSingleCompanyGroupUserSchema: JSONSchemaType<CreateSingleCompanyGroupUserSchemaType> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['companyGroupId', 'userId'],
    properties: {
      companyGroupId: {type: 'number'},
      userId: {type: 'number'},
    },
  } as const;

export interface CreatePluralCompanyGroupUserSchemaType {
  companyGroupId: number[];
  userId: number;
}

export const CreatePluralCompanyGroupUserSchema: JSONSchemaType<CreatePluralCompanyGroupUserSchemaType> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['companyGroupId', 'userId'],
    properties: {
      companyGroupId: {type: 'array', items: {type: 'number'}},
      userId: {type: 'number'},
    },
  } as const;

export interface CreateCompanyGroupUserResponse {
  companyGroupId: number;
  userId: number;
}

interface UserInGroup {
  user: {firstName: string; lastName: string; email: string; id: number};
}

export interface GetUsersInGroup {
  total: number;
  entities: Array<UserInGroup>;
}

export interface GetGroupForUser {
  companyGroup: {
    name: string;
    id: number;
  };
}

export interface GetGroupsForUser0 {
  entities: GetGroupForUser[];
  total: number;
}
