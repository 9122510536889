import {useDebounce} from 'ahooks';
import {Button, Divider, Select, SelectProps} from 'antd';
import {APIClass} from 'aws-amplify';
import {useCallback, useEffect, useState} from 'react';

import {apiGet} from '../../util/api';
import {Spinner} from './Spinner';

type DynamicLabel = 'name' | 'title' | ['firstName', 'lastName'];

interface DynamicElem {
  id: number;
  name?: string;
  title?: string;
  firstName?: string;
  lastName?: string;
}
interface DynamicData<T extends DynamicElem> {
  entities: Array<T>;
  total: number;
}

interface SortParam {
  command: 'sort';
  value: 'name' | 'title';
}

interface DirectionParam {
  command: 'direction';
  value: 'asc' | 'desc';
}

type DynamicParam = SortParam | DirectionParam;
export type DynamicParams = Array<DynamicParam>;

type DynamicFilter = (elem: DynamicElem) => boolean;

interface DynamicSelectProps extends SelectProps {
  label?: DynamicLabel;
  mode?: 'multiple' | 'tags';
  url: string;
  api: APIClass | null;
  params?: DynamicParams;
  filter?: DynamicFilter;
  enabled?: boolean;
}

export const DynamicSelect = <T extends DynamicElem>({
  mode,
  url,
  api,
  params,
  label = 'name',
  filter = () => true,
  enabled = true,
  ...props
}: DynamicSelectProps) => {
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search, {wait: 500});
  const [resources, setResources] = useState<DynamicElem[] | undefined>(
    undefined,
  );
  const [skip, setSkip] = useState<number>(0);
  const [total, setTotal] = useState<number>(100);
  const [loading, setLoading] = useState<boolean>(false);

  const doCall = useCallback(async () => {
    if (!api || !enabled) {
      return;
    }

    setLoading(true);

    const parameters = new URLSearchParams();

    if (debouncedSearch) {
      parameters.append('filters', `name:${debouncedSearch}`);
    }

    parameters.append('skip', skip.toString());
    if (params && params.length > 0) {
      for (const param of params) {
        parameters.append(param.command, param.value);
      }
    }

    const results = await apiGet<DynamicData<T>>(
      api,
      url + '?' + parameters.toString(),
    );

    if (!results) {
      setResources([]);
      setTotal(0);
    } else {
      setResources(
        skip === 0
          ? results.entities
          : (prev) => [...(prev ?? []), ...results.entities],
      );
      setTotal(Number(results.total));
    }

    setLoading(false);
  }, [api, debouncedSearch, params, skip, url, enabled]);

  useEffect(() => {
    void doCall();
  }, [doCall]);

  useEffect(() => {
    setResources([]);
    setTotal(0);
  }, [debouncedSearch]);

  return (
    <Select<T>
      {...props}
      options={resources?.filter(filter).map((resource) => ({
        label: Array.isArray(label)
          ? label.map((l) => resource[l]).join(' ')
          : resource[label],
        value: resource.id,
      }))}
      mode={mode}
      showSearch
      allowClear
      onSearch={(value) => {
        setSearch(value);
        setSkip(0);
      }}
      filterOption={false}
      getPopupContainer={(triggerNode) => triggerNode.parentElement}
      dropdownRender={(menu) => (
        <>
          {menu}
          {skip + 10 < total && (
            <>
              <Divider style={{margin: '8px 0'}} />
              <Button
                loading={loading}
                onClick={() => {
                  setSkip((prev) => prev + 10);
                }}
              >
                Charger plus
              </Button>
            </>
          )}
          {loading && <Spinner size="default" tip="Chargement des données" />}
        </>
      )}
    />
  );
};
