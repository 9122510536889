import {
  CalculatorOutlined,
  CheckSquareOutlined,
  ExclamationCircleFilled,
  InfoCircleOutlined,
  LineOutlined,
  QuestionCircleOutlined,
  ReadOutlined,
} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Form,
  FormListFieldData,
  Input,
  InputNumber,
  notification,
  Row,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import {FormListProps} from 'antd/es/form';
import {
  Chapter,
  ChapterType,
  CreatorForm,
  CreatorItem,
  DefaultChapter,
  findAllChapterFormulasUsedInMainForm,
  findAllFormulas,
  isDefaultChapter,
  isSeparatorChapter,
  isTitleChapter,
  SeparatorChapter,
  TitleChapter,
} from 'holo-api';
import {FC, Fragment, useEffect, useMemo, useState} from 'react';

import {useOnItemChange, useOnItemRemove} from '../../../Creator';
import {useCreatorStore} from '../../../stores/creator';
import {highlightItem, verifyFormulaUsedInMainForm} from '../../../util/form';
import {emptyUUID} from '../../../util/validator';
import {DeleteButton} from '../DeleteButton';
import {QuestionFormList} from './QuestionFormList';

const ItemTitle: FC<{
  item: CreatorItem;
  field: FormListFieldData;
}> = ({item, field}) => {
  if (item.type === 'separator') {
    return null;
  }
  const color =
    item.type === 'formula'
      ? item.formulaType === 'chapter'
        ? 'green'
        : item.formulaType === 'form'
          ? 'orange'
          : undefined
      : undefined;
  return (
    <Fragment key={item.itemKey}>
      <Col>
        <Tooltip
          title={
            item.type === 'formula'
              ? item.formulaType === 'chapter'
                ? `${item.title} (formule du chapitre)`
                : item.formulaType === 'form'
                  ? `${item.title} (formule du formulaire)`
                  : item.title
              : item.title
          }
        >
          <Button
            style={{backgroundColor: color}}
            icon={
              item.type === 'formula' ? (
                <CalculatorOutlined />
              ) : (
                item.type === 'question' &&
                ((item.questionType === 'select' && <CheckSquareOutlined />) ||
                  (item.questionType === 'text' && <QuestionCircleOutlined />))
              )
            }
            onClick={() => {
              highlightItem(`C${field.name}I${item.itemKey}`, {
                scrollIntoView: true,
              });
            }}
          >
            <Typography.Text ellipsis style={{width: 100}}>
              {item.title}
            </Typography.Text>
          </Button>
        </Tooltip>
      </Col>
    </Fragment>
  );
};

const ChapterFormTitle: FC<{
  type: ChapterType | undefined;
  chapterIndex: number;
  field: FormListFieldData;
  move: (from: number, to: number) => void;
}> = ({type, chapterIndex, field, move}) => {
  const errors = useCreatorStore((state) => state.errors);
  const [indexInputValue, setIndexInputValue] = useState<number | null>(
    chapterIndex + 1,
  );
  const form = Form.useFormInstance();
  const chapters = Form.useWatch(['chapters'], {form});

  const moveChapter = (previous: number): void => {
    const value = Number(indexInputValue);
    if (isNaN(value)) {
      notification.error({message: 'Il faut renseigner un nombre'});
      setIndexInputValue(previous + 1);
      return;
    }
    if (value < 1) {
      notification.error({
        message: 'La position doit être supérieure ou égale à 1',
      });
      setIndexInputValue(previous + 1);
      return;
    }
    let index = Number(indexInputValue);
    if (index > chapters.length) {
      index = chapters.length;
      setIndexInputValue(index);
    }
    move(field.name, index - 1);
    setTimeout(() => {
      highlightItem(`C${field.key}`, {scrollIntoView: true});
    }, 1);
  };

  return (
    <Row align="top" justify="space-between">
      <Space>
        <Space size={2}>
          {!(type === ChapterType.SEPARATOR) && (
            <Typography.Text type="danger">*</Typography.Text>
          )}
          {(type === ChapterType.DEFAULT || !type) && (
            <Typography.Text>Chapitre</Typography.Text>
          )}
          {type === ChapterType.TITLE && (
            <Typography.Text>Intitulé du titre</Typography.Text>
          )}
          {type === ChapterType.SEPARATOR && (
            <Typography.Text>Titre du séparateur</Typography.Text>
          )}
        </Space>
        {errors.some((e) => e.name[1] === chapterIndex) && (
          <ExclamationCircleFilled style={{color: 'red'}} />
        )}
      </Space>
      <Space
        style={{marginBottom: 8}}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Typography.Text type="secondary">Ordre :</Typography.Text>
        <InputNumber
          controls={true}
          value={indexInputValue}
          wheel={false}
          onChange={(value) => {
            setIndexInputValue(value);
          }}
          onPressEnter={() => {
            moveChapter(field.name);
          }}
          onBlur={() => {
            moveChapter(field.name);
          }}
        />
      </Space>
    </Row>
  );
};

const ChapterItemsTitles: FC<{
  chapter: Chapter;
  field: FormListFieldData;
  showFormulas: boolean;
  showSelects: boolean;
  showQuestions: boolean;
}> = ({chapter, field, showFormulas, showSelects, showQuestions}) => {
  const chapterItems = useCreatorStore((state) => state.chapterItems);
  if (chapter.chapterType !== ChapterType.DEFAULT) {
    return null;
  }
  const itemsTitles = chapterItems[field.name]
    ?.filter(
      (item) =>
        (showFormulas && item.type === 'formula') ||
        (showSelects &&
          item.type === 'question' &&
          item.questionType === 'select') ||
        (showQuestions &&
          item.type === 'question' &&
          item.questionType === 'text'),
    )
    .map((item, index) => {
      return <ItemTitle item={item} field={field} key={index} />;
    });

  return <>{itemsTitles}</>;
};

const ChapterFormListField: FC<{
  name: string | number | (string | number)[];
  field: FormListFieldData;
  chapterIndex: number;
  move: (from: number, to: number) => void;
  remove: (name: number) => void;
}> = ({field, chapterIndex, move, remove, ...props}) => {
  const isActiveKey = useCreatorStore((state) => state.activeKey[field.key]);
  const addActiveKey = useCreatorStore((state) => state.addCurrentActiveKey);
  const removeActiveKey = useCreatorStore(
    (state) => state.removeCurrentActiveKey,
  );

  const form = Form.useFormInstance<CreatorForm>();
  const initialForm = useCreatorStore((state) => state.initialForm);
  const color = initialForm?.color;
  const currentChapter = form.getFieldValue([
    'chapters',
    field.name,
  ]) as Chapter;

  const mainForm = useCreatorStore((state) => state.mainForm);
  const allMainFormFormulas = useMemo(
    () => (mainForm ? findAllFormulas(mainForm?.contents) : []),
    [mainForm],
  );

  const templateFormulasMapping = useCreatorStore(
    (state) => state.templateFormulasMapping,
  );

  const setFormulasToFix = useCreatorStore((state) => state.setFormulasToFix);

  const onItemRemove = useOnItemRemove(form);
  const onItemChange = useOnItemChange(form);
  const setCurrentFormFormula = useCreatorStore(
    (state) => state.setCurrentFormFormula,
  );

  const questionListName = useMemo(
    () => [field.name, 'items'] as [number, string],
    [field.name],
  );
  const [showFormulas, setShowFormulas] = useState<boolean>(true);
  const [showSelects, setShowSelects] = useState<boolean>(true);
  const [showQuestions, setShowQuestions] = useState<boolean>(true);

  const errors = useCreatorStore((state) => state.errors);
  const setErrors = useCreatorStore((state) => state.setErrors);

  useEffect(() => {
    form.setFieldValue(['chapters', field.name, 'chapterKey'], field.key);
  }, [form, field.key, field.name]);

  return (
    <div
      key={`${field.name}_${chapterIndex}`}
      style={{
        marginBottom: 24,
      }}
    >
      {isDefaultChapter(currentChapter) && (
        <>
          <Form.Item name="uuid" hidden>
            <Input hidden />
          </Form.Item>
          <Form.Item name="version" hidden>
            <Input hidden />
          </Form.Item>
          <Form.Item name="chapterType" hidden>
            <Input hidden />
          </Form.Item>
          <Form.Item name="chapterKey" hidden>
            <Input hidden />
          </Form.Item>
          <Collapse
            activeKey={isActiveKey ? field.key : undefined}
            style={{
              boxShadow: `0 0 5px ${color ?? '#eee'}40`,
              border: `2px solid ${color ?? '#eee'}40`,
            }}
            onChange={() => {
              isActiveKey
                ? removeActiveKey(field.key.toString())
                : addActiveKey(field.key.toString());
            }}
            items={[
              {
                key: field.key,
                forceRender: true,
                label: (
                  <Form.Item noStyle>
                    <ChapterFormTitle
                      move={move}
                      field={field}
                      chapterIndex={chapterIndex}
                      type={currentChapter.chapterType}
                    />
                    <Row align="middle" gutter={8} id={`C${field.key}`}>
                      <Col flex={1}>
                        <Form.Item
                          name={[field.name, 'title']}
                          rules={[{required: true}]}
                          noStyle
                        >
                          <Input.TextArea
                            autoSize
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <DeleteButton
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          onConfirm={() => {
                            const currentForm = form.getFieldsValue();
                            if (mainForm) {
                              const allFormulas =
                                findAllChapterFormulasUsedInMainForm(
                                  currentChapter,
                                );
                              const formulasToFix = verifyFormulaUsedInMainForm(
                                allFormulas.map((formula) => formula.uuid),
                                allMainFormFormulas,
                                templateFormulasMapping,
                              );
                              setFormulasToFix(formulasToFix);
                            }
                            const formFormulaIndex = currentForm.chapters[
                              field.key
                            ].items.findIndex(
                              (item) =>
                                item.type === 'formula' &&
                                item.formulaType === 'form',
                            );
                            if (formFormulaIndex !== -1) {
                              setCurrentFormFormula(undefined);
                            }
                            onItemRemove(`C${field.key + 1}`, () => {
                              remove(field.name);
                              const newErrors = errors.filter(
                                (error) =>
                                  JSON.stringify(error.name.slice(0, 2)) !==
                                  JSON.stringify(['chapters', field.key]),
                              );
                              setErrors(newErrors);
                              onItemChange();
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                ),
                children: (
                  <>
                    <Row style={{marginBottom: 15}}>
                      <Form.Item
                        name={[field.name, 'answeringIsOptional']}
                        valuePropName="checked"
                        noStyle
                      >
                        <Checkbox>
                          L'utilisateur a la possibilité de cocher la case "Non
                          concerné" pour tout ce chapitre
                        </Checkbox>
                      </Form.Item>
                    </Row>
                    <Row style={{marginBottom: 15}}>
                      <Checkbox
                        checked={showFormulas}
                        onChange={() => {
                          setShowFormulas((prev) => !prev);
                        }}
                      >
                        Montrer les formules
                      </Checkbox>
                      <Checkbox
                        checked={showSelects}
                        onChange={() => {
                          setShowSelects((prev) => !prev);
                        }}
                      >
                        Montrer les questions à choix
                      </Checkbox>
                      <Checkbox
                        checked={showQuestions}
                        onChange={() => {
                          setShowQuestions((prev) => !prev);
                        }}
                      >
                        Montrer les questions texte
                      </Checkbox>
                    </Row>
                    <Row style={{marginBottom: 15}} gutter={[8, 8]}>
                      <ChapterItemsTitles
                        chapter={currentChapter}
                        field={field}
                        showFormulas={showFormulas}
                        showSelects={showSelects}
                        showQuestions={showQuestions}
                      />
                    </Row>
                    <QuestionFormList
                      prefixName={props.name}
                      name={questionListName}
                      chapterIndex={chapterIndex}
                      chapterKey={field.key}
                    />
                  </>
                ),
              },
            ]}
          />
        </>
      )}
      {isTitleChapter(currentChapter) && (
        <Form.Item
          style={{
            backgroundColor: '#f9f9f9',
            border: '1px solid #d9d9d9',
            padding: '12px 40px',
            borderRadius: 8,
          }}
        >
          <ChapterFormTitle
            move={move}
            field={field}
            chapterIndex={chapterIndex}
            type={currentChapter.chapterType}
          />
          <Row align="middle" gutter={8} id={`C${field.key}`}>
            <Col flex={1}>
              <Form.Item hidden name={[field.name, 'uuid']}>
                <Input hidden />
              </Form.Item>
              <Form.Item hidden name={[field.name, 'chapterType']}>
                <Input hidden />
              </Form.Item>
              <Form.Item
                name={[field.name, 'title']}
                rules={[{required: true}]}
                noStyle
              >
                <Input
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
              </Form.Item>
            </Col>
            <Col>
              <DeleteButton
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onConfirm={() => {
                  remove(field.name);
                  const newErrors = errors.filter(
                    (error) =>
                      JSON.stringify(error.name.slice(0, 2)) !==
                      JSON.stringify(['chapters', field.key]),
                  );
                  setErrors(newErrors);
                  onItemChange();
                }}
              />
            </Col>
          </Row>
        </Form.Item>
      )}
      {isSeparatorChapter(currentChapter) && (
        <Form.Item
          style={{
            backgroundColor: '#f9f9f9',
            border: '1px solid #d9d9d9',
            padding: '12px 40px',
            borderRadius: 8,
          }}
        >
          <ChapterFormTitle
            move={move}
            field={field}
            chapterIndex={chapterIndex}
            type={currentChapter.chapterType}
          />
          <Row align="middle" gutter={8} id={`C${field.key}`}>
            <Col flex={1}>
              <Form.Item hidden name={[field.name, 'uuid']}>
                <Input hidden />
              </Form.Item>
              <Form.Item hidden name={[field.name, 'chapterType']}>
                <Input hidden />
              </Form.Item>
              <Form.Item name={[field.name, 'title']} noStyle>
                <Input
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
              </Form.Item>
            </Col>
            <Col>
              <DeleteButton
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onConfirm={() => {
                  remove(field.name);
                  const newErrors = errors.filter(
                    (error) =>
                      JSON.stringify(error.name.slice(0, 2)) !==
                      JSON.stringify(['chapters', field.key]),
                  );
                  setErrors(newErrors);
                  onItemChange();
                }}
              />
            </Col>
          </Row>
        </Form.Item>
      )}
    </div>
  );
};

export const ChapterFormList: FC<Omit<FormListProps, 'children'>> = (props) => {
  return (
    <Form.List {...props}>
      {(fields, {add, move, remove}) => {
        return (
          <>
            {fields.map((field, index) => (
              <ChapterFormListField
                key={field.key}
                name={props.name}
                field={field}
                chapterIndex={index}
                move={move}
                remove={remove}
              />
            ))}
            <Space>
              <Button
                icon={<ReadOutlined />}
                type="dashed"
                onClick={() => {
                  add({
                    uuid: emptyUUID,
                    version: '1.0.0',
                    chapterType: ChapterType.DEFAULT,
                    title: '',
                    items: [],
                  } as DefaultChapter);
                }}
              >
                Ajouter un chapitre
              </Button>
              <Button
                icon={<InfoCircleOutlined />}
                type="dashed"
                onClick={() => {
                  add({
                    uuid: emptyUUID,
                    title: '',
                    chapterType: ChapterType.TITLE,
                  } as TitleChapter);
                }}
              >
                Ajouter un titre
              </Button>
              <Button
                icon={<LineOutlined />}
                type="dashed"
                onClick={() => {
                  add({
                    uuid: emptyUUID,
                    title: '',
                    chapterType: ChapterType.SEPARATOR,
                  } as SeparatorChapter);
                }}
              >
                Ajouter un séparateur
              </Button>
            </Space>
          </>
        );
      }}
    </Form.List>
  );
};
