import {AxiosError} from 'axios';

export const CognitoErrorLabel: {[key: string]: string} = {
  UserNotFoundException: 'Utilisateur introuvable',
  UsernameExistsException:
    'Cet email est déjà utilisé par un autre utilisateur',
  NotAuthorizedException: 'Accès refusé',
  CodeMismatchException: 'Le code de vérification est invalide',
  UserNotConfirmedException: "Votre adresse email n'a pas été vérifiée",
  LimitExceededException:
    "Le nombre maximal d'erreur est atteint. Veuillez attendre 15 minutes.",
};

export const isAxiosError = (err: unknown): err is AxiosError =>
  !!(err as AxiosError)?.isAxiosError;

export interface CodedError {
  name: string;
  code: string;
}

export const isCodedError = (err: unknown): err is CodedError =>
  !!(err as CodedError)?.code;
