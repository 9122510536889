import {Col, Collapse, Row, Space, theme, Typography} from 'antd';
import {FC} from 'react';

import capitals from './images/capitals.png';
import context from './images/contexte.png';
import esg from './images/esg.png';
import meaning from './images/meaning.png';
import method from './images/method.png';

export const panelStyle = {
  marginBottom: '24px',
  borderRadius: '8px',
  border: '3px solid #eee',
};

export const App: FC = () => {
  const {token} = theme.useToken();

  // if (process.env.REACT_APP_APP_NAME !== `Holo Diag`) {
  //   return <div />;
  // }

  return (
    <Space
      direction="vertical"
      style={{width: '100%', backgroundColor: token.colorBgBase}}
      size="middle"
    >
      <Typography.Title
        style={{textAlign: 'center', color: token.colorPrimary}}
      >
        INTRODUCTION AUX ACTIFS IMMATÉRIELS
      </Typography.Title>
      <Collapse
        bordered={false}
        style={{background: token.colorBgContainer}}
        items={[
          {
            label: <span style={{fontWeight: 'bold'}}>CONTEXTE</span>,
            style: panelStyle,
            key: 1,
            children: (
              <>
                <Row wrap={false} align="middle" gutter={32}>
                  <Col>
                    <Typography>
                      <Typography.Paragraph>
                        <Typography.Text>
                          Les modifications sociétales et environnementales
                          auxquelles nous sommes confrontés nous amènent à{' '}
                        </Typography.Text>
                        <Typography.Text strong>
                          considérer volontairement ou non, d'autres critères de
                          performance que ceux indexés sur le profit financier.
                        </Typography.Text>
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                        <Typography.Text>
                          Au-delà de l'évaluation traditionnelle basée sur les
                          dividendes versés,{' '}
                        </Typography.Text>
                        <Typography.Text strong>
                          les entreprises sont de plus en plus scrutées pour
                          leur impact social, sociétal et environnemental, à
                          travers des critères comme les ESG ou les exigences
                          réglementaires telles que la CSRD.{' '}
                        </Typography.Text>
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                        <Typography.Text strong>
                          Dans les économies développées, la clé du succès
                          réside désormais dans l'immatériel.
                        </Typography.Text>
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                        La capacité à innover, à conceptualiser, à mobiliser et
                        à générer des idées occupe dorénavant une position
                        centrale dans la transformation économique et sociale,
                        ainsi que dans la compétitivité des entreprises. Quelles
                        que soient leurs tailles, les organisations s'efforcent
                        de se démarquer et de progresser de manière durable et
                        sereine dans un contexte mouvant et complexe, souvent
                        désigné par le terme VUCA (Volatility, Uncertainty,
                        Complexity & Ambiguity).
                      </Typography.Paragraph>
                    </Typography>
                    <Typography>
                      <Typography.Paragraph>
                        <Typography.Text>
                          Contrairement aux actifs matériels que l'on peut voir
                          et toucher,
                        </Typography.Text>{' '}
                        <Typography.Text strong>
                          les richesses immatérielles jouent un rôle essentiel
                          dans la création de valeur future, mais leur
                          évaluation est souvent négligée.
                        </Typography.Text>
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                        <Typography.Text>Pourtant, </Typography.Text>
                        <Typography.Text strong>
                          ces richesses immatérielles représentent des leviers
                          considérables
                        </Typography.Text>
                        <Typography.Text>
                          qui peuvent être activés à travers divers moyens tels
                          que l'engagement des employés, la capacité
                          d'innovation, la gestion anticipée des compétences, la
                          réputation de la marque, la fluidité des échanges
                          d'informations, la qualité des relations humaines, et
                          les engagements envers les parties prenantes dans le
                          cadre de la RSE. Elles incluent également la
                          sécurisation des savoir-faire, les valeurs partagées
                          et l'agilité organisationnelle.
                        </Typography.Text>
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                        <Typography.Text>
                          Toutefois, pour transformer ces atouts en un avantage
                          compétitif durable et vertueux,{' '}
                        </Typography.Text>
                        <Typography.Text strong>
                          il est indispensable de mesurer et de piloter le
                          capital immatériel.
                        </Typography.Text>
                      </Typography.Paragraph>
                    </Typography>
                  </Col>
                  <Col>
                    <img src={context} style={{width: 350}} />
                  </Col>
                </Row>
              </>
            ),
          },
          {
            label: (
              <span style={{fontWeight: 'bold'}}>
                LES ENJEUX DU XXIe SIÈCLE
              </span>
            ),
            style: panelStyle,
            key: 2,
            children: (
              <>
                <Row
                  wrap={false}
                  gutter={32}
                  align="middle"
                  style={{paddingTop: 16}}
                >
                  <Col>
                    <img src={esg} width={250} />
                  </Col>
                  <Col>
                    <Typography>
                      <Typography.Title level={5}>
                        Transformations sociales et économiques
                      </Typography.Title>
                      <Typography.Paragraph>
                        <Typography.Text>
                          En raison de l'évolution des préoccupations
                          environnementales, sociales et de gouvernance (ESG),
                          les réglementations ont considérablement renforcé
                          leurs exigences pour les entreprises et les
                          investisseurs.
                        </Typography.Text>
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                        <Typography.Text>
                          Holo Way offre aux dirigeants de petites et moyennes
                          entreprises (PME/ETI){' '}
                        </Typography.Text>
                        <Typography.Text strong>
                          une approche novatrice qui intègre les richesses
                          immatérielles dans leur stratégie, leur permettant
                          ainsi de concilier la performance économique avec les
                          défis actuels et futurs liés à l'humain et à
                          l'environnement.
                        </Typography.Text>
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                        <Typography.Text>
                          Grâce à Holo Diag, notre outil spécialement conçu à
                          cet effet,{' '}
                        </Typography.Text>
                        <Typography.Text strong>
                          les organisations sont guidées dans leur
                          transformation en consolidant des mesures tangibles et
                          des preuves allant au-delà des critères ESG/RSE. Cette
                          approche mobilise les collaborateurs autour d'une
                          politique commune et durable, favorisant ainsi son
                          adaptation aux nouvelles normes et aux évolutions du
                          marché.
                        </Typography.Text>
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                        <Typography.Text strong>
                          En adoptant une gestion proactive des richesses
                          immatérielles, les organisations peuvent bénéficier
                          d'avantages tant humains que financiers, tout en se
                          préparant à l'avenir et sécurisant le présent.
                        </Typography.Text>
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                        <Typography.Text>
                          Puisque faire face aux nouvelles exigences
                          réglementaires et
                        </Typography.Text>
                        <Typography.Text strong>
                          assurer sa pérennité nécessite d'identifier son
                          potentiel de création de valeur durable, nous
                          proposons une nouvelle approche qui prend en compte la
                          dimension humaine de l'entreprise. Cela inclut ses
                          savoirs, l'engagement de ses collaborateurs, sa
                          culture d'entreprise, sa relation avec son territoire,
                          ainsi que ses parties prenantes...
                        </Typography.Text>
                        <Typography.Text>
                          Cette perspective permet d'appréhender les risques et
                          leviers qui contribuent à la compétitivité et à la
                          durabilité des organisations.
                        </Typography.Text>
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                        Que ce soit lors d'un lancement de projet, d'une
                        croissance externe, d'une cession ou d'une
                        transformation de modèle, une entreprise qui s'appuie
                        sur ses ressources immatérielles peut déployer une
                        stratégie intégrée, portée par l'ensemble de ses parties
                        prenantes, plutôt que de subir une transformation dans
                        un environnement méconnu ou incertain.
                      </Typography.Paragraph>
                    </Typography>
                  </Col>
                </Row>
                <Row wrap={false} align="middle" gutter={32}>
                  <Col>
                    <Typography.Title level={5}>
                      La recherche de sens
                    </Typography.Title>
                    <Typography>
                      <Typography.Paragraph>
                        <Typography.Text>
                          Ces dernières années, les bouleversements majeurs dans
                          le monde professionnel, tels que la digitalisation et
                          le télétravail, ont profondément influencé nos vies et
                          notre rapport au travail.{' '}
                        </Typography.Text>
                        <Typography.Text strong>
                          Pour trouver un sens à notre activité professionnelle,
                          il est primordial que celle-ci résonne avec notre
                          identité, nos compétences et nos valeurs, et qu'elle
                          contribue non seulement à l'entreprise, mais également
                          au-delà.
                        </Typography.Text>
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                        <Typography.Text>
                          Cela implique que les managers collaborent avec leurs
                          équipes pour{' '}
                        </Typography.Text>
                        <Typography.Text strong>
                          co-créer du sens au quotidien,{' '}
                        </Typography.Text>
                        <Typography.Text>
                          plutôt que de l'imposer. Cette démarche peut englober
                          une révision de la stratégie d'entreprise, un meilleur
                          équilibre entre les contributions et les récompenses,
                          une participation accrue des employés aux décisions,
                          la promotion de l'égalité, un renforcement de la
                          collaboration ou encore une redéfinition des parcours
                          professionnels, entre autres.
                        </Typography.Text>
                      </Typography.Paragraph>
                      <Typography.Paragraph strong>
                        Finalement, le sens au travail se construit lorsque nous
                        nous percevons comme des contributeurs, développant un
                        style de travail aligné avec notre identité, et
                        construisant des relations significatives au sein d'une
                        communauté partageant des valeurs communes et une vision
                        porteuse de sens.
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                        <Typography.Text strong>
                          Les leviers qui renforcent l'attrait d'une marque
                          employeur ou qui mobilisent les équipes autour d'une
                          vision commune{' '}
                        </Typography.Text>
                        <Typography.Text>
                          peuvent être identifiés à travers l'analyse du capital
                          immatériel.{' '}
                        </Typography.Text>
                        <Typography.Text strong>
                          En identifiant et en pilotant ce qui confère de la
                          substance et du sens à la contribution de chacun au
                          sein d'une équipe, la dynamique vertueuse de la
                          performance globale (tant matérielle qu'immatérielle)
                          peut être enclenchée.{' '}
                        </Typography.Text>
                        <Typography.Text>
                          L'intuition seule ne suffit pas...
                        </Typography.Text>
                      </Typography.Paragraph>
                    </Typography>
                  </Col>
                  <Col>
                    <img src={meaning} width={300} />
                  </Col>
                </Row>
              </>
            ),
          },
          {
            label: (
              <span style={{fontWeight: 'bold'}}>
                LA PLATEFORME D'ANALYSE ET DE PILOTAGE HOLO DIAG
              </span>
            ),
            style: panelStyle,
            key: 3,
            children: (
              <>
                <Row
                  wrap={false}
                  gutter={32}
                  style={{paddingTop: 16, marginBottom: 16}}
                  align="middle"
                >
                  <Col>
                    <img src={method} width={400} />
                  </Col>
                  <Col>
                    <Space direction="vertical">
                      <Typography>
                        <Typography.Title level={5}>
                          Un Diagnostic holistique
                        </Typography.Title>
                        <Typography.Paragraph>
                          <Typography.Text>
                            Holo Diag est une approche méthodologique à 360°
                            conçue pour transcender la vision traditionnellement
                            financière de l'entreprise.{' '}
                          </Typography.Text>
                          <Typography.Text strong>
                            En croisant les perspectives stratégiques, sociales,
                            de qualité et environnementales, cet outil permet de
                            mesurer et d'analyser les performances réelles de
                            l'entreprise dans une optique durable et vertueuse.
                          </Typography.Text>
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                          <Typography.Text>
                            Grâce à cette approche, il devient possible de
                            comprendre et d'évaluer la richesse immatérielle de
                            l'entreprise{' '}
                          </Typography.Text>
                          <Typography.Text strong>
                            pour la piloter de manière efficace.
                          </Typography.Text>
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                          <Typography.Text>
                            Holo Diag dépasse la vision strictement financière
                            ou RSE de l’entreprise en{' '}
                          </Typography.Text>
                          <Typography.Text strong>
                            s’inscrivant dans une connaissance intégrale et
                            concrète basée sur le travail réel.
                          </Typography.Text>
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                          Ses effets se révèlent durables, permettant notamment
                          de
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                          <ul style={{lineHeight: '2em'}}>
                            <li>
                              Affirmer l'identité de l'entreprise et ses
                              <strong>
                                {' '}
                                caractéristiques distinctives sur le marché
                              </strong>
                            </li>
                            <li>
                              Mobiliser les collaborateurs et{' '}
                              <strong>attirer les talents de demain</strong>
                            </li>
                            <li>
                              Ancrer l'entreprise dans la{' '}
                              <strong>
                                {' '}
                                transition sociétale, environnementale et
                                numérique
                              </strong>
                            </li>
                            <li>
                              Développer des{' '}
                              <strong>
                                {' '}
                                innovations durables face à l’incertitude.
                              </strong>
                            </li>
                          </ul>
                        </Typography.Paragraph>
                      </Typography>
                    </Space>
                  </Col>
                </Row>
                <Row align="middle" wrap={false} gutter={32}>
                  <Col>
                    <Typography>
                      <Typography.Title level={5}>
                        Une performance immatérielle prédictive de la
                        performance financière
                      </Typography.Title>
                      <Typography.Paragraph>
                        <Typography.Text>
                          Parce que nous savons que votre histoire est beaucoup
                          plus riche que ce qu'on en lit dans un bilan
                          comptable,{' '}
                        </Typography.Text>
                        <Typography.Text strong>
                          notre livrable laisse une trace argumentée et
                          documentée qui va au-delà des ratios habituels.
                        </Typography.Text>
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                        Ce document structuré décrit l'intérêt de vos différents
                        publics à s'engager avec vous.
                      </Typography.Paragraph>
                      <ul style={{lineHeight: '2em'}}>
                        <li>
                          <Typography.Text>
                            Étant donné que la maturité immatérielle est
                            étroitement liée à la performance et à la pérennité
                            de votre activité, le bilan Holo Diag vous permet de{' '}
                          </Typography.Text>
                          <Typography.Text strong>
                            renouer les bases d'un dialogue productif avec vos
                            parties prenantes, y compris vos partenaires
                            financiers (prêts bancaires, levées de fonds,
                            cessions...).
                          </Typography.Text>
                        </li>
                        <li>
                          <Typography.Text strong>
                            Notre processus d'analyse ainsi que la synthèse
                            finale, rassemblent et{' '}
                          </Typography.Text>
                          <Typography.Text>
                            consolident les informations essentielles pour la
                            création d'un rapport de durabilité ou la
                            formalisation d'une démarche RSE dans le cadre
                            d'appels d'offres.
                          </Typography.Text>
                        </li>
                        <li>
                          <Typography.Text>
                            L’analyse de votre singularité à travers le prisme
                            de l’immatériel{' '}
                          </Typography.Text>
                          <Typography.Text strong>
                            fournit une matière tangible et argumentée pour
                            partager et communiquer efficacement votre stratégie
                            avec vos collaborateurs et vos clients.
                          </Typography.Text>
                        </li>
                      </ul>
                    </Typography>
                  </Col>
                  <Col>
                    <img src={capitals} width={350} />
                  </Col>
                </Row>
              </>
            ),
          },
        ]}
      />
    </Space>
  );
};
