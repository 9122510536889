import {notification} from 'antd';
import {APIClass} from 'aws-amplify';
import {
  AdminFormContentsType,
  AnswerMatrixT,
  ChapterType,
  CreatorForm,
  customMath,
  findAllTransferredFormulas,
  Form as FormType,
  FormCategory,
  FormContentsType,
  Formula,
  getKeysFromReference,
  isAnswerNoAnswer,
  isAnswerToChapterValidated,
  isAnswerToSelectQuestion,
  isAnswerToTextQuestion,
  isDefaultChapter,
  isFormulaReference,
  isNumericQuestionReference,
  isSelectQuestionReference,
  isTemplateFormulaReference,
  ItemType,
  replaceMatrixIdByName,
  replaceMatrixNameById,
  SingleUserAnswer,
} from 'holo-api';
import {JSONPath} from 'jsonpath-plus';

import {formGetSingular} from '../client/form';
import {CreatorStoreProps} from '../stores/creator';
import {apiGet} from './api';

const {parse} = customMath;

// imported type from antd is SO complicated it basically resolves into any when used
export type NamePath = string | number | Array<string | number>;

const toArray = (value: NamePath) => (Array.isArray(value) ? value : [value]);

export const colors = [
  '#f7981d',
  '#7e3794',
  '#11a9cc',
  '#a61d4c',
  '#4285f4',
  '#f4b400',
  '#65b045',
  '#795548',
  '#999999',
  '#f1ca3a',
  '#3f5ca9',
  '#c3d03f',
];

export const getIndexesFromKeys = (
  form: CreatorForm,
  chapterKey: number,
  itemKey: number,
): number[] => {
  const chapterIndex = form.chapters.findIndex(
    (chapter) => chapter.chapterKey === chapterKey,
  );
  if (!form.chapters[chapterIndex]) {
    return [-1, -1];
  }
  const itemIndex = form.chapters[chapterIndex].items?.findIndex(
    (item) => item.itemKey === itemKey,
  );
  return [chapterIndex, itemIndex];
};

export const mergeNamePath = (...names: NamePath[]): NamePath => {
  const name: NamePath = [];
  names.forEach((n) => {
    name.push(...toArray(n));
  });
  return name;
};

export const compareNamePath = (
  _a: NamePath | undefined,
  _b: NamePath | undefined,
): boolean => {
  if (!_a || !_b) {
    return false;
  }
  const a = toArray(_a);
  const b = toArray(_b);

  if (a.length !== b.length) {
    return false;
  }
  for (let i = 0; i < a.length && i < b.length; i++) {
    if (a[i] !== b[i]) {
      return false;
    }
  }

  return true;
};

export const getChapterFormula = (
  form: FormContentsType,
  index: number,
): Formula => {
  return JSONPath({
    path: `$.chapters[${index}].items[?(@.formulaType === "chapter")]`,
    json: form,
  })[0];
};

export const getFormFormula = (form: FormContentsType): Formula => {
  return JSONPath({
    path: '$..items[?(@.formulaType === "form")]',
    json: form,
  })[0];
};

interface HighlightOptions {
  scrollIntoView?: boolean;
  scrollDelay?: number;
  className?: string;
}

export const buildCreatorTagMapping = async (
  revertTagMapping: Record<string, string>,
  formData: Awaited<ReturnType<typeof formGetSingular>>,
  api: APIClass,
) => {
  let templateIndex = 1;
  const allTemplates: CreatorStoreProps['formTemplates'] = [];
  const tagMapping: Record<string, string> = {};
  for (const template of formData.templates) {
    const fullTemplate = await apiGet<FormType>(
      api,
      `/form/${template.id}?role=ADMIN`,
      {},
    );
    const templateContent = fullTemplate.contents as FormContentsType;
    const allTranferredFormulas = findAllTransferredFormulas(templateContent);
    if (allTranferredFormulas.length === 0) {
      continue;
    }
    const usedFormulas: CreatorStoreProps['formTemplates'][number]['formulas'] =
      [];
    const tIndex = templateIndex; // just to avoid typescript error :shrug:
    allTranferredFormulas.forEach((formula, formulaIndex) => {
      const tag = `T${tIndex}F${formulaIndex + 1}`;
      usedFormulas.push({
        uuid: formula.uuid,
        title: formula.title,
        tag,
      });
      const targetTag = `T${template.id}F"${formula.uuid}"`;
      tagMapping[tag] = targetTag;
      revertTagMapping[targetTag] = tag;
    });

    allTemplates.push({
      id: template.id,
      title: template.title,
      formulas: usedFormulas,
    });
    templateIndex++;
  }
  return {tagMapping, allTemplates};
};

export const highlightItem = (
  itemHTMLId: string,
  options?: HighlightOptions,
): HTMLElement | undefined => {
  const highlightClassName = options?.className ?? 'item-highlighted';
  Array.from(document.getElementsByClassName(highlightClassName)).forEach(
    (element: Element) => {
      element.classList.remove(highlightClassName);
    },
  );
  const item = document.getElementById(itemHTMLId);
  if (!item) {
    console.error('Could not find component to highlight');
    return;
  }
  setTimeout(() => {
    item.classList.add(highlightClassName);
  }, 1);

  if (options?.scrollIntoView) {
    setTimeout(() => {
      item.scrollIntoView({behavior: 'smooth', block: 'center'});
    }, options?.scrollDelay ?? 100);
  }

  return item;
};

export const rewriteFormulasOnSave = (
  formulas: Formula[],
  form: CreatorForm,
  allMatrices: AnswerMatrixT[],
  templateFormulasMapping: Record<string, string>,
) => {
  const referenceDictionary: {[key: string]: string} = {};
  formulas.forEach((formulaItem: Formula) => {
    formulaItem.formula = replaceMatrixNameById(formulaItem, allMatrices);
    const parsed = parse(formulaItem.formula);
    formulaItem.formula = '';
    const html = document.createElement('div');
    html.innerHTML = parsed.toHTML();
    Array.from(html.children).forEach((child) => {
      const textContent = child.textContent;
      if (!textContent) {
        return;
      }
      if (
        child.classList.contains('math-number') ||
        child.classList.contains('math-parenthesis') ||
        child.classList.contains('math-paranthesis') ||
        child.classList.contains('math-function') ||
        child.classList.contains('math-separator') ||
        child.classList.contains('math-operator')
      ) {
        formulaItem.formula += textContent;
        return;
      }
      if (Object.hasOwn(referenceDictionary, textContent)) {
        formulaItem.formula += referenceDictionary[textContent];
        return;
      }
      const isSelectionQuestion = isSelectQuestionReference(textContent);
      const isNumericQuestion = isNumericQuestionReference(textContent);
      const isFormula = isFormulaReference(textContent);
      const isTemplateFormula = isTemplateFormulaReference(textContent);
      const [chapterKey, itemKey] = getKeysFromReference(textContent);
      const [chapterIndex, itemIndex] = getIndexesFromKeys(
        form,
        chapterKey,
        itemKey,
      );
      if (isTemplateFormula) {
        const updatedReference = templateFormulasMapping[textContent];
        referenceDictionary[textContent] = updatedReference;
        formulaItem.formula += updatedReference;
        return;
      }

      if (chapterKey === chapterIndex && itemKey === itemIndex) {
        referenceDictionary[textContent] = textContent;
        formulaItem.formula += textContent;
        return;
      }
      let updatedReference: string | undefined = undefined;
      if (isSelectionQuestion) {
        updatedReference = `C${chapterIndex + 1}S${itemIndex + 1}`;
      }
      if (isNumericQuestion) {
        updatedReference = `C${chapterIndex + 1}Q${itemIndex + 1}`;
      }
      if (isFormula) {
        updatedReference = `C${chapterIndex + 1}F${itemIndex + 1}`;
      }
      if (!updatedReference) {
        return;
      }
      referenceDictionary[textContent] = updatedReference;
      formulaItem.formula += updatedReference;
    });
  });
};

export const convertFormToCreatorForm = (
  form: AdminFormContentsType,
  extractedInfo: {
    priority: number | null | undefined;
    mainFormId: number | null | undefined;
    category: FormCategory | undefined;
  },
  revertTagMapping: Record<string, string>,
  allMatrices: AnswerMatrixT[],
): form is CreatorForm => {
  form.priority = extractedInfo.priority;
  form.mainFormId = extractedInfo.mainFormId;
  form.category = extractedInfo.category;

  form.chapters.forEach((chapter, chapterIndex) => {
    // @ts-expect-error chapterKey does not exists
    chapter.chapterKey = chapterIndex;
    if (chapter.chapterType === ChapterType.DEFAULT || !chapter.chapterType) {
      chapter.items.forEach((item, itemIndex) => {
        // @ts-expect-error itemKey does not exists
        item.itemKey = itemIndex;

        if (item.type === ItemType.FORMULA) {
          const templateFormulaMatches = item.formula.matchAll(
            /T(?<templateIndex>\d+)F"(?<formulaUuid>[\w-]+)"/g,
          );

          for (const match of templateFormulaMatches) {
            if (!match.groups) {
              continue;
            }
            const {templateIndex, formulaUuid} = match.groups;
            if (!templateIndex || !formulaUuid) {
              continue;
            }
            if (!revertTagMapping[match[0]]) {
              item.formula = item.formula.replaceAll(match[0], 'TXFX');
              notification.error({
                key: item.title,
                message: `Formule "${item.title}" : Des formules de templates ne sont plus accessibles. Veuillez corriger les formules concernées.`,
              });
              return;
            }
            item.formula = item.formula.replaceAll(
              match[0],
              revertTagMapping[match[0]],
            );
          }
          const updatedFormula = replaceMatrixIdByName(item, allMatrices);
          item.formula = updatedFormula;
        }
      });
    }
  });
  return true;
};

export const deleteKeysOnForm = (form: CreatorForm) => {
  form.chapters.forEach((chapter) => {
    // TODO: remove the next check when all forms are converted to correct format with chapterTypes
    if (!chapter.chapterType) {
      chapter.chapterType = ChapterType.DEFAULT;
    }
    delete chapter['chapterKey'];
    if (isDefaultChapter(chapter)) {
      chapter.items.forEach((item) => delete item['itemKey']);
    }
  });
};

export const isAnswerValid = (value: SingleUserAnswer) => {
  if (isAnswerToChapterValidated(value)) return false;
  return (
    (isAnswerToSelectQuestion(value) && (value.answer ?? []).length > 0) ||
    (isAnswerToTextQuestion(value) && (value.text ?? '').length > 0) ||
    (isAnswerNoAnswer(value) && value.willNotAnswer)
  );
};

export interface FormulasToFix {
  uuid: string;
  value: string;
  title: string;
  tags: string[];
}

export const verifyFormulaUsedInMainForm = (
  templateFormulaUuids: string[],
  mainFormFomulas: Formula[],
  templateFormulasMapping: Record<string, string>,
) => {
  const formulas: FormulasToFix[] = [];
  for (const uuid of templateFormulaUuids) {
    let tag = '';
    for (const [key, value] of Object.entries(templateFormulasMapping)) {
      if (value.includes(uuid)) {
        tag = key;
      }
    }
    if (tag !== '') {
      mainFormFomulas.forEach(({formula, title, uuid: mainFormFormulaUuid}) => {
        if (formula.includes(tag)) {
          const parsed = parse(formula);
          const html = document.createElement('div');
          html.innerHTML = parsed.toHTML();
          const children = Array.from(html.children);
          if (children.findIndex((child) => child.textContent === tag) !== -1) {
            const foundFormulaToFix = formulas.find(
              (formulaToFix) => formulaToFix.uuid === mainFormFormulaUuid,
            );
            if (foundFormulaToFix) {
              foundFormulaToFix.tags.push(tag);
            } else {
              formulas.push({
                uuid: mainFormFormulaUuid,
                value: formula,
                title,
                tags: [tag],
              });
            }
          }
        }
      });
    }
  }
  return formulas;
};
