import {Table, TableProps} from 'antd';
import {SortOrder} from 'antd/es/table/interface';
import React, {Key} from 'react';

interface GenericTableProps<T extends object> {
  total: number | undefined;
  loading: boolean;
  dataSource: Array<T>;
  usePagination: [
    {pageNumber: number; pageSize: number},
    React.Dispatch<
      React.SetStateAction<{
        pageNumber: number;
        pageSize: number;
      }>
    >,
  ];
  setCurrentSort: React.Dispatch<
    React.SetStateAction<
      | {
          column: Key | readonly Key[] | undefined;
          order: SortOrder | undefined;
        }
      | undefined
    >
  >;
}

export const GenericTable = <T extends object>({
  columns,
  total,
  loading,
  dataSource,
  usePagination,
  setCurrentSort,
}: GenericTableProps<T> & {columns: TableProps<T>['columns']}) => {
  const [pagination, setPagination] = usePagination;
  const onChange: TableProps<T>['onChange'] = (
    pagination,
    filters,
    sorter,
    {action},
  ) => {
    if (action === 'sort') {
      const sort = Array.isArray(sorter) ? sorter[0] : sorter;
      const targetColumn = sort.field;
      const order = sort.order;
      if (!order) {
        setCurrentSort(undefined);
        return;
      }
      if (!targetColumn) {
        console.error('The sorter does not have any "field" property value');
        return;
      }
      setCurrentSort({column: targetColumn, order});
    }
  };
  return (
    <Table
      columns={columns}
      rowKey={'id'}
      dataSource={dataSource ?? []}
      sticky
      onChange={onChange}
      loading={loading}
      pagination={{
        pageSizeOptions: [10, 20, 50, 100],
        pageSize: pagination.pageSize,
        showSizeChanger: true,
        total: total,
        onChange: (page, pageSize) => {
          setPagination({
            pageNumber: page,
            pageSize,
          });
        },
      }}
    />
  );
};
