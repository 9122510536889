import {LoadingOutlined} from '@ant-design/icons';
import {Col, List, message, Modal, ModalProps, Row, Typography} from 'antd';
import {APIClass} from 'aws-amplify';
import dayjs from 'dayjs';
import {
  isAnswerNoAnswer,
  isAnswerToSelectQuestion,
  isAnswerToTextQuestion,
  isQuestion,
  isSelectQuestion,
  SelectQuestion,
  SingleUserAnswer,
  TextQuestion,
} from 'holo-api';
import {useEffect, useState} from 'react';

import {getFormAnswer, getTemplateInstanceAnswer} from '../../../client/form';
import {CompanyUsers, useViewerStore} from '../../../stores/viewer';
import {useApi} from '../../../util/auth';

export interface HistoryModalParams {
  selectOrTextQuestion: SelectQuestion | TextQuestion;
  chapterIdx: number;
  formId: number;
  companyId: number | undefined;
  templateInstanceId: number | undefined;
}

interface HistoryModalProps
  extends Pick<ModalProps, 'open' | 'onOk' | 'onCancel'> {
  params: HistoryModalParams | null;
}

type SortedData = {
  userId: number;
  userName: string;
  date: string;
  shortDate: string;
  answerUUID: string | string[];
  answerTitle: string;
};

const prepareSortedData = async (
  api: APIClass,
  params: HistoryModalParams,
  userIdToUser: CompanyUsers,
) => {
  const allAnswers = params.templateInstanceId
    ? await getTemplateInstanceAnswer(api, params.templateInstanceId)
    : await getFormAnswer(api, params.formId);

  const userIdToAnswer = new Map<number, SingleUserAnswer>();
  for (const answer of allAnswers) {
    for (const questionUUID of Object.keys(answer.contents))
      if (questionUUID === params.selectOrTextQuestion.uuid) {
        const singleAnswer = answer.contents[questionUUID];
        if (singleAnswer) {
          userIdToAnswer.set(answer.userId, singleAnswer);
        }
      }
  }

  const sortedData: Array<SortedData> = [];
  for (const [userId, value] of userIdToAnswer) {
    let answerTitle = '';
    let answerUUID: string | string[] = 'noUUID';
    if (isAnswerNoAnswer(value) && value.willNotAnswer) {
      answerTitle = 'Pas de réponse';
    } else if (isAnswerToTextQuestion(value)) {
      answerTitle = value.text || '""';
    } else if (isAnswerToSelectQuestion(value)) {
      if (isQuestion(params.selectOrTextQuestion)) {
        if (isSelectQuestion(params.selectOrTextQuestion)) {
          for (const answer of params.selectOrTextQuestion.answers) {
            if (
              (Array.isArray(value.answer) &&
                value.answer.includes(answer.uuid)) ||
              value.answer === answer.uuid
            ) {
              if (answerTitle) {
                answerTitle += ', ';
              }
              answerTitle += answer.label;
            }
          }
        }
      }
      answerUUID = value.answer;
    }

    if (answerTitle) {
      const user = userIdToUser[userId];
      if (
        ((isAnswerNoAnswer(value) && value.willNotAnswer) ||
          isAnswerToSelectQuestion(value) ||
          isAnswerToTextQuestion(value)) &&
        value.date
      ) {
        sortedData.push({
          userId,
          userName: user
            ? `${user.firstName} ${user.lastName}`
            : 'Utilisateur supprimé',
          date: value.date,
          shortDate: dayjs(value.date).format('DD/MM/YYYY'),
          answerUUID,
          answerTitle,
        });
      }
    }
  }

  sortedData.sort((a, b) => dayjs(b.date).diff(dayjs(a.date)));
  return sortedData;
};

export const HistoryModal = ({params, ...props}: HistoryModalProps) => {
  const api = useApi();
  const [response, setResponse] = useState<SortedData[] | null>(null);
  const {usersLoaded, companyUsers} = useViewerStore((state) => ({
    usersLoaded: state.usersLoaded,
    companyUsers: state.companyUsers,
  }));

  useEffect(() => {
    if (!usersLoaded) {
      return;
    }
    const prepareUI = async () => {
      if (!api || !params) {
        setResponse(null);
      } else if (usersLoaded) {
        try {
          setResponse(await prepareSortedData(api, params, companyUsers));
        } catch (err) {
          void message.error((err as Error).message);
        }
      }
    };
    void prepareUI();
  }, [api, params, usersLoaded, companyUsers]);

  return (
    <Modal
      title={`Historique des réponses de "${params?.selectOrTextQuestion.title}"`}
      open={props.open}
      onOk={(e) => {
        setResponse(null);
        props.onOk?.(e);
      }}
      onCancel={(e) => {
        setResponse(null);
        props.onCancel?.(e);
      }}
      cancelButtonProps={{style: {display: 'none'}}}
    >
      {!response ? (
        <LoadingOutlined />
      ) : Array.isArray(response) ? (
        <List>
          {response.map((data, index) => {
            const noAnswer =
              data.answerTitle === 'Pas de réponse' ||
              data.answerTitle === '""';
            return (
              <List.Item key={index}>
                <Row style={{display: 'flex', flex: 1}}>
                  <Col
                    flex="1 1 50%"
                    style={{padding: 8, backgroundColor: '#eee'}}
                  >
                    <div style={{fontWeight: 'bold'}}>{data.shortDate}</div>
                    <div style={{color: 'royalblue'}}>{data.userName}</div>
                  </Col>
                  <Col
                    flex="1 1 50%"
                    style={{
                      padding: 8,
                      backgroundColor: index === 0 ? '#ffcc8040' : undefined,
                      border: index !== 0 ? '1px solid #eee' : undefined,
                    }}
                  >
                    <Typography.Text
                      type={noAnswer ? 'secondary' : undefined}
                      italic={noAnswer}
                    >
                      {data.answerTitle}
                    </Typography.Text>
                  </Col>
                </Row>
              </List.Item>
            );
          })}
        </List>
      ) : null}
    </Modal>
  );
};
