import {
  AuditOutlined,
  DashboardOutlined,
  EditOutlined,
  FileDoneOutlined,
  FilePdfOutlined,
  GroupOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  SettingTwoTone,
  ShopOutlined,
  TableOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  UserSwitchOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import {Layout, Menu, MenuProps, message, Tooltip} from 'antd';
import {Role} from 'holo-api';
import {FC, useCallback, useEffect, useMemo} from 'react';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';

import {usePackStore} from '../../stores/pack';
import {useUserStore} from '../../stores/user';
import {useUser, useUserRole} from '../../util/auth';

interface SideBarProps {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
}

export const Sidebar: FC<SideBarProps> = ({collapsed, setCollapsed}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const preliminaryForm = usePackStore((state) => state.preliminaryForm);
  const forms = usePackStore((state) => state.forms);
  const packIsLoaded = usePackStore((state) => state.packIsLoaded);
  const packId = usePackStore((state) => state.packId);
  const userRole = useUserRole();
  const isAdmin = userRole === Role.ADMIN;
  const isConsultant = userRole === Role.CONSULTANT;
  const isCompanyAdmin = userRole === Role.COMPANY_ADMIN;
  const userInfo = useUserStore((state) => state.userInfo);
  const cognitoUser = useUser();
  const [params] = useSearchParams();
  const formParam = params.get('form');

  useEffect(() => {
    if (location.pathname.includes('viewer') && formParam && packIsLoaded) {
      const formId = parseInt(formParam);
      const found =
        formId === preliminaryForm?.id ||
        forms.find((form) => {
          return (
            form.id === formId ||
            form.subForms.find((subForm) => subForm.id === formId)
          );
        });

      if (!found) {
        void message.error(
          `Le formulaire ${formId} n'existe pas dans le pack de l'entreprise.`,
        );
        navigate('/');
      }
    }
  }, [
    navigate,
    formParam,
    forms,
    preliminaryForm,
    packIsLoaded,
    location.pathname,
  ]);

  const menuItems: MenuProps['items'] = useMemo(() => {
    return cognitoUser && !cognitoUser.challengeName
      ? [
          ...(isAdmin || (userInfo?.companyId && packId)
            ? [
                {
                  icon: <DashboardOutlined />,
                  key: '/dashboard',
                  label: 'Tableau de bord',
                },
                isAdmin || isConsultant || isCompanyAdmin
                  ? {
                      icon: <InfoCircleOutlined />,
                      key: `/viewer/?form=${preliminaryForm?.id}`,
                      label: (
                        <Tooltip title={preliminaryForm?.title}>
                          {preliminaryForm?.title}
                        </Tooltip>
                      ),
                    }
                  : null,
              ]
            : []),
          ...((isAdmin || userInfo?.companyId) && forms?.length > 0
            ? [
                {
                  label: 'Actifs',
                  key: '',
                  icon: <FileDoneOutlined />,
                  children: [
                    ...forms.map((form) => {
                      if (form.subForms.length === 0) {
                        return {
                          key: `/viewer/?form=${form.id}`,
                          icon: (
                            <SettingTwoTone
                              twoToneColor={form.color ?? '#ffffff'}
                            />
                          ),
                          label: (
                            <Tooltip
                              title={form.title.length > 18 ? form.title : ''}
                            >
                              {form.title}
                            </Tooltip>
                          ),
                        };
                      } else {
                        return {
                          key: `/viewer/?form=${form.id}`,
                          icon: (
                            <SettingTwoTone
                              twoToneColor={form.color ?? '#ffffff'}
                            />
                          ),
                          label: (
                            <Tooltip
                              title={form.title.length > 16 ? form.title : ''}
                            >
                              <span
                                onClick={() => {
                                  navigate(`/viewer/?form=${form.id}`);
                                }}
                              >
                                {form.title}
                              </span>
                            </Tooltip>
                          ),
                          children: form.subForms.map((subForm) => ({
                            key: `/viewer/?form=${subForm.id}`,
                            label: (
                              <Tooltip
                                title={
                                  subForm.title.length > 21 ? subForm.title : ''
                                }
                              >
                                {subForm.title}
                              </Tooltip>
                            ),
                          })),
                        };
                      }
                    }),
                  ],
                },
              ]
            : [
                {
                  icon: <WarningOutlined />,
                  key: '',
                  label: (
                    <Tooltip title="Aucun formulaire associé">
                      Aucun formulaire
                    </Tooltip>
                  ),
                },
              ]),
          ...((isAdmin || isConsultant) && userInfo?.companyId
            ? [
                {
                  icon: <AuditOutlined />,
                  label: `Plan d'action`,
                  key: '/action-plan',
                },
              ]
            : []),
          ...(isAdmin
            ? [
                {
                  icon: <EditOutlined />,
                  key: '/creator',
                  label: (
                    <Tooltip title="Créateur de formulaire">
                      Créateur de formulaire
                    </Tooltip>
                  ),
                },
                {
                  icon: <TableOutlined />,
                  key: '/matrices',
                  label: 'Matrices',
                },
                {
                  icon: <FilePdfOutlined />,
                  key: '/modeles',
                  label: 'Modèles',
                },
                {
                  icon: <UserSwitchOutlined />,
                  key: '/consultants',
                  label: 'Consultants',
                },
                {
                  icon: <GroupOutlined />,
                  key: '/packs',
                  label: 'Packs',
                },
                {
                  icon: <ShopOutlined />,
                  key: '/companies',
                  label: 'Entreprises',
                },
                {
                  icon: <UsergroupAddOutlined />,
                  key: '/company-groups',
                  label: 'Groupes',
                },
                {
                  icon: <UserOutlined />,
                  key: '/users',
                  label: 'Utilisateurs',
                },
              ]
            : (isCompanyAdmin || isConsultant) && userInfo?.companyId
              ? [
                  {
                    icon: <UsergroupAddOutlined />,
                    key: '/company-groups',
                    label: 'Groupes',
                  },
                  {
                    icon: <UserOutlined />,
                    key: '/users',
                    label: 'Utilisateurs',
                  },
                ]
              : []),
          {type: 'divider'},
          {
            icon: <HomeOutlined />,
            key: '/',
            label: (
              <Tooltip title="Introduction aux actifs immatériels">
                Introduction aux actifs immatériels
              </Tooltip>
            ),
          },
          {
            icon: <InfoCircleOutlined />,
            key: '/introduction',
            label: (
              <Tooltip title="Utilisation de la plateforme">
                Utilisation de la plateforme
              </Tooltip>
            ),
          },
          {
            icon: <FilePdfOutlined />,
            key: '/shared-files',
            label: (
              <Tooltip title="Ressources à télécharger">
                Ressources à télécharger
              </Tooltip>
            ),
          },
        ]
      : [{icon: <HomeOutlined />, key: '/', label: 'Accueil'}];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cognitoUser,
    forms,
    isAdmin,
    isCompanyAdmin,
    isConsultant,
    preliminaryForm,
    userInfo?.companyId,
  ]);

  const calcSelectedKeys = useCallback(() => {
    if (location.pathname.startsWith('/creator')) {
      return ['/creator'];
    } else if (location.pathname.startsWith('/modele/')) {
      return ['/modeles'];
    } else {
      return [location.pathname + location.search];
    }
  }, [location]);

  return (
    <Layout.Sider
      collapsed={collapsed}
      onCollapse={(collapsed) => {
        setCollapsed(collapsed);
      }}
      collapsible
      style={{
        height: 'calc(100vh - 112px)',
        overflowY: 'auto',
        position: 'fixed',
        left: 0,
        top: '64px',
        bottom: '48px',
        zIndex: 900,
      }}
    >
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={calcSelectedKeys()}
        inlineIndent={10}
        onClick={({key}) => {
          if (key === location.pathname + location.search) {
            return;
          }
          navigate(key as string);
        }}
        items={menuItems}
      />
    </Layout.Sider>
  );
};
