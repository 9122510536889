import {
  DownOutlined,
  LoadingOutlined,
  PieChartOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Dropdown,
  FloatButton,
  Form,
  FormInstance,
  Input,
  message,
  Row,
  Space,
  UploadFile,
} from 'antd';
import {APIClass} from 'aws-amplify';
import {
  GetReportO,
  reportLabel,
  ReportPage,
  ReportPageType,
  ReportPageV2,
  StaticReportPage,
  undeprecateReportPageIterable,
} from 'holo-api';
import {useEffect, useState} from 'react';

import {getSearchedFormTitles} from '../../client/form';
import {getReport, reportContext, updateReport} from '../../client/pdf';
import {useApi} from '../../util/auth';
import {AllReportPages, chartIcon} from './AllReportPages';
import {ActifSelectorOptions} from './charts/shared/reportInterfaces';
import {TemplateTag} from './charts/shared/RichTextEditor';
import {defaultChartValues} from './defaultChartValues';
import {GenerateReport} from './GenerateReport';

export interface ModeleFormData {
  name: string;
  description?: string;
}

export const ReportEditor = ({
  reportO,
  setReportO,
  preview,
  modeleForm,
}: {
  reportO: GetReportO;
  setReportO: (input: GetReportO) => void;
  preview: boolean;
  modeleForm: FormInstance<ModeleFormData>;
}) => {
  const api = useApi();
  const [dirty, setDirty] = useState(false);
  const [actifSelectorOptions, setActifSelectorOptions] =
    useState<ActifSelectorOptions>([]);
  const [saving, setSaving] = useState(false);
  const [templateTags, setTemplateTags] = useState<Array<TemplateTag>>([]);

  useEffect(() => {
    if (api) {
      void (async () => {
        // reasonable limit (if exeeded UI will be worthless anyhow)
        const output = await getSearchedFormTitles(api, {limit: 1000});
        const options = output.entities.map((entity) => ({
          label: entity.title,
          value: entity.id,
        }));
        setActifSelectorOptions(options);
      })();
    }
  }, [api, setActifSelectorOptions]);

  useEffect(() => {
    if (api) {
      void (async () => {
        const {prelimTags} = await reportContext(api, {});
        setTemplateTags(
          prelimTags.map((tag) => ({
            value: `{${tag}}`,
            label: tag.substring(1),
          })),
        );
      })();
    }
  }, [api]);

  const onUploadStaticPage = async (
    uploadFile: UploadFile<unknown>,
    page: StaticReportPage,
  ) => {
    page.pageName = uploadFile.name;
    if (api) {
      await updateReport(api, {
        reportId: reportO.reportId,
        report: reportO.report,
      });
      const updatedReport = await getReport(api, {
        reportId: reportO.reportId,
        accessFiles: true,
      });
      setReportO(updatedReport);

      const accessFile = updatedReport.accessFiles?.find(
        (accessFile) => accessFile.s3Key === page.pdfS3Key,
      );

      if (accessFile) {
        await fetch(accessFile.putSignedUrl, {
          method: 'PUT',
          body: uploadFile.originFileObj,
          headers: {
            'Content-Type': 'application/pdf',
            'Content-Disposition': `attachment; filename=${encodeURIComponent(uploadFile.name)}`,
          },
        });
      }
    }
  };

  const onSave = async () => {
    if (api) {
      setSaving(true);
      // no accessFiles in updateReport
      await updateReport(api, {
        reportId: reportO.reportId,
        name: reportO.name,
        description: reportO.description,
        report: reportO.report,
      });
      setDirty(false);
      setSaving(false);
      void message.success({
        key: 'report_save_success',
        content: 'Modèle sauvegardé avec succès !',
      });
    }
  };

  const updatePages = async (api: APIClass, pages: Array<ReportPage>) => {
    const newReport = {...reportO};
    newReport.report.pages = pages;
    setReportO(newReport);
    setDirty(true);
  };

  const onReorderPage = async ({
    fromIndex,
    toIndex,
  }: {
    fromIndex: number;
    toIndex: number;
  }) => {
    const pages = [...reportO.report.pages];
    const length = pages.length;
    if (api) {
      if (
        fromIndex >= 0 &&
        fromIndex < length &&
        toIndex >= 0 &&
        toIndex < length
      ) {
        pages.splice(toIndex, 0, ...pages.splice(fromIndex, 1));
        await updatePages(api, pages);
      }
    }
  };

  const onDeletePage = async (page: ReportPageV2, removeIndex: number) => {
    if (api) {
      await updatePages(
        api,
        reportO.report.pages.filter((page, index) => index !== removeIndex),
      );
    }
  };

  const onSetPage = async (newPage: ReportPageV2, index: number) => {
    if (api) {
      const newPages = [...reportO.report.pages];
      newPages[index] = newPage;
      await updatePages(api, newPages);
    }
  };

  const onValueChanged = async (data: ModeleFormData) => {
    const newReport = {...reportO};
    newReport.name = data.name;
    newReport.description = data.description;
    setReportO(newReport);
    setDirty(true);
  };

  const onAddChartPage = async ({pageType}: {pageType: ReportPageType}) => {
    const newPage = defaultChartValues(pageType);
    const pages = reportO.report.pages;
    if (api) {
      if (pageType === ReportPageType.static) {
        // we push to DB via API NOW instead of just marking as dirty
        await updateReport(api, {
          reportId: reportO.reportId,
          name: reportO.name,
          description: reportO.description,
          report: {
            pages: [...pages, newPage],
          },
        });
        // must update accessFiles with info to post on S3
        setReportO(
          await getReport(api, {
            reportId: reportO.reportId,
            accessFiles: true,
          }),
        );
      } else {
        await updatePages(api, [...pages, newPage]);
      }
    }
  };

  const buttonPageTypes = [
    ReportPageType.cover,
    ReportPageType.static,
    ReportPageType.richtext,
  ];

  const popupMenuPageTypes = [
    ReportPageType.indexDurable,
    ReportPageType.radar,
    ReportPageType.poidsActif,
    ReportPageType.weightedCapital,
    ReportPageType.forceFaiblesse,
    ReportPageType.formulaTree,
    ReportPageType.tagRadar,
    ReportPageType.tagDetails,
    ReportPageType.competitivite,
    ReportPageType.equlibreForce,
    ReportPageType.conseilConsultant,
  ];

  const allPages = Array.from(
    undeprecateReportPageIterable(reportO.report.pages),
  );

  return (
    <>
      <Row justify="end" style={{marginBottom: 8}}>
        <Col>
          <GenerateReport preview={preview} reportId={reportO.reportId} />
        </Col>
      </Row>
      <Form
        form={modeleForm}
        layout="vertical"
        onValuesChange={(_changedValues, allValues) =>
          onValueChanged(allValues)
        }
      >
        <Form.Item label="Nom" name="name" rules={[{required: true}]}>
          <Input />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input.TextArea autoSize />
        </Form.Item>
      </Form>

      <AllReportPages
        accessFiles={reportO.accessFiles}
        actifSelectorOptions={actifSelectorOptions}
        allPages={allPages}
        onDeletePage={onDeletePage}
        onReorderPage={onReorderPage}
        onSetPage={onSetPage}
        onUploadStaticPage={onUploadStaticPage}
        templateTags={templateTags}
      ></AllReportPages>

      <Divider></Divider>
      <Row justify="center">
        <Col>
          <Button.Group>
            {buttonPageTypes.map((pageType) => (
              <Button onClick={() => onAddChartPage({pageType})} key={pageType}>
                {chartIcon(pageType)}
                {reportLabel(pageType)}
              </Button>
            ))}

            <Dropdown
              menu={{
                items: popupMenuPageTypes.map((chartType) => ({
                  label: reportLabel(chartType),
                  key: chartType,
                  icon: chartIcon(chartType),
                })),
                onClick: (event) =>
                  onAddChartPage({pageType: event.key as ReportPageType}),
              }}
            >
              <Button>
                <Space>
                  <PieChartOutlined />
                  Diagramme
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </Button.Group>
        </Col>
      </Row>
      <FloatButton
        icon={saving ? <LoadingOutlined /> : <SaveOutlined />}
        onClick={() => onSave()}
        tooltip={
          dirty ? 'Sauvegarder modèle' : 'Aucun changement à sauvegarder'
        }
        type={dirty ? 'primary' : undefined}
      />
    </>
  );
};
