import {useDebounceFn} from 'ahooks';
import {Form, Input, Select} from 'antd';
import {
  FormCategory,
  formCategoryLabel,
  orderedFormCategories,
  ReportPageType,
  WeightedCapitalChartReportPage,
} from 'holo-api';
import {useEffect} from 'react';

interface WeightedCapitalFormType {
  title?: string;
  subTitle?: string;
  category: FormCategory;
  explainTable?: string;
  explainBar?: string;
}

export const WeightedCapitalChart = ({
  chartPage,
  setChartPage,
}: {
  chartPage: WeightedCapitalChartReportPage;
  setChartPage: (page: WeightedCapitalChartReportPage) => void;
}) => {
  const [form] = Form.useForm<WeightedCapitalFormType>();

  useEffect(() => {
    form.setFieldsValue({
      title: chartPage.title,
      subTitle: chartPage.subTitle,
      category: chartPage.category,
      explainBar: chartPage.explainBar,
      explainTable: chartPage.explainTable,
    });
  }, [form, chartPage]);

  const {run: onChangedPage} = useDebounceFn(
    async (values: WeightedCapitalFormType) => {
      try {
        const validated = await form.validateFields();
        const newChartPage: WeightedCapitalChartReportPage = {
          pageType: ReportPageType.weightedCapital,
          title: validated.title,
          subTitle: validated.subTitle,
          category: validated.category,
          explainBar: validated.explainBar,
          explainTable: validated.explainTable,
        };
        setChartPage(newChartPage);
      } catch (err) {
        console.warn('onChangedPage', values);
        console.error('Not a valid form', err);
      }
    },
    {wait: 500},
  );

  return (
    <Form
      form={form}
      onValuesChange={(_changedValues, values) => onChangedPage(values)}
    >
      <Form.Item label="Titre" name="title">
        <Input />
      </Form.Item>
      <Form.Item label="Sous Titre" name="subTitle">
        <Input />
      </Form.Item>

      <Form.Item label="Capital" name="category" required={true}>
        <Select
          style={{width: '100%'}}
          options={orderedFormCategories.map((category) => ({
            label: formCategoryLabel(category),
            value: category,
          }))}
        />
      </Form.Item>

      <Form.Item label="Explication du tableau" name="explainTable">
        <Input />
      </Form.Item>

      <Form.Item label="Explication du graphique" name="explainBar">
        <Input />
      </Form.Item>
    </Form>
  );
};
