import {JSONSchemaType} from 'ajv';

import {Right} from '../../database/types';

export const formPackCompanyGroupRightRoutes = {
  singular: '/formPackCompanyGroupRight',
  singularId: '/formPackCompanyGroupRight/{formId}/{companyGroupId}',

  pluralLeft: '/formPack/{formId}/companyGroup/rights',
  pluralRight: '/companyGroup/{companyGroupId}/formPack/rights',
};

export interface CreateFormPackCompanyGroupRightSchemaType {
  formId: number;
  companyGroupId: number;
  right: Right;
}

export const CreateFormPackCompanyGroupRightSchema: JSONSchemaType<CreateFormPackCompanyGroupRightSchemaType> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['formId', 'companyGroupId', 'right'],
    properties: {
      formId: {type: 'number'},
      companyGroupId: {type: 'number'},
      right: {
        type: 'string',
        enum: Object.values(Right),
      },
    },
  } as const;

export interface FormPackCompanyGroupRightGetRightPluralEntity {
  companyGroupId: number;
  formId: number;
  right: Right;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
}

export interface FormPackCompanyGroupRightGetRightPluralO {
  entities: Array<FormPackCompanyGroupRightGetRightPluralEntity>;
  total: number;
}
