import {Modal, ModalProps} from 'antd';
import {FC} from 'react';

interface AssignModalProps extends ModalProps {
  content: React.ReactNode;
}

export const AssignModal: FC<AssignModalProps> = ({...props}) => {
  return (
    <Modal
      title={props.title}
      width="600px"
      centered
      cancelText="Annuler"
      closable
      maskClosable={false}
      open={props.open}
      onOk={(e) => {
        props.onOk?.(e);
      }}
      onCancel={(e) => {
        props.onCancel?.(e);
      }}
    >
      {props.content}
    </Modal>
  );
};
