import {aggregateFormSummary, FormAggregation} from 'holo-api';
import {useEffect, useState} from 'react';

import {useFormsSummaries} from './useFormsSummaries';

export const useFormsAggregation = () => {
  const {formsSummaries} = useFormsSummaries();
  const [formAggregations, setFormsAggregations] = useState<
    Array<FormAggregation> | undefined
  >(undefined);

  useEffect(() => {
    if (!formsSummaries) {
      setFormsAggregations(undefined);
      return;
    }

    setFormsAggregations(
      formsSummaries.map((formSummary) => aggregateFormSummary(formSummary)),
    );
  }, [formsSummaries]);

  return formAggregations;
};
