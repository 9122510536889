import {Button, Col, Form, Modal, Row, Select, Tabs} from 'antd';
import {
  assertUnreachable,
  CreateAnswerMatrixT,
  Matrix1D,
  Matrix2D,
  MatrixID,
  MatrixPA,
  UpdateAnswerMatrixT,
} from 'holo-api';
import {useCallback, useEffect, useMemo, useState} from 'react';

import {
  answerMatrixCreate,
  answerMatrixGetPlural,
  answerMatrixUpdate,
} from './client/answerMatrix';
import {FormMat1D} from './components/ui/matrix/Matrix1D';
import {FormMat2D} from './components/ui/matrix/Matrix2D';
import {FormMatID} from './components/ui/matrix/MatrixID';
import {FormMatPA} from './components/ui/matrix/MatrixPA';
import {useMatrixStore} from './stores/matrix';
import {useApi} from './util/auth';

type TabType = '1D' | '2D' | 'PA' | 'ID';

export const Matrices = () => {
  const api = useApi();
  const [modal, contextHolder] = Modal.useModal();

  const [activeTab, setActiveTab] = useState<TabType>('1D');
  const [matrixId, setMatrixId] = useState<number | undefined>(undefined);
  const [form1D] = Form.useForm<Matrix1D>();
  const [form2D] = Form.useForm<Matrix2D>();
  const [formID] = Form.useForm<MatrixID>();
  const cols1D = Form.useWatch('cols', {form: form1D, preserve: true});
  const cols2D = Form.useWatch('cols', {form: form2D, preserve: true});

  const matrices1d = useMatrixStore((state) => state.matrix1d);
  const matrices2d = useMatrixStore((state) => state.matrix2d);
  const matricesPA = useMatrixStore((state) => state.matrixPA);
  const matricesID = useMatrixStore((state) => state.matrixID);
  const setMatrices = useMatrixStore((state) => state.setMatrices);

  const [saving, setSaving] = useState(false);
  const [matrixPA, setMatrixPA] = useState<MatrixPA | undefined>(undefined);
  const [dirty, setDirty] = useState<boolean>(false);

  const activeForm = useMemo(() => {
    switch (activeTab) {
      case '1D':
        return form1D;
      case '2D':
        return form2D;
      case 'PA':
        return undefined;
      case 'ID':
        return formID;
      default:
        assertUnreachable(activeTab);
        return null;
    }
  }, [form1D, form2D, formID, activeTab]);

  const activeMatrices = useMemo(() => {
    switch (activeTab) {
      case '1D':
        return matrices1d;
      case '2D':
        return matrices2d;
      case 'PA': {
        const matrix = matricesPA[0];
        if (matrix) {
          setMatrixId(matrix.id);
        }
        return matricesPA;
      }
      case 'ID': {
        const matrix = matricesID[0];
        if (matrix) {
          setMatrixId(matrix.id);
        }
        return matricesID;
      }
      default:
        assertUnreachable(activeTab);
        return null;
    }
  }, [matrices1d, matrices2d, matricesPA, matricesID, activeTab]);

  const loadMatrix = useCallback(() => {
    if (!matrixId) {
      activeForm?.resetFields();
      return;
    }
    try {
      switch (activeTab) {
        case '1D':
          {
            const matrix = matrices1d.find((matrix) => matrix.id === matrixId);
            if (!matrix) {
              throw new Error('Matrix not found');
            }
            form1D.setFieldsValue(matrix);
          }
          break;
        case '2D':
          {
            const matrix = matrices2d.find((matrix) => matrix.id === matrixId);
            if (!matrix) {
              throw new Error('Matrix not found');
            }
            form2D.setFieldsValue(matrix);
          }
          break;
        case 'PA':
          {
            const matrix = matricesPA.find((matrix) => matrix.id === matrixId);
            if (!matrix) {
              throw new Error('Matrix not found');
            }
            setMatrixPA(matrix);
          }
          break;
        case 'ID':
          {
            const matrix = matricesID.find((matrix) => matrix.id === matrixId);
            if (!matrix) {
              throw new Error('Matrix not found');
            }
            formID.setFieldsValue(matrix);
          }
          break;
        default:
          assertUnreachable(activeTab);
      }
    } catch (err) {
      console.error(err);
    }
  }, [
    activeTab,
    form1D,
    form2D,
    formID,
    matrices1d,
    matrices2d,
    matricesPA,
    matricesID,
    activeForm,
    matrixId,
    setMatrixPA,
  ]);

  const loadMatrices = useCallback(async () => {
    if (!api) {
      return;
    }

    const matrices = await answerMatrixGetPlural(api);
    setMatrices(matrices.entities);
  }, [api, setMatrices]);

  useEffect(() => {
    void loadMatrices();
  }, [loadMatrices]);

  useEffect(() => {
    loadMatrix();
  }, [loadMatrix]);

  const saveMatrix = useCallback(
    async (matrix: Matrix1D | Matrix2D | MatrixPA) => {
      if (!api) {
        return;
      }

      try {
        setSaving(true);

        if (!matrixId || matrixId === -1) {
          const foundInMatrix1d = matrices1d.find(
            (curMatrix) => curMatrix.name === matrix.name,
          );
          const foundInMatrix2d = matrices2d.find(
            (curMatrix) => curMatrix.name === matrix.name,
          );
          const foundInMatrixPA = matricesPA.find(
            (curMatrix) => curMatrix.name === matrix.name,
          );
          if (foundInMatrix1d || foundInMatrix2d || foundInMatrixPA) {
            void modal.error({
              title: 'Le nom de la matrice est déjà utilisé',
            });
          } else {
            const input: CreateAnswerMatrixT = {
              matrixND: matrix,
            };
            const {id} = await answerMatrixCreate(api, input);
            void modal.success({
              title: 'Matrice sauvegardée',
              content: 'La nouvelle matrice a été sauvegardée avec succès !',
              onOk: async () => {
                await loadMatrices();
                setMatrixId(id);
              },
            });
          }
        } else {
          const input: UpdateAnswerMatrixT = {
            matrixND: matrix,
          };
          await answerMatrixUpdate(api, matrixId, input);
          setDirty(false);
          void modal.success({
            title: 'Matrice sauvegardée',
            content: 'La matrice a été sauvegardée avec succès !',
            onOk: loadMatrices,
          });
        }
      } finally {
        setSaving(false);
      }
    },
    [api, matrixId, loadMatrices, matrices1d, matrices2d, matricesPA, modal],
  );

  const handleFinish = useCallback(async () => {
    switch (activeTab) {
      case '1D':
        {
          try {
            const values = await form1D.validateFields();
            values.cols = values.cols.sort((a, b) => a.max - b.max);
            await saveMatrix(values);
          } catch (err) {
            console.error(err);
          }
        }
        break;
      case '2D':
        {
          try {
            const values = await form2D.validateFields();
            values.cols.forEach((col, index) => {
              if (index === 0) {
                col.rows = col.rows.sort((a, b) => a.max - b.max);
                return;
              }
              col.rows.forEach(
                (row, index) => (row.max = values.cols[0].rows[index].max),
              );
              col.rows = col.rows.sort((a, b) => a.max - b.max);
            });
            values.cols = values.cols.sort((a, b) => a.max - b.max);
            await saveMatrix(values);
          } catch (err) {
            console.error(err);
          }
        }
        break;
      case 'PA':
        {
          try {
            if (matrixPA) {
              await saveMatrix(matrixPA);
            }
          } catch (err) {
            console.error(err);
          }
        }
        break;
      case 'ID':
        {
          try {
            const values = await formID.validateFields();
            await saveMatrix(values);
          } catch (err) {
            console.error(err);
          }
        }
        break;
      default:
        assertUnreachable(activeTab);
    }
  }, [activeTab, form1D, form2D, formID, saveMatrix, matrixPA]);

  const Sauvegarder = () => {
    return (
      <Button
        type="primary"
        style={{marginTop: 30}}
        onClick={handleFinish}
        loading={saving}
        disabled={!dirty}
      >
        Sauvegarder
      </Button>
    );
  };

  const isPA = activeTab === 'PA';

  const onMatrixPAChanged = (matrixPA: MatrixPA) => {
    setMatrixPA(matrixPA);
    setDirty(true);
  };

  return (
    <>
      {contextHolder}
      <Row justify="center" gutter={8} style={{marginBottom: 8}}>
        <Row gutter={16}>
          <Col flex={1}>
            <Select
              showSearch
              allowClear
              style={{minWidth: '300px'}}
              listItemHeight={32}
              listHeight={200}
              value={matrixId}
              placeholder={`Sélectionner une matrice ${
                isPA ? '' : activeTab
              } à charger`}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={activeMatrices?.map((matrix) => ({
                label: matrix.name,
                value: matrix.id,
              }))}
              onSelect={(value) => {
                if (!value) {
                  return;
                }
                setMatrixId(value);
              }}
              disabled={isPA}
            />
          </Col>
        </Row>
        <Col>
          <Button
            onClick={() => {
              setMatrixId(undefined);
              activeForm?.resetFields;
            }}
            disabled={isPA}
          >
            Réinitialiser la matrice
          </Button>
        </Col>
      </Row>
      <Tabs
        type="card"
        onChange={(activeKey) => {
          setMatrixId(undefined);
          setActiveTab(activeKey as TabType);
          activeForm?.resetFields();
        }}
        items={[
          {
            key: '1D',
            label: '1D',
            children: (
              <>
                <FormMat1D
                  form1D={form1D}
                  cols1D={cols1D}
                  setDirty={setDirty}
                ></FormMat1D>
                <Sauvegarder />
              </>
            ),
          },
          {
            key: '2D',
            label: '2D',
            children: (
              <>
                <FormMat2D
                  form2D={form2D}
                  cols2D={cols2D}
                  setDirty={setDirty}
                ></FormMat2D>
                <Sauvegarder />
              </>
            ),
          },
          {
            key: 'PA',
            label: 'Poids Actifs',
            children: (
              <>
                <FormMatPA
                  matrixPA={matrixPA}
                  onMatrixPAChanged={onMatrixPAChanged}
                ></FormMatPA>
                <Sauvegarder />
              </>
            ),
          },
          {
            key: 'ID',
            label: 'Indice Création Valeur Durable',
            children: (
              <>
                <FormMatID formID={formID} setDirty={setDirty}></FormMatID>
                <Sauvegarder />
              </>
            ),
          },
        ]}
      />
    </>
  );
};
