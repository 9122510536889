import {FloatButton, Layout as AntLayout, theme} from 'antd';
import {FC, useEffect, useState} from 'react';
import {Outlet} from 'react-router-dom';

import {usePackStore} from '../../stores/pack';
import {useApi} from '../../util/auth';
import {Footer} from './Footer';
import {Header} from './Header';
import {Sidebar} from './Sidebar';

const {Content} = AntLayout;

export const Layout: FC = () => {
  const {token} = theme.useToken();
  const api = useApi();
  const loadPack = usePackStore((state) => state.loadPack);

  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  useEffect(() => {
    document.title = process.env.REACT_APP_APP_NAME ?? '';
  }, []);

  useEffect(() => {
    if (!api) {
      return;
    }
    void loadPack(api);
  }, [api, loadPack]);

  return (
    <AntLayout
      style={{
        minHeight: '100vh',
      }}
    >
      <Sidebar
        collapsed={sidebarCollapsed}
        setCollapsed={setSidebarCollapsed}
      />
      <Content>
        <Header />
        <AntLayout style={{marginLeft: sidebarCollapsed ? 80 : 200}}>
          <div id="backdrop" />
          <Content
            style={{
              margin: 16,
              padding: 16,
              backgroundColor: token.colorBgContainer,
            }}
          >
            <Outlet />
          </Content>
          <FloatButton.BackTop
            duration={300}
            type="primary"
            tooltip="Haut de page"
            style={{right: 'calc((100vw - 200px - 40px) / 2)'}}
          />
          <Footer />
        </AntLayout>
      </Content>
    </AntLayout>
  );
};
