import {EditOutlined, RollbackOutlined} from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  FormItemProps,
  Input,
  InputRef,
  Row,
  Skeleton,
  Tag,
  Tooltip,
} from 'antd';
import {NamePath} from 'antd/es/form/interface';
import {
  AnswerMatrixT,
  CreatorItem,
  customMath,
  getKeysFromReference,
  isDefaultChapter,
  isFormulaReference,
  isNumericQuestionReference,
  isSelectQuestionReference,
  isTemplateFormulaReference,
  isTextQuestion,
  ItemType,
} from 'holo-api';
import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {LoadingState} from '../../../hooks/useLoading';
import {useCreatorStore} from '../../../stores/creator';
import {useMatrixStore} from '../../../stores/matrix';
import {
  colors,
  compareNamePath,
  getIndexesFromKeys,
  highlightItem,
  mergeNamePath,
} from '../../../util/form';
import {parseAndTraverseFormula} from '../../../util/math';
const {evaluate} = customMath;

export interface MathScope {
  [key: string]: number;
}

export const CreatorFormulaInput: FC<
  FormItemProps & {
    prefixName: NamePath;
    chapterKey: number;
    formulaKey: number;
  }
> = ({prefixName, chapterKey, formulaKey, ...props}) => {
  const name = useMemo(
    () => mergeNamePath(prefixName, props.name ?? 'formula'),
    [prefixName, props.name],
  );
  const form = Form.useFormInstance();
  const value = Form.useWatch(name, form);

  const inputRef = useRef<InputRef>(null);

  const [loading, setLoading] = useState(false);
  const [tokens, setTokens] = useState<ReactNode[]>([]);

  const currentFormulaName = useCreatorStore(
    (state) => state.currentFormulaName,
  );
  const setCurrentFormulaName = useCreatorStore(
    (state) => state.setCurrentFormulaName,
  );
  const activeKeys = useCreatorStore((state) => state.currentActiveKeys);
  const addActiveKey = useCreatorStore((state) => state.addCurrentActiveKey);
  const initialForm = useCreatorStore((state) => state.initialForm);
  const templateFormulasMapping = useCreatorStore(
    (state) => state.templateFormulasMapping,
  );
  const {matrix1d, matrix2d, loading: matricesLoading} = useMatrixStore();
  const allMatrices = useMemo(
    () =>
      [...matrix1d, ...matrix2d].map((m) => {
        const am: AnswerMatrixT = {
          id: m.id,
          name: m.name,
          description: m.description ? m.description : '',
          mat2d: m.mat2d,
          matrixND: m,
        };
        return am;
      }),
    [matrix1d, matrix2d],
  );

  const validateFormula = useCallback(
    (formula: string): [boolean, string] => {
      const checkingScope: MathScope = {};

      try {
        const currentForm = JSON.parse(JSON.stringify(form.getFieldsValue()));
        const tokens: JSX.Element[] = [];

        parseAndTraverseFormula(formula, (child, index) => {
          const textContent = child.textContent ?? '';
          const isSelectQuestion = isSelectQuestionReference(textContent);
          const isNumericQuestion = isNumericQuestionReference(textContent);
          const isFormula = isFormulaReference(textContent);
          const isTemplateFormula = isTemplateFormulaReference(textContent);
          let tooltip = '';
          const [referenceChapterKey, itemKey] =
            getKeysFromReference(textContent);

          if (!textContent) {
            if (child.classList.contains('math-implicit-binary-operator')) {
              const nextSibling = child.nextSibling?.textContent;
              const previousSibling = child.previousSibling?.textContent;
              throw new Error(
                `Une multiplication implicite a été repérée : ${previousSibling}${nextSibling}`,
              );
            }
            throw new Error('Un des symboles de la formule est erroné');
          }
          if (child.classList.contains('math-symbol')) {
            if (
              !isSelectQuestion &&
              !isNumericQuestion &&
              !isFormula &&
              !isTemplateFormula
            ) {
              throw new Error(
                'Le format ne correspond pas à une référence (question, formule) ni à un nombre: ' +
                  textContent,
              );
            } else {
              if (isTemplateFormula) {
                if (!templateFormulasMapping[textContent]) {
                  throw new Error(
                    `Aucune formule de template ne correspond à cette référence: ${textContent}`,
                  );
                }
                checkingScope[textContent] = Math.random();
                return;
              }

              const [referenceChapterKey, itemKey] =
                getKeysFromReference(textContent);
              const [chapterIndex, itemIndex] = getIndexesFromKeys(
                currentForm,
                referenceChapterKey,
                itemKey,
              );

              const item: CreatorItem =
                currentForm.chapters[chapterIndex].items[itemIndex];

              if (!isTemplateFormula && !item) {
                throw new Error(
                  `Aucun élément ne se trouve à cette référence: ${textContent}`,
                );
              }

              if (isFormula && item.type !== ItemType.FORMULA) {
                throw new Error(
                  `Aucune formule ne correspond à cette description : ${textContent}`,
                );
              }
              if (
                (isSelectQuestion || isNumericQuestion) &&
                item.type !== ItemType.QUESTION
              ) {
                throw new Error(
                  `Aucune question ne correspond à cette description : ${textContent}`,
                );
              }
              if (
                isNumericQuestion &&
                isTextQuestion(item) &&
                item.numeric !== true
              ) {
                throw new Error(
                  `Des questions ne sont pas de type numérique : ${textContent}`,
                );
              }
              if (
                isFormula &&
                referenceChapterKey === chapterKey &&
                itemKey === formulaKey
              ) {
                throw new Error(
                  `Il n'est pas possible de référencer la formule avec elle-même : ${textContent}`,
                );
              }
              checkingScope[textContent] = Math.random();

              if (
                !isTemplateFormula &&
                item &&
                (item.type === 'question' || item.type === 'formula')
              ) {
                tooltip = item.title;
              }
            }
          }

          const color = child.classList.contains('math-symbol')
            ? colors[(referenceChapterKey + itemKey) % 12]
            : child.classList.contains('math-number')
              ? '#15c'
              : child.classList.contains('math-parenthesis') ||
                  child.classList.contains('math-paranthesis')
                ? '#555'
                : child.classList.contains('math-function')
                  ? 'pink'
                  : undefined;

          const tag = (
            <Tag
              color={color}
              style={
                isSelectQuestion || isNumericQuestion || isFormula
                  ? {cursor: 'pointer'}
                  : {}
              }
              onClick={() => {
                if (!isSelectQuestion && !isNumericQuestion && !isFormula) {
                  return;
                }
                const itemComponent = document.getElementById(
                  `C${referenceChapterKey}I${itemKey}`,
                );
                if (!itemComponent) {
                  return;
                }
                const key = referenceChapterKey.toString();
                highlightItem(`C${referenceChapterKey}I${itemKey}`, {
                  scrollIntoView: true,
                  scrollDelay: activeKeys?.includes(key) ? 0 : 500,
                });

                if (!activeKeys) {
                  addActiveKey(key);
                  return;
                } else {
                  if (activeKeys.includes(key)) {
                    return;
                  }
                  addActiveKey(key);
                }
              }}
            >
              {textContent}
            </Tag>
          );

          tokens.push(
            isSelectQuestion || isNumericQuestion || isFormula ? (
              <Tooltip title={tooltip} key={index}>
                {tag}
              </Tooltip>
            ) : (
              <span key={index}>{tag}</span>
            ),
          );
        });
        evaluate(formula, {...checkingScope, allMatrices});

        setTokens(tokens);
        setLoading(false);
        return [true, 'Success'];
      } catch (error) {
        let message = 'Erreur inconnue';
        if (error instanceof Error) {
          message = error.message;
        }
        return [false, message];
      }
    },
    [
      activeKeys,
      addActiveKey,
      allMatrices,
      chapterKey,
      form,
      formulaKey,
      templateFormulasMapping,
    ],
  );

  useEffect(() => {
    if (matricesLoading !== LoadingState.Loaded) {
      return;
    }
    if (!value) {
      setTokens([]);
      if (loading) {
        setLoading(false);
      }
      return;
    }

    if (!compareNamePath(currentFormulaName, name)) {
      validateFormula(value);
      return;
    }

    if (!loading) {
      setLoading(true);
    }
  }, [
    value,
    matricesLoading,
    currentFormulaName,
    name,
    loading,
    validateFormula,
  ]);

  return (
    <Form.Item label="Formule" required>
      <Row align="top" gutter={8}>
        <Col flex={1}>
          <Form.Item
            {...props}
            rules={[
              {
                required: true,
              },
              {
                validateTrigger: 'onSubmit',
                validator: (_, value) => {
                  const [isValid, message] = validateFormula(value);
                  if (!isValid) {
                    return Promise.reject(message);
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              ref={inputRef}
              readOnly
              suffix={
                initialForm ? (
                  <Tooltip title="Revenir à la formule initiale">
                    <Button
                      size="small"
                      onClick={async (e) => {
                        const targetChapter = initialForm?.chapters[chapterKey];
                        if (!isDefaultChapter(targetChapter)) {
                          throw new Error(
                            'The targeted chapter is not valid and does not contain this formula',
                          );
                        }
                        const item = targetChapter.items[formulaKey];
                        if (item.type !== ItemType.FORMULA) {
                          return;
                        }
                        e.stopPropagation();
                        form.setFieldValue(name, item.formula);
                        void form.validateFields([name]);
                      }}
                    >
                      <RollbackOutlined />
                    </Button>
                  </Tooltip>
                ) : null
              }
              onClick={() => {
                const backdrop = document.getElementById('backdrop');
                backdrop?.classList.remove('active');

                const drawer =
                  document.getElementsByClassName('formula-drawer')?.[0];
                drawer?.classList.remove('shake');

                setCurrentFormulaName(
                  name,
                  value,
                  inputRef.current?.input?.selectionStart ?? undefined,
                  inputRef.current?.input?.selectionEnd ?? undefined,
                );
                setTimeout(() => {
                  backdrop?.classList.add('active');
                  drawer?.classList.add('shake');
                }, 1);
              }}
            />
          </Form.Item>
        </Col>
        <Col>
          <Tooltip title="Modifier la formule">
            <Button
              style={{
                marginTop: 1,
              }}
              icon={<EditOutlined />}
              onClick={() => {
                setCurrentFormulaName(name, value);
              }}
            />
          </Tooltip>
        </Col>
      </Row>
      {loading || matricesLoading !== LoadingState.Loaded ? (
        <Skeleton title={false} paragraph={{rows: 1}} active />
      ) : (
        tokens
      )}
    </Form.Item>
  );
};
