import {FC, useCallback, useEffect, useState} from 'react';
import {Navigate, useNavigate} from 'react-router-dom';

import {useSignOut, useUser} from '../../util/auth';
import {Spinner} from '../ui/Spinner';

export const Logout: FC = () => {
  const user = useUser();
  const signOut = useSignOut();
  const navigate = useNavigate();

  const [processRedirect, setProcessRedirect] = useState(false);

  const handleRedirect = useCallback(() => {
    if (user) {
      handleRedirect();
      return;
    }

    navigate('/login', {replace: true});
  }, [navigate, user]);

  const handleSignOut = useCallback(async () => {
    await signOut();
    setProcessRedirect(true);
  }, [signOut]);

  useEffect(() => {
    if (!user || !processRedirect) return;
    setProcessRedirect(false);

    handleRedirect();
  }, [handleRedirect, processRedirect, user]);

  if (user) {
    void handleSignOut();
    return <Spinner tip="Déconnection en cours" />;
  }

  return <Navigate to="/login" replace />;
};
