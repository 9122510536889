import {UserSchemaType} from 'holo-api';
import {create} from 'zustand';

interface UserStoreProps {
  userInfo: UserSchemaType | undefined;
  setUserInfo: (userInfo: UserSchemaType | undefined) => void;
  isCompanyIdReady: boolean;
  companyId: number | undefined;
  setCompanyId: (id: number | undefined) => void;
}

export const useUserStore = create<UserStoreProps>((set) => ({
  userInfo: undefined,
  setUserInfo: (userInfo: UserSchemaType | undefined) =>
    set((state) => ({
      ...state,
      userInfo: userInfo ? {...userInfo} : undefined, // TS screaming if spreading value that can be undefined
    })),
  isCompanyIdReady: false,
  companyId: undefined,
  setCompanyId: (id: number | undefined) =>
    set((state) => ({...state, companyId: id, isCompanyIdReady: true})),
}));
