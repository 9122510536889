import {JSONSchemaType} from 'ajv';

import {GetSingularForm, GetSingularFormSchema} from './form';

// **************************************************************************

export interface CompanyPackContentsV1 {
  version: 1;
  savedMainFormIds: Array<number>;
  savedFormMap: Record<string, GetSingularForm | undefined>; // key is formId
  savedTemplateInstances: Array<{
    id: number;
    name: string;
    weight: number;
    templateId: number;
  }>;
}

export const CompanyPackContentsV1Schema: JSONSchemaType<CompanyPackContentsV1> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['version', 'savedMainFormIds', 'savedFormMap'],
    properties: {
      version: {type: 'integer', const: 1},
      savedMainFormIds: {type: 'array', items: {type: 'integer'}},
      savedFormMap: {
        type: 'object',
        required: [],
        additionalProperties: GetSingularFormSchema,
        propertyNames: {type: 'string', pattern: '\\d+'},
      },
      savedTemplateInstances: {
        type: 'array',
        items: {
          type: 'object',
          required: [],
          properties: {
            id: {type: 'integer'},
            name: {type: 'string'},
            weight: {type: 'integer'},
            templateId: {type: 'integer'},
          },
        },
      },
    },
  };

export type CompanyPackContents = CompanyPackContentsV1;

export const CompanyPackContentsSchema: JSONSchemaType<CompanyPackContents> = {
  type: 'object',
  oneOf: [CompanyPackContentsV1Schema],
};

// **************************************************************************

export const companyPackGetSingularIdPath = '/companyPack/{companyId}/{packId}';
export interface CompanyPackGetSingularIdP {
  companyId: string;
  packId: string;
}
export interface CompanyPackGetSingularIdO {
  companyId: number;
  packId: number;
  isClosed: boolean;
  contents?: CompanyPackContents;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
}
export const CompanyPackGetSingularIdPSchema: JSONSchemaType<CompanyPackGetSingularIdP> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['companyId', 'packId'],
    properties: {
      companyId: {type: 'string'},
      packId: {type: 'string'},
    },
  };
export const CompanyPackGetSingularIdOSchema: JSONSchemaType<CompanyPackGetSingularIdO> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['companyId', 'packId', 'isClosed', 'createdAt', 'updatedAt'],
    properties: {
      isClosed: {type: 'boolean'},
      companyId: {type: 'integer'},
      packId: {type: 'integer'},
      contents: {...CompanyPackContentsSchema, nullable: true},
      createdAt: {type: 'string'},
      updatedAt: {type: 'string'},
      deletedAt: {type: 'string', nullable: true},
    },
  };

// **************************************************************************

export const companyPackGetPluralLeftPath = '/company/{companyId}/packs';
export interface CompanyPackGetPluralLeftP {
  companyId: string;
}
export interface CompanyPackGetLeftPluralElem {
  name: string;
  id: number;
  updatedAt: string;
  minorVersion: number;
  patchVersion: number;
}
export interface CompanyPackGetLeftPluralO {
  entities: {
    pack: CompanyPackGetLeftPluralElem;
  }[];
  total: number;
}
export const CompanyPackGetPluralLeftPSchema: JSONSchemaType<CompanyPackGetPluralLeftP> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['companyId'],
    properties: {
      companyId: {type: 'string'},
    },
  };
export const CompanyPackGetLeftPluralOSchema: JSONSchemaType<CompanyPackGetLeftPluralO> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['entities', 'total'],
    properties: {
      entities: {
        type: 'array',
        items: {
          type: 'object',
          additionalProperties: false,
          required: ['pack'],
          properties: {
            pack: {
              type: 'object',
              additionalProperties: false,
              required: [
                'name',
                'id',
                'updatedAt',
                'minorVersion',
                'patchVersion',
              ],
              properties: {
                name: {type: 'string'},
                id: {type: 'integer'},
                updatedAt: {type: 'string'},
                minorVersion: {type: 'integer'},
                patchVersion: {type: 'integer'},
              },
            },
          },
        },
      },
      total: {type: 'integer'},
    },
  };
// **************************************************************************

export const companyPackGetPluralRightPath = '/pack/{packId}/companies';
export interface CompanyPackGetPluralRightP {
  packId: string;
}
export interface CompanyPackGetRightPluralElem {
  company: {
    name: string;
    activePackId?: number | null;
  };
}

export interface CompanyPackGetRightPluralO {
  entities: CompanyPackGetRightPluralElem[];
}

export const CompanyPackGetPluralRightPSchema: JSONSchemaType<CompanyPackGetPluralRightP> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['packId'],
    properties: {
      packId: {type: 'string'},
    },
  };

export const CompanyPackGetRightPluralOSchema: JSONSchemaType<CompanyPackGetRightPluralO> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['entities'],
    properties: {
      entities: {
        type: 'array',
        items: {
          type: 'object',
          additionalProperties: false,
          required: ['company'],
          properties: {
            company: {
              type: 'object',
              additionalProperties: false,
              required: ['name'],
              properties: {
                name: {type: 'string'},
                activePackId: {type: 'integer', nullable: true},
              },
            },
          },
        },
      },
    },
  };

// **************************************************************************

export const companyPackPutSingularPath = '/companyPack';

export interface CompanyPackPutSingularI {
  companyId: number;
  packId: number;
  setAsActivePack: boolean;
}

export interface CompanyPackPutSingularO {
  companyId: number;
  packId: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | undefined;
}

export const CompanyPackPutSingularISchema: JSONSchemaType<CompanyPackPutSingularI> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['companyId', 'packId', 'setAsActivePack'],
    properties: {
      companyId: {type: 'number'},
      packId: {type: 'number'},
      setAsActivePack: {type: 'boolean'},
    },
  };

export const CompanyPackPutSingularOSchema: JSONSchemaType<CompanyPackPutSingularO> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['companyId', 'packId', 'createdAt'],
    properties: {
      companyId: {type: 'number'},
      packId: {type: 'number'},
      createdAt: {type: 'string'},
      updatedAt: {type: 'string'},
      deletedAt: {type: 'string', nullable: true},
    },
  };

// **************************************************************************

export const companyPackDeleteSingularIdPath =
  '/companyPack/{companyId}/{packId}';

export interface CompanyPackDeleteSingularIdP {
  companyId: string;
  packId: string;
}
export const CompanyPackDeleteSingularIdPSchema: JSONSchemaType<CompanyPackDeleteSingularIdP> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['companyId', 'packId'],
    properties: {
      companyId: {type: 'string'},
      packId: {type: 'string'},
    },
  };

// **************************************************************************

export const companyPackPostSetActivePath =
  '/companyPack/{companyId}/{packId}/setActive';

export interface CompanyPackSetActiveP {
  companyId: string;
  packId: string;
}
export const CompanyPackSetActivePSchema: JSONSchemaType<CompanyPackSetActiveP> =
  {
    type: 'object',
    additionalProperties: false,
    required: ['companyId', 'packId'],
    properties: {
      companyId: {type: 'string'},
      packId: {type: 'string'},
    },
  };

// **************************************************************************
