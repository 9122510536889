import {Col, Form, message, Modal, ModalProps, Row, Select} from 'antd';
import {APIClass} from 'aws-amplify';
import {Right} from 'holo-api';
import {CSSProperties, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import {
  createFormPackCompanyGroupRight,
  formPackCompanyGroupRightGetRightPlural,
} from '../../../client/pack';
import {usePackStore} from '../../../stores/pack';
import {useApi} from '../../../util/auth';
import {Spinner} from '../Spinner';

const formStyle: CSSProperties = {
  padding: '8px',
};

const titleStyle: CSSProperties = {
  fontWeight: 'bold',
  marginBottom: '12px',
};

type ActiveRights = Record<number, Right>;

const collectAllRights = async (input: {
  api: APIClass;
  companyId: number;
  companyGroupId: number;
}) => {
  const output = await formPackCompanyGroupRightGetRightPlural({
    api: input.api,
    companyGroupId: input.companyGroupId,
  });

  const activeRights: ActiveRights = {};

  for (const right of output.entities) {
    activeRights[right.formId] = right.right;
  }
  return {activeRights};
};

const updateWithFormValues = async (input: {
  activeRights: ActiveRights;
  api: APIClass;
  companyId: number;
  companyGroupId: number;
  response: MyResponse;
}) => {
  for (const [key, value] of Object.entries(input.activeRights)) {
    const formId = Number(key);

    const existingRight = input.response.activeRights[formId];
    if (!existingRight || existingRight !== value) {
      await createFormPackCompanyGroupRight({
        api: input.api,
        data: {
          formId,
          companyGroupId: input.companyGroupId,
          right: value,
        },
      });
    }
  }
};

export interface AddFormToGroupModalProps extends ModalProps {
  companyGroupId: number;
  companyId: number;
  groupName: string;
}

interface MyResponse {
  activeRights: ActiveRights;
}

export const EditGroupRightsModal = ({
  companyGroupId,
  companyId,
  groupName,
  ...props
}: AddFormToGroupModalProps) => {
  const [response, setResponse] = useState<MyResponse | null | Error>(null);
  const [sending, setSending] = useState(false);
  const api = useApi();
  const [modalForm] = Form.useForm<{[key: number]: Right}>();
  const {forms} = usePackStore();

  useEffect(() => {
    if (!props.open) {
      // because render is called on closed modals ?!
      return;
    }
    if (!api) {
      return;
    }

    const nonNullApi = api;
    async function fetchData() {
      try {
        const allRights = await collectAllRights({
          api: nonNullApi,
          companyId,
          companyGroupId,
        });
        setResponse(allRights);
      } catch (error) {
        console.info('error', error);
        setResponse(error as Error);
      }
    }

    void fetchData();
  }, [api, companyId, companyGroupId, props.open]);

  const ModalContent = () => {
    if (!response) {
      return <Spinner />;
    } else if (response instanceof Error) {
      return <div>Error: {response.message}</div>;
    } else {
      modalForm.setFieldsValue(response.activeRights);
      return (
        <Form form={modalForm} style={formStyle}>
          <Row>
            <Col span={15}>
              <div style={titleStyle}>Nom du formulaire</div>
            </Col>
            <Col span={5}>
              <div style={titleStyle}>Accès</div>
            </Col>
          </Row>
          {forms.map((form) => {
            return (
              <Row
                key={form.id}
                style={{borderBottom: '1px solid #eee', padding: '16px 0'}}
                align="middle"
              >
                <Col span={15}>
                  <Link to={`/creator/?form=${form.id}`}>
                    {form.title as string}
                  </Link>
                </Col>
                <Col span={5}>
                  <Form.Item
                    name={form.id}
                    initialValue={Right.READ_WRITE}
                    style={{marginBottom: 0}}
                  >
                    <Select
                      options={[
                        {value: Right.NONE, label: 'Aucun'},
                        {value: Right.READ, label: 'Lecture'},
                        {value: Right.READ_WRITE, label: 'Écriture'},
                      ]}
                    ></Select>
                  </Form.Item>
                </Col>
              </Row>
            );
          })}
        </Form>
      );
    }
  };

  if (api === null) {
    return null;
  }

  const doOnOk = async () => {
    const activeRights = modalForm.getFieldsValue();
    setSending(true);
    try {
      if (response) {
        if (response instanceof Error) {
          throw response;
        }
        await updateWithFormValues({
          activeRights,
          api,
          companyId,
          companyGroupId,
          response,
        });
      }
    } catch (error) {
      console.error(error);
      void message.error(
        'Une erreur est survenue au moment de mettre à jour les droits, veuillez réessayer ou le signaler.',
      );
    } finally {
      setSending(false);
    }
  };

  return (
    <Modal
      title={`Gérer les droits d'accès de "${groupName}"`}
      open={props.open}
      width={'800px'}
      confirmLoading={sending}
      onOk={async (e) => {
        await doOnOk();
        modalForm.resetFields();
        setResponse(null);
        props.onOk?.(e);
      }}
      onCancel={(e) => {
        modalForm.resetFields();
        setSending(false);
        setResponse(null);
        props.onCancel?.(e);
      }}
    >
      <ModalContent />
    </Modal>
  );
};
