import {Form, Input, Modal} from 'antd';
import {useState} from 'react';

import {createReport} from '../../client/pdf';
import {useApi} from '../../util/auth';

export const CreateReport = ({
  createReportOpen,
  setCreateReportOpen,
  reportCreated,
}: {
  createReportOpen: boolean;
  setCreateReportOpen: (open: boolean) => void;
  reportCreated: (reportId: number) => void;
}) => {
  const [form] = Form.useForm<{
    reportName: string;
    reportDecription?: string;
  }>();
  const api = useApi();
  const [waiting, setWaiting] = useState(false);

  const doCreateReport = async ({
    name,
    description,
  }: {
    name: string;
    description?: string;
  }) => {
    if (api) {
      const output = await createReport(api, {
        name,
        description,
        report: {
          pages: [],
        },
      });
      reportCreated(output.reportId);
    }
  };

  return (
    <Modal
      title="Créer un nouveau rapport"
      open={createReportOpen}
      confirmLoading={waiting}
      onCancel={() => {
        setCreateReportOpen(false);
      }}
      onOk={async () => {
        try {
          setWaiting(true);
          const values = form.getFieldsValue();
          await doCreateReport({
            name: values.reportName,
            description: values.reportDecription,
          });
        } finally {
          setWaiting(false);
          setCreateReportOpen(false);
        }
      }}
    >
      <Form form={form}>
        <Form.Item
          name="reportName"
          label="Nom du rapport"
          required
          rules={[
            {
              validator(_r, value) {
                if (value.trim() === '') {
                  return Promise.reject('Veuillez donner un nom à ce modèle');
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="reportDecription" label="Description">
          <Input.TextArea autoSize />
        </Form.Item>
      </Form>
    </Modal>
  );
};
