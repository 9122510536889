import {Rule, RuleObject} from 'antd/es/form';

const tagRegEx = /^[0-9A-Z_]*$/;

export const formulaTagNormalize = (tag: string) => {
  if (tag) {
    return (tag.at(0) !== '#' ? `#${tag}` : tag).toUpperCase();
  }
};

export const formulaTagsNormalize = (tags: Array<string>) =>
  tags.map((tag) => formulaTagNormalize(tag));

export const formulaTagRules: Array<RuleObject> = [
  {
    validator: async (_rule, tag: string | undefined) => {
      if (tag && tag.length && tag[0] !== '#') {
        throw new Error(`Un tag doit commencer par #: ${tag}`);
      }
    },
  },
  {
    validator: async (_rule, tag: string | undefined) => {
      if (tag && tag.length < 4) {
        throw new Error(
          `Un tag ne doit pas avoir moins de 4 charactères: ${tag}`,
        );
      }
    },
  },
  {
    validator: async (_rule, tag: string | undefined) => {
      if (tag && tag.length > 16) {
        throw new Error(
          `Un tag ne doit pas avoir plus de 16 charactères: ${tag}`,
        );
      }
    },
  },
  {
    validator: async (_rule, tag: string | undefined) => {
      if (tag) {
        const match = tagRegEx.exec(tag.slice(1));
        if (!match) {
          throw new Error(
            `Un tag ne peut contenir que des majuscules, des chiffres et _: ${tag}`,
          );
        }
      }
    },
  },
];

export const optionalTagRules: Array<RuleObject> = [
  {
    required: false,
  },
  ...formulaTagRules,
];

export const formulaTagsRules: Array<Rule> = formulaTagRules.map((rule) => ({
  type: 'array',
  defaultField: rule,
}));
