import {APIClass} from 'aws-amplify';
import {isAxiosError} from 'axios';
import {
  CreateUserSchemaType,
  PostUserCreationByAdminO,
  UpdateUserRequestSchemaType,
  UpdateUserResponseSchemaType,
  UserSchemaType,
} from 'holo-api';
import httpStatus from 'http-status';

import {ConsultantsTableDataType} from '../Consultants';
import {UsersTableDataType} from '../Users';
import {anonApiDel, apiDel, apiGet, apiPost} from '../util/api';
import {
  PaginationQuery,
  queryParameters,
  SearchQuery,
  SortQuery,
} from './queryParameters';

export const preRegisterCompanyUser = async (
  api: APIClass,
  companyId: number,
  input: CreateUserSchemaType,
) =>
  apiPost<number, CreateUserSchemaType>(api, `/user/company/${companyId}`, {
    body: input,
  });

export const userCreationByAdmin = async ({
  api,
  input,
}: {
  api: APIClass;
  input: CreateUserSchemaType;
}) =>
  apiPost<PostUserCreationByAdminO, CreateUserSchemaType>(
    api,
    `/user/creationByAdmin`,
    {
      body: input,
    },
  );

export const getEmailAvailable = async (api: APIClass, email: string) => {
  let available = true;
  let userId = 0;
  try {
    await apiGet<void>(api, `/user/emailAvailable/${email}`);
  } catch (err) {
    if (
      isAxiosError(err) &&
      err.response &&
      err.response.status === httpStatus.CONFLICT
    ) {
      available = false;
      userId = err.response.data.conflictId;
    } else {
      throw err;
    }
  }
  return {
    available,
    userId,
  };
};

export const userPostSingular = async (
  api: APIClass,
  userId: number,
  input: UpdateUserRequestSchemaType,
) =>
  apiPost<number, UpdateUserRequestSchemaType>(api, `/user/${userId}`, {
    body: input,
  });

export const userGetSingular = async (api: APIClass, userId: number) =>
  apiGet<UserSchemaType>(api, `/user/${userId}`);

export const userGetSelf = async (api: APIClass) =>
  apiGet<UserSchemaType>(api, '/user/me');

export const userUpdateSelf = async (
  api: APIClass,
  input: UpdateUserRequestSchemaType,
) =>
  apiPost<UpdateUserResponseSchemaType, UpdateUserRequestSchemaType>(
    api,
    '/user/me',
    {body: input},
  );

export const userUpdate = async (
  api: APIClass,
  input: UpdateUserRequestSchemaType,
  id: number,
) =>
  apiPost<UpdateUserResponseSchemaType, UpdateUserRequestSchemaType>(
    api,
    `/user/${id}`,
    {body: input},
  );

export const deleteUnassignedCognitoUser = ({
  anonymousApi,
  email,
}: {
  anonymousApi: APIClass;
  email: string;
}) =>
  anonApiDel<{count: number}>(
    anonymousApi,
    `/anon/user/unassignedCognitoUser/${email}`,
  );

export const deleteUser = ({api, userId}: {api: APIClass; userId: number}) =>
  apiDel<{count: number}>(api, `/user/${userId}`);

export const userCreateRegister = ({
  api,
  input,
}: {
  api: APIClass;
  input: CreateUserSchemaType;
}) =>
  apiPost<void, CreateUserSchemaType>(api, '/user/register', {
    body: input,
  });

export const resendTempPassword = ({
  api,
  userId,
}: {
  api: APIClass;
  userId: number;
}) =>
  apiPost<string, NonNullable<unknown>>(
    api,
    `/user/${userId}/resendTempPassword`,
    {body: {}},
  );

export const getPluralConsultants = ({
  api,
  sort,
  searchText,
  pagination,
}: {
  api: APIClass;
  sort?: SortQuery;
  searchText?: SearchQuery;
  pagination: PaginationQuery;
}) => {
  const params = queryParameters({sort, searchText, pagination});
  return apiGet<{
    entities: ConsultantsTableDataType[];
    total: number;
  }>(api, '/users/consultants?' + params.toString());
};

export const getPluralUsers = ({
  api,
  sort,
  searchText,
  pagination,
}: {
  api: APIClass;
  sort?: SortQuery;
  searchText?: SearchQuery;
  pagination: PaginationQuery;
}) => {
  const params = queryParameters({sort, searchText, pagination});
  return apiGet<{
    entities: UsersTableDataType[];
    total: number;
  }>(api, '/users?' + params.toString());
};

export const getCognito = ({api}: {api: APIClass}) =>
  apiGet<object>(api, '/cognito');
