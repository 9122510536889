import {
  EyeInvisibleOutlined,
  EyeOutlined,
  PlusSquareOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Collapse,
  Form,
  FormListFieldData,
  Input,
  InputNumber,
  Row,
  Tooltip,
} from 'antd';
import {FormListProps} from 'antd/es/form';
import {SelectAnswer} from 'holo-api';
import {FC} from 'react';

import {useCreatorStore} from '../../../stores/creator';
import {mergeNamePath} from '../../../util/form';
import {emptyUUID} from '../../../util/validator';
import {DeleteButton} from '../DeleteButton';

const AnswerFormListField: FC<{
  field: FormListFieldData;
  chapterKey: number;
  questionKey: number;
  remove: (name: number) => void;
}> = ({field, remove, chapterKey, questionKey}) => {
  const setIsTouched = useCreatorStore((state) => state.setIsTouched);

  const form = Form.useFormInstance();

  const name = mergeNamePath(
    'chapters',
    chapterKey,
    'items',
    questionKey,
    'answers',
    field.name,
    'value',
  );
  const value = Form.useWatch(name, form);

  return (
    <div>
      <Form.Item name="uuid" hidden>
        <Input hidden />
      </Form.Item>
      <Form.Item name="version" hidden>
        <Input hidden />
      </Form.Item>
      <Form.Item label="Réponse" required>
        <Row key={field.key} gutter={8}>
          <Col flex={1}>
            <Form.Item
              name={[field.name, 'label']}
              rules={[{required: true}]}
              noStyle
            >
              <Input.TextArea autoSize />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name={[field.name, 'value']}
              rules={[{required: !value === null}]}
              noStyle
            >
              <InputNumber
                placeholder="Valeur"
                readOnly={value === null}
                wheel={false}
                formatter={
                  (value) =>
                    value === null
                      ? 'N/A'
                      : value
                        ? new Intl.NumberFormat().format(Number(value))
                        : 'N/A' // forced initial value because ant doesn't re-render when changing value undefined -> null
                }
                parser={(value) =>
                  value === '' ? 0 : value!.replace(',', '.')
                }
                addonAfter={
                  <Tooltip
                    title={
                      value === null
                        ? 'Donner une valeur numérique à cette réponse'
                        : 'Ignorer cette valeur dans les calculs'
                    }
                  >
                    <Button
                      type="text"
                      style={{padding: 0}}
                      icon={
                        value === null ? (
                          <EyeInvisibleOutlined />
                        ) : (
                          <EyeOutlined />
                        )
                      }
                      onClick={() => {
                        setIsTouched(true);
                        form.setFieldValue(name, value === null ? 0 : null);
                      }}
                    />
                  </Tooltip>
                }
              />
            </Form.Item>
          </Col>
          <Col>
            <DeleteButton
              onConfirm={() => {
                remove(field.name);
              }}
            />
          </Col>
        </Row>
      </Form.Item>
    </div>
  );
};

export const AnswerFormList: FC<
  Omit<FormListProps, 'children'> & {chapterKey: number; questionKey: number}
> = (props) => {
  return (
    <Form.List {...props}>
      {(fields, {add, remove}, {errors}) => (
        <Collapse
          items={[
            {
              key: 'answers',

              label: 'Réponses possibles',
              extra: (
                <span style={{color: 'red'}}>
                  <Form.ErrorList errors={errors} />
                </span>
              ),
              children: (
                <>
                  {fields.map((field) => (
                    <AnswerFormListField
                      field={field}
                      remove={remove}
                      key={field.key}
                      chapterKey={props.chapterKey}
                      questionKey={props.questionKey}
                    />
                  ))}
                  <Button
                    icon={<PlusSquareOutlined />}
                    type="dashed"
                    onClick={() => {
                      add({
                        uuid: emptyUUID,
                        version: '1.0.0',
                        label: '',
                        value: 0,
                      } as SelectAnswer);
                    }}
                  >
                    Ajouter une réponse
                  </Button>
                </>
              ),
            },
          ]}
        />
      )}
    </Form.List>
  );
};
