import {
  CalculatorOutlined,
  CheckSquareOutlined,
  DragOutlined,
  InfoCircleOutlined,
  LineOutlined,
  QuestionCircleOutlined,
  VerticalAlignTopOutlined,
} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  CheckboxProps,
  Col,
  Divider,
  Form,
  FormListFieldData,
  FormListOperation,
  Input,
  InputNumber,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Tag,
  theme,
  Tooltip,
  Typography,
} from 'antd';
import {FormListProps} from 'antd/es/form';
import {NamePath} from 'antd/es/form/interface';
import {
  CreatorChapter,
  CreatorForm,
  findAllFormulas,
  findChapterFormulaAtIndex,
  Formula,
  isDefaultChapter,
  isQuestion,
  Item,
  ItemType,
  Question,
  QuestionType,
  Separator,
  Title,
} from 'holo-api';
import {FC, PropsWithChildren, useEffect, useMemo, useState} from 'react';

import {
  useInsertCurrentFormulaValue,
  useOnItemChange,
  useOnItemRemove,
} from '../../../Creator';
import {useCreatorStore} from '../../../stores/creator';
import {
  colors,
  highlightItem,
  mergeNamePath,
  verifyFormulaUsedInMainForm,
} from '../../../util/form';
import {
  formulaTagNormalize,
  formulaTagsNormalize,
  formulaTagsRules,
  optionalTagRules,
} from '../../../util/formulaTagRules';
import {parseAndTraverseFormula} from '../../../util/math';
import {emptyUUID} from '../../../util/validator';
import {DeleteButton} from '../DeleteButton';
import {AnswerFormList} from './AnswerFormList';
import {CreatorFormula} from './CreatorFormula';
import {QuestionBurgerDropDown} from './QuestionBurgerDropDown';

export const ScoreTagList = ({fieldName}: {fieldName: number}) => {
  const isPreliminaryForm = useCreatorStore((state) => state.isPreliminaryForm);
  if (isPreliminaryForm) {
    return (
      <Form.Item
        label="Tag"
        name={[fieldName, 'prelimTag']}
        normalize={formulaTagNormalize}
        rules={optionalTagRules}
      >
        <Input placeholder="Vous pouvez choisir un tag si besoin" />
      </Form.Item>
    );
  } else {
    return (
      <>
        <Form.Item
          label="Tags"
          name={[fieldName, 'tags']}
          rules={formulaTagsRules}
          normalize={formulaTagsNormalize}
        >
          <Select
            mode="tags"
            tokenSeparators={[' ', ',']}
            allowClear
            open={false}
            style={{width: '100%'}}
            placeholder="Vous pouvez choisir un ou plusieurs tag(s) si besoin"
          />
        </Form.Item>
        <Form.Item label="Label Rapport" name={[fieldName, 'reportLabel']}>
          <Input />
        </Form.Item>
      </>
    );
  }
};

const ConfirmCheckbox: FC<CheckboxProps & {itemReference: string}> = ({
  itemReference,
  children,
  onChange,
  ...props
}) => {
  const form = Form.useFormInstance();
  const onItemRemove = useOnItemRemove(form);

  return (
    <>
      <Checkbox
        {...props}
        onChange={(e) => {
          if (e.target.checked === false) {
            onItemRemove(itemReference, () => {
              onChange?.(e);
            });
          } else {
            onChange?.(e);
          }
        }}
      >
        {children}
      </Checkbox>
    </>
  );
};

const AddWrapper: FC<
  PropsWithChildren<{chapterKey: number; add: FormListOperation['add']}>
> = ({chapterKey, add, children}) => {
  const setChapterAdd = useCreatorStore((state) => state.setChapterAdd);

  useEffect(() => {
    setChapterAdd(chapterKey, add);
  }, [add, chapterKey, setChapterAdd]);

  return <div>{children}</div>;
};

interface Props {
  prefixName: NamePath;
  chapterIndex: number;
  chapterKey: number;
  name: [number, string];
  field: FormListFieldData;
  remove: (name: number) => void;
  move: (from: number, to: number) => void;
}

const QuestionFormListField: FC<Props> = ({
  field,
  remove,
  move,
  prefixName,
  chapterIndex,
  chapterKey,
  ...props
}) => {
  const {token} = theme.useToken();
  const initialForm = useCreatorStore((state) => state.initialForm);

  const [currentChapterFormula, setCurrentChapterFormula] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    if (!initialForm) {
      return;
    }
    const chapterFormula = findChapterFormulaAtIndex(initialForm, chapterIndex);
    if (!chapterFormula) {
      return;
    }
    setCurrentChapterFormula(chapterFormula.title);
  }, [initialForm, setCurrentChapterFormula, chapterIndex]);

  const setCurrentFormFormula = useCreatorStore(
    (state) => state.setCurrentFormFormula,
  );

  const chapterAdd = useCreatorStore((state) => state.chapterAdd);

  const form = Form.useFormInstance();
  const templateFormulasMapping = useCreatorStore(
    (state) => state.templateFormulasMapping,
  );
  const mainForm = useCreatorStore((state) => state.mainForm);
  const allMainFormFormulas = useMemo(
    () => (mainForm ? findAllFormulas(mainForm?.contents) : []),
    [mainForm],
  );
  const [targetChapterForm] = Form.useForm();

  const onItemRemove = useOnItemRemove(form);
  const onItemChange = useOnItemChange(form);
  const insertCurrentFormulaValue = useInsertCurrentFormulaValue(form);

  const setFormulasToFix = useCreatorStore((state) => state.setFormulasToFix);

  const name = mergeNamePath('chapters', props.name, field.name);
  const currentItem = form.getFieldValue(name) as Item;
  const isTemplate = form.getFieldValue('patent');

  const numeric = Form.useWatch(mergeNamePath(name, 'numeric'), {form});
  const questionDisabled = Form.useWatch(mergeNamePath(name, 'disabled'), {
    form,
  });

  const itemKeyName = mergeNamePath('chapters', props.name, [
    field.name,
    'itemKey',
  ]);
  const currentChapter = form.getFieldValue(
    mergeNamePath('chapters', chapterKey),
  );
  const currentChapterItems = currentChapter?.items;
  const [indexInputValue, setIndexInputValue] = useState<number | null>(
    field.name + 1,
  );
  const errors = useCreatorStore((state) => state.errors);
  const setErrors = useCreatorStore((state) => state.setErrors);

  useEffect(() => {
    form.setFieldValue(itemKeyName, field.key);
  }, [form, field.key, itemKeyName]);

  let fieldReference = '';
  const identifier = `C${chapterKey}I${field.key}`;

  if (currentItem.type === ItemType.QUESTION) {
    if (currentItem.questionType === QuestionType.SELECT) {
      fieldReference = `C${chapterKey + 1}S${field.key + 1}`;
    }
    if (currentItem.questionType === QuestionType.TEXT) {
      fieldReference = `C${chapterKey + 1}Q${field.key + 1}`;
    }
  }
  if (currentItem.type === ItemType.FORMULA) {
    fieldReference = `C${chapterKey + 1}F${field.key + 1}`;
  }

  const valueIsQuestion = useMemo(() => isQuestion(currentItem), [currentItem]);
  const questionType = useMemo(
    () => isQuestion(currentItem) && currentItem.questionType,
    [currentItem],
  );

  useEffect(() => {
    setIndexInputValue(field.name + 1);
  }, [field.name]);

  const moveItem = (previous: number): void => {
    const value = Number(indexInputValue);
    if (isNaN(value)) {
      notification.error({message: 'Il faut renseigner un nombre'});
      setIndexInputValue(previous + 1);
      return;
    }
    if (value < 1) {
      notification.error({
        message: 'La position doit être supérieure ou égale à 1',
      });
      setIndexInputValue(previous + 1);
      return;
    }
    let index = Number(indexInputValue);
    if (index > currentChapterItems.length) {
      index = currentChapterItems.length;
      setIndexInputValue(index);
    }
    move(field.name, index - 1);
    setTimeout(() => {
      highlightItem(identifier, {scrollIntoView: true});
    }, 1);
  };

  const notNumeric =
    currentItem.type === ItemType.QUESTION &&
    currentItem.questionType === QuestionType.TEXT &&
    !numeric;

  const [modal, contextHolder] = Modal.useModal();

  return (
    <div
      id={identifier}
      key={identifier}
      style={{
        backgroundColor: token.colorBgLayout,
        padding: token.padding,
        borderRadius: token.borderRadius,
        marginBottom: token.margin,
        position: 'relative',
      }}
    >
      {contextHolder}

      <Space
        style={{
          position: 'absolute',
          top: '8px',
          right: '8px',
          zIndex: 1,
        }}
      >
        <QuestionBurgerDropDown
          chapterIdx={props.name[0]}
          itemIdx={field.name}
        ></QuestionBurgerDropDown>
        <Typography.Text type="secondary">Ordre :</Typography.Text>
        <InputNumber
          controls={true}
          value={indexInputValue}
          wheel={false}
          onChange={(value) => {
            setIndexInputValue(value);
          }}
          onPressEnter={() => {
            moveItem(field.name);
          }}
          onBlur={() => {
            moveItem(field.name);
          }}
        />
        <Tooltip title="Déplacer cet élément vers un autre chapitre de ce formulaire ?">
          <Button
            onClick={() => {
              const targetChapters = (
                form.getFieldValue('chapters') as CreatorChapter[]
              ).filter(
                (targetChapter) =>
                  isDefaultChapter(targetChapter) &&
                  targetChapter.chapterKey !== currentChapter.chapterKey,
              );
              const targetChapterModal = modal.confirm({
                width: '50%',
                title: `Déplacer l'élément "${currentItem.type === ItemType.SEPARATOR ? '' : currentItem.title}" vers un autre chapitre`,
                onCancel: () => {
                  targetChapterForm.resetFields();
                  targetChapterModal.destroy();
                },
                onOk: () => {
                  const {targetChapterKey} = targetChapterForm.getFieldsValue();
                  if (!chapterAdd || !chapterAdd[targetChapterKey]) {
                    return;
                  }
                  chapterAdd[targetChapterKey](currentItem);
                  setTimeout(() => {
                    const formData: CreatorForm = form.getFieldsValue();
                    const targetChapter = formData.chapters.find(
                      (chapter) => chapter.chapterKey === targetChapterKey,
                    );
                    if (!targetChapter) {
                      return;
                    }
                    const movedItem =
                      targetChapter.items[targetChapter.items.length - 1];
                    formData.chapters.forEach((chapter, chapterIndex) => {
                      if (!isDefaultChapter(chapter)) {
                        return;
                      }
                      chapter.items.forEach((item, itemIndex) => {
                        if (
                          item.type === ItemType.FORMULA &&
                          item.formula.includes(fieldReference)
                        ) {
                          let editedFormula = '';
                          parseAndTraverseFormula(item.formula, (child) => {
                            const text = child.textContent;
                            if (text !== fieldReference) {
                              editedFormula += text;
                            } else {
                              if (currentItem.type === ItemType.QUESTION) {
                                if (
                                  currentItem.questionType ===
                                  QuestionType.SELECT
                                ) {
                                  editedFormula += `C${targetChapterKey + 1}S${movedItem.itemKey! + 1}`;
                                }
                                if (
                                  currentItem.questionType === QuestionType.TEXT
                                ) {
                                  editedFormula += `C${targetChapterKey + 1}Q${movedItem.itemKey! + 1}`;
                                }
                              }
                              if (currentItem.type === ItemType.FORMULA) {
                                editedFormula += `C${targetChapterKey + 1}F${movedItem.itemKey! + 1}`;
                              }
                            }
                          });
                          const name = [
                            'chapters',
                            chapterIndex,
                            'items',
                            itemIndex,
                            'formula',
                          ];
                          form.setFieldValue(name, editedFormula);
                        }
                      });
                      onItemChange();
                      highlightItem(
                        `C${targetChapterKey}I${movedItem.itemKey}`,
                        {scrollIntoView: true},
                      );
                    }),
                      500;
                  });
                  remove(field.name);
                  const newErrors = errors.filter(
                    (error) =>
                      JSON.stringify(error.name.slice(0, 4)) !==
                      JSON.stringify(name),
                  );
                  setErrors(newErrors);
                  targetChapterModal.destroy();
                },
                content: (
                  <Form form={targetChapterForm}>
                    <Form.Item name="targetChapterKey">
                      <Select
                        options={targetChapters.map((targetChapter) => ({
                          label: targetChapter.title,
                          value: targetChapter.chapterKey,
                        }))}
                      />
                    </Form.Item>
                  </Form>
                ),
              });
            }}
            icon={<DragOutlined />}
            type="default"
          />
          {contextHolder}
        </Tooltip>
        <Tooltip title="Revenir en haut du chapitre">
          <Button
            onClick={() => {
              const chapter = document.getElementById(`C${chapterKey}`);
              chapter?.scrollIntoView({behavior: 'smooth', block: 'center'});
            }}
            icon={<VerticalAlignTopOutlined />}
            type="default"
          />
        </Tooltip>
      </Space>
      <Form.Item name="uuid" hidden>
        <Input hidden />
      </Form.Item>
      <Form.Item name="version" hidden>
        <Input hidden />
      </Form.Item>
      <Form.Item name={[field.name, 'itemKey']} preserve hidden>
        <Input hidden />
      </Form.Item>
      <Form.Item name={[field.name, 'disabled']} preserve hidden>
        <Input hidden />
      </Form.Item>
      <Form.Item name={[field.name, 'prefill']} preserve hidden>
        <Input hidden />
      </Form.Item>
      <Row
        justify="space-between"
        align="middle"
        gutter={32}
        style={{
          marginBottom: 8,
        }}
      >
        <Col>
          <Space>
            <Typography.Text>
              {currentItem.type !== ItemType.SEPARATOR ? (
                <span style={{color: 'red'}}>* </span>
              ) : null}
              {valueIsQuestion
                ? questionType === QuestionType.TEXT
                  ? 'Question'
                  : 'Sélection'
                : currentItem.type === ItemType.SEPARATOR
                  ? 'Titre du séparateur'
                  : currentItem.type === ItemType.TITLE
                    ? 'Libellé du titre'
                    : 'Titre de la formule'}
            </Typography.Text>
            {currentItem.type !== ItemType.SEPARATOR &&
            currentItem.type !== ItemType.TITLE ? (
              <Tag
                color={
                  notNumeric
                    ? 'grey'
                    : colors[(chapterKey + field.key) % colors.length]
                }
                style={{
                  cursor: notNumeric ? 'not-allowed' : 'pointer',
                  opacity: notNumeric ? 0.2 : 1,
                }}
                onClick={() => {
                  if (notNumeric) {
                    return;
                  }
                  insertCurrentFormulaValue(fieldReference);
                }}
              >
                {fieldReference}
              </Tag>
            ) : null}
            {currentItem.type === ItemType.QUESTION &&
              currentItem.questionType === QuestionType.TEXT && (
                <>
                  <Form.Item
                    name={[field.name, 'numeric']}
                    valuePropName="checked"
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    <ConfirmCheckbox itemReference={fieldReference}>
                      Réponse numérique
                    </ConfirmCheckbox>
                  </Form.Item>
                </>
              )}
          </Space>
        </Col>
      </Row>
      <Row gutter={8} style={{marginBottom: 24}}>
        <Col flex={1}>
          <Form.Item
            name={[
              field.name,
              currentItem.type === ItemType.SEPARATOR ? 'separator' : 'title',
            ]}
            rules={
              currentItem.type !== ItemType.SEPARATOR
                ? [{required: true}]
                : undefined
            }
            style={{
              marginBottom: 0,
            }}
          >
            <Input onBlur={onItemChange} />
          </Form.Item>
        </Col>
        {currentItem.type === ItemType.QUESTION && (
          <Col>
            <Form.Item
              name={[field.name, 'optional']}
              valuePropName="checked"
              style={{
                marginBottom: 0,
              }}
            >
              <Checkbox>Réponse optionnelle</Checkbox>
            </Form.Item>
          </Col>
        )}
        <Col>
          <DeleteButton
            disabled={
              currentItem.type === ItemType.QUESTION && !!currentItem.prefill
            }
            onConfirm={() => {
              if (currentItem.type === ItemType.FORMULA) {
                if (currentItem.formulaType === 'form') {
                  setCurrentFormFormula(undefined);
                }
                if (currentItem.formulaType === 'chapter') {
                  setCurrentChapterFormula(undefined);
                }
                if (currentItem.usedInMainForm) {
                  const formulas = verifyFormulaUsedInMainForm(
                    [currentItem.uuid],
                    allMainFormFormulas,
                    templateFormulasMapping,
                  );
                  setFormulasToFix(formulas);
                }
              }
              onItemRemove(fieldReference, () => {
                remove(field.name);
                const newErrors = errors.filter(
                  (error) =>
                    JSON.stringify(error.name.slice(0, 4)) !==
                    JSON.stringify(name),
                );
                setErrors(newErrors);
                onItemChange();
              });
            }}
          />
        </Col>
      </Row>
      {currentItem.type === ItemType.QUESTION && (
        <>
          <Form.Item
            name={[field.name, 'placeholder']}
            label="Exemple de réponse"
          >
            <Input.TextArea
              autoSize
              disabled={questionDisabled}
              placeholder='Par défaut : "Veuillez sélectionner une réponse"'
            />
          </Form.Item>
          <Form.Item name={[field.name, 'tooltip']} label="Info-bulle">
            <Input.TextArea autoSize />
          </Form.Item>
          <Form.Item
            name={[field.name, 'proofFactor']}
            label="Facteur de preuve"
          >
            <Input.TextArea autoSize />
          </Form.Item>
          {!isTemplate && !currentItem.prefill && (
            <ScoreTagList fieldName={field.name}></ScoreTagList>
          )}
        </>
      )}
      {currentItem.type === ItemType.FORMULA && (
        <CreatorFormula
          prefixName={prefixName}
          field={field}
          chapterIndex={chapterIndex}
          chapterKey={chapterKey}
          name={props.name}
          currentChapterFormula={currentChapterFormula}
          setCurrentChapterFormula={setCurrentChapterFormula}
          setCurrentFormFormula={setCurrentFormFormula}
          value={currentItem}
          isTemplate={isTemplate}
        />
      )}
      {isQuestion(currentItem) &&
        currentItem.questionType === QuestionType.SELECT && (
          <>
            <Divider />
            <Form.Item
              name={[field.name, 'multiple']}
              required
              label="Nombre maximum de réponses attendues"
              rules={[
                {
                  validator: async (_, value) => {
                    if (
                      isNaN(Number(value)) ||
                      value === '' ||
                      Number(value) < 1
                    ) {
                      return Promise.reject(
                        'Veuillez renseigner un nombre supérieur à 0',
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <InputNumber min={1} wheel={false} />
            </Form.Item>
            <AnswerFormList
              name={[field.name, 'answers']}
              chapterKey={chapterKey}
              questionKey={field.name}
              rules={[
                {
                  validator: async (_, answers) => {
                    if (!answers || answers.length === 0) {
                      return Promise.reject(
                        'Il faut renseigner au moins une réponse !',
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            />
          </>
        )}
    </div>
  );
};

export const QuestionFormList: FC<
  Omit<FormListProps, 'children'> & {
    prefixName: NamePath;
    name: [number, string];
    chapterIndex: number;
    chapterKey: number;
  }
> = ({prefixName, chapterIndex, chapterKey, ...props}) => {
  const form = Form.useFormInstance();
  const onItemChange = useOnItemChange(form);

  return (
    <Form.List {...props}>
      {(fields, {add, move, remove}) => (
        <AddWrapper add={add} chapterKey={chapterKey}>
          <>
            {fields.map((field) => {
              return (
                <QuestionFormListField
                  key={field.key}
                  name={props.name}
                  field={field}
                  remove={remove}
                  move={move}
                  prefixName={prefixName}
                  chapterIndex={chapterIndex}
                  chapterKey={chapterKey}
                />
              );
            })}
            <Button.Group>
              <Button
                icon={<CheckSquareOutlined />}
                type="dashed"
                onClick={() => {
                  add({
                    uuid: emptyUUID,
                    version: '1.0.0',
                    title: '',
                    placeholder: '',
                    optional: false,
                    multiple: 1,
                    questionType: QuestionType.SELECT,
                    answers: [],
                    type: ItemType.QUESTION,
                  } as Question);
                }}
              >
                Ajouter une sélection
              </Button>
              <Button
                icon={<QuestionCircleOutlined />}
                type="dashed"
                onClick={() => {
                  add({
                    uuid: emptyUUID,
                    version: '1.0.0',
                    title: '',
                    placeholder: '',
                    optional: false,
                    numeric: false,
                    questionType: QuestionType.TEXT,
                    type: ItemType.QUESTION,
                  } as Question);
                }}
              >
                Ajouter une question
              </Button>
              <Button
                icon={<CalculatorOutlined />}
                type="dashed"
                onClick={() => {
                  add({
                    uuid: emptyUUID,
                    version: '1.0.0',
                    formula: '',
                    title: '',
                    tooltip: '',
                    usedInMainForm: false,
                    type: ItemType.FORMULA,
                    formulaType: 'default',
                  } as Formula);
                  onItemChange();
                }}
              >
                Ajouter une formule
              </Button>
              <Button
                icon={<LineOutlined />}
                type="dashed"
                onClick={() => {
                  add({
                    uuid: emptyUUID,
                    version: '1.0.0',
                    separator: '',
                    type: ItemType.SEPARATOR,
                  } as Separator);
                }}
              >
                Ajouter un séparateur
              </Button>
              <Button
                icon={<InfoCircleOutlined />}
                type="dashed"
                onClick={() => {
                  add({
                    uuid: emptyUUID,
                    version: '1.0.0',
                    title: 'Sans titre',
                    type: ItemType.TITLE,
                  } as Title);
                }}
              >
                Ajouter un titre
              </Button>
            </Button.Group>
          </>
        </AddWrapper>
      )}
    </Form.List>
  );
};
