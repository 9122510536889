import {CaretRightOutlined} from '@ant-design/icons';
import {Collapse, Divider, Form, theme, Typography} from 'antd';
import {
  AnswerMatrixT,
  Chapter,
  DefaultChapter,
  evaluateFormulas,
  findChapterFormulaAtIndex,
  FormContentsType,
  isAnswerToTextQuestion,
  isSelectQuestion,
  isSeparatorChapter,
  isTextQuestion,
  isTitleChapter,
  Item,
  ItemType,
  Role,
  SelectQuestion,
  SingleUserAnswer,
  TextQuestion,
  UserAnswerText,
} from 'holo-api';
import {FC, useCallback, useMemo, useState} from 'react';

import {useMatrixStore} from '../../../stores/matrix';
import {useUserStore} from '../../../stores/user';
import {useViewerStore} from '../../../stores/viewer';
import {useUserRole} from '../../../util/auth';
import {highlightItem, isAnswerValid} from '../../../util/form';
import {HistoryModal, HistoryModalParams} from '../modals/HistoryModal';
import {ChapterProgress} from './ChapterProgress';
import {ChapterWideNoAnswer} from './ChapterWideNoAnswer';
import {ConsultantComment} from './ConsultantComment';
import {ViewerChapter} from './ViewerChapter';
import {ViewerFormula} from './ViewerFormula';
import {ViewerNumericInput} from './ViewerNumericInput';
import {ViewerSelectInput} from './ViewerSelectInput';
import {ViewerSeparatorItem} from './ViewerSeparatorItem';
import {ViewerTextInput} from './ViewerTextInput';
import {ViewerTitleItem} from './ViewerTitleItem';

interface ViewerFormViewProps {
  viewAsUser: boolean;
  formId: number;
  isTemplate: boolean;
  setAnswersSending: (sending: boolean) => void;
}

type OnHistoryInput = {selectOrTextQuestion: SelectQuestion | TextQuestion};

const panelStyle = {
  marginBottom: '24px',
  border: '1px solid #dddddd',
  backgroundColor: '#eeeeee',
  borderRadius: '8px',
};

type HistoryModalData =
  | {
      isOpen: true;
      params: HistoryModalParams;
    }
  | {isOpen: false};

const GeneratedItems: FC<{
  chapter: DefaultChapter;
  index: number;
  viewAsUser: boolean;
  formId: number;
  templateInstanceId: number | undefined;
  companyId: number | undefined;
  onHistory: (
    input: OnHistoryInput,
    index: number,
    formId: number,
    companyId: number | undefined,
    templateInstanceId: number | undefined,
  ) => void;
}> = ({
  chapter,
  index: chapterIdx,
  viewAsUser,
  formId,
  templateInstanceId,
  companyId,
  onHistory,
}) => {
  const generatedItems = useMemo(
    () =>
      chapter.items.map((props: Item, index) => {
        switch (props.type) {
          case ItemType.QUESTION:
            if (isSelectQuestion(props)) {
              return (
                <ViewerSelectInput
                  {...props}
                  key={props.uuid}
                  optional={chapter.answeringIsOptional || props.optional}
                  chapterIdx={chapterIdx}
                  viewAsUser={viewAsUser}
                  id={index}
                  onHistory={() =>
                    onHistory(
                      {selectOrTextQuestion: props},
                      index,
                      formId,
                      companyId,
                      templateInstanceId,
                    )
                  }
                />
              );
            } else if (isTextQuestion(props)) {
              if (props.numeric) {
                return (
                  <ViewerNumericInput
                    {...props}
                    key={props.uuid}
                    optional={chapter.answeringIsOptional || props.optional}
                    id={index}
                    chapterIdx={chapterIdx}
                    viewAsUser={viewAsUser}
                    onHistory={() =>
                      onHistory(
                        {selectOrTextQuestion: props},
                        index,
                        formId,
                        companyId,
                        templateInstanceId,
                      )
                    }
                  />
                );
              } else {
                return (
                  <ViewerTextInput
                    {...props}
                    key={props.uuid}
                    optional={chapter.answeringIsOptional || props.optional}
                    id={index}
                    chapterIdx={chapterIdx}
                    viewAsUser={viewAsUser}
                    onHistory={() =>
                      onHistory(
                        {selectOrTextQuestion: props},
                        index,
                        formId,
                        companyId,
                        templateInstanceId,
                      )
                    }
                  />
                );
              }
            } else {
              throw new Error('Les questions sont invalides');
            }
          case ItemType.FORMULA:
            return (
              <ViewerFormula
                {...props}
                chapterIdx={chapterIdx}
                id={index}
                templateInstanceId={templateInstanceId}
                formId={formId}
                key={props.uuid}
                viewAsUser={viewAsUser}
              />
            );
          case ItemType.TITLE:
            return <ViewerTitleItem {...props} key={props.uuid} />;
          case ItemType.SEPARATOR:
            return <ViewerSeparatorItem {...props} key={props.uuid} />;
          default:
            throw new Error('Item type incorrect');
        }
      }),
    [
      chapter,
      companyId,
      formId,
      chapterIdx,
      onHistory,
      templateInstanceId,
      viewAsUser,
    ],
  );

  return <div key="items">{generatedItems}</div>;
};
export const ViewerFormView: FC<ViewerFormViewProps> = ({
  isTemplate,
  formId,
  viewAsUser,
  setAnswersSending,
}) => {
  const {token} = theme.useToken();
  const userRole = useUserRole();
  const isAdmin = userRole === Role.ADMIN;
  const isConsultant = userRole === Role.CONSULTANT;
  const canViewChapterNote = isAdmin || isConsultant;
  const {companyId} = useUserStore();

  const [historyModalData, setHistoryModalData] = useState<HistoryModalData>({
    isOpen: false,
  });

  const form = useViewerStore((state) => state.form);
  const chapters = useMemo(() => {
    if (!form?.chapters) {
      return [] as DefaultChapter[];
    }

    return form.chapters;
  }, [form]);
  const activeKeys = useViewerStore((state) => state.activeKeys);
  const setActiveKeys = useViewerStore((state) => state.setActiveKeys);
  const formulaValues = useViewerStore((state) => state.formulaValues);
  const updateAnswersCount = useViewerStore(
    (state) => state.updateAnswersCount,
  );
  const templateFormulasResults = useViewerStore(
    (state) => state.templateFormulasResults,
  );
  const updatePendingUserAnswers = useViewerStore(
    (state) => state.updatePendingUserAnswers,
  );
  const validatedChapters = useViewerStore((state) => state.validatedChapters);
  const isPreliminaryForm = useViewerStore((state) => state.isPreliminaryForm);
  const templateInstanceId = useViewerStore(
    (state) => state.templateInstanceId,
  );
  const updateValidatedChapters = useViewerStore(
    (state) => state.updateValidatedChapters,
  );
  const formAnswers = useViewerStore((state) => state.formAnswers);
  const setFormulaValues = useViewerStore((state) => state.setFormulaValues);
  const {matrix1d, matrix2d} = useMatrixStore();
  const allMatrices = useMemo(
    () =>
      [...matrix1d, ...matrix2d].map((m) => {
        const am: AnswerMatrixT = {
          id: m.id,
          name: m.name,
          description: m.description ? m.description : '',
          mat2d: m.mat2d,
          matrixND: m,
        };
        return am;
      }),
    [matrix1d, matrix2d],
  );
  const onHistory = useCallback(
    (
      input: OnHistoryInput,
      index: number,
      formId: number,
      companyId: number | undefined,
      templateInstanceId: number | undefined,
    ) =>
      setHistoryModalData({
        isOpen: true,
        params: {
          selectOrTextQuestion: input.selectOrTextQuestion,
          chapterIdx: index,
          formId,
          companyId,
          templateInstanceId,
        },
      }),
    [],
  );

  return (
    <>
      <HistoryModal
        open={historyModalData.isOpen}
        params={historyModalData.isOpen ? historyModalData.params : null}
        onOk={() => {
          setHistoryModalData({isOpen: false});
        }}
        onCancel={() => {
          setHistoryModalData({isOpen: false});
        }}
      />
      <Collapse
        activeKey={activeKeys}
        onChange={(key) => {
          setActiveKeys(Array.isArray(key) ? key : [key]);
        }}
        bordered={false}
        expandIcon={({isActive}) => (
          <CaretRightOutlined
            rotate={isActive ? 90 : 0}
            style={{marginTop: 10}}
          />
        )}
        style={{
          background: token.colorBgContainer,
          marginTop: isTemplate ? undefined : 16,
        }}
        items={chapters.map((chapter: Chapter, index: number) => {
          if (!form || (isTemplate && !templateInstanceId)) {
            return {};
          }
          if (isTitleChapter(chapter)) {
            return {
              showArrow: false,
              label: (
                <Typography.Title
                  level={3}
                  key={index}
                  style={{marginTop: 0, textAlign: 'center'}}
                >
                  {chapter.title}
                </Typography.Title>
              ),
            };
          }
          if (isSeparatorChapter(chapter)) {
            return {
              showArrow: false,
              label: <Divider key={index}>{chapter.title ?? ''}</Divider>,
            };
          }
          const currentId =
            isTemplate && templateInstanceId ? templateInstanceId : formId;
          const chapterFormula = findChapterFormulaAtIndex(form, index);
          let chapterFormulaResult = undefined;
          if (!chapterFormula) {
            chapterFormulaResult = 'Aucune formule de chapitre définie';
          }
          if (
            chapterFormula &&
            chapterFormula.uuid &&
            formulaValues[currentId] &&
            Object.hasOwn(formulaValues[currentId], chapterFormula.uuid)
          ) {
            chapterFormulaResult =
              formulaValues[currentId][chapterFormula.uuid].result;
          }

          return {
            label: (
              <Typography.Text
                ellipsis
                style={{
                  maxWidth: '60vw',
                  fontWeight: 'bold',
                  fontSize: '1.5em',
                }}
              >
                {chapter.title}
              </Typography.Text>
            ),
            key: chapter.uuid,
            forceRender: true,
            style: {
              ...panelStyle,
              boxShadow: `0 0 5px ${form?.color ?? '#eee'}40`,
              border: `2px solid ${form?.color ?? '#eee'}40`,
            },
            extra:
              !canViewChapterNote || (canViewChapterNote && viewAsUser) ? (
                <ChapterProgress chapter={chapter} />
              ) : (
                <Typography>
                  {isPreliminaryForm
                    ? ''
                    : chapterFormulaResult ?? 'Note indisponible'}
                </Typography>
              ),
            children: (
              <ViewerChapter
                uuid={chapter.uuid}
                onValuesChange={(changed, values) => {
                  if (!formId || (isTemplate && !templateInstanceId)) {
                    return;
                  }
                  setAnswersSending(true);
                  Object.entries(changed).forEach(([_k, value]) => {
                    if (isAnswerToTextQuestion(value as SingleUserAnswer)) {
                      const v = value as UserAnswerText;
                      if (v.text === null) {
                        v.text = '';
                      }
                    }
                  });
                  const currentCount = Object.values(values).filter((value) =>
                    isAnswerValid(value as SingleUserAnswer),
                  ).length;
                  updateAnswersCount({
                    [chapter.uuid]: currentCount,
                  });
                  const pendingAnswers = {...changed};
                  if (validatedChapters[chapter.uuid] === true) {
                    pendingAnswers[chapter.uuid] = {validated: false};
                    updateValidatedChapters({
                      [chapter.uuid]: false,
                    });
                  }
                  updatePendingUserAnswers(pendingAnswers);
                  const newFormulaValues = evaluateFormulas(
                    form,
                    {...formAnswers, ...values},
                    templateFormulasResults,
                    allMatrices,
                  );
                  const currentFormulaValues =
                    formulaValues[
                      isTemplate && templateInstanceId
                        ? templateInstanceId
                        : formId
                    ];
                  const formulaResultsChanged = Object.entries(
                    currentFormulaValues,
                  ).some(
                    ([key, value]) =>
                      newFormulaValues[key].result !== value.result,
                  );

                  if (formulaResultsChanged) {
                    formulaValues[
                      isTemplate && templateInstanceId
                        ? templateInstanceId
                        : formId
                    ] = newFormulaValues;

                    setFormulaValues(formulaValues);
                  }
                }}
                onFinish={() => {
                  updateValidatedChapters({
                    [chapter.uuid]: true,
                  });
                  updatePendingUserAnswers({
                    [chapter.uuid]: {validated: true},
                  });
                  const nextChapter = (form as FormContentsType).chapters[
                    index + 1
                  ];
                  if (nextChapter) {
                    setActiveKeys([
                      ...activeKeys.filter((key) => key !== chapter.uuid),
                      nextChapter.uuid,
                    ]);
                    setTimeout(() => {
                      highlightItem(`C${index + 1}I0`, {
                        scrollIntoView: true,
                      });
                    }, 500);
                  } else {
                    setActiveKeys([]);
                  }
                }}
              >
                <div>
                  {chapter.answeringIsOptional && (
                    <ChapterWideNoAnswer
                      formId={
                        isTemplate && templateInstanceId
                          ? templateInstanceId
                          : formId
                      }
                      isTemplate={isTemplate}
                      chapter={chapter}
                    />
                  )}
                  <GeneratedItems
                    key="generated-items"
                    chapter={chapter}
                    index={index}
                    viewAsUser={viewAsUser}
                    formId={formId}
                    templateInstanceId={templateInstanceId}
                    companyId={companyId}
                    onHistory={onHistory}
                  />
                </div>
              </ViewerChapter>
            ),
          };
        })}
      />
      {form && !isPreliminaryForm && (
        <Form>
          <ConsultantComment
            uuid={form.uuid}
            outsideFormContext={true}
            collapseTitle="Recommandations du consultant"
          />
        </Form>
      )}
    </>
  );
};
