import {MinusOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, Flex, Form, FormInstance, InputNumber, Popconfirm} from 'antd';
import {Matrix1D, MatrixValue} from 'holo-api';

import {defaultMatrixTableCellStyle, MatrixShared} from './MatrixShared';

export const FormMat1D = ({
  form1D,
  cols1D,
  setDirty,
}: {
  form1D: FormInstance<Matrix1D>;
  cols1D: Array<MatrixValue>;
  setDirty: (dirty: boolean) => void;
}) => {
  return (
    <Form
      initialValues={{
        name: '',
        description: '',
        mat2d: false,
        cols: [{max: 0, value: 0}],
      }}
      layout="vertical"
      form={form1D}
      onValuesChange={() => setDirty(true)}
    >
      <MatrixShared></MatrixShared>
      <Flex>
        <table style={{borderCollapse: 'collapse'}}>
          <thead>
            <tr>
              <th style={defaultMatrixTableCellStyle}>Maximum</th>
              {cols1D?.map((_col, index) => (
                <th style={defaultMatrixTableCellStyle} key={index}>
                  <Form.Item
                    noStyle
                    name={['cols', index, 'max']}
                    rules={[
                      {
                        validator: (_r, value) => {
                          if (typeof value !== 'number') {
                            return Promise.reject();
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <InputNumber
                      style={{width: '100px'}}
                      controls={false}
                      variant="borderless"
                      wheel={false}
                    />
                  </Form.Item>
                  <Popconfirm
                    title={`Suppression de la colonne`}
                    description="Êtes-vous sûr de vouloir supprimer cette colonne ?"
                    onConfirm={() => {
                      const formData = form1D.getFieldsValue();
                      formData.cols.splice(index, 1);
                      form1D.setFieldsValue(formData);
                    }}
                  >
                    <Button
                      type="default"
                      size="small"
                      icon={<MinusOutlined />}
                    />
                  </Popconfirm>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{...defaultMatrixTableCellStyle, textAlign: 'center'}}>
                Valeur
              </td>
              {cols1D?.map((_col, index) => (
                <td style={defaultMatrixTableCellStyle} key={index}>
                  <Form.Item
                    noStyle
                    name={['cols', index, 'value']}
                    rules={[
                      {
                        validator: (_r, value) => {
                          if (typeof value !== 'number') {
                            return Promise.reject();
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <InputNumber
                      variant="borderless"
                      controls={false}
                      wheel={false}
                    />
                  </Form.Item>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
        <Button
          type="dashed"
          style={{marginLeft: 8}}
          onClick={() => {
            const formData = form1D.getFieldsValue();
            if (!formData.cols) {
              formData.cols = [];
            }
            formData.cols.push({max: 0, value: 0});
            form1D.setFieldsValue(formData);
          }}
          icon={<PlusOutlined />}
        />
      </Flex>
    </Form>
  );
};
